import { Component, OnInit } from '@angular/core';
import { RouteService } from '../../../core/config/route.service';
import { UserQuery } from '../../../domain/user/user.query';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';

@Component({
  selector: 'app-backend-sidenav',
  templateUrl: './backend-sidenav.component.html',
  styleUrls: ['./backend-sidenav.component.scss']
})
export class BackendSidenavComponent implements OnInit {
  constructor(
    private userQuery: UserQuery,
    private routeService: RouteService,
    private authService: AuthenticationService
  ) { }
  isUsersCollapsed = true;
  isSettingsCollapsed = true;
  name: string;
  profilePic = '';
  googleImageUrl: string;

  adminBList = '';
  adminUList = '';
  adminUAdd = '';
  adminBReport = '';
  adminMOAT = '';
	adminAnnouncements = '';
  ngOnInit() {
    this.userQuery.userInfo$.subscribe(x => {
      this.name = x.FirstName + ' ' + x.LastName;
      this.googleImageUrl = this.authService.user
        ? this.authService.user.picture
        : '';
    });
    this.userQuery.profilePic$.subscribe(x => (this.profilePic = x));
    this.routeService.RouteChange$.subscribe(x => {
      this.adminBList = x.adminBusinessList;
      this.adminUList = x.adminUsersList;
      this.adminUAdd = x.adminUsersAdd;
      this.adminBReport = x.adminBusinessReport;
			this.adminAnnouncements = x.adminAnnouncements;
      this.adminMOAT = x.adminMOAT;
    });
  }
}
