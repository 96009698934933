import { CombinedAttachment } from '../_general/attachment.model';
import { ViewDisplayValue } from '../_general/display-value.viewmodel';
import * as R from 'ramda';
export interface DocumentModel {
	Id: number;
	DocumentName: string;
	FileName: string;
	FileExtension: string;
	CreateDateTime: string;
	CreatedByStaffId: number;
	ModifiedDateTime: string;
	ModifiedByStaffId: number;
	IsActive: boolean;
	CustomerID: number;
	DocumentTypeCode: string;
	DocumentLink: string;
	CreatedByStaffLevel?: number;
}

export interface DocumentModelState {
	fileSize?: any;
	id: number;
	documentName: string;
	fileName: string;
	fileExtension: string;
	createDateTime: string;
	createdByStaffId: number;
	modifiedDateTime: string;
	modifiedByStaffId: number;
	isActive: boolean;
	customerID: number;
	documentTypeCode: string;
	documentLink: string;
	createdByStaffLevel?: number;
	type?: string;
	referenceID?: number;
}

export class DocumentFile {
	FileName: string;
	Document: string;
	CustomerId: number;
	DocumentType: string;
}

export interface DocumentUploadState {
  referenceId?: number;
  document: string;
  documentId?: number;
  fileName?: string;
  type?: string;
  name?: string;
  documentLink?: string;
}

export interface DocumentUploadResponse {
  ReferenceId?: number;
  Document: string;
  DocumentString?: number;
  FileName?: string;
  Type?: string;
  DocumentURL?: string;
	PhotoURL?: string;
}

export interface TenmplateDocumentUploadState {
	referenceId?: number;
	document: string;
	documentId?: number;
	fileName?: string;
	type?: string;
	name?: string;
	documentLink?: string;
}

export interface DocumentUpload {
	ReferenceId?: number;
	Document?: string;
	DocumentId?: number; // New
	DocumentID?: number; // Old
	FileName?: string;
	Type?: string;
	DocumentType?: string;
	DocumentTypeCode?: string;
	CustomerId?: string;
	DocumentObject?: DocumentObject[];
}

export interface DocumentObject {
	OrderNo: number;
	Document: string;
	Type: string;
}

export interface BlDocumentModel {
	Id: number;
	DocumentName: string;
	FileName: string;
	FileExtension: string;
	CreateDateTime: string;
	CreatedByStaffId: number;
	ModifiedDateTime: string;
	ModifiedByStaffId: number;
	IsActive: boolean;
	CustomerID: number;
	DocumentTypeCode: string;
	DocumentLink: string;
	CreatedByStaffLevel?: number;
	DocumentTag?: string;
}

export enum DocumentTypes {
	AdviceProcess = 'AP',
	Disclosure = 'CRTDD',
	DisclosureUpload = 'CRTDUD',
	Declaration = 'CRTD',
	SOA = 'SOA',
	SOAUpload = 'CRTSOA',
	Upload = 'CRTCLR',
	EndProcess = 'APEPE', // L&R End Process Email
	MortgageEndProcess = 'MAPEPE', // Mortgage End Process Email
	soaDocument = 'LOATSOAD',
	KiwiSaverEndProcess = 'KAPEPE',
	UserTrackerDocument = 'UTD',
	ClientFactFind = 'LOATCFF',
	StatementOfAdviceEmail = 'LOATSOAES',
	ConfirmationCall = 'LOATCCES',

	// LOAT CFF
	LOATFFGenericCoverPage = 'LOATFFGCC',
	LOATFFProcessDocument = 'LOATFFPD',
	LOATClientFactFindDocument = 'LOATCFFD',
	LOATFFExistingPolicyDocument = 'LOATFFEPD',
	LOATFFNextSteps = 'LOATFFNS',

	// LOAT SOA
	LOATSOAGenericCoverPage = 'LOATSOAGCP',
	LOATSOADocument = 'LOATSOAD',

	// MOAT
	MOATGenericCover = 'MOATGCP',
	MOATFFDocument = 'MOATFFD',
	MOATRecordOfAdviceDocument = 'MOATROAD',

	// KOAT
	KOATGenericCover = 'KOATGCP',
	KOATRecordOfAdviceDocument = 'KOATASD',

	// CAR
	CARChangeRequest = 'CARCR',
	CARUpdateStructure = 'CARUS',

	// CAR DISCLOSURE
	CARDisclosure = 'APCARDD',
}

export enum DocumentTypesMOAT {
	Disclosure = 'MOATDD',
	Application = 'MAD',
	MOAT = 'M',
	Declaration = 'MOATD',
	AdviceProcess = 'AP',
	Mortgage = 'M',
	ROAUpload = 'MOATROA',
	ReviewApplication = 'MOATRA',
	ReviewApplicationPDF = 'MOATRAP',
	ROA = 'MROA',
	ClientFactFind = 'MOATCFF',
	OtherDocuments = 'O',
	MOATROAEmailSettings = 'MOATROAE',
	MOATReviewAppEmailBody = 'MOATRAEB',
	MOATServicingCalc = 'MOATASC',
}

export enum DocumentTypesKOAT {
	AdviceProcess = 'AP',
	Disclosure = 'KOATDD',
	AdviceSummary = 'KOATAS',
	KOATAdviceSummaryEmailSettings = 'KOATASE',
	Declaration = 'KOATD',
	Application = 'KAD', // TODO:
	AdviceSummaryPDF = 'KOATASP',
}

export enum AdviceProcessDocumentTypes {
	Declaration = 'APD',
	soaCombinedPdf = 'CRTSOAPDF',
}

export enum AdviceProcessDocumentTypesMOAT {
	FacfFind = 'APMFFD',
	Declaration = 'APMD',
	Disclosure = 'APDD',
	SOS = 'APSOS',
	ROA = 'APMROA',
}

export enum AdviceProcessDocumentTypesKOAT {
	Disclosure = 'APDD',
	Declaration = 'APKD',
	AdviceSummary = 'APAS',
}

export enum SignatureTypes {
	AuthorityToProceed = 'CRTATPS',
	Declaration = 'CRTDS',
}

export enum SignatureTypesMOAT {
	Declaration = 'MOATDS',
}

export enum SignatureTypesKOAT {
	Declaration = 'KOATDS',
}

export enum DefaultFileNames {
	Declaration = 'Declaration.txt',
	Disclosure = 'DisclosureDocument.txt',
	SettingsEmailBody = 'staff-body-email.txt',
	SOA = 'statement-of-advice.txt',
	ROA = 'record-of-advice.txt',
	SOS = 'scope-of-service.txt',
	NotesPurpose = 'purpose.txt',
	NotesClientBackgroundCharacter = 'client-background-character.txt',
	NotesRecommendation = 'recommendation.txt',
	NotesOtherInformation = 'other-information.txt',
	ReviewApplication = 'review-application.txt',
	SosTemplateSettings = 'sos-template-settings.txt',
	EndProcess = 'end-process.txt',
	MortgageEndProcess = 'mortgage-end-process.txt',
	ClientFactFind = 'ClientFactFind.txt',
	ReviewApplicationEmailBody = 'review-application-email-body.txt',
	KoatSosTemplateSettings = 'koat-sos-template-settings.txt',
	AdviceSummary = 'advice-summary.txt',
	LoatCff = 'LoatClientFactFind.txt',
	ConfirmationCall = 'confirmation-call.txt',
	CAREndProcess = 'customer-alteration-request-end-process.txt',
	CARChangeRequest = 'customer-alteration-request-change-request.txt',
	CARUpdateStructure = 'customer-alteration-request-update-structure.txt',
}

export enum CRMDocumentTypeCodes {
	Office = 'OD',
	Adviser = 'AXD',
	FAP = 'FAP',
	Other = 'XD',
}

export enum CRMDocumentTypeNames {
	Office = 'Office Documents',
	Adviser = 'Adviser Documents',
	FAP = 'FAP Documents',
	Other = 'Other Documents',
}

export const GetCRMDocumentViewDisplay = () => {
	const keys = Object.keys(CRMDocumentTypeCodes);
	const crmDocs: ViewDisplayValue[] = [];
	const makeViewDisplay = (key: string) => {
		crmDocs.push({
			display: CRMDocumentTypeNames[key as keyof typeof CRMDocumentTypeNames],
			value: CRMDocumentTypeCodes[key as keyof typeof CRMDocumentTypeCodes],
		});
	};
	R.forEach(makeViewDisplay, keys);
	return crmDocs;
};


//
// OrderNo = 0 is reserved for Aesthetic cover page (combined by BE)
export const KoatAdviceSummaryPdfOrder: CombinedAttachment[] = [
	{
		orderNo: 1,
		document: '',
		type: DocumentTypes.KOATGenericCover,
		toGeneratePdf: true,
	},
	{
		orderNo: 2,
		document: '',
		type: DocumentTypes.KOATRecordOfAdviceDocument,
		toGeneratePdf: true,
	},
]
