import { Metakey } from './../../../business-page/business-page-datatable.config';
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { LeadSearchState, LeadSearchStore } from './lead-search.store';
import * as R from 'ramda';
import {
	combineLatest,
	distinctUntilChanged,
	withLatestFrom,
	shareReplay,
  mergeMap,
  filter,
} from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import {
	columns,
	futureWorkColumns,
	templateRow,
} from '../lead-search-page/lead-search-datatable.config';
import { Row } from './lead-search.model';
import { LeadSearchUiQuery } from './lead-search-ui.query';
import { mapToLookupObject } from '../../../../domain/dropdown-value/dropdown-value.model';
import { util } from '../../../../util/util';
import sort from 'fast-sort';
import { DropdownValueQuery } from '../../../../domain/dropdown-value/dropdown-value.query';
import { BLStaffsQuery } from '../../../../domain/bl-staff/bl-staffs.query';
import { UserQuery } from '../../../../domain/user/user.query';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';
import { FieldMetadata } from 'src/app/shared/dynamic-field/field-metadata.model';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';

/** Query for lead search data */
@Injectable()
export class LeadSearchQuery extends QueryEntity<LeadSearchState, Row> {
  /** Adviser choices */
  private advisers$ = this.blstaffQuery.availableStaffs$.pipe(
    map((x) =>
      R.map(
        (y) =>
          ViewDisplayValue.Map(
            y.StaffID?.toString(),
            `${y.FirstName} ${y.LastName}`
          ),
        x
      )
    ),
    map((x) => (x ? x?.sort((a, b) => a.display.localeCompare(b.display)) : x))
  );
  /** Activity type choices */
  private AT$ = this.dropdownQuery.orderedChoices$('AT');
  /** Lead Status choices */
  private LS$ = this.dropdownQuery.orderedChoices$('LS');
  /** Lead type choices */
  private PCLT$ = this.dropdownQuery.orderedChoices$('PCLT');
  /** Lead origin choices */
  private PCLE$ = this.dropdownQuery.orderedChoices$('PCLE');
  /** String list of metakeys of currently active columns. */
  columns$ = this.select((x) => x.columns);
  /** Check if has selected 1 row */
  hasBulkItemCheckedEntity$: Observable<boolean> = this.selectAll().pipe(
    map(entities => entities.some((entity) => entity.bulk?.value))
  );
  /** Check if has unselected 1 row */
  hasBulkItemUnCheckedEntity$: Observable<boolean> = this.selectAll().pipe(
    map(entities => entities.some((entity) => !entity.bulk?.value))
  );
  /** Check if has unselected 1 row */
  bulkItemsSelected$: Observable<Row[]> = this.selectAll().pipe(
    map(entities => entities.filter((entity) => entity.bulk?.value))
  );
  /** Search form values */
  searchForm$ = this.select((x) => x.searchForm);
  isComplete$ = this.select((x) => x.isComplete);
  allStaff$ = this.blstaffQuery.allStaffsChoices$;

  /** All Adviser choices */
  private allAdvisersChoice$ = this.blstaffQuery.staffs$.pipe(
    map((x) =>
      R.map(
        (y) =>
          ViewDisplayValue.Map(
            y.StaffID?.toString(),
            `${y.FirstName} ${y.LastName}`
          ),
        x
      )
    ),
    map((x) => (x ? x?.sort((a, b) => a.display.localeCompare(b.display)) : x))
  );

  /** All Lead Gen choices */
  private allLeadGen$ = this.blstaffQuery.leadGens$.pipe(
		map((x) =>
      R.map(
        (y) =>
          ViewDisplayValue.Map(
            y.StaffID?.toString(),
            `${y.FirstName} ${y.LastName}`
          ),
        x
      )
    ),
    map((x) => (x ? x?.sort((a, b) => a.display.localeCompare(b.display)) : x)),
  );

  /** `enhancedTableColumn` type. Used to provide
   * settings to cells like required, or editable or dropdown
   * choices.
   */
  tableColumns$ = this.columns$.pipe(
    withLatestFrom(this.userQuery.isUserWithOptFields$, this.businessConfigQuery.adviserReworkFeature$),
    map(([tableColumns, isUserWithOptFields, adviserReworkFeature]) => {
      return tableColumns
        ?.filter(column => {
          return adviserReworkFeature ? 
          !(column.includes("Alt. Adviser") || column === "Adviser") || futureWorkColumns.find(f => f.metakey === column) :
            !futureWorkColumns.find(f => f.metakey === column) ;
        })
          ?.map((col) => {
            let futureCol = null;
            if (adviserReworkFeature) {
              futureCol = futureWorkColumns.find(f => f.metakey === col);
            }
            return futureCol ? futureCol : columns?.find(y => 
              !isUserWithOptFields
                ? y.metakey === col
                : y.metakey === col &&
                  y.metakey !== 'Mobile' &&
                  y.metakey !== 'Email' &&
                  y.metakey !== 'Physical Address')
          })
          ?.filter((x) => x);
    }),
    withLatestFrom(this.select((x) => x.columnWidths)),
    map(([tableColumns, widths]) =>
      tableColumns?.map((column) => {
        const widthConf = widths?.filter(x => x)?.find(
          (width) => width.metakey === column.metakey
        ); 
        const newColumn = { ...column, $$id: column.name };
        if (widthConf) {
          return { ...newColumn, width: widthConf.width };
        } else {
          return newColumn;
        }
      })
    ),
    combineLatest(this.allAdvisersChoice$, this.AT$, this.LS$, this.PCLT$, this.PCLE$),
		withLatestFrom(this.allLeadGen$),
    map(([[cols, adv, , ls, pclt, pcle], leadGen]) => {
      return cols?.map((x) => {
        if (
          x.metakey === 'Adviser' ||
          x.metakey === 'LR Adviser' || 
          x.metakey === 'GI Adviser' ||
          x.metakey === 'Mortgage Adviser' ||
          x.metakey === 'FG Adviser' ||
          x.metakey === 'KS Adviser' ||
          x.metakey === 'Investment Adviser' ) {
          return {
            ...x,
            choices: adv,
            choicesAsObject: mapToLookupObject(adv),
          };
        } else if (x.metakey === 'Lead Gen') {
          return {
            ...x,
            choices: leadGen,
            choicesAsObject: mapToLookupObject(leadGen),
          };
        } else if (x.metakey === 'Alt. Adviser') {
          return {
            ...x,
            choices: adv,
            choicesAsObject: mapToLookupObject(adv),
            sortValueGetter: (f, c) => {
              const isEmpty = util.isNullOrEmpty(f.value);
              const noChoices = util.isNullOrEmpty(adv);
              if (isEmpty || noChoices) {
                return undefined;
              }
        
              const customChoices: ViewDisplayValue[] = R.uniq(adv?.map((po) =>
                ViewDisplayValue.Map(po.value?.toString(), po.display)
              ));

              const getMultiselectValues = this.getMultiselectValues(customChoices, f);

              return getMultiselectValues;
            },
          };
        } else if (x.metakey === 'Lead Status') {
          return {
            ...x,
            choices: ls,
            choicesAsObject: mapToLookupObject(ls),
          };
        } else if (x.metakey === 'Lead Type') {
          return {
            ...x,
            choices: pclt,
            choicesAsObject: mapToLookupObject(pclt),
          };
        } else if (x.metakey === 'Lead Origin') {
          return {
            ...x,
            choices: pcle,
            choicesAsObject: mapToLookupObject(pcle),
          };
        } else {
          return x;
        }
      });
    }),
    distinctUntilChanged((x, y) => R.equals(x, y)),
    shareReplay(1)
  );

  getMultiselectValues = (
    choices: ViewDisplayValue[],
    field: FieldMetadata<any>
  ) => {
    if (!field.value || field.value === '[]' || field.value.length === 0) {
      return '';
    }
    const values: string[] =
      typeof field.value === 'string'
        ? Array.isArray(JSON.parse(field.value))
          ? JSON.parse(field.value)
          : []
        : [];
    return values
      ?.map((v) =>
        R.propOr(
          '',
          'display',
          choices?.find((c) => `${c.value}` === `${v}`)
        )
      )
      ?.filter((x) => x)
      ?.join(', ');
  };
  
  /** Table columns that are not currently active.
   * Used to be able to re-add them.
   */
  hiddenTableColumns$ = this.columns$.pipe(
    withLatestFrom(this.userQuery.isUserWithOptFields$, this.businessConfigQuery.adviserReworkFeature$),
    map(([tableColumns, isUserWithOptFields, adviserReworkFeature]) => {
      const allColumns = [...columns, ...(adviserReworkFeature ? futureWorkColumns : [])];

      return allColumns?.filter((x) => {
        return !isUserWithOptFields
        ? !tableColumns?.includes(x.metakey)
        : !tableColumns?.includes(x.metakey) &&
          x.metakey !== 'Mobile' &&
          x.metakey !== 'Email' &&
          x.metakey !== 'Physical Address'
      }
      ).filter(x => adviserReworkFeature ? x.metakey !== 'Adviser' && x.metakey !== 'Alt. Adviser' : x) // TODO: remove);
    }),
    shareReplay(1)
  );
  /** Currently selected column. */
  sorts$ = this.uiStore.select((x) => [{ dir: x.sort, prop: x.propSort }]);
  /** Cells that are currently loading.
   * Object lookup format for performance
   */
  cellsLoading$ = this.uiStore.select((state) => state.cellLoadingStatus);
  /** Cells that are currently in edit mode.
   * Object lookup format for performance
   */
  cellsEditing$ = this.uiStore.select((state) => state.cellEditStatus);
  /** Temporary value of cells
   * Object lookup format for performance
   * Used because table virtualization makes components lose temp data.
   */
  cellsTempvalue$ = this.uiStore.select((state) => state.cellTempValue);
  /** Rows that are currently loading.
   * Object lookup format for performance
   */
  rowsLoading$ = this.uiStore.select((state) => state.rowLoadingStatus);
  isSearching$ = this.uiStore.select((state) => state.isSearching);
  templateRow$: Observable<Row> = of(templateRow);
  /** Row data. Unsorted. */
  rows$: Observable<Row[]> = this.selectAll();
  hasRows$: Observable<boolean> = this.selectCount().pipe(map((x) => x > 0));
  /** Count of data as determined by backend.
   * Used to show total count of rows.
   */
  count$ = this.select((x) => x.count);
  /** Rows sorted using fast-sort.
   * * Create a list of values(`decorated`) with different sortValues (Schwartzian)
   * * use fast-sort to sort rows
   * * get value and leave sort values behind.
   */
  sortedRows$ = this.uiStore
    .select((x) => [x.propSort, x.sort])
    .pipe(
      distinctUntilChanged((p, q) => R.equals(p, q)),
      combineLatest(this.rows$),
      withLatestFrom(this.tableColumns$),
      withLatestFrom(this.isSearching$),
      withLatestFrom(this.allStaff$),
      map(
        ([
          [[[[prop, sortDirection], rows], tableColumns], isSearching],
          allStaff,
        ]) => {
          if (sortDirection === '' || prop === '' || isSearching) {
            return rows;
          } else {
            const column = tableColumns?.find((x) => x.prop === prop);
            if (util.isNullOrEmpty(column)) {
              return rows;
            }
            const decorated = rows?.map<[number, any, number, Row]>((r) => {
              const actualValue = column.sortValueGetter(
                r[prop],
                prop === 'Adviser' || prop === 'LeadGen' ? allStaff : column.choices
              );
              return [
                this.spaceSortValueGetter(actualValue),
                R.defaultTo('', actualValue),
                r.CustomerId,
                r,
              ];
            });
            return sort(decorated)
              .by([
                { asc: (u) => u[0] },
                {
                  [sortDirection]: (u) =>
                    u[1] && isNaN(u[1]) ? u[1]?.toLowerCase() : u[1],
                } as any,
                { asc: (u) => u[2] },
              ])
              ?.map((x) => x[3]);
          }
        }
      )
    );

	getCustomerName = (customerId: number) =>
		this.getEntity(customerId).Name.value;

	constructor(
		protected dropdownQuery: DropdownValueQuery,
		protected blstaffQuery: BLStaffsQuery,
		protected leadSearchStore: LeadSearchStore,
		public uiStore: LeadSearchUiQuery,
		protected userQuery: UserQuery,
    protected businessConfigQuery: BusinessConfigQuery
	) {
		super(leadSearchStore);
	}

	/** Create a value usable as index for sorting.
	 * Its only necessary to know if value is empty or not.
	 * So if it is not empty, return 1 which is first in sort index.
	 * And 2 if empty.
	 */
	private spaceSortValueGetter(
		fieldValue: string | number | null | undefined
	): 1 | 2 {
		let stringValue: string;
		if (util.isNullOrEmpty(fieldValue)) {
			stringValue = '';
		} else if (typeof fieldValue === 'string') {
			stringValue = fieldValue?.trim();
		} else {
			stringValue = fieldValue?.toString();
		}
		if (stringValue === '') {
			return 2;
		} else {
			return 1;
		}
	}
}
