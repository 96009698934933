import {
	Component,
	NgZone,
	OnDestroy,
	OnInit,
	Renderer2,
	ViewChild,
	ElementRef,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { isNil, uniq } from 'ramda';
import {
	BehaviorSubject,
	from,
	iif,
	Observable,
	Observer,
	of,
	Subject,
	throwError,
} from 'rxjs';
import {
	catchError,
	concatMap,
	filter,
	finalize,
	map,
	mergeMap,
	switchMap,
	take,
	takeUntil,
	tap,
	toArray,
	withLatestFrom,
} from 'rxjs/operators';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { BusinessConfigQuery } from 'src/app/domain/business-config/business-config.query';
import {
	BR,
	filterNonEmailMergeTags,
	getContentWithMergeTags,
	removeEmptyParagraphs,
} from 'src/app/shared/converter/content-merge-tags';
import {
	getSaveSuccess,
	logMessage,
} from 'src/app/shared/error-message/error-message';
import { ConfirmModalComponent } from 'src/app/shared/modal/confirm-modal/confirm-modal.component';
import { EmailModalComponent } from 'src/app/shared/modal/crt/email/email-modal.component';
import { SignatureModalComponent } from 'src/app/shared/modal/signature-modal/signature-modal.component';
import {
	UploadModalComponent,
	ALLOWED_DOCUMENT_FILE_TYPES,
} from 'src/app/shared/modal/upload-modal/upload-modal.component';
import {
	AdviceProcessOnlineRoutes,
	AdviceProcessPageCodes,
	AdviceProcessSectionCodes,
	KOATDocumentField,
	KiwiSaverAdviceProcessPageIds,
	KiwiSaverAdviceProcessPageNames,
	ServiceAdviceProcessState,
} from 'src/app/shared/models/advice-process/advice-process.model';
import { MergeTagsMapper } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.mapper';
import { MergeTagState } from 'src/app/shared/models/client-review-template/merge-tags/merge-tags.model';
import { PeopleState } from 'src/app/shared/models/client-review-template/people/people.model';
import {
	AdviceProcessDocumentTypesKOAT,
	DefaultFileNames,
	DocumentModelState,
	DocumentTypesKOAT,
	SignatureTypesKOAT,
} from 'src/app/shared/models/documents/document.model';
import { WysiwygComponent } from 'src/app/shared/wysiwyg/wysiwyg.component';
import MomentUtil from 'src/app/util/moment.util';
import { convertUtil, objectUtil } from 'src/app/util/util';
import { EmailService } from '../../../client-review-template/states/email/email.service';
import {
	SignatureModel,
	signatureTemplate,
} from '../../../client-review-template/util/templates/signature-template';
import { ClientFactFindMapper } from '../../_shared/mapper/client-fact-find.mapper';
import { CrtDocumentService } from '../../_shared/service/crt-document.service';
import { CrtKiwiSaverService } from '../state/crt-kiwisaver.service';
import { DeclarationState, SignatureState } from './state/declaration.model';
import { DeclarationService } from './state/declaration.service';
import { ToPdfService } from 'src/app/shared/services/to-pdf/to-pdf.service';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';
import { RouteService } from 'src/app/core/config/route.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CrtKiwiSaverQuery } from '../state/crt-kiwisaver.query';
import { PeopleQuery } from '../people-entities/people/state/people.query';
import { MergeTagsService } from '../state/merge-tags/crt-kiwisaver-mergetags.service';
import { SettingsTypes } from '@modules/kiwisaver-settings/state/kiwisaver-settings.model';
import { DeclarationSettingState } from '@modules/kiwisaver-settings/declaration-settings/declaration-template-settings/state/declaration-template-settings.model';
import { DependantsService } from '../people-entities/dependants/state/dependants.service';
import { PeopleService } from '../people-entities/people/state/people.service';
import { CrtKiwisaverFactFindPeopleQuery } from '../fact-find/states/crt-ks-fact-find-people.query';
import { CrtFactFindPeople } from '../fact-find/states/crt-ks-fact-find-people.store';
import { CrtKsFactFindService } from '../fact-find/states/crt-ks-fact-find.service';
import { LoatCffPdfOptions } from '@modules/crm/loat/states/declaration/declaration.service';
import { HtmlPdfConfigState } from '../../_shared/service/html-pdf/defaults-config';

@Component({
	selector: 'app-declaration',
	templateUrl: './declaration.component.html',
	styleUrls: ['./declaration.component.scss'],
})
export class DeclarationComponent implements OnInit, OnDestroy {
	private onDestroy$ = new Subject<void>();
	public bsModalRef: BsModalRef;
	public bsModalRefSign: BsModalRef;
	public bsModalRefUpload: BsModalRef;

	pageHeaders = KiwiSaverAdviceProcessPageNames;
	pageIds = KiwiSaverAdviceProcessPageIds;

	declaration$ = this.koatQuery.declaration$;
	people$ = this.peopleQuery.people$;
	ffPeople$ = this.crtKiwiFactFindPeopleQuery.people$;

	uploadDocumentDeclarationId: number;

	form: UntypedFormGroup;
	signature: any = {
		DocumentLink: '',
	};
	hasSignature = false;

	content = '';
	data: DeclarationSettingState;
	isEnableEmailOption = false;
	triggerPrint = new BehaviorSubject<object>(null);
	generatePdf$ = new BehaviorSubject<string>(null);
	pdf: string;
	document;
	pdfUrlString: string;

	declarationSettings$ = this.koatQuery.declarationSettings$;
	isCollapsed: boolean;

	isLoadingDocument: boolean;
	isDownloading: boolean;
	isLoading = false;

	isReloadDocument: boolean;

	combinedCFFDeclaration: string;

	sidebar = this.koatQuery.getValue().sidebars;
	groupName = this.koatQuery
		.getValue()
		.primaryClient?.groupName?.toString()
		?.toUpperCase();
	fileName = `${this.groupName} DECLARATION`;
	declarationFileName = `${this.groupName} DECLARATION`;
	uploadDeclarationFileName = `${this.groupName} AMENDED DECLARATION`;

	@ViewChild('contentEditor') editor: WysiwygComponent;
	@ViewChild('signatureSection') signatureSection: ElementRef;

	zoom$ = this.koatQuery.pageCompleted$.pipe(
		map((x: any) =>
			x?.some((y) => y?.includes('ZOOM'))
				? +x?.find((y) => y?.includes('ZOOM'))?.substr(4)
				: 120
		)
	);

	mergeTags$ = this.koatQuery.mergeTags$;
	declarationEmailSettings$ = this.koatQuery.declarationEmailSettings$;
	peopleAndDependents$ = this.peopleQuery.peopleAndDependentsFromCRTOnly$.pipe(
		withLatestFrom(this.ffService.peopleButtonState$),
		map(([x, y]) =>
			x
				.map((obj) => ({
					...obj,
					name: y.find((obj2) => obj2.cRTId === obj.cRTId)?.name || '',
				}))
				?.filter((p) => p?.name !== '')
		)
	);
	adviceProcess = this.koatQuery.getValue().adviceProcess;
	otherMergeTags$ = new BehaviorSubject<MergeTagState[]>(null);
	adviceDocumentName = 'Declaration Document';
	defaultContent = '<p></p>';
	isDisabledNext = true;

	// isTabLoading$ = this.koatService.isTabLoading$;

	businessFapName = this.businessConfigQuery.getValue().config.FAP;

	themeConfig$ = this.businessConfigQuery.themeConfig$;

	pdfHeaderText = {
		cff: 'Client Fact Find',
		declaration: 'Declaration'
	}
	pdfTemplateClass = {
		cff: 'loatcff-pdf pdf-design-v2',
		declaration: 'declaration-pdf-file pdf-design-v2 pdf-design-v2-body',
	}

	constructor(
		private fb: UntypedFormBuilder,
		private koatService: CrtKiwiSaverService,
		private peopleQuery: PeopleQuery,
		private koatQuery: CrtKiwiSaverQuery,
		private declarationService: DeclarationService,
		private modalService: BsModalService,
		private zone: NgZone,
		private loggerService: LoggerService,
		private crtDocService: CrtDocumentService,
		private peopleService: PeopleService,
		private emailService: EmailService,
		private mtService: MergeTagsService,
		private businessConfigQuery: BusinessConfigQuery,
		private renderer: Renderer2,
		private toPdfService: ToPdfService,
		private router: Router,
		private route: ActivatedRoute,
		private routeService: RouteService,
		private dependantsService: DependantsService,
		private activatedRoute: ActivatedRoute,
		private crtKiwiFactFindPeopleQuery: CrtKiwisaverFactFindPeopleQuery,
		private ffService: CrtKsFactFindService,
	) {
		this.buildForm();
	}

	get Signatures() {
		return this.form.get('signatures') as UntypedFormArray;
	}

	ngOnInit(): void {
		this.prepareData();
		this.koatService
			.updatePageStarted(AdviceProcessPageCodes.KiwiSaverDeclaration)
			.pipe(take(1))
			.subscribe();

		this.mtService.mergeTags$
			.pipe(
				filter((data) => !!data),
				take(1),
				mergeMap(async () => {
					let results = [];
					const requests = [
						this.mtService
							.getFactFindMt(+this.koatQuery.getValue().adviceProcessId)
							.pipe(tap((x) => (results = [...results, ...x]))),
					];
					for (const request of requests) {
						await request.toPromise();
					}
					return results;
				}),
				tap((x) => this.otherMergeTags$.next(x)),
				withLatestFrom(this.koatQuery.sidebars$),
				tap(([, sidebars]) => {
					const isDisabled =
						this.declarationService.disableDeclaration(sidebars);
					if (isDisabled) {
						// this.navigateToTab(MOATProcessRoutes.ClientSOP, AdviceProcessSubRoutes.People);
					}
				})
			)
			.subscribe();

		this.form.valueChanges
			.pipe(
				filter((x) => !!x),
				tap((x) => {
					const data = this.prepareFormValue(x);
					this.declarationService.setDeclarationFormValue({
						...data,
						content: !!this.isReloadDocument ? this.editor?.content : '',
					});
				})
			)
			.subscribe();

		this.koatQuery.adviceProcess$
			.pipe(
				tap((data) => this.checkDocuments(data)),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	checkDocuments(adviceProcess: ServiceAdviceProcessState) {
		// Check if user is able to proceed to Fact Find pages/Your Kiwisaver Tab
		// If Disclosure, SOS, Declaration has saved advice documents
		const documents = adviceProcess?.documents;
		const index =
			documents?.findIndex(
				(x) => x?.field === KOATDocumentField?.Declaration
			) || 0;

		if (index <= 0) {
			this.isDisabledNext = false;
			return;
		}

		const isDisabled = [...documents]
			?.splice(0, index + 1)
			?.map((x) => !!x?.value)
			?.includes(false);

		this.isDisabledNext = isDisabled;
	}

	/**
	 * Build a reactive form
	 */
	buildForm() {
		this.form = this.fb.group({
			document: this.fb.group({
				referenceId: [null],
				value: [''],
			}),
			signatures: this.fb.array([]),
		});
	}

	/**
	 * On load page, prepare the data
	 */
	prepareData() {
		this.declaration$
			.pipe(
				filter((x) => !!x),
				withLatestFrom(this.ffPeople$),
				takeUntil(this.onDestroy$)
			)
			.subscribe(([d, people]) => {
				this.form.reset();
				this.clearFormArray(this.Signatures);

				if (!!d && !!d?.document) {
					this.form.patchValue({
						document: {
							referenceId: d?.document?.referenceId || 0,
							value: d?.document?.value || null,
						},
					});
				}

				if (!!people) {
					people?.forEach((p: any) => {
						this.Signatures.push(
							this.patchSignature(
								p,
								(d?.signatures ?? [])?.find((x) => +x.referenceID === +p.cRTId)
							)
						);
					});
				}

				if (!!d && d?.signatures?.length > 0) {
					this.Signatures.controls?.forEach((s, i) => {
						this.getSignature(+s.get('secondaryReferenceID')?.value, i);
					});
				}

				if (!this.content) {
					this.loadDeclaration();
				}

				this.loadEmailSettings();
			});
	}

	clearFormArray = (formArray: UntypedFormArray) => {
		while (formArray.length !== 0) {
			formArray?.removeAt(0);
		}
		// tslint:disable-next-line: semicolon
	};

	scrollToSignature() {
		setTimeout(() => {
			this.signatureSection?.nativeElement?.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		}, 300);
	}

	/**
	 * Loads the document
	 */
	loadDeclaration(scrollToSignature?: boolean) {
		this.isLoadingDocument = true;
		this.declarationSettings$
			.pipe(
				tap((data) => {
					this.data = data;
				}),
				withLatestFrom(this.koatQuery.declaration$),
				concatMap(([dataSettings, declaration]) => {
					return !dataSettings
						? of(null)
						: this.declarationService
								.getDeclarationDocumentFile(
									!!declaration?.document?.referenceId
										? +declaration?.document?.referenceId
										: +dataSettings.template
								)
								.pipe(
									map((res) =>
										res
											? (objectUtil.mapPascalCaseToCamelCase(
													res
											  ) as DocumentModelState)
											: null
									)
								);
				}),
				concatMap((data: any) => {
					return iif(
						() => !!data?.documentLink,
						this.declarationService.getDocumentFromURL(data?.documentLink),
						of(this.defaultContent)
					);
				}),
				withLatestFrom(this.koatQuery.declaration$),
				tap(([content, declaration]) => {
					this.content = getContentWithMergeTags(
						content,
						this.koatQuery.getValue().mergeTags
					);
				}),
				concatMap(([x, declaration]) => {
					return !x
						? of(null)
						: iif(
								() => isNil(declaration?.cRTId) || declaration?.cRTId === 0,
								this.addDeclaration(),
								of(x)
						  );
				}),
				tap(() => this.generatedPdf()),
				finalize(() => {
					this.isLoadingDocument = false;
					if (scrollToSignature) {
						this.scrollToSignature();
					}
				}),
				take(1)
			)
			.subscribe();
	}

	/**
	 * Load declaration from settings
	 */
	reloadSettings() {
		this.isLoadingDocument = true;
		this.declarationService
			.getDeclarationDocument(0, SettingsTypes.Declaration)
			.pipe(
				concatMap((x: any) =>
					iif(
						() => x?.Template,
						this.declarationService
							.getDeclarationDocumentFile(+x?.Template)
							.pipe(take(1)),
						of(null)
					)
				),
				concatMap((data: any) =>
					this.declarationService.getDocumentFromURL(data.DocumentLink)
				),
				map((content) => {
					const template = content || this.defaultContent;
					const newData = getContentWithMergeTags(
						template,
						this.koatQuery.getValue().mergeTags
					);
					this.content = newData;
					return newData;
				}),
				tap((x) => {
					this.isReloadDocument = true;
					const form = this.prepareFormValue();
					const data = {
						...form,
						signatures: form.signatures?.map((y) => ({
							referenceID: y.referenceID,
							secondaryReferenceID: null,
							value: y?.fullName,
							dateValue: null,
						})),
						dateValue: null,
					};
					this.form.reset(data);
					this.declarationService.setDeclarationFormValue({
						...data,
						content: x,
					});
				}),
				concatMap((x) => convertUtil.convertToBase64(x)),
				withLatestFrom(this.declaration$),
				concatMap(([x, declaration]) =>
					this.saveDeclarationDocument(
						x,
						declaration.cRTId,
						declaration?.document?.referenceId
					)
				),
				tap((x) =>
					this.form.patchValue({
						document: {
							referenceId: x,
							value: 'Declaration.txt',
						},
					})
				),
				concatMap((x) => this.updateDeclaration(this.prepareFormValue())),
				finalize(() => (this.isLoadingDocument = false)),
				take(1)
			)
			.subscribe();
	}

	/**
	 * Reload Template
	 */
	reloadTemplate() {
		const confirm = new Observable((obs) => {
			this.reloadSettings();
			obs.next();
			obs.complete();
		});
		const decline = new Observable((obs: Observer<any>) => {
			obs.complete();
		});
		const initState = {
			header: 'Reload Template',
			message: `Are you sure you want to reload template?`,
			confirm$: confirm,
			decline$: decline,
		};
		this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	/** Print functionality */
	print(event) {
		this.triggerPrint.next(event);
	}

	/**
	 * Generate a PDF
	 * @param event
	 */
	generatedPdf() {
		const signatures = this.Signatures?.controls?.map(
			(x) =>
				({
					name: x.get('fullName').value,
					date: MomentUtil.formatToDisplayDate(
						MomentUtil.DateStringToMoment(x.get('date').value)
					),
					image: x.get('signature').value,
				} as SignatureModel)
		);
		const signatureContent = signatureTemplate(signatures ?? []);
		const content = `<div class="${this.pdfTemplateClass.declaration}">${this.content}${BR}${BR}${signatureContent}</div>`;
		this.generatePdf$.next(content);
	}

	/** Get the pdf url string */
	getPdf(pdf) {
		this.pdf = pdf;
	}

	open(evt) {
		this.isCollapsed = evt;
	}

	/**
	 * Get the document signature and
	 * assigning the signature on specific for
	 * @param documentId signature dodument id
	 * @param i index of form
	 * @returns document object for the signaure
	 */
	getSignature(documentId: number, i: number) {
		if (!documentId) {
			return;
		}

		this.declarationService
			.getSignature(+documentId)
			.pipe(take(1))
			.subscribe((data) => {
				this.Signatures.controls[i]
					.get('signature')
					.setValue(data?.DocumentLink);
			});
	}

	patchSignature(p?: CrtFactFindPeople, s?: SignatureState) {
		const g = this.fb.group({
			referenceID: [p?.cRTId],
			secondaryReferenceID: [
				!!s?.secondaryReferenceID ? s?.secondaryReferenceID : null,
			],
			fullName: [
				{
					value: `${p.firstName || ''} ${p.lastName || ''}`,
					disabled: true,
				},
			],
			date: [
				!!s?.dateValue
					? MomentUtil.formatDateToMoment(s?.dateValue)
					: MomentUtil.momentNowNz(),
			],
			signature: [''],
		});

		return g;
	}

	prepareFormValue(x?: DeclarationState) {
		const data = this.form.getRawValue();
		return {
			...(x ? x : data),
			document: {
				referenceId: data?.document?.referenceId || 0,
				value: data?.document?.value || null,
			},
			signatures: data.signatures?.map((y) => ({
				referenceID: y.referenceID || 23031507,
				secondaryReferenceID: !!y.secondaryReferenceID
					? y?.secondaryReferenceID
					: 0,
				value: y?.fullName,
				dateValue: MomentUtil.formatDateToServerDate(y?.date),
			})),
		};
	}

	/**
	 * Save Document for Declaration
	 * @param document Document to be uploaded
	 */
	saveDeclarationDocument(
		document: string,
		referenceId: number,
		documentId?: number
	) {
		const request = {
			ReferenceId: referenceId,
			Document: document,
			FileName: DefaultFileNames.Declaration,
			Type: DocumentTypesKOAT.Declaration,
			DocumentID: documentId,
		};
		return this.koatService.saveDocument(request);
	}

	/**
	 * Add New Declaration
	 */
	addDeclaration() {
		let referenceId;
		const data = this.prepareFormValue();

		return this.declarationService.addDeclaration(data).pipe(
			tap((x) => (referenceId = x)),
			concatMap((x) =>
				convertUtil.convertToBase64(
					getContentWithMergeTags(
						this.content,
						this.koatQuery.getValue().mergeTags
					)
				)
			),
			concatMap((x) => this.saveDeclarationDocument(x, referenceId)),
			tap((x) =>
				this.form.patchValue({
					document: {
						referenceId: x,
						value: 'Declaration.txt',
					},
				})
			),
			concatMap((x) =>
				this.declarationService.updateDeclaration({
					...data,
					cRTId: +referenceId,
					document: {
						referenceId: x,
						value: 'Declaration.txt',
					},
				})
			)
		);
	}

	updateDeclaration(declaration: DeclarationState) {
		return of(declaration).pipe(
			withLatestFrom(this.koatQuery.declaration$),
			concatMap(([x, declarationState]) =>
				this.declarationService.updateDeclaration({ ...declarationState, ...x })
			),
			take(1)
		);
	}

	/**
	 * Save Document for Declaration
	 * @param document Document to be uploaded
	 */
	saveSignatureDocument(document: string, name: string) {
		const request = {
			ReferenceId: this.koatQuery.getValue()?.declaration?.cRTId,
			Document: document,
			FileName: `${name ?? ' Client'}-Signature.png`,
			Type: SignatureTypesKOAT.Declaration,
		};
		return this.koatService.saveDocument(request);
	}

	saveSignature(signature: string, index: number) {
		const name = this.Signatures.controls[index]?.get('value')?.value;
		return of(signature).pipe(
			concatMap((x) =>
				iif(
					() => !!x,
					this.saveSignatureDocument(x?.replace(/^data:(.*,)?/, ''), name),
					of(x)
				)
			),
			tap((x) => {
				this.Signatures.controls[index]
					?.get('secondaryReferenceID')
					.setValue(x);
				this.Signatures.controls[index]?.get('signature').setValue('');
			}),
			concatMap(() => this.updateDeclaration(this.prepareFormValue())),
			take(1)
		);
	}

	/**
	 * Signing the form and openning the popup modal where to sign
	 * @param i Indexed of the form signature
	 */
	sign(i) {
		const saveFn$ = (signature: string) =>
			new Observable((obs) => {
				this.onChange();
				obs.next(signature as string);
				obs.complete();
			}).pipe(
				mergeMap((x: string) => this.saveSignature(x, i)),
				tap(() => {
					this.loadDeclaration(true);
				})
			);

		const initialState = {
			// header: 'Signature',
			subHeader: 'Draw Signature',
			saveFn$,
		};

		this.bsModalRefSign = this.modalService.show(SignatureModalComponent, {
			initialState,
			ignoreBackdropClick: true,
			class: 'modal-dialog-centered modal-dialog',
			keyboard: false,
		});
	}

	/**
	 * This will save a document on CRM Advice Process
	 * @param id Document ReferenceId saved in declaration
	 */
	saveDocument() {
		const field = KOATDocumentField?.Declaration;
		this.isLoading = !this.isLoading;

		if (
			!!this.koatQuery
				.getValue()
				?.adviceProcess?.documents?.some((x) => x?.field === field && x?.value)
		) {
			const confirm = new Observable((obs) => {
				this.savePDF()
					.pipe(
						tap(() => (this.isLoading = false)),
						// switchMap(() => this.uploadSeparateDeclarationPDF()),
						catchError((err) => {
							this.isLoading = false;
							return throwError(new Error(err));
						}),
						take(1)
					)
					.subscribe();
				obs.next();
				obs.complete();
			});

			const decline = new Observable((obs: Observer<any>) => {
				this.isLoading = false;
				obs.complete();
			});

			const latestDeclarationFileName = this.koatQuery
				.getValue()
				.adviceProcess.documents?.find((document) => document.field === field);

			const initState = {
				header: '',
				message: `A document is already uploaded for Declaration,`,
				subMessage:
					latestDeclarationFileName?.value?.documentName ?? this.fileName,
				secondaryMessage: `Do you want to replace this?`,
				confirm$: confirm,
				decline$: decline,
			};
			this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
				class: 'modal-dialog-centered modal-dialog',
				initialState: initState,
				ignoreBackdropClick: true,
				keyboard: false,
			});
		} else {
			this.savePDF()
				.pipe(
					tap(() => (this.isLoading = false)),
					// switchMap(() => this.uploadSeparateDeclarationPDF()),
					catchError((err) => {
						this.isLoading = false;
						return throwError(new Error(err));
					}),
					take(1)
				)
				.subscribe();
		}
	}

	combinePDF(file: { Document: string; FileName: string }) {
		let uploadDocumentId = null;
		const uploadDocument = {
			ReferenceId: this.koatQuery.getValue().adviceProcessId,
			CustomerId: this.koatQuery.getValue().primaryClient?.customerID,
			Document: file.Document,
			FileName: file.FileName,
			DocumentType: ServicesCodes.KiwiSaver,
		};

		return of(uploadDocument).pipe(
			concatMap((x) => this.crtDocService.saveDocument(x)),
			tap((x) => (uploadDocumentId = x)),
			withLatestFrom(this.people$),
			concatMap(([clientFactFindContent, people]: [any, any]) => {
				const template = this.getDeclarationContent();

				return this.crtDocService.generatePDFbase64(template, {
					filename: `${this.fileName}.pdf`,
				});
			}),
			map((x) => ({
				ReferenceId: this.koatQuery.getValue().adviceProcessId,
				CustomerId: this.koatQuery.getValue().primaryClient?.customerID,
				Document: x,
				FileName: `${this.fileName}.pdf`,
				DocumentType: ServicesCodes.KiwiSaver,
			})),
			concatMap((x) => 
				this.savePDF()), // declaration file with header logo and footer
			map((x) => [this.uploadDocumentDeclarationId, uploadDocumentId]?.filter(Boolean)?.map((id) => +id)),
			concatMap((x) => this.crtDocService.combineDocuments(x)),
			mergeMap((x) => convertUtil.convertToBase64(x)),
			map((x) => ({
				ReferenceId: this.koatQuery.getValue().adviceProcessId,
				CustomerId: this.koatQuery.getValue().primaryClient?.customerID,
				Document: x,
				FileName: `${this.uploadDeclarationFileName}.pdf`,
				DocumentType: ServicesCodes.KiwiSaver,
				Type: AdviceProcessDocumentTypesKOAT.Declaration,
			})),
			concatMap((x) => this.crtDocService.saveDocument(x)),
			concatMap((x) => this.updateAdviceProcessDocument(x)),
			concatMap(() => this.updateDeclaration(this.prepareFormValue())),
			take(1),
			tap(
				() => {
					this.zone.run(() =>
						this.loggerService.Success(
							{},
							getSaveSuccess(this.adviceDocumentName)
						)
					);
				},
				(err) => {
					this.zone.run(() =>
						this.loggerService.Warning(err, logMessage.shared.general.error)
					);
				}
			)
		);
	}

	savePDF() {
		const data = this.form.getRawValue()?.signatures?.map(
			(x) =>
				({
					name: x.fullName,
					date: MomentUtil.formatToDisplayDate(x.date),
					image: x.signature,
				} as SignatureModel)
		);
		const template = `<div class="${this.pdfTemplateClass.declaration}">${
			this.editor?.content
		}${BR}${BR}${signatureTemplate(data)}</div>`;

		return this.getDeclarationHeaderFooterOptions().pipe(
			mergeMap((options) =>
				this.crtDocService.downloadDocumentPDF(template, this.fileName, options)
			),
			mergeMap((x) => convertUtil.convertToBase64(x)),
			map((x) => ({
				ReferenceId: this.koatQuery.getValue().adviceProcessId,
				CustomerId: this.koatQuery.getValue().primaryClient?.customerID,
				Document: x,
				FileName: `${this.fileName}.pdf`,
				DocumentType: ServicesCodes.KiwiSaver,
				Type: AdviceProcessDocumentTypesKOAT.Declaration,
			})),
			concatMap((x) => this.crtDocService.saveDocument(x)),
			tap((x) => {
				this.uploadDocumentDeclarationId = x;
			}),
			concatMap((x) => this.updateAdviceProcessDocument(x)),
			concatMap(() => this.updateDeclaration(this.prepareFormValue())),
			tap(
				() => {
					this.zone.run(() =>
						this.loggerService.Success(
							{},
							getSaveSuccess(this.adviceDocumentName)
						)
					);
				},
				(err) => {
					this.zone.run(() =>
						this.loggerService.Warning(err, logMessage.shared.general.error)
					);
				}
			),
			take(1)
		);
	}

	uploadSeparateDeclarationPDF(file?: { Document: string; FileName: string }) {
		const template = file || this.content;
		return this.patchSignatureImages().pipe(
			map((x) => {
				const signature = x?.map(
					(x: any) =>
						({
							name: x.fullName,
							date: MomentUtil.formatToDisplayDate(x.date),
							image: x.signature,
						} as SignatureModel)
				);
				return `${template}${BR}${BR}${signatureTemplate(signature)}`;
			}),
			concatMap((x) =>
				this.crtDocService.generatePDFbase64(x, {
					filename: `${this.declarationFileName}.pdf`,
				})
			),
			map((x) => ({
				ReferenceId: this.koatQuery.getValue().adviceProcessId,
				CustomerId: this.koatQuery.getValue().primaryClient?.customerID,
				Document: !file ? x : file.Document,
				FileName: !file ? `${this.declarationFileName}.pdf` : file.FileName,
				DocumentType: ServicesCodes.KiwiSaver,
			})),
			concatMap((x) => this.crtDocService.saveDocument(x)),
			mergeMap(() => this.koatService.refetchClientDocument()),
			take(1)
		);
	}

	patchSignatureImages() {
		return of(this.form.getRawValue()?.signatures).pipe(
			concatMap((signatures) =>
				from(signatures).pipe(
					concatMap((item: SignatureState) =>
						iif(
							() => !!item?.secondaryReferenceID && !item?.signature,
							this.declarationService
								.getSignature(item.secondaryReferenceID)
								.pipe(
									map((detail) => ({
										...item,
										signature: detail?.DocumentLink,
									}))
								),
							of(item)
						)
					),
					toArray()
				)
			),
			take(1)
		);
	}

	/**
	 * Update Advice Process with Document
	 * @param id Document ID to be save in Advice Process
	 * @param type Field to where to save in Advice Process
	 * @returns Advice Process
	 */
	updateAdviceProcess(id: number, type: string) {
		return this.crtDocService.updateAdviceProcess(id, type).pipe(take(1));
	}

	getSignatureContent() {
		const data = this.form.getRawValue();

		const signatures = data?.signatures?.map(
			(x) =>
				({
					name: x.fullName,
					date: MomentUtil.formatToDisplayDate(x.date),
					image: x.signature,
				} as SignatureModel)
		);

		return signatureTemplate(signatures ?? []);
	}

	getDeclarationContent() {
		const content = this.content;
		const signatureContent = this.getSignatureContent();

		return `<div class="${this.pdfTemplateClass.declaration}">${content}${BR}${BR}${signatureContent}</div>`;
	}

	getClientFactFindContent(cffSettingsTemplate: string, people: PeopleState[]) {
		const mergeTags = this.cFFMergeTags();
		const convertedCFF = getContentWithMergeTags(
			cffSettingsTemplate,
			mergeTags
		);
		const parsedTemplate = ClientFactFindMapper.processCFF(
			convertedCFF,
			mergeTags,
			this.businessConfigQuery.getValue().config,
			people
		);

		return parsedTemplate || '';
	}

	downloadDocumentPDF() {
		this.isDownloading = true;
		this.isLoading = !this.isLoading;

		const template = this.getDeclarationContent();
		const filename = this.fileName;
		return this.getDeclarationHeaderFooterOptions()
			.pipe(
				mergeMap((options) =>
					this.crtDocService.downloadDocumentPDF(template, filename, {
						...options,
						filename
					})
				),
				tap((x) => {
					const name = `${this.fileName}.pdf`;
					const a = this.renderer.createElement('a');
					this.renderer.setStyle(a, 'display', 'none');
					const url = window.URL.createObjectURL(x);
					this.renderer.setAttribute(a, 'href', url);
					this.renderer.setAttribute(a, 'download', name);
					a.click();
					window.URL.revokeObjectURL(url);
				}),
				take(1),
				tap(() => (this.isLoading = false)),
				switchMap((x) =>
					this.saveDocumentOnBackground(x, this.prepareFormValue())
				)
			)
			.subscribe();
	}

	saveDocumentOnBackground = (document, formValue) =>
		of(document).pipe(
			mergeMap((x) => convertUtil.convertToBase64(x)),
			map((x) => ({
				ReferenceId: this.koatQuery.getValue().adviceProcessId,
				CustomerId: this.koatQuery.getValue().primaryClient?.customerID,
				Document: x,
				FileName: `${this.fileName}.pdf`,
				DocumentType: ServicesCodes.KiwiSaver,
				Type: AdviceProcessDocumentTypesKOAT.Declaration,
			})),
			concatMap((x) => this.crtDocService.saveDocument(x)),
			concatMap((x) => this.updateAdviceProcessDocument(x)),
			concatMap(() => this.updateDeclaration(formValue)),
			take(1)
		);

	cFFMergeTags() {
		const CFFMergeTags = ClientFactFindMapper.updateCFFMergeTags(
			this.koatQuery.getValue().mergeTags
		);
		return MergeTagsMapper.mapCrtMergeTags(CFFMergeTags);
	}

	cFFHtmlTemplate(cffTemplate: string, declarationTemplate: string) {
		const template = removeEmptyParagraphs(
			`<div class="koat-cff-pdf">${cffTemplate?.concat(
				declarationTemplate
			)}</div>`
		);
		return template;
	}

	uploadDocument() {
		const upload = (req: FileList) =>
			new Observable((obs) => {
				obs.next();
				obs.complete();
			}).pipe(
				tap(() => (this.isLoading = true)),
				map(() => req),
				mergeMap((x) =>
					iif(
						() => req[0].type.startsWith('image/'),
						this.toPdfService.fromImage(req[0]),
						convertUtil.toBase64(x[0]).pipe(take(1))
					)
				),
				map((file: any) => {
					return {
						Document: `${((file.content as string) ?? '')?.replace(
							/^data:(.*,)?/,
							''
						)}`,
						FileName: `${file.filename}`,
					};
				}),
				concatMap((x) => this.combinePDF(x).pipe(map(() => x))),
				mergeMap(() => this.koatService.refetchClientDocument()),
				tap(() => (this.isLoading = false)),
				takeUntil(this.onDestroy$)
			);

		const initialState = {
			customUpload: upload,
			isSingleUpload: true,
			isFileList: true,
			headerTitle: 'Save Document',
			restrict: ALLOWED_DOCUMENT_FILE_TYPES,
		};
		this.bsModalRefUpload = this.modalService.show(UploadModalComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	onChange() {
		this.koatService.setHasFormChanges(true);
	}

	zoomFn(zoom) {
		const update = (d: string[]) =>
			this.koatService.updatePageCompleted(d).pipe(
				tap(() => this.koatService.updatePageCompleted(d)),
				take(1)
			);

		of(zoom)
			.pipe(
				// debounceTime(100),
				withLatestFrom(this.koatQuery.pageCompleted$),
				concatMap(([z, list]) =>
					update([
						...(list ?? [])?.filter((l) => !l?.includes('ZOOM')),
						`ZOOM${z}`,
					])
				),
				take(1)
			)
			.subscribe();
	}

	/**
	 * Load declaration email settings
	 */
	loadEmailSettings() {
		this.declarationEmailSettings$
			.pipe(
				take(1),
				tap((data) => {
					if (data) {
						this.isEnableEmailOption = data.isEnableEmailOption;
					}
				})
			)
			.subscribe();
	}

	sendEmail() {
		this.getDeclarationHeaderFooterOptions()
			.pipe(
				tap((newPdfOptions) => {
					const initState = {
						header: 'Email Client',
						enableDefaultBcc: true,
						sendEmailFn$: this.sendToRecipients,
						saveEmailFn$: this.saveCrtEmail,
						emailSettings$: this.declarationEmailSettings$,
						peopleDropdown: this.peopleAndDependents$,
						attachments: [
							{
								fileName: `${this.fileName}.pdf`,
								generateContentCallback$: of(this.getDeclarationContent()),
								content: this.crtDocService.contentForPdf(
									`<div class="${this.pdfTemplateClass.declaration}">${this.content}</div>`
								),
								newPdfOptions: {
									...newPdfOptions,
									FileName: this.fileName,
								},
							},
						],
						successMessage: 'Declaration document has been emailed to',
						adviceProcess: this.adviceProcess,
						businessConfig$: this.businessConfigQuery.businessConfig$,
						mergeTags$: filterNonEmailMergeTags(this.mergeTags$),
						defaultLinkDocumentTab: ServicesCodes.KiwiSaver,
						documentInfo: {
							documentType: ServicesCodes.KiwiSaver,
							type: DocumentTypesKOAT.Application,
							referenceId: this.koatQuery.getValue().adviceProcessId,
							customerId: this.koatQuery.getValue().primaryClient?.customerID,
						},
					};

					this.modalService.show(EmailModalComponent, {
						class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
						initialState: initState,
						ignoreBackdropClick: true,
						keyboard: false,
					});
				}),
				take(1)
			)
			.subscribe();
	}

	updateAdviceProcessDocument(documentId?: number) {
		return this.crtDocService.updateKOATAdviceProcess(
			documentId,
			AdviceProcessDocumentTypesKOAT.Declaration
		);
	}

	saveCrtEmail = (data) => {
		return new Observable<any>((obs) => {
			obs.next(data);
			obs.complete();
		}).pipe(
			mergeMap((x) =>
				iif(
					() => data?.sectionCode === AdviceProcessSectionCodes.Dependants,
					this.dependantsService.update(data),
					this.peopleService.update(data)
				)
			)
		);
	};

	sendToRecipients = (data) => {
		return this.emailService.sendToRecipients(
			data,
			SettingsTypes.KOATDeclarationEmail,
			this.koatQuery.getValue()?.declaration.cRTId,
			filterNonEmailMergeTags(this.mergeTags$)
		);
	};

	navigateToTab(path, subPath) {
		// Base route
		const route = this.routeService.crtPage(
			this.route.snapshot.paramMap.get('customerType') !== 'client',
			+this.route.snapshot.paramMap.get('clientId'),
			+this.route.snapshot.paramMap.get('adviceProcessId'),
			AdviceProcessOnlineRoutes.KiwiSaver
		);

		// Navigate to route
		this.router.navigate([...route, path, subPath]);
	}

	complete$ = () => {
		return of(true).pipe(
			withLatestFrom(this.koatService.pageCompleted$),
			mergeMap(([, pageCompleted]) => {
				const code = AdviceProcessPageCodes.Declaration;
				const list = uniq([...pageCompleted, code]);
				return this.koatService.updatePageCompleted(list);
			}),
			tap(() => {
				this.router.navigate(
					[`../../${KiwiSaverAdviceProcessPageIds.Declaration}`],
					{
						relativeTo: this.activatedRoute.parent,
					}
				);
				let redirectRoute = [];
				const clientId = +this.route.snapshot.paramMap.get('clientId');
				const apId = +this.route.snapshot.paramMap.get('adviceProcessId');

				redirectRoute = this.routeService.customerAdviceProcess(clientId, apId);
				this.router.navigate(redirectRoute);
			}),
			take(1)
		);
	};

	previous$ = () => {
		return of(true).pipe(
			tap(() => {
				const route = [
					`../${KiwiSaverAdviceProcessPageIds.FactFind}/${KiwiSaverAdviceProcessPageIds.Providers}`,
				];
				this.router.navigate(route, {
					relativeTo: this.activatedRoute,
				});
			})
		);
	};

	// pauseExitFn = () =>
	// 	of(false).pipe(
	// 		tap(() => (this.skipSaving = true)),
	// 		take(1)
	// 	);
	// loadingComponentFn = (value: boolean) => this.koatService.isTabLoading(value);

	// saveComponentFn = () => this.saveFn();

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}

	private getDeclarationHeaderFooterOptions() {
		return this.getKoatCffPdfOptions$(this.pdfHeaderText.declaration).pipe(
			map((options) => {
				const otherHeaderOptions = new URLSearchParams({
					startHeaderOnPage: '1',
				})?.toString();
				const otherFooterOptions = new URLSearchParams({
					startPageNumberOn: '1',
				})?.toString();
				return {
					...options,
					FileName: options?.filename,
					FooterHtmlUrlParam: `${options?.FooterHtmlUrlParam}&${otherFooterOptions}`,
					HeaderHtmlUrlParam: `${options?.HeaderHtmlUrlParam}&${otherHeaderOptions}`,
				};
			}),
			take(1)
		)
	}


	private getKoatCffPdfOptions$ = (header: string) => {
		return this.themeConfig$.pipe(
			withLatestFrom(this.businessConfigQuery.businessOATLogo$),
			map(([theme, oatLogo]) => {
				// Header config For PDF
				const HeaderHtmlUrlParam = new URLSearchParams({
					headerText: header || '',
					headerLogo: oatLogo?.toString()?.trim() || '',
				})?.toString();
				// Footer config for PDF
				const FooterHtmlUrlParam = new URLSearchParams({
					footerColor: theme?.primarycolor || '#00263e',
				})?.toString();

				return {
					...this.declarationService.getDeclarationPdfOptions(),
					...LoatCffPdfOptions,
					FileName: this.fileName,
					HeaderHtmlUrlParam,
					FooterHtmlUrlParam,
				} as HtmlPdfConfigState
			}),
			take(1)
		);
	}
}
