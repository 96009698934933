import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Idle
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { DomainModule } from './domain/domain.module';
import { NavigationComponent } from './modules/navigation/navigation.component';
import { LoginComponent } from './modules/login/login.component';
import { HomeComponent } from './modules/home/home.component';
import { ExportsComponent } from './modules/exports/exports.component';

// Admin Modules
import { NavigationModule } from './modules/navigation/navigation.module';
import { TlUserModule } from './modules/user/modules/tl-user/tl-user.module';
import { BlUserModule } from './modules/user/modules/bl-user/bl-user.module';
import { UserDetailsModule } from './modules/user/comps/user-details/user-details.module';
import { ManageUsersModule } from './modules/user/comps/manage-users/manage-users.module';
import { BlBusinessModule } from './modules/business/bl-business/bl-business.module';
import { BusinessPageModule } from './modules/business-page/business-page.module';
import { DataImportModule } from './modules/data-import/data-import.module';
import { UnauthorizedComponent } from './modules/unauthorized/unauthorized.component';
import { ErrorComponent } from './modules/error/error.component';

import { TlReportModule } from './modules/tl-report/tl-report.module';
import { ThemeModule } from './modules/admin/theme/theme.module';
import { LogoModule } from './modules/admin/logo/logo.module';

// CRM Modules
import { CrmModule } from './modules/crm/crm.module';

import { appInitializer } from './app.initializer';
import { JwtInterceptor } from './core/interceptors/jwt.interceptor';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { AuthenticationService } from './core/authentication/authentication.service';

// Shared Modules
import { SharedModule } from './shared/shared.module';
import { NotFoundComponent } from './shared/not-found/not-found.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DropdownModule } from './modules/dropdown/dropdown.module';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { EmailsModule } from './modules/emails/emails.module';
import { LocalService } from './core/services/local.service';
import { LoggerService } from './core/logger/logger.service';
import { Router } from '@angular/router';
import { CrtSettingsModule } from './modules/crt-settings/crt-settings.module';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

// Froala Editor Wysiwyg
import 'froala-editor/js/plugins.pkgd.min.js';
import { CrtPageModule } from './modules/crm/crt-page/crt-page.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ViewPdfComponent } from './modules/view-pdf/view-pdf.component';
import { TlMoatSettingsModule } from '@modules/tl-moat-settings/tl-moat-settings.module';
import { KiwiSaverSettingsModule } from '@modules/kiwisaver-settings/kiwisaver-settings.module';

import { AnnouncementPopupModule } from '@modules/annoucements/shared/announcement-popup/announcement-popup.module';
import { ExportsModule } from '@modules/exports/exports.module';
import { TransferModule } from '@modules/transfer/transfer.module';
import { IntercomModule } from 'ng-intercom';
import { HeaderComponent } from '@shared/header/header.component';
import { IconService } from '@core/icons/icon.service';

// TODO: Move all imports here
const imports = [];

// Enable AkitaNgDevtools for development mode
if (!environment.production) {
	imports.push(AkitaNgDevtools.forRoot());
}

@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
		NotFoundComponent,
		NavigationComponent,
		LoginComponent,
		UnauthorizedComponent,
		ViewPdfComponent,
		ErrorComponent,
	],
	imports: [
		...imports,
		SharedModule,
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		BrowserAnimationsModule,
		MatMomentDateModule,
		NgIdleKeepaliveModule.forRoot(),

		FormsModule,
		CoreModule,
		DomainModule,

		//#region  ngx-bootstrap modules
		ModalModule.forRoot(),
		BsDropdownModule.forRoot(),
		TabsModule.forRoot(),
		CollapseModule.forRoot(),
		ProgressbarModule.forRoot(),
		PaginationModule.forRoot(),
		AccordionModule.forRoot(),
		//#region end

		// Admin
		BusinessPageModule,
		BlBusinessModule,
		NavigationModule,
		TlUserModule,
		BlUserModule,
		UserDetailsModule,
		ManageUsersModule,
		DataImportModule,
		EmailsModule,
		TlReportModule,
		DropdownModule,
		ThemeModule,
		LogoModule,
		CrtSettingsModule,
		TlMoatSettingsModule,
		KiwiSaverSettingsModule,

		// CRM
		CrmModule,

		CrtPageModule,
		NgxExtendedPdfViewerModule,

		// Standalone
		HeaderComponent,

		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production,
		}),

		AnnouncementPopupModule,
		ExportsModule,
		TransferModule,

		IntercomModule.forRoot({
			appId: environment.intercom?.appId,
			updateOnRouterChange: true, // will automatically run `update` on router event changes. Default: `false`,
			alignment: 'right',
		})
	],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: appInitializer,
			multi: true,
			deps: [AuthenticationService, LocalService],
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: JwtInterceptor,
			multi: true,
			deps: [AuthenticationService, LocalService, Router],
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ErrorInterceptor,
			multi: true,
			deps: [AuthenticationService, LoggerService, LocalService, Router],
		},
		IconService
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
