import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { tap, delay, mergeMap, map } from 'rxjs/operators';
import { JsonResultStatus } from '../../../core/base/api.service';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { logMessage, unallowedFileChars } from '../../error-message/error-message';
import { fileUtil } from '@util/util';

/**
 * allowed file types in
 * LOAT - Declaration, Authority to Proceed
 * MOAT - Disclosure, Scope of Service, Declaration, Application > Documents
 */
export const ALLOWED_DOCUMENT_FILE_TYPES =
	'.pdf, image/jpg, image/jpeg, image/png, image/bmp, .docx, .csv';

@Component({
	selector: 'app-upload-modal',
	templateUrl: './upload-modal.component.html',
	styleUrls: ['./upload-modal.component.scss'],
})
export class UploadModalComponent implements UploadModalModel {
	// set outside
	upload: (req: FormData, dataForFrontUpdate?) => Observable<any>;
	customUpload: (req: any) => Observable<any>;
	data: any;
	isSingleUpload: boolean;
	maxFileSizeText: string = '25MB';
	maxFileSize: number; // File Size in KB
	restrict: string;
	additionalInfo: string;
	isFileList: boolean;
	headerTitle: string;

	fileform: FormData;
	filelist: any;
	filenames: string[];
	success: boolean;
	failed: boolean;
	uploading: boolean;
	message: string;
	responseMessage: any;

	cancelLoading: boolean;

	currentFileSize: number;
	allowedFileExtensions: string[];
	stripFileName:boolean = false;

	get dataForFrontUpdate() {
		return Object.assign({}, this.data, { filenames: this.filenames });
	}

	constructor(
		public bsModalRef: BsModalRef,
		private loggerService: LoggerService
	) {
		this.filenames = [];
		this.fileform = null;
		this.filelist = [];
		this.success = false;
		this.uploading = false;
		this.message = 'Successfully uploaded file!';
	}

	selectFile(event) {
		if (!!this.isSingleUpload) {
			this.fileform = null;
			this.filelist = [];
			this.filenames = [];
		}
	}

	chooseFile(event) {
		if (this.uploading) {
			return;
		}
		const fileList: FileList = event.target.files;
		const limitSize = this.maxFileSize ? this.maxFileSize : 25 * 1024;
		if (
			(Array.from(fileList) as File[])?.some(
				(x) => Math.round(x.size / 1024) > limitSize
			)
		) {
			this.loggerService.Warning(
				{},
				logMessage.shared.fileUploadSize.dynamic.error.replace(
					'%fileSize%',
					this.maxFileSizeText
				)
			);
			return;
		}

		if (
			this.currentFileSize &&
			(Array.from(fileList) as File[])?.some(
				(x) => Math.round(x.size / 1024) + this.currentFileSize > limitSize
			)
		) {
			this.loggerService.Warning(
				{},
				logMessage.shared.fileUploadSize.dynamic.error.replace(
					'%fileSize%',
					this.maxFileSizeText
				)
			);
			return;
		}

		if (this.allowedFileExtensions?.length > 0) {
			const hasInvalidFile = (Array.from(fileList) as File[])?.some((x) => {
				const ext = x?.name?.split('.')?.reverse()?.[0];
				const allowedExtension = this.allowedFileExtensions?.map((x) => x?.toLowerCase());
				const extension = ext?.toLowerCase();
				return !allowedExtension?.includes(extension);
			});
			if (hasInvalidFile) {
				this.loggerService.Warning(
					{},
					logMessage.shared.fileInvalidExtension.error
				);
				return;
			}
		}

		if (
			(Array.from(fileList) as File[])?.some((f: any) =>
				unallowedFileChars.some((x) => f.name?.includes(x))
			)
		) {
			this.loggerService.Warning({}, logMessage.shared.fileName.error);
			return;
		}


		const fileform: FormData = new FormData();

		if (fileList.length > 0) {
			Array.from(fileList)?.forEach((f) => {
				fileform.append('', f, this.stripFileName?fileUtil.formatFileName(f.name):f.name);
			});
			if (this.data) {
				Object.keys(this.data)?.forEach((d) => {
					fileform.append(d, this.data[d]);
				});
			}
			this.fileform = fileform;
			this.filelist = fileList;
			this.filenames = Array.from(fileList)?.map((x) => this.stripFileName?fileUtil.formatFileName(x.name):x.name);
		}
	}

	uploadClick = () => {
		if (this.cancelLoading) {
			return;
		}
		if (
			Array.from(this.filelist)?.some((f: any) =>
				unallowedFileChars.some((x) => f.name?.includes(x))
			)
		) {
			this.loggerService.Warning({}, logMessage.shared.fileName.error);
			return;
		}

		return !!this.customUpload
			? new Observable((obs) => {
					this.uploading = true;
					obs.next();
					obs.complete();
			  })
					.pipe(mergeMap(() => this.customUpload(this.filelist)))
					.subscribe(
						() => (this.uploading = false),
						(err) => {
							this.uploading = false;
						},
						() => {
							this.close();
						}
					)
			: new Observable((obs) => {
					obs.next();
					obs.complete();
			  })
					.pipe(
						tap(() => {
							this.uploading = true;
						}),
						map(() => (this.isFileList ? this.filelist : this.fileform)),
						mergeMap((req) =>
							!this.dataForFrontUpdate
								? this.upload(req)
								: this.upload(req, this.dataForFrontUpdate)
						),
						delay(1000)
					)
					.subscribe(
						(res) => {
							this.responseMessage = res;
						},
						() => {
							this.uploading = false;
						},
						() => {
							this.success = true;
							this.uploading = false;

							setTimeout(() => {
								this.close();
							}, 5000);
						}
					);
	};

	close() {
		this.cancelLoading = true;
		this.bsModalRef.hide();
		setTimeout(() => (this.cancelLoading = false), 500);
	}

}

export interface UploadModalModel {
	upload: (req: FormData) => Observable<JsonResultStatus>;
	customUpload: (req: any) => Observable<any>;
	data: any;
	isSingleUpload: boolean; // Multiple file upload
	restrict: string; // File restrictions, by default no restrictions
	additionalInfo: string; // Additional direction information
	isFileList: boolean; // request as filelist
	headerTitle: string; // Header title
	allowedFileExtensions: string[]; // Allowed file extensions
}
