import * as R from 'ramda';

export interface User {
  // id: number;
  // fullName: string;
  // email: string;
  // securityGroup: string;
  // securityRoles: string[];
  // widgetOrder?: { code: string }[];
  // AdviserIdsAssigned?: number[];
  // GoogleUserId?: string;
  // GoogleImageUrl?: string;
  // routeTo?: string;

  AdviserIdsAssigned: number[];
  BirthDate: string;
  BusinessPhone: string;
  Businesses: string[];
  CreatedByStaffId?: number;
  EmailAddress: string;
  FirstName: string;
  HomeAddress: string;
  IsActive: number;
  LastName: string;
	FullName?: string;
  MobilePhone: string;
  RouteTo: string;
  SecurityGroup: string;
  SecurityRoles: string[];
  StaffID: number;
  Services?: string;
  StartDate: string;
  UpdatedByStaffId?: number;
  WidgetOrder: { code: string }[];
  StaffSettings: {
    ActivityColumns: string;
    ActivityColumnsWidth: string;
    BusinessPageColumns: string;
    BusinessPageColumnsWidth: string;
    CustomerSearchColumns: string;
    CustomerSearchColumnsWidth: string;
    DashboardWidgetConfig: string;
    FGSearchColumns: string;
    FGSearchColumnsWidth: string;
    KiwiSaverSearchColumns: string;
    KiwiSaverSearchColumnsWidth: string;
    InvestmentSearchColumns: string;
    InvestmentSearchColumnsWidth: string;
    LRSearchColumns: string;
    LRSearchColumnsWidth: string;
    LeadSearchColumns: string;
    LeadSearchColumnsWidth: string;
    MortgageSearchColumns: string;
    MortgageSearchColumnsWidth: string;
    AdviceProcessSearchColumns: string;
    AdviceProcessSearchColumnsWidth: string;
		ShowInAdviserList?: string;
  };

  IsLoading?: boolean;
}

export function createUser({
  AdviserIdsAssigned = [],
  BirthDate = '',
  Businesses = [],
  BusinessPhone = '',
  EmailAddress = '',
  FirstName = '',
  HomeAddress = '',
  IsActive = null,
  LastName = '',
  MobilePhone = '',
  RouteTo = '',
  StaffID = null,
  StartDate = '',
  SecurityGroup = '',
  SecurityRoles = [],
  WidgetOrder = [],
  StaffSettings = null,
  Services = '',
}: Partial<User>) {
  return {
    AdviserIdsAssigned,
    BirthDate,
    Businesses,
    BusinessPhone,
    EmailAddress,
    FirstName,
    HomeAddress,
    IsActive,
    LastName,
    MobilePhone,
    RouteTo,
    StaffID,
    StartDate,
    SecurityGroup,
    SecurityRoles,
    WidgetOrder,
    StaffSettings,
    Services,
  };
}

export const isTapLevel = (user: User) =>
	R.isNil(user)
		? false
		: ['SO', 'SA', 'BAE', 'SM']?.includes(user.SecurityGroup?.toUpperCase());
export const hasPopover = (user: User) =>
  R.isNil(user)
    ? false
    : ['SO', 'SA', 'BAE', 'SM', 'BO', 'BAI', 'BM']?.includes(user.SecurityGroup);
export const isAutoSelectUser = (user: User) =>
  R.isNil(user)
    ? false
    : ['BO', 'AM', 'A', 'LG', 'LGI', 'BAI', 'BAV']?.includes(user.SecurityGroup);
export const getId = (user: User) => (R.isNil(user) ? null : user.StaffID);
export const isUserWithOptFields = (user: User) =>
  R.isNil(user) ? false : ['A', 'AM', 'LGI', 'LG']?.includes(user.SecurityGroup);
export const canBulkTransfer = (user: User) =>
  R.isNil(user) ? false : ['SO', 'SA', 'SM', 'BO', 'BM', 'AM', 'CCA']?.includes(user.SecurityGroup);  
export const canUpdateLeadStatus = (user: User) =>
{
  if(R.isNil(user)) {
    return false;
  } else {
    return (['A', 'AM'].includes(user.SecurityGroup) || user.SecurityRoles.includes('FEL'));
  }
}

