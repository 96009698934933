<div id="leadBulkTranferForm">
	<form [formGroup]="form">
		<h4
			class="card-header font-weight-bold text-light tap-bg-primary d-flex justify-content-between"
		>
			Bulk Transfer
			<span class="material-icons close-btn cursor-pointer" (click)="cancel()">
				close
			</span>
		</h4>
		<div class="p-4 form-body">
			<div class="form-row">
				<div class="col-12 col-lg-5 d-flex align-items-center">
					<label class="font-weight-bold" for="from"
						>What user tag are you transferring?</label
					>
				</div>
				<div class="col-12 col-lg-7">
					<div class="crt-custom-dropdown w-100">
						<select
							id="adviserType"
							formControlName="adviserType"
							placeholder="Select"
							[selectPlaceholder]="{ defaultValue: '' }"
							class="form-control theme-crm-field-disabled crt-form-control"
						>
							<option value="" selected disabled hidden>Select</option>
							<option *ngFor="let d of adviserProviders$" [value]="d.id">
								{{ d.name }}
							</option>
						</select>
					</div>
				</div>
			</div>
			<div class="form-row">
				<div class="col-12 col-lg-5 d-flex align-items-center">
					<label class="font-weight-bold" for="to"
						>What user would you like to assign?</label
					>
				</div>
				<div class="col-12 col-lg-7">
					<div class="crt-custom-dropdown w-100">
						<select
							id="adviser"
							formControlName="adviser"
							placeholder="Select"
							[selectPlaceholder]="{ defaultValue: '' }"
							class="form-control theme-crm-field-disabled crt-form-control"
						>
							<option value="" selected disabled hidden>Select</option>
							<option *ngFor="let d of advisers$ | async" [value]="d.value">
								{{ d.display }}
							</option>
						</select>
					</div>
				</div>
			</div>
			<div class="form-row" *ngIf="form.get('leadStatus')?.enabled">
				<div class="col-12 col-lg-5 d-flex align-items-center">
					<label class="font-weight-bold" for="to"
						>What Lead Status would you like to assign?</label
					>
				</div>
				<div class="col-12 col-lg-7">
					<div class="crt-custom-dropdown w-100">
						<select
							id="leadStatus"
							formControlName="leadStatus"
							placeholder="Select"
							class="form-control theme-crm-field-disabled crt-form-control"
						>
							<option
								[class.preselect]="d.value === 'Unchanged'"
								*ngFor="let d of LST$ | async"
								[value]="d.value"
							>
								{{ d.display }}
							</option>
						</select>
					</div>
				</div>
			</div>
		</div>

		<div class="p-4">
			<ngx-datatable
				#mydatatable
				class="bootstrap"
				[style.height]="tableHeight"
				[columns]="columns$ | async"
				[rows]="rows$ | async"
				[columnMode]="'force'"
				[rowIdentity]="rowIdentity"
				[scrollbarH]="true"
				[trackByProp]="'CustomerId'"
				[scrollbarV]="true"
				[rowHeight]="40"
				[headerHeight]="32"
				[footerHeight]="false"
				[externalSorting]="true"
				sortType="single"
			>
				<ngx-datatable-column
					*ngFor="let c of columns$ | async"
					[prop]="c.prop"
					[resizeable]="true"
					[name]="c.name"
					[cellClass]="c?.cellClass"
					[frozenLeft]="c.metakey === 'Name'"
				>
					<ng-template ngx-datatable-header-template let-column="column">
						<span class="datatable-header-cell-wrapper">
							<span class="datatable-header-cell-label">{{ column.name }}</span>
						</span>
					</ng-template>

					<ng-template
						ngx-datatable-cell-template
						let-row="row"
						let-value="value"
						let-rowIndex="rowIndex"
					>
						<!-- Tried to provide template for each column but [Object, object] bug happens so made it this way -->
						<ng-container [ngSwitch]="c.controlType">
							<app-display
								*ngSwitchCase="'display'"
								[value]="value.value"
								[index]="rowIndex"
								[fieldId]="c.fieldId"
							>
							</app-display>
							<app-dropdown
								*ngSwitchCase="'dropdown'"
								[index]="rowIndex"
								[fieldId]="c.fieldId"
								[value]="value.value"
								[isEditable]="false"
								[restrict]="value.restrict"
								[isRequired]="value.isRequired"
								[choices]="
									c.metakey?.includes('Adviser')
										? (allAdviserChoices$ | async)
										: c.metakey === 'Lead Gen'
										? (leadGenChoices$ | async)
										: undefined
								"
								[choicesObject]="
									c.metakey?.includes('Adviser')
										? (allAdviserChoicesAsObject$ | async)
										: c.metakey === 'Lead Gen'
										? (leadGenChoicesAsObject$ | async)
										: undefined
								"
								[allChoices]="
									c.metakey?.includes('Adviser') || c.metakey === 'Lead Gen'
										? (allStaffsChoices$ | async)
										: undefined
								"
								[allChoicesObject]="
									c.metakey?.includes('Adviser') || c.metakey === 'Lead Gen'
										? (allStaffsChoicesAsObject$ | async)
										: undefined
								"
							></app-dropdown>
						</ng-container>
					</ng-template>
				</ngx-datatable-column>
			</ngx-datatable>
		</div>

		<div class="form-buttons pl-4 pr-4 pb-4">
			<button
				id="cancelBtn"
				class="btn-light"
				[attr.disabled]="isTransferring || isLoading ? '' : null"
				(click)="cancel()"
			>
				Cancel
			</button>
			<button
				id="tranferBtn"
				type="button"
				[disabled]="form.invalid || isTransferring"
				class="btn btn-primary tap-bg-primary text-light"
				(click)="transfer()"
			>
				Transfer
			</button>
			<app-manual-loader-mini
				*ngIf="isTransferring"
				color="#FFF"
				[isLoading]="true"
			></app-manual-loader-mini>
		</div>
	</form>
</div>
