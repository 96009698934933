import { TransferQuery } from './../../../transfer/state/transfer.query';
import {
	Component,
	OnInit,
	Renderer2,
	OnDestroy,
	Output,
	EventEmitter,
	TemplateRef,
	AfterViewInit,
	ViewChild,
} from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { LeadSearchRequest } from '../lead-search-request.model';
import { LeadSearchService } from '../state/lead-search.service';
import { LeadSearchQuery } from '../state/lead-search.query';
import { takeUntil, map, first, finalize, take, tap, mergeMap } from 'rxjs/operators';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { LeadSearchStore } from '../state/lead-search.store';
import { LeadSearchUiQuery } from '../state/lead-search-ui.query';
import { request } from '../lead-search.util';
import { LeadSearchUiStore } from '../state/lead-search-ui.store';
import { ViewDisplayValue } from 'src/app/shared/models/_general/display-value.viewmodel';
import { DropdownValueQuery } from 'src/app/domain/dropdown-value/dropdown-value.query';
import { BLStaffsQuery } from 'src/app/domain/bl-staff/bl-staffs.query';
import { UserQuery } from 'src/app/domain/user/user.query';
import { strUtil } from 'src/app/util/util';
import MomentUtil from 'src/app/util/moment.util';
import { ActivatedRoute } from '@angular/router';
import { CustomerTypes } from 'src/app/shared/models/_general/client.model';
import { formUtil } from '../../../../util/form.util';
import { ExportsQuery } from '@modules/exports/state/exports.query';
import { ExportsStatus, ExportsType } from '@modules/exports/state/exports.model';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { ExportsService } from '@modules/exports/state/exports.service';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { LeadBulkTransferComponent } from '../lead-bulk-transfer/lead-bulk-transfer.component';
import { omit } from 'ramda';
declare var $: any;

@Component({
	selector: 'app-lead-search-form',
	templateUrl: './lead-search-form.component.html',
	styleUrls: ['./lead-search-form.component.scss'],
})
export class LeadSearchFormComponent
	implements OnInit, AfterViewInit, OnDestroy
{
	private onDestroy$ = new Subject<void>();

	@ViewChild(PopoverDirective) popover: PopoverDirective;

	exportStatus$ = this.exportsQuery.status$;

	availableStaffChoices$: Observable<ViewDisplayValue[]> =
		this.bLStaffsQuery.adviserChoicesOption$;

	staffChoices$: Observable<ViewDisplayValue[]> =
		this.bLStaffsQuery.availableStaffsChoices$.pipe(
			map((x) => (x ? x?.sort((a, b) => a.display.localeCompare(b.display)) : x))
		);
    

	leadGenChoices$: Observable<ViewDisplayValue[]> =
		this.bLStaffsQuery.leadGenChoices$.pipe(
			map((x) => (x ? x?.sort((a, b) => a.display.localeCompare(b.display)) : x))
		);
	adviserStatus$: Observable<ViewDisplayValue[]> = of([
		{
			display: 'Inactive',
			value: '0',
		},
		{
			display: 'Active',
			value: '1',
		},
		{
			display: 'Paused',
			value: '2',
		},
	]);
	PCLE$: Observable<ViewDisplayValue[]> =
		this.dropdownValueQuery.orderedChoices$('PCLE');
	LS$: Observable<ViewDisplayValue[]> =
		this.dropdownValueQuery.orderedChoices$('LS');
	PCLT$: Observable<ViewDisplayValue[]> =
		this.dropdownValueQuery.orderedChoices$('PCLT');
	AT$: Observable<ViewDisplayValue[]> =
		this.dropdownValueQuery.orderedChoices$('AT');

	isTapLevel$ = this.userQuery.isTapLevel$;
	userInfo$ = this.userQuery.userInfo$;
  adviserReworkFeature$ = this.businessConfigQuery.adviserReworkFeature$;

	readonly contactTypeChoices = [
		{ display: 'People', value: CustomerTypes.PrimaryCustomerIndividual },
		{ display: 'Business', value: CustomerTypes.PrimaryCustomerCompany },
	];
	form: UntypedFormGroup = this.fb.group({
		Advisers: this.fb.control([]),
		AltAdvisers: this.fb.control([]),
		FirstName: this.fb.control(null),
		LastName: this.fb.control(null),
		AdviserStatuses: this.fb.control([]),
		LeadOrigins: this.fb.control([]),
		LeadStatuses: this.fb.control([]),
		LeadType: this.fb.control([]),
		LeadGens: this.fb.control([]),
		NextActivityTypes: this.fb.control([]),
		NextActivityAssignedTo: this.fb.control([]),
		NextActivityDateMin: this.fb.control(null),
		NextActivityDateMax: this.fb.control(null),
		CreatedDateMin: this.fb.control(null),
		CreatedDateMax: this.fb.control(null),
		LastAssignedDateMin: this.fb.control(null),
		LastAssignedDateMax: this.fb.control(null),
		LastInteractionDateMin: this.fb.control(null),
		LastInteractionDateMax: this.fb.control(null),
		ContactTypes: this.fb.control([]),
	} as { [key in keyof LeadSearchRequest]: any });
	count$ = this.leadSearchQuery.count$;

	isSearching$ = this.leadSearchQuery.uiStore.isSearching$;
	isExporting$ = this.leadSearchQuery.uiStore.isExporting$;
	columnFromOpen$ = this.leadSearchQuery.uiStore.columnFormPopupOpen$;
  hasBulkItemCheckedEntity$ = this.leadSearchQuery.hasBulkItemCheckedEntity$;
  bulkTransferFeature$ = this.businessConfigQuery?.bulkTransferFeature$("L");
  transferStatus$ = this.transferQuery.status$;

	openColumnPopup = this.leadSearchService.togglePopup;

	@Output() toggleSearch = new EventEmitter<{
		showSearch: boolean;
		height: number;
		width: number;
	}>();
	showSearch = false;

	/************** Progress Bar ****************/
	progress = {
		width: '0%',
	};
	index = 0;
	percent = 0;
	count = 0;
	totalCount = 0;
	modalRef: BsModalRef;
	msg = 'Export Progress';
	/************** END Progress Bar *************/

	constructor(
		private renderer: Renderer2,
		private dropdownValueQuery: DropdownValueQuery,
		private bLStaffsQuery: BLStaffsQuery,
		private fb: UntypedFormBuilder,
		private leadSearchQuery: LeadSearchQuery,
		private leadSearchService: LeadSearchService,
		private userQuery: UserQuery,
		private modalService: BsModalService,
		protected leadSearchStore: LeadSearchStore,
		private leadSearchUiQuery: LeadSearchUiQuery,
		private leadSearchUiStore: LeadSearchUiStore,
		private activitedRoute: ActivatedRoute,
		private exportsQuery: ExportsQuery,
		private exportsService: ExportsService,
    protected businessConfigQuery: BusinessConfigQuery,
    protected transferQuery: TransferQuery
	) {}

	ngOnInit() {
		this.userInfo$.pipe(takeUntil(this.onDestroy$)).subscribe((x) => {
			if (
				x.SecurityGroup === 'LG' ||
				x.SecurityGroup === 'LGI' ||
				x.SecurityGroup === 'BAV'
			) {
				this.form.get('LeadGens').setValue([x.StaffID?.toString()]);
			}
		});
		const form = formUtil.createFormGroup(this.fb, {
			Advisers: [],
			FirstName: null,
			LastName: null,
			AdviserStatuses: [],
			LeadOrigins: [],
			LeadStatuses: [],
			LeadType: [],
			LeadGens: [],
			NextActivityTypes: [],
			NextActivityAssignedTo: [],
			NextActivityDateMin: null,
			NextActivityDateMax: null,
			CreatedDateMin: null,
			CreatedDateMax: null,
			ContactTypes: [],
		});
	}

	ngAfterViewInit(): void {
		if (
			this.leadSearchQuery.getValue().count > 0 ||
			this.leadSearchQuery.getValue().searchForm
		) {
			const advisers = this.leadSearchQuery.getValue().searchForm.Advisers;
			const altAdvisers = this.leadSearchQuery.getValue().searchForm.AltAdvisers;
			const fname = this.leadSearchQuery.getValue().searchForm.FirstName;
			const lname = this.leadSearchQuery.getValue().searchForm.LastName;
			const ls = this.leadSearchQuery.getValue().searchForm.LeadStatuses;
			const lo = this.leadSearchQuery.getValue().searchForm.LeadOrigins;
			const lgs = this.leadSearchQuery.getValue().searchForm.LeadGens;
			const ads = this.leadSearchQuery.getValue().searchForm.AdviserStatuses;
			const lt = this.leadSearchQuery.getValue().searchForm.LeadType;
			const ct = this.leadSearchQuery.getValue().searchForm.ContactTypes;
			const naT = this.leadSearchQuery.getValue().searchForm.NextActivityTypes;
			const naAT =
				this.leadSearchQuery.getValue().searchForm.NextActivityAssignedTo;

			const naMin =
				this.leadSearchQuery.getValue().searchForm.NextActivityDateMin;
			const naMax =
				this.leadSearchQuery.getValue().searchForm.NextActivityDateMax;
			const cdMin = this.leadSearchQuery.getValue().searchForm.CreatedDateMin;
			const cdMax = this.leadSearchQuery.getValue().searchForm.CreatedDateMax;

			this.form.get('FirstName').setValue(fname);
			this.form.get('LastName').setValue(lname);

			this.form.get('Advisers').setValue(advisers?.map((x) => x.toString()));
			this.form.get('AltAdvisers').setValue(altAdvisers?.map((x) => x.toString()));
			this.form.get('LeadStatuses').setValue(ls?.map((x) => x.toString()));
			this.form.get('LeadOrigins').setValue(lo?.map((x) => x.toString()));
			this.form.get('AdviserStatuses').setValue(ads?.map((x) => x.toString()));
			this.form.get('LeadType').setValue(lt?.map((x) => x.toString()));
			this.form.get('ContactTypes').setValue(ct?.map((x) => x.toString()));
			this.form.get('LeadGens').setValue(lgs?.map((x) => x.toString()));
			this.form
				.get('NextActivityTypes')
				.setValue(naT?.map((x) => x.toString()));
			this.form
				.get('NextActivityAssignedTo')
				.setValue(naAT?.map((x) => x.toString()));

			this.form
				.get('NextActivityDateMin')
				.setValue(MomentUtil.formatDateToMoment(naMin));
			this.form
				.get('NextActivityDateMax')
				.setValue(MomentUtil.formatDateToMoment(naMax));
			this.form
				.get('CreatedDateMin')
				.setValue(MomentUtil.formatDateToMoment(cdMin));
			this.form
				.get('CreatedDateMax')
				.setValue(MomentUtil.formatDateToMoment(cdMax));
		}
	}

	public prepareFormValue(): LeadSearchRequest {
		const formValue: formType = this.form.value;

		const req: LeadSearchRequest = {
			...formValue,
			Advisers: formValue.Advisers?.map((x) => +x),
			AltAdvisers: formValue.AltAdvisers?.map((x: string): number => +x),
			FirstName: strUtil.safeTrim(formValue.FirstName),
			LastName: strUtil.safeTrim(formValue.LastName),
			LeadGens: formValue.LeadGens?.map((x) => +x),
			NextActivityDateMin: MomentUtil.formatToServerDate(
				formValue.NextActivityDateMin
			),
			NextActivityDateMax: MomentUtil.formatToServerDate(
				formValue.NextActivityDateMax
			),
			CreatedDateMin: MomentUtil.formatToServerDate(formValue.CreatedDateMin),
			CreatedDateMax: MomentUtil.formatToServerDate(formValue.CreatedDateMax),
			LastAssignedDateMin: MomentUtil.formatToServerDate(formValue.LastAssignedDateMin),
			LastAssignedDateMax: MomentUtil.formatToServerDate(formValue.LastAssignedDateMax),
			LastInteractionDateMin: MomentUtil.formatToServerDate(formValue.LastInteractionDateMin),
			LastInteractionDateMax: MomentUtil.formatToServerDate(formValue.LastInteractionDateMax),
		};
		return req;
	}

	search(): void {
		const req = JSON.parse(JSON.stringify(this.prepareFormValue()));
    this.leadSearchUiStore.setIsSelectAll(false);

		this.leadSearchUiQuery.currentSort$
			.pipe(
				first(),
				map((x) => {
					req.Paging = {
						Index: 1,
						Column: x.propSort,
						Direction: x.sort,
					};
				}),
				finalize(() => {
					this.leadSearchService
						.search(req)
						.pipe(takeUntil(this.onDestroy$))
						.subscribe(() => {
							setTimeout(() => {
								$('datatable-body').scrollTop(1);
								$('datatable-body').scrollLeft(1);
							}, 1);
							setTimeout(() => {
								$('datatable-body').scrollTop(0);
								$('datatable-body').scrollLeft(0);
							}, 1);
						});
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	/******************************** EXPORT **********************************/
	openModal(exportTemplate: TemplateRef<any>) {
		this.modalRef = this.modalService.show(
			exportTemplate,
			Object.assign(
				{},
				{
					class: 'modal-dialog-centered gray modal-xl w-50',
					ignoreBackdropClick: true,
				}
			)
		);
	}

	export() {
		const status = this.exportsQuery.getValue().status;

		if (status === ExportsStatus.STARTED) {
			return;
		}

		this.index++;
		const req = request(
			this.prepareFormValue().Paging ? this.prepareFormValue().Paging : null,
			this.prepareFormValue(),
			this.index
		);

		const newReq = omit(['LastAssignedDateMin', 'LastAssignedDateMax', 'LastInteractionDateMin', 'LastInteractionDateMax'], req);

		this.exportsService.queueExport(newReq, ExportsType.LEAD)
			.pipe(
				mergeMap((id: string) =>
					this.exportsService.startPolling(this.exportsService.getExportStatus(id))
				)
			)
			.subscribe(
				(data) => {
					if (data.Status === ExportsStatus.COMPLETE) {
						this.downloadExport2(data.DocumentLink);
						this.hidePopover();
					}
				},
				(err) => {
					this.hidePopover();
				}
			);

		setTimeout(() => {
			this.popover.hide();
		}, 2500);
	}

	computeProgess() {
		this.percent = +((this.index * 100) / this.totalCount)?.toFixed(2);
		this.progress = {
			width: this.percent + '%',
		};
	}

	downloadExport(file: any) {
		const name =
			this.activitedRoute.snapshot.paramMap.get('companyCode') + '-Lead.csv';
		const a = this.renderer.createElement('a');
		this.renderer.setStyle(a, 'display', 'none');
		const url = window.URL.createObjectURL(file);
		this.renderer.setAttribute(a, 'href', url);
		this.renderer.setAttribute(a, 'download', name);
		a.click();
		window.URL.revokeObjectURL(url);
	}

	downloadExport2(url: string) {
		const name = this.activitedRoute.snapshot.paramMap.get('companyCode') + '-Customer.csv';

		const a = this.renderer.createElement('a');
		this.renderer.setStyle(a, 'display', 'none');
		this.renderer.setAttribute(a, 'href', url);
		this.renderer.setAttribute(a, 'download', name);
		a.click();
	}

	clearExportProgress() {
		this.index = 0;
		this.percent = 0;
		this.progress = {
			width: '0%',
		};
		this.totalCount = 0;
	}
	/**************************** END EXPORT **********************************/

	toggle() {
		this.showSearch = !this.showSearch;
		document.body.style.overflowY = 'hidden';
		let x = 0;
		let data;
		const setIntervalHeight = setInterval(() => {
			if (x < 1 && !data) {
				x += 1;
				data = {
					showSearch: this.showSearch,
					height: $('form').height(),
					width: $('form').width(),
				};
			} else if (x > 0) {
				x += 1;
				if (data.height !== $('form').height()) {
					data.height = $('form').height();
				} else {
					this.toggleSearch.emit(data);
					clearInterval(setIntervalHeight);
				}
			}
		}, 100);
	}

	reset() {
		this.form.get('Advisers').reset([]);
		this.form.get('AltAdvisers').reset([]);
		this.form.get('FirstName').reset('');
		this.form.get('LastName').reset('');
		this.form.get('AdviserStatuses').reset([]);
		this.form.get('CreatedDateMax').reset('');
		this.form.get('CreatedDateMin').reset('');

		if (
			this.userQuery.getValue().SecurityGroup !== 'LG' &&
			this.userQuery.getValue().SecurityGroup !== 'LGI' &&
			this.userQuery.getValue().SecurityGroup !== 'BAV'
		) {
			this.form.get('LeadGens').reset([]);
		}

		this.form.get('LeadOrigins').reset([]);
		this.form.get('LeadStatuses').reset([]);
		this.form.get('LeadType').reset([]);
		this.form.get('NextActivityDateMax').reset('');
		this.form.get('NextActivityDateMin').reset('');
		this.form.get('NextActivityTypes').reset([]);
		this.form.get('NextActivityAssignedTo').reset([]);
		this.form.get('ContactTypes').reset([]);

		
		this.form.get('LastAssignedDateMin').reset('');
		this.form.get('LastAssignedDateMax').reset('');
		this.form.get('LastInteractionDateMin').reset('');
		this.form.get('LastInteractionDateMax').reset('');
	}

	showPopover() {
		this.exportStatus$
			.pipe(
				take(1),
				tap((status) => {
					if (status === ExportsStatus.STARTED) {
						this.popover.show();

						setTimeout(() => {
							this.popover.hide();
						}, 2000);
					}
				})
			)
			.subscribe();
	}

	hidePopover() {
		this.popover.hide();
	}

  transferBulkItems() {
    const dataRowsCopy$ = this.leadSearchQuery.bulkItemsSelected$.pipe(
      map(dataRows => [...dataRows]) // Assuming dataRows is an array, using spread operator for shallow copy
    );

    this.modalService.show(LeadBulkTransferComponent, {
      class: 'modal-dialog-centered modal-dialog modal-lg modal-workflow',
      initialState: {
        dataRows$: dataRowsCopy$
      },
      ignoreBackdropClick: true,
      keyboard: false
    });
  }

	ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}

type formType = { [key in keyof LeadSearchRequest]: any };
