import { Component, Input, ViewChild } from '@angular/core';
import {
	AbstractControl,
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
} from '@angular/forms';
import {
	InvestmentReturnData,
	havenCalc,
} from '@modules/crm/crt-page/_shared/calculations/haven-calculator';
import { CrtKiwiSaverService } from '@modules/crm/crt-page/crt-kiwisaver/state/crt-kiwisaver.service';
import { DateInputComponent } from '@shared/date-input/date-input.component';
import { AdviceProcessPageCodes } from '@shared/models/advice-process/advice-process.model';
import { validMomentDate } from '@shared/validator/valid-moment-date/valid-moment-date';
import { VerticalBarChartComponent } from '@shared/vertical-bar-chart/vertical-bar-chart.component';
import MomentUtil from '@util/moment.util';
import { numUtil } from '@util/util';
import * as Chartist from 'chartist';
import { IBarChartOptions, IChartistData } from 'chartist';
import { map, take } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
	selector: 'app-calculator-chart',
	templateUrl: './calculator-chart.component.html',
	styleUrls: ['./calculator-chart.component.scss'],
})
export class CalculatorChartComponent {
	@ViewChild('verticalBar') verticalBar: VerticalBarChartComponent;

	form = new UntypedFormGroup({
		birthMonth: new UntypedFormControl(null),
		birthYear: new UntypedFormControl(null),
		annualIncome: new UntypedFormControl(null),
		kiwisaverBalance: new UntypedFormControl(null),
		employeeContribution: new UntypedFormControl(null),
		employerContribution: new UntypedFormControl(null),
		inflation: new UntypedFormControl(null),
	});

	private _projection: any;
	@Input() set projection(projection: any) {
		this._projection = projection;
		this.form.patchValue(projection);
		setTimeout(() => this.onChange(), 100);
	}

	currencyFormatter = (min: number = 0) =>
		numUtil.intlCurrencyFormatterFactory({
			minimumFractionDigits: min,
		});

	compactCurrencyFormatter = (min: number = 0, max: number = 2) =>
		numUtil.intlCurrencyFormatterFactoryCompact({
			minimumFractionDigits: min,
			maximumFractionDigits: max,
		});

	years$ = of(1958).pipe(
		map((yearStart) => {
			const yearEnd = new Date().getFullYear();
			return Array.from(
				{ length: yearEnd - yearStart + 1 },
				(_value, key) => key + yearStart
			);
		})
	);

	@ViewChild('dateOfBirthInput') dateOfBirthInput: DateInputComponent;

	get birthMonth(): AbstractControl {
		return this.form.controls.birthMonth;
	}

	get birthYear(): AbstractControl {
		return this.form.controls.birthYear;
	}

	get employeeContribution(): AbstractControl {
		return this.form.controls.employeeContribution;
	}

	getAnnualIncome() {
		const value = this.form.get('annualIncome').value;
		return this.currencyFormatter(2).format(value);
	}

	getKiwisaverBalance() {
		const value = this.form.get('kiwisaverBalance').value;
		return this.currencyFormatter(2).format(value);
	}

	data: IChartistData = {
		series: [],
		labels: [],
	};

	numberformatter = new Intl.NumberFormat('en-NZ', {
		// @ts-ignore-next
		notation: 'compact',
	});

	chartOptions = this.chartOptionsFactory([]);
	investmentReturnData: InvestmentReturnData = {
		defensive: havenCalc.var.DEFENSIVE_NET_RETURN,
		conservative: havenCalc.var.CONSERVATIVE_NET_RETURN,
		balanced: havenCalc.var.BALANCED_NET_RETURN,
		growth: havenCalc.var.GROWTH_NET_RETURN,
		aggressive: havenCalc.var.AGGRESSIVE_NET_RETURN,
	};

	@Input() disabled: boolean = false;
	@Input() showAccordionResults: boolean = false;

	constructor(
		private fb: UntypedFormBuilder,
		private koatService: CrtKiwiSaverService
	) {
		this.buildForm();
	}

	ngOnInit(): void {
		this.koatService
			.updatePageStarted(AdviceProcessPageCodes.KiwiSaverProjection)
			.pipe(take(1))
			.subscribe();

		setTimeout(() => {
			this.onChange();
		}, 100);
	}

	buildForm() {
		this.form = this.fb.group({
			dateOfBirth: ['', [validMomentDate()]],
			annualIncome: [''],
			kiwisaverBalance: [''],
			employeeContribution: [''],
			employerContribution: [''],
			inflation: [''],
			birthMonth: [''],
			birthYear: [''],
		});
	}

	onChange() {
		const values = this.calculate();
		const series = Object.values(values);
		this.chartOptions = this.chartOptionsFactory(series);
		this.data = {
			series: [series],
			labels:
				Object.keys(values)?.map((x) => this.capitalizeFirstLetter(x)) || [],
		};
	}

	capitalizeFirstLetter(value: string) {
		return value?.charAt(0)?.toUpperCase() + value?.slice(1);
	}

	dateChanged(
		monthInput: HTMLSelectElement,
		yearInput: HTMLSelectElement
	): void {
		const dob = MomentUtil.DateStringToMoment(
			`${yearInput.value}-${monthInput.value}-01`
		);
		this.form.get('dateOfBirth').setValue(dob);
		this.onChange();
	}

	isEllipsisActive(e: HTMLElement): boolean {
		return e ? e.offsetWidth < e.scrollWidth : false;
	}

	onDraw = (data: any) => {
		if (data.type === 'grid') {
			data.element.attr({ style: 'stroke-dasharray: 0' });
		} else if (data.type === 'bar') {
			const barOpacity = (data.index + 1) / (data.series.length + 1);
			data.element.attr({
				style: `opacity: ${barOpacity};${data.element._node.getAttribute(
					'style'
				)}`,
			});

			const HEIGHT = 30;
			const MARGIN_LEFT = 10;

			const WINDOW_WIDTH = document.documentElement.clientWidth;

			// const bubbleXAxis =
			// 	WINDOW_WIDTH < 610
			// 		? data.x1 - data.axisX.stepLength / 2 + MARGIN_LEFT
			// 		: WINDOW_WIDTH < 768
			// 		? data.x1 - data.axisX.stepLength / 2 + MARGIN_LEFT * 0.6
			// 		: WINDOW_WIDTH < 800
			// 		? data.x1 - data.axisX.stepLength / 2 + MARGIN_LEFT * 1.5
			// 		: WINDOW_WIDTH < 992
			// 		? data.x1 - data.axisX.stepLength / 2 + MARGIN_LEFT * 2.3
			// 		: WINDOW_WIDTH < 1250
			// 		? data.x1 - data.axisX.stepLength / 2 + MARGIN_LEFT * 3
			// 		: data.x1 - data.axisX.stepLength / 2 + MARGIN_LEFT * 4;

			const bubbleWidth =
				WINDOW_WIDTH < 610
					? data.axisX.stepLength - MARGIN_LEFT
					: WINDOW_WIDTH < 768
					? data.axisX.stepLength - MARGIN_LEFT * 0.6
					: WINDOW_WIDTH < 800
					? data.axisX.stepLength - MARGIN_LEFT * 2.5
					: WINDOW_WIDTH < 992
					? data.axisX.stepLength - MARGIN_LEFT * 4
					: WINDOW_WIDTH < 1250
					? data.axisX.stepLength - MARGIN_LEFT * 5
					: data.axisX.stepLength - MARGIN_LEFT * 8;

			const stepLength = data.axisX.stepLength;

			const objWidth = WINDOW_WIDTH < 745 ? stepLength : stepLength * 0.6;

			const styles = `
				position: relative;
				overflow: hidden;
				color: ${this.verticalBar.primaryWidgetColor};
				background-color: #fff;
				text-align: center;
				line-height: ${HEIGHT}px;
				border-radius: 2rem;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				font-size: 12px;
				font-weight: bold;
			`;

			const foreignObjectAttrs = {
				x: data.x1 - data.axisX.stepLength / 2 + (stepLength - objWidth) / 2,
				y: data.y2 - HEIGHT / 2,
				width: objWidth,
				height: HEIGHT,
				title:
					WINDOW_WIDTH < 992
						? this.compactCurrencyFormatter().format(data.value.y)
						: this.currencyFormatter().format(data.value.y),
				style: styles,
			};
			const foreignObject = data.group.elem(
				'foreignObject',
				foreignObjectAttrs,
				'ct-custom-label'
			);

			const group = data.group._node;
			const elements = group.getElementsByTagName('text');

			setTimeout(() => {
				const text = elements[data.index];
				if (text) {
					const textWidth = text.getBoundingClientRect().width;
					const displayValue =
						textWidth > bubbleWidth
							? this.compactCurrencyFormatter().format(data.value.y)
							: this.currencyFormatter().format(data.value.y);

					foreignObject.text(displayValue);

					const obj = data.element._node.nextElementSibling;
					const shouldShrink = obj.scrollWidth > obj.clientWidth;

					if (shouldShrink) {
						foreignObject._node.textContent = '';
						foreignObject.text(this.compactCurrencyFormatter(0, 1).format(data.value.y));
					}

					foreignObject.animate({
						y: {
							dur: '0.5s',
							from: data.y1,
							to: foreignObjectAttrs.y,
							easing: 'easeOutQuad',
						} as Chartist.IChartistAnimationOptions,
					});
					
					foreignObject._node.addEventListener('mouseenter', (e) => {
						if (e.target.scrollWidth > e.target.clientWidth) {
							e.target.style.direction = 'rtl';
							e.target.style.overflow = 'visible';
						}
					});

					foreignObject._node.addEventListener('mouseleave', (e) => {
						e.target.style.direction = 'ltr';
						e.target.style.overflow = 'hidden';
					});
				}
			}, 100);
		}
	};

	calculate() {
		// Values from form fields;
		const formValue = this.form.getRawValue();
		const birthMonth = this.form.get('birthMonth').value;
		const birthYear = this.form.get('birthYear').value;
		const salary = +(formValue.annualIncome || 0);
		const balance = +(formValue.kiwisaverBalance || 0);
		const employeeContribution = +(formValue.employeeContribution || 0);
		const employerContribution = +(formValue.employerContribution || 0);
		const inflation = +formValue.inflation; // 0 = without; 1 = with

		// Calculator Variables
		const ageDiff = havenCalc.getAgeDiff(
			havenCalc.var.AGE_LIMIT,
			birthMonth,
			birthYear
		);
		const employeeContributionRate = salary * employeeContribution;
		const employerContributionRate = salary * employerContribution;
		const tax = havenCalc.getTax(salary, employerContributionRate);
		const a10 = employerContributionRate - tax;
		const taxCredit = havenCalc.getTaxCredit(
			employeeContributionRate,
			havenCalc.var.VOLUNTARY_CONTRIBUTION
		);
		const contribution = havenCalc.getContribution(
			employeeContributionRate,
			a10,
			taxCredit,
			havenCalc.var.VOLUNTARY_CONTRIBUTION
		);
		const pir = havenCalc.getPIR(salary);
		const wageGrowth = havenCalc.getPercentage(havenCalc.var.WAGE_GROWTH);
		const cpiAgainstReturns = havenCalc.getPercentage(
			havenCalc.var.CPI_AGAINST_RETURNS
		);

		// Defensive
		// OLD
		// const defensiveGrossReturns = havenCalc.getPercentage(
		// 	havenCalc.var.DEFENSIVE_GROSS_RETURNS
		// );
		// const defensiveFees = havenCalc.getPercentage(havenCalc.var.DEFENSIVE_FEES);
		// const defensiveNetReturn = havenCalc.getNetReturnAfterPIR(
		// 	defensiveGrossReturns,
		// 	defensiveFees,
		// 	pir
		// );
		// NEW
		const defensiveNetReturn = havenCalc.getPercentage(
			havenCalc.var.DEFENSIVE_NET_RETURN
		);

		// Conservative
		// OLD
		// const conservativeGrossReturns = havenCalc.getPercentage(
		// 	havenCalc.var.CONSERVATIVE_GROSS_RETURNS
		// );
		// const conservativeFees = havenCalc.getPercentage(
		// 	havenCalc.var.CONSERVATIVE_FEES
		// );
		// const conservativeNetReturn = havenCalc.getNetReturnAfterPIR(
		// 	conservativeGrossReturns,
		// 	conservativeFees,
		// 	pir
		// );
		// NEW
		const conservativeNetReturn = havenCalc.getPercentage(
			havenCalc.var.CONSERVATIVE_NET_RETURN
		);

		// Balanced
		// OLD
		// const balancedGrossReturns = havenCalc.getPercentage(
		// 	havenCalc.var.BALANCED_GROSS_RETURNS
		// );
		// const balancedFees = havenCalc.getPercentage(havenCalc.var.BALANCED_FEES);
		// const balancedNetReturn = havenCalc.getNetReturnAfterPIR(
		// 	balancedGrossReturns,
		// 	balancedFees,
		// 	pir
		// );
		// NEW
		const balancedNetReturn = havenCalc.getPercentage(
			havenCalc.var.BALANCED_NET_RETURN
		);

		// Growth
		// OLD
		// const growthGrossReturns = havenCalc.getPercentage(
		// 	havenCalc.var.GROWTH_GROSS_RETURNS
		// );
		// const growthGrossFees = havenCalc.getPercentage(havenCalc.var.GROWTH_FEES);
		// const growthNetReturn = havenCalc.getNetReturnAfterPIR(
		// 	growthGrossReturns,
		// 	growthGrossFees,
		// 	pir
		// );
		// NEW
		const growthNetReturn = havenCalc.getPercentage(
			havenCalc.var.GROWTH_NET_RETURN
		);

		// Aggressive
		// OLD
		// const aggressiveGrossReturns = havenCalc.getPercentage(
		// 	havenCalc.var.AGGRESSIVE_GROSS_RETURNS
		// );
		// const aggressiveFees = havenCalc.getPercentage(
		// 	havenCalc.var.AGGRESSIVE_FEES
		// );
		// const aggressiveNetReturn = havenCalc.getNetReturnAfterPIR(
		// 	aggressiveGrossReturns,
		// 	aggressiveFees,
		// 	pir
		// );
		// NEW
		const aggressiveNetReturn = havenCalc.getPercentage(
			havenCalc.var.AGGRESSIVE_NET_RETURN
		);

		// OVERALL RESULT OF THE COMPUTATION =================================
		// Defensive
		const resultDefensiveNonInflation = havenCalc.getTotalResultNoInflation(
			balance,
			defensiveNetReturn,
			contribution,
			wageGrowth,
			ageDiff
		);
		const resultDefensiveWithInflation = havenCalc.getTotalResultWithInflation(
			resultDefensiveNonInflation,
			cpiAgainstReturns,
			ageDiff
		);

		// Conservative
		const resultConservativeNonInflation = havenCalc.getTotalResultNoInflation(
			balance,
			conservativeNetReturn,
			contribution,
			wageGrowth,
			ageDiff
		);
		const resultConservativeWithInflation =
			havenCalc.getTotalResultWithInflation(
				resultConservativeNonInflation,
				cpiAgainstReturns,
				ageDiff
			);

		// Balanced
		const resultBalancedNonInflation = havenCalc.getTotalResultNoInflation(
			balance,
			balancedNetReturn,
			contribution,
			wageGrowth,
			ageDiff
		);
		const resultBalancedWithInflation = havenCalc.getTotalResultWithInflation(
			resultBalancedNonInflation,
			cpiAgainstReturns,
			ageDiff
		);

		// Growth
		const resultGrowthNonInflation = havenCalc.getTotalResultNoInflation(
			balance,
			growthNetReturn,
			contribution,
			wageGrowth,
			ageDiff
		);
		const resultGrowthWithInflation = havenCalc.getTotalResultWithInflation(
			resultGrowthNonInflation,
			cpiAgainstReturns,
			ageDiff
		);

		// Aggressive
		const resultAggressiveNonInflation = havenCalc.getTotalResultNoInflation(
			balance,
			aggressiveNetReturn,
			contribution,
			wageGrowth,
			ageDiff
		);
		const resultAggressiveWithInflation = havenCalc.getTotalResultWithInflation(
			resultAggressiveNonInflation,
			cpiAgainstReturns,
			ageDiff
		);

		if (+inflation === 0) {
			return {
				'Defensive Fund': resultDefensiveNonInflation,
				'Conservative Fund': resultConservativeNonInflation,
				'Balanced Fund': resultBalancedNonInflation,
				'Growth Fund': resultGrowthNonInflation,
				'Aggressive Fund': resultAggressiveNonInflation,
			};
		}
		return {
			'Defensive Fund': resultDefensiveWithInflation,
			'Conservative Fund': resultConservativeWithInflation,
			'balanced Fund': resultBalancedWithInflation,
			'growth Fund': resultGrowthWithInflation,
			'aggressive Fund': resultAggressiveWithInflation,
		};
	}

	private chartOptionsFactory(series: number[]): IBarChartOptions {
		const maxValue = series?.length ? Math.max(...series) : null;
		const verticalLineCount = 4;
		const verticalLineValue = Math.round(maxValue / 4);
		const ticks = maxValue
			? Array.from(
					{ length: verticalLineCount },
					(_value, key) => (key + 1) * verticalLineValue
			  )
			: [];
		return {
			height: '400px',
			axisY: {
				// @ts-ignore-next
				type: Chartist.FixedScaleAxis,
				ticks,
				showGrid: true,
				offset: 50,
				labelInterpolationFnc: (value: any, index: number, labels: number[]) =>
					'$' + this.numberformatter.format(value),
			},
			axisX: {
				offset: 50,
				showGrid: false,
				labelOffset: {
					y: 20,
				},
			},
		};
	}

	getData(): any {
		return {
			...this._projection,
			...this.form.getRawValue(),
		};
	}
}
