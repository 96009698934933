import {
	Component,
	OnInit,
	Input,
	ViewChild,
	OnChanges,
	ChangeDetectionStrategy,
	ViewChildren,
	QueryList,
	ChangeDetectorRef,
	SimpleChanges,
	OnDestroy,
	NgZone,
} from '@angular/core';

import { Observable, Observer, of, Subject } from 'rxjs';
import { tap, takeUntil, concatMap, map, take, mergeMap, delay } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';

import {
	LrInsuranceState,
	LRProviderGroupState,
} from '../../models/services/lr-insurance/lr-provider-group.model';
import { DocumentGroupState } from '../../models/documents/document-group.model';
import { LinkDocumentComponent } from '../link-document/link-document.component';

import { ConfirmModalComponent } from '../../modal/confirm-modal/confirm-modal.component';

import { ViewDisplayValue } from '../../../shared/models/_general/display-value.viewmodel';
import { documentUtil } from '../service-utils/document.util';
import { LrCustomerServiceState } from '../../models/services/lr-insurance/lr-insurance.model';
import { LrInsuranceServiceFormComponent } from '../lr-insurance-service-form/lr-insurance-service-form.component';
import { util } from '../../../core/util/util.service';
import { ServicesCodes } from '../../models/services/services.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import MomentUtil from '@util/moment.util';
import { Moment } from 'moment';
import { PrimaryClientState } from '@shared/models/client-profile/primary-client/primary-client.model';
import { LrInsuranceMapper } from '@shared/models/services/lr-insurance/lr-insurance.mapper';
import { mainGroupSorter } from '../service-utils/lr-insurance-sorter.util';
import { AdviceProcessCode } from '@shared/models/advice-process/advice-process.model';
import * as R from 'ramda';

@Component({
	selector: 'app-lr-insurance-service',
	templateUrl: './lr-insurance-service.component.html',
	styleUrls: ['./lr-insurance-service.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LrInsuranceServiceComponent
	implements OnInit, OnChanges, OnDestroy
{
	private onDestroy$ = new Subject<void>();

	public bsModalRef: BsModalRef;
	@Input() isLead: boolean;
	@Input() isCompany: boolean;
	@Input() tabContent: any[];
	@Input() providers: ViewDisplayValue[]; // LRP
	@Input() policyStatuses: ViewDisplayValue[]; // LRPS
	@Input() products: ViewDisplayValue[]; // LRPR
	@Input() policyTypes: ViewDisplayValue[]; // LRPT
	@Input() claimStatus: ViewDisplayValue[]; // LRCS
	@Input() discounts: ViewDisplayValue[]; // LRPD
	@Input() payments: ViewDisplayValue[]; // LRPF
	@Input() cancellationCodes: ViewDisplayValue[]; // LRCC
	@Input() retentionStatus: ViewDisplayValue[]; // LRRS
	@Input() policyOwners: ViewDisplayValue[];
	@Input() lrInsurance: LrInsuranceState;
	@Input() document: DocumentGroupState;
	@Input() customerId: number;
	@Input() primaryClient: PrimaryClientState;
	@Input() primaryCompany: any;

	@Input() addFn$: ({ data, linkDocumentId }) => Observable<any>;
	@Input() saveFn$: ({ data, provider, policyNumber }) => Observable<any>;
	@Input() deleteFn$: ({ data, provider, policyNumber }) => Observable<any>;
	@Input() archiveFn$: ({
		data,
		provider,
		policyNumber,
		isArchive,
	}) => Observable<any>;
	@Input() deleteNoteFn$: ({
		id,
		data,
		provider,
		policyNumber,
	}) => Observable<any>;
	@Input() updateDocumentFn$: ({
		id,
		customerId,
		provider,
		policyNumber,
	}) => Observable<any>;
	@Input() downloadLink$: ({ documentID }) => Observable<string>;
	@Input() addNoteFn$: ({ data, provider, policyNumber }) => Observable<any>;
	@Input() updateNextReviewFn$: (lRNextReview) => Observable<string>;

	@Input() isLoading: boolean;
	@Input() documentIsLoading: boolean;

	@ViewChild('sidebarNav') sidebarNav: TabsetComponent;
	@ViewChildren('lrForms') lrForms: QueryList<LrInsuranceServiceFormComponent>;
	@ViewChild('addForm') addForm: LrInsuranceServiceFormComponent;

	isAddNewPolicy = false;
	isSaving = false;
	isSavingDocument = false;
	hasSelectedDocument = false;
	isAddNewGroup = false;
	isShowArchived = false;
	isSavingNRD = false;

	activeProvider: string;
	activeLR:LRProviderGroupState;
	activePolicyNumber: string;
	activeGroupName: string;
	lrInsuranceState: LrInsuranceState;
	lrList: LRProviderGroupState[];

	currentLinkedDocument: any;
	currentLinkedDocumentId: string;
	companyCode: string;

	popupCount = 0;
	lrFormState: LrCustomerServiceState;
	form: FormGroup;

	@Input() deceasedClients: any[];
	//Input needed for Claims
	@Input() claimsFeature:boolean = false;
	@Input() adviser: number;
	@Input() lrClaimsList:any;
	@Input() redirectToAP$: (data:{id?:string,serviceCode?:string,customerServiceId?:string}) => Observable<any>;
	@Input() openAPModalFn$:(data)=>void;

	get LRNextReview() {
		return this.form.get('lRNextReview');
	}

	constructor(
		private fb: FormBuilder,
		private modalService: BsModalService,
		private cd: ChangeDetectorRef,
		private zone: NgZone,
	) {
		this.form = this.fb.group({
			lRNextReview: '',
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes) {
			if (changes.primaryClient || changes.primaryCompany) {
				this.setLrReviewDate();
			}
			if (changes.lrInsurance && changes.lrInsurance.currentValue !== null) {
				this.lrInsuranceState = Object.assign({}, this.lrInsurance);
				
				this.lrInsuranceState.lRs = this.lrInsuranceState.lRs.map(lr => {
					const allInactive = lr.customerServices.every(cs => cs.isActive === 0);
					const anyActive = lr.customerServices.some(cs => cs.isActive === 1);
					const allArchived = lr.customerServices.every(cs => cs.isActive === 2);
					const isActive = allInactive ? 0 : (anyActive ? 1 : (allArchived ? 2 : null));

					return {...lr, isActive};
				})?.sort(mainGroupSorter);

				this.lrList = this.lrInsuranceState.lRs;

				if (
					this.lrInsuranceState &&
					this.lrInsuranceState.lRs.length > 0 &&
					!this.activeProvider
				) {
					this.setInit();
				}

				if (changes.customerId) {
					this.setInit();
				}
			}

			if (
				changes.documentIsLoading &&
				changes.documentIsLoading.currentValue === false
			) {
				const getLr = this.lrInsuranceState.lRs?.find(
					(x) =>
						x?.provider === this.activeProvider &&
						x?.policyNumber === this.activePolicyNumber
				);
				if (!!getLr?.linkDocument) {
					this.currentLinkedDocumentId = getLr?.linkDocument;
					this.currentLinkedDocument = documentUtil(
						this.document,
						+this.currentLinkedDocumentId
					);
				} else {
					this.currentLinkedDocumentId = null;
					this.currentLinkedDocument = null;
				}
			}

			if (
				changes.lrInsurance &&
				changes.lrInsurance.previousValue &&
				this.activeProvider
			) {
				let i = -1;
				this.lrInsuranceState.lRs?.filter((lr, index) => {
					if (
						lr.provider === this.activeProvider &&
						lr.policyNumber === this.activePolicyNumber
					) {
						return (i = index);
					}
				});

				if (i === -1) {
					// set last tab to active
					return setTimeout(() => {
						const tab =
							this.sidebarNav?.tabs?.[this.lrInsuranceState?.lRs?.length - 1];
						if (tab) {
							tab.active = true;
						}
					}, 0);
				} else {
					return setTimeout(() => {
						const tab = this.sidebarNav?.tabs?.[i];
						if (tab) {
							tab.active = true;
						}
					}, 0);
				}
			}
		}
	}

	ngOnInit() {
		if (!this.lrInsurance) {
			this.lrInsuranceState = Object.assign(
				{},
				{
					totalInforceAPI: 0,
					lRs: [],
				}
			);
			this.lrList = [];
		}

		if (!this.lrInsuranceState || this.lrInsuranceState.lRs.length < 1) {
			this.activeGroupName = '';
			this.activeProvider = '';
			this.activeLR = null;
			this.activePolicyNumber = '';

			this.currentLinkedDocumentId = '';
			this.currentLinkedDocument = null;
		} else {
			this.setInit();
		}
		this.setLrReviewDate();
	}

	downloadLink = (data) => {
		this.downloadLink$({ documentID: data })
			.pipe(
				tap((res) => {
					window.location.href = res;
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	};

	refresh() {
		this.cd.detectChanges();
	}

	/**
	 * Show/Hide archived services
	 * @param isShowArchived boolean
	 */
	showArchived(isShowArchived: boolean) {
		this.isShowArchived = isShowArchived;
	}

	setInit() {
		this.activeGroupName =
			this.lrInsuranceState.lRs[0]?.provider +
			': ' +
			this.lrInsuranceState.lRs[0]?.policyNumber;
		this.activeProvider = this.lrInsuranceState.lRs[0]?.provider;
		this.activePolicyNumber = this.lrInsuranceState.lRs[0]?.policyNumber;
		this.activeLR = this.lrInsuranceState.lRs[0];

		this.currentLinkedDocumentId = this.lrInsuranceState.lRs[0]?.linkDocument;
		this.currentLinkedDocument = documentUtil(
			this.document,
			+this.currentLinkedDocumentId
		);
	}

	setLrReviewDate() {
		const data = this.isCompany ? this.primaryCompany : this.primaryClient;
		const lRNextReview = LrInsuranceMapper.mapLrNextReviewView(
			data?.lRNextReview
		);
		this.form.reset({ lRNextReview });
	}

	trackByFn(index: number, item: LRProviderGroupState) {
		return index;
	}

	trackById(index: number, item: LrCustomerServiceState) {
		return item.customerServiceID;
	}

	selectLrTab = (item) => {
		const id = !!item.id ? item.id : item.customerServiceId;
		const lrGroup = this.lrList?.find((lr) =>
			lr.customerServices?.some((cs) => cs.customerServiceID === id)
		);
		if (lrGroup) {
			const groupNavId = lrGroup.provider + '-' + lrGroup.policyNumber;
			setTimeout(() => {
				const selectedTabIndex = this.sidebarNav?.tabs?.findIndex(
					(x) => x.id === groupNavId
				);
				if (selectedTabIndex > -1) {
					this.sidebarNav.tabs[selectedTabIndex].active = true;
					const form = this.lrForms?.find(
						(lr) => lr.form.value.customerServiceID === id
					);
					if (form) {
						form.collapseForm(true);

						setTimeout(() => {
							form.formProp.nativeElement.scrollIntoView({
								behavior: 'smooth',
							});
						}, 1);
					}
				}
			}, 1000);
		}
	};

	confirmModal(data, addMode: boolean, isDelete: boolean) {
		const confirm = new Observable((obs: Observer<any>) => {
			if (!isDelete) {
				addMode ? this.createLr(data) : this.saveLr(data);
			} else {
				this.deleteLr(data);
			}
			obs.complete();
		});

		const decline = new Observable((obs: Observer<any>) => {
			if (addMode) {
				this.addForm.editForm(true);
			}
			obs.complete();
		});

		const initState = {
			header: 'Confirmation Message',
			message: `There is no primary alteration in this group. Are you sure you want to continue?`,
			confirm$: confirm,
			decline$: decline,
		};

		this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});

		this.refresh();
	}

	confirmInforceStatusModal(data, addMode: boolean, msg: string) {
		const confirm = new Observable((obs: Observer<any>) => {
			addMode ? this.createLr(data) : this.saveLr(data);
			obs.complete();
		});

		const decline = new Observable((obs: Observer<any>) => {
			if (addMode) {
				this.addForm.editForm(true);
			}
			obs.complete();
		});

		const initState = {
			header: 'Confirmation Message',
			message: msg,
			confirm$: confirm,
			decline$: decline,
			isOkBtn: true,
		};

		this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});

		this.refresh();
	}

	confirmCreate(data) {
		if (this.isAddNewGroup) {
			if (data.policyType !== 'Primary') {
				this.confirmModal(data, true, false);
			} else {
				const momentStartDate = util.DateStringToMomentLoose(data.startDate);
				const momentAnniversaryDate = util.DateStringToMomentLoose(
					data.anniversaryDate
				);
				if (
					data.startDate &&
					data.anniversaryDate &&
					momentStartDate.isValid() &&
					momentAnniversaryDate.isValid() &&
					momentAnniversaryDate.diff(momentStartDate, 'days') <= -1
				) {
					this.confirmInforceStatusModal(
						data,
						true,
						'Start date should be before Anniversary date.'
					);
				} else if (
					data.policyStatus === 'Inforce' &&
					(!data.startDate ||
						!momentStartDate?.isValid() ||
						!data.anniversaryDate ||
						!momentAnniversaryDate?.isValid())
				) {
					if (
						(!data.startDate || !momentStartDate?.isValid()) &&
						(!data.anniversaryDate || !momentAnniversaryDate?.isValid())
					) {
						this.confirmInforceStatusModal(
							data,
							true,
							`Policy Status is Inforce, there should be Start and Anniversary Dates.`
						);
					} else {
						const message = !data.startDate ? 'Start' : 'Anniversary';
						this.confirmInforceStatusModal(
							data,
							true,
							`Policy Status is Inforce, there should be a ${message} Date.`
						);
					}
				} else {
					this.createLr(data);
				}
			}
		} else if (
			!this.isAddNewGroup &&
			this.isAddNewPolicy &&
			(!this.lrFormState || this.lrFormState.policyType !== 'Primary') &&
			data.policyType !== 'Primary'
		) {
			this.confirmModal(data, true, false);
		} else {
			this.createLr(data);
		}
	}

	createLr(data) {
		data.customerID = this.customerId;

		setTimeout(() => {
			this.addForm.formSaving(true);
			this.addForm.editForm(false);
			this.addForm.collapseForm(true);
		}, 0);

		this.addFn$({ data, linkDocumentId: this.currentLinkedDocumentId })
			.pipe(
				tap((x) => {
					if (x) {
						if (this.isAddNewGroup) {
							this.currentLinkedDocument = null;
						}
						this.isAddNewGroup = false;
						this.isAddNewPolicy = false;
						this.isSaving = false;
						this.popupCount = 0;
						this.activeProvider = data.provider;
						setTimeout(()=>{
							this.activeLR = this.lrInsuranceState.lRs?.find(
								(x)=>
									data.provider===x.provider&&
									x.policyNumber===data.policyNumber);
						},0);
						this.activePolicyNumber = data.policyNumber;
						this.refresh();
					}
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(
				() => {},
				() => {
					this.addForm.formSaving(false);
				},
				() => {}
			);
	}

	confirmSaveLR(req: { data; isAddNote }, isTransfer?: boolean) {
		if (req.isAddNote) {
			this.saveLr(req.data);
		} else {
			// Same group
			if (
				req.data.provider === this.activeProvider &&
				req.data.policyNumber === this.activePolicyNumber
			) {
				const lrs = this.lrInsuranceState.lRs?.find(
					(x) =>
						x.provider === req.data.provider &&
						x.policyNumber === req.data.policyNumber
				);
				const primaryCount = lrs?.customerServices
					? lrs.customerServices?.filter((x) => x.policyType === 'Primary')
					: [];

				if (
					(primaryCount.length === 1 &&
						req.data.policyType !== 'Primary' &&
						req.data.customerServiceID === primaryCount[0].customerServiceID) ||
					(primaryCount.length < 1 && req.data.policyType !== 'Primary')
				) {
					this.confirmModal(req.data, false, false);
				} else {
					const momentStartDate = util.DateStringToMomentLoose(
						req.data.startDate
					);
					const momentAnniversaryDate = util.DateStringToMomentLoose(
						req.data.anniversaryDate
					);
					if (
						req.data.startDate &&
						req.data.anniversaryDate &&
						momentStartDate.isValid() &&
						momentAnniversaryDate.isValid() &&
						momentAnniversaryDate.diff(momentStartDate, 'days') <= -1
					) {
						this.confirmInforceStatusModal(
							req.data,
							false,
							'Start date should be before Anniversary date.'
						);
					} else if (
						req.data.policyStatus === 'Inforce' &&
						(!req.data.startDate ||
							!momentStartDate?.isValid() ||
							!req.data.anniversaryDate ||
							!momentAnniversaryDate?.isValid())
					) {
						if (
							(!req.data.startDate || !momentStartDate?.isValid()) &&
							(!req.data.anniversaryDate || !momentAnniversaryDate?.isValid())
						) {
							this.confirmInforceStatusModal(
								req.data,
								false,
								`Policy Status is Inforce, there should be Start and Anniversary Dates.`
							);
						} else {
							const message = !req.data.startDate ? 'Start' : 'Anniversary';
							this.confirmInforceStatusModal(
								req.data,
								false,
								`Policy Status is Inforce, there should be a ${message} Date.`
							);
						}
					} else {
						this.saveLr(req.data);
					}
				}
			} else {
				// Transfering to other group
				const lrs = this.lrInsuranceState.lRs?.find(
					(x) =>
						x.provider === this.activeProvider &&
						x.policyNumber === this.activePolicyNumber
				);
				const primaryCount = lrs.customerServices?.filter(
					(x) => x.policyType === 'Primary'
				);

				if (
					(primaryCount.length === 1 &&
						req.data.customerServiceID === primaryCount[0].customerServiceID &&
						(lrs.customerServices.length > 1 ||
							(lrs.customerServices.length === 1 &&
								req.data.policyType !== 'Primary'))) ||
					(primaryCount.length === 0 && lrs.customerServices.length > 1) ||
					(primaryCount.length === 0 &&
						lrs.customerServices.length === 1 &&
						req.data.policyType !== 'Primary')
				) {
					this.confirmModal(req.data, false, false);
				} else {
					const momentStartDate = util.DateStringToMomentLoose(
						req.data.startDate
					);
					const momentAnniversaryDate = util.DateStringToMomentLoose(
						req.data.anniversaryDate
					);
					if (
						!isTransfer &&
						req.data.startDate &&
						req.data.anniversaryDate &&
						momentStartDate.isValid() &&
						momentAnniversaryDate.isValid() &&
						momentAnniversaryDate.diff(momentStartDate, 'days') <= -1
					) {
						this.confirmInforceStatusModal(
							req.data,
							false,
							'Start date should be before Anniversary date.'
						);
					} else if (
						!isTransfer &&
						req.data.policyStatus === 'Inforce' &&
						(!req.data.startDate ||
							!momentStartDate?.isValid() ||
							!req.data.anniversaryDate ||
							!momentAnniversaryDate?.isValid())
					) {
						if (
							(!req.data.startDate || !momentStartDate?.isValid()) &&
							(!req.data.anniversaryDate || !momentAnniversaryDate?.isValid())
						) {
							this.confirmInforceStatusModal(
								req.data,
								false,
								`Policy Status is Inforce, there should be Start and Anniversary Date.`
							);
						} else {
							const message = !req.data.startDate ? 'Start' : 'Anniversary';
							this.confirmInforceStatusModal(
								req.data,
								false,
								`Policy Status is Inforce, there should be a ${message} Date.`
							);
						}
					} else {
						this.saveLr(req.data);
					}
				}
			}
		}
	}

	saveLr(data) {
		this.lrForms
			?.find((x) => x.form.value.customerServiceID === data.customerServiceID)
			.formSaving(true);
		// this.lrForms
		// 	?.find((lr) => lr.form.value.customerServiceID === data.customerServiceID)
		// 	.editForm(false);

		this.isAddNewGroup = false;

		data.customerID = this.customerId;
		data.documentLink = this.currentLinkedDocumentId;

		this.saveFn$(data)
			.pipe(
				tap((x) => {
					if (x) {
						this.activeProvider = data.provider;
						this.activePolicyNumber = data.policyNumber;
						this.isAddNewPolicy = false;
						this.popupCount = 0;
						this.refresh();

						setTimeout(() => {
							this.isSaving = false;
							this.lrForms
								?.find(
									(lr) =>
										lr.form.value.customerServiceID === data.customerServiceID
								)
								.formSaving(false);
							this.lrForms
								?.find(
									(lr) =>
										lr.form.value.customerServiceID === data.customerServiceID
								)
								.collapseForm(true);
								this.activeLR = this.lrInsuranceState.lRs?.find(
									(x)=>
										data.provider===x.provider&&
										x.policyNumber===data.policyNumber);
						}, 0);
					}
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(
				() => {
					this.lrForms
						?.find(
							(x) => x.form.value.customerServiceID === data.customerServiceID
						)
						.formSaving(false);

					this.lrForms
						?.find(
							(lr) => lr.form.value.customerServiceID === data.customerServiceID
						)
						.editForm(false);
				},
				() => {
					const lr = this.lrForms?.find(
						(x) => x.form.value.customerServiceID === data.customerServiceID
					);
					if (lr) {
						lr.formSaving(false);
						lr.prepData();
					}
				},
				() => {
					this.lrForms
						?.find(
							(x) => x.form.value.customerServiceID === data.customerServiceID
						)
						.formSaving(false);
				}
			);
	}

	confirmDeleteLr(data) {
		const lrs = this.lrInsuranceState.lRs?.find(
			(x) =>
				x.provider === data.provider && x.policyNumber === data.policyNumber
		);
		const primaryCount = lrs.customerServices?.filter(
			(x) => x.policyType === 'Primary'
		);

		if (
			(primaryCount.length === 1 &&
				data.customerServiceID === primaryCount[0].customerServiceID) ||
			primaryCount.length === 0
		) {
			this.confirmModal(data, false, true);
		} else {
			this.deleteLr(data);
		}
	}

	deleteLr(data) {
		this.lrForms
			?.find((lr) => lr.form.value.customerServiceID === data.customerServiceID)
			.formSaving(true);
		const provider = data.provider ? data.provider:this.activeProvider;
		const policyNumber = this.activePolicyNumber;
		data.customerID = this.customerId;
		this.deleteFn$({ data, provider, policyNumber })
			.pipe(
				tap((x) => {
					if (x) {
						this.lrForms
							?.find(
								(lr) =>
									lr.form.value.customerServiceID === data.customerServiceID
							)
							.formSaving(false);
						this.activeProvider = provider;
						this.activePolicyNumber = policyNumber;
					}
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(
				() => {
					this.lrForms
						?.find(
							(x) => x.form.value.customerServiceID === data.customerServiceID
						)
						.formSaving(false);
				},
				() => {
					this.lrForms
						?.find(
							(x) => x.form.value.customerServiceID === data.customerServiceID
						)
						.formSaving(false);
				},
				() => {
					this.lrForms
						?.find(
							(x) => x.form.value.customerServiceID === data.customerServiceID
						)
						.formSaving(false);
				}
			);
	}

	archiveLr(req: { lr; isArchive }) {
		this.lrForms
			?.find(
				(lr) => lr.form.value.customerServiceID === req.lr.customerServiceID
			)
			.formSaving(true);
		const provider = this.activeProvider;
		const policyNumber = this.activePolicyNumber;
		const newReq = Object.assign({}, req.lr);
		newReq.customerID = this.customerId;

		this.archiveFn$({
			data: newReq,
			provider,
			policyNumber,
			isArchive: req.isArchive,
		})
			.pipe(
				tap((x) => {
					if (x) {
						this.lrForms
							?.find(
								(lr) =>
									lr.form.value.customerServiceID === req.lr.customerServiceID
							)
							.formSaving(false);
					}
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(
				() => {
					this.lrForms
						?.find(
							(lr) =>
								lr.form.value.customerServiceID === req.lr.customerServiceID
						)
						.formSaving(false);
				},
				() => {
					this.lrForms
						?.find(
							(lr) =>
								lr.form.value.customerServiceID === req.lr.customerServiceID
						)
						.formSaving(false);
				},
				() => {
					this.lrForms
						?.find(
							(lr) =>
								lr.form.value.customerServiceID === req.lr.customerServiceID
						)
						.formSaving(false);
				}
			);
	}

	upsertDocument(id) {
		this.documentIsLoading = true;
		const provider = this.activeProvider;
		const policyNumber = this.activePolicyNumber;
		this.cd.detectChanges();

		this.updateDocumentFn$({
			id,
			customerId: this.customerId,
			provider,
			policyNumber,
		}).subscribe(
			(x) => {
				if (x) {
					this.zone.run(() => {
						this.currentLinkedDocumentId = id;
						this.currentLinkedDocument = id
							? documentUtil(this.document, +this.currentLinkedDocumentId)
							: null;
						this.cd.detectChanges();
					});
				}
			},
			() => {
				this.documentIsLoading = false;
				this.cd.detectChanges();
			},
			() => {
				this.documentIsLoading = false;
				this.cd.detectChanges();
			}
		);
	}

	addNewLRGroup() {
		this.isAddNewPolicy = true;
		this.isAddNewGroup = true;
		this.activeGroupName = '';
		this.activeProvider = '';
		this.activePolicyNumber = '';
		this.popupCount = 0;
		this.lrFormState = null;

		// Should add new data on the list
		this.lrInsuranceState.lRs = [
			...this.lrInsuranceState.lRs,
			{
				provider: '',
				policyNumber: '',
				customerServices: [],
				startDate: null,
				aPI: 0,
				linkDocument: '',
				color: 'dark',
			},
		];

		this.currentLinkedDocumentId = '';
		this.currentLinkedDocument = null;

		setTimeout(
			() =>
				(this.sidebarNav.tabs[this.sidebarNav.tabs.length - 1].active =
					true),
			0
		);
	}

	cancelNewGroup() {
		this.isAddNewPolicy = false;
		this.isAddNewGroup = false;
		this.popupCount = 0;

		// Removes last data on array
		this.lrInsuranceState.lRs.pop();

		this.sidebarNav.tabs[0].active = true;
	}

	addPolicyAlteration() {
		this.lrFormState = null;
		const lrState = this.lrInsuranceState.lRs?.find(
			(lr) =>
				lr.provider === this.activeProvider &&
				lr.policyNumber === this.activePolicyNumber
		);

		if (lrState) {
			const state = lrState.customerServices?.find(
				(cs) =>
					cs.policyType &&
					cs.policyType?.toLowerCase() === 'primary' &&
					cs.policyStatus &&
					cs.policyStatus?.toLowerCase() === 'inforce'
			);
			const state2 = lrState.customerServices?.find(
				(cs) => cs.policyType && cs.policyType?.toLowerCase() === 'primary'
			);

			state
				? (this.lrFormState = state)
				: state2
				? (this.lrFormState = state2)
				: (this.lrFormState = lrState.customerServices[0]);
		}
		this.isAddNewPolicy = true;
	}

	cancelAddPolicyAlteration() {
		this.isAddNewPolicy = false;
		this.popupCount = 0;
	}

	linkDocument() {
		const initState = {
			selectedDetail: 'Link Document',
			document: this.document,
			initialSelectedTab: ServicesCodes.LR?.toLowerCase(),
		};
		this.bsModalRef = this.modalService.show(LinkDocumentComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
		this.bsModalRef.content.getSelectedDocumentValue$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(
				(doc) => {
					// this.currentLinkedDocument = doc;
					this.zone.run(() => {
						this.documentIsLoading = true;
						this.upsertDocument(doc.id);
						this.hasSelectedDocument = true;
					});
				},
				() => {
					this.hasSelectedDocument = false;
				},
				() => {}
			);
	}

	unlinkedDocument() {
		this.documentIsLoading = true;
		this.upsertDocument('');
		this.hasSelectedDocument = false;
		this.cd.markForCheck();
	}

	selectGroupConfirm(lr) {
		// tabset triggers unwanted select method, added this validation to fix that bug
		if (!lr.provider) {
			return;
		}

		const selectedTab = lr.provider + '-' + lr.policyNumber;

		this.currentLinkedDocumentId = lr.linkDocument;
		this.currentLinkedDocument = this.currentLinkedDocumentId
			? documentUtil(this.document, +this.currentLinkedDocumentId)
			: null;
		this.activeProvider = lr.provider;
		this.activeLR = lr;
		this.activePolicyNumber = lr.policyNumber;

		if (
			!this.isAddNewGroup &&
			this.isAddNewPolicy &&
			(lr.provider !== this.activeProvider ||
				lr.policyNumber !== this.activePolicyNumber) &&
			this.popupCount < 1
		) {
			this.selectGroup(selectedTab);
		}

		if (this.isAddNewGroup) {
			this.activeProvider = '';
			this.activeLR = null;
			this.activePolicyNumber = '';
			this.selectGroup(selectedTab);
		}
	}

	selectGroup(selectedTab: string) {
		this.popupCount++;
		const groupNavId = this.activeProvider + '-' + this.activePolicyNumber;

		const confirm = new Observable((obs: Observer<any>) => {
			if (this.isAddNewPolicy && this.isAddNewGroup) {
				this.lrInsuranceState.lRs?.pop();
			}
			this.isAddNewPolicy = false;
			this.isAddNewGroup = false;
			this.popupCount = 0;
			this.refresh();

			setTimeout(
				() =>
					(this.sidebarNav.tabs.find((x) => x.id === selectedTab).active =
						true),
				2
			);
			obs.complete();
		});

		const decline = new Observable((obs: Observer<any>) => {
			this.popupCount = 0;
			this.refresh();
			obs.complete();
		});

		const initState = {
			header: 'Discard Confirmation',
			message: `Current information will be discarded?`,
			confirm$: confirm,
			decline$: decline,
		};

		this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});

		if (this.isAddNewPolicy && this.isAddNewGroup) {
			setTimeout(
				() =>
					(this.sidebarNav.tabs[this.sidebarNav.tabs.length - 1].active =
						true),
				2
			);
		} else {
			setTimeout(
				() =>
					(this.sidebarNav.tabs.find((x) => x.id === groupNavId).active = true),
				2
			);
		}
	}

	split = (data: any) => {
		this.confirmSaveLR(data, true);
	};

	deleteNote = (req: { noteId; data }) => {
		this.lrForms
			?.find(
				(lr) => lr.form.value.customerServiceID === req.data.customerServiceID
			)
			.formSaving(true);
		const provider = this.activeProvider;
		const policyNumber = this.activePolicyNumber;
		req.data.customerID = this.customerId;
		this.deleteNoteFn$({
			id: req.noteId,
			data: req.data,
			provider: this.activeProvider,
			policyNumber: this.activePolicyNumber,
		})
			.pipe(
				tap((x) => {
					if (x) {
						this.lrForms
							?.find(
								(lr) =>
									lr.form.value.customerServiceID === req.data.customerServiceID
							)
							.formSaving(false);
						this.activeProvider = provider;
						this.activePolicyNumber = policyNumber;
					}
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(
				() => {
					this.lrForms
						?.find(
							(lr) =>
								lr.form.value.customerServiceID === req.data.customerServiceID
						)
						.formSaving(false);
				},
				() => {
					this.lrForms
						?.find(
							(lr) =>
								lr.form.value.customerServiceID === req.data.customerServiceID
						)
						.formSaving(false);
				},
				() => {
					this.lrForms
						?.find(
							(lr) =>
								lr.form.value.customerServiceID === req.data.customerServiceID
						)
						.formSaving(false);
				}
			);
	};

	addNote(data) {
		data.customerID = this.customerId;
		this.addNoteFn$(data)
			.pipe(
				tap((x) => {
					if (x) {
						setTimeout(() => {
							this.isSaving = false;
							this.lrForms
								?.find(
									(lr) =>
										lr.form.value.customerServiceID === data.customerServiceID
								)
								.formSaving(false);
							this.lrForms
								?.find(
									(lr) =>
										lr.form.value.customerServiceID === data.customerServiceID
								)
								.collapseForm(true);
						}, 0);
					}
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(
				() => {
					this.lrForms
						?.find(
							(x) => x.form.value.customerServiceID === data.customerServiceID
						)
						.formSaving(false);
				},
				() => {
					const lr = this.lrForms?.find(
						(x) => x.form.value.customerServiceID === data.customerServiceID
					);
					if (lr) {
						lr.formSaving(false);
						lr.prepData();
					}
				},
				() => {
					this.lrForms
						?.find(
							(x) => x.form.value.customerServiceID === data.customerServiceID
						)
						.formSaving(false);
				}
			);
	}

	// updateReviewDateFn$ = (event: MatDatepickerInputEvent<Moment>) =>
	// 	of(event).pipe(
	// 		map((x) => (!!x?.value ? MomentUtil.formatToServerDate(x.value) : '')),
	// 		concatMap(this.updateNextReviewFn$)
	// 	);
	openClaimsAdvice(){
		const {clientsInvolved,customerServiceId} = this.getInfoOnActiveProvider()
		const selectedInfo = {
			clientsInvolved: clientsInvolved,
			customerID: this.customerId,
			processCode: AdviceProcessCode.LRClaim,
			adviser: this.adviser,
			customerServiceId:customerServiceId,
		}
		this.openAPModalFn$(selectedInfo);
	}

	getInfoOnActiveProvider(){
		const customerServiceId = [];
		const customerServiceList:LrCustomerServiceState[] = []
		this.activeLR?.customerServices.map(item=>{
			customerServiceList.push(LrInsuranceMapper.mapToView(item));
			customerServiceId.push(item.customerServiceID);
		});
		const clientsInvolved = this.uniqPolicyOwners(customerServiceList);
		return {customerServiceId,clientsInvolved}
	}

	uniqPolicyOwners(data:LrCustomerServiceState[]){
		return R.pipe(
			R.map(R.prop('policyOwners')),
			R.flatten,
			R.reject(R.isNil),
			R.uniq
		)(data);
	}

	updateReviewDate() {
		this.isSavingNRD = true;
		const formControl = this.form.get('lRNextReview');
		const formatedDate = !!formControl?.value
			? MomentUtil.formatToServerDate(formControl?.value)
			: null;

		// if (formatedDate) {
			const currentErrors = formControl.errors;
			// If there are errors and 'dateError' is present, remove it
			if (currentErrors && currentErrors.dateError) {
				delete currentErrors.dateError;

				// Set the updated errors
				formControl.setErrors(
					Object.keys(currentErrors).length > 0 ? currentErrors : null
				);
			}
			return this.updateNextReviewFn$(formatedDate)
				.pipe(take(1))
				.subscribe(() => {
					this.isSavingNRD = false;
				});
		// } else {
		// 	this.setLrReviewDate();
		// 	this.isSavingNRD = false;
		// }
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
function MomentToDatetimeString(value: Moment) {
	throw new Error('Function not implemented.');
}
