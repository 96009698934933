import { ProgressStatus } from "@shared/progress-popup/progress";


export enum Transfer {
   LIMIT = 1000
}

export enum TransferType {
	CLIENT = 'CTE',
	LEAD = 'LTE',
	LR = 'LRTE',
	UDE = 'UDTE',
	KSE = 'KSTE',
}

export type TransferExportPayload = {
  CustomerIds: number[];
  Field: string;
  NewValue: string;
  AdditionalValue?: string;
}

export interface ExportsState {
	staffId: number | null;
	exportId: string | null;
	reportCode: string  | null;
	reportLabel: string  | null;
	timeRemaining: number  | null;
  timeElapsed: number  | null;
	progress: number | null;
	status: ProgressStatus  | null;
	attempts: number | null;
	error: any;
}