<div class="activity-timeline" [class.disabled]="isSaving$ | async">
	<div class="activity-timeline__header clearfix mb-3">
		<div class="clearfix row align-items-center mb-2">
			<div class="col activity-timeline__header__title">Activity Timeline</div>
			<ng-container>
				<span
					class="col-auto activity-timeline__header__icon md-20 tap-text-color-h6 ng-star-inserted pr-0"
					id="activityTimelineOpenReferralPopUp"
					(click)="openClientReferralPopUp()"
				>
					<svg
						version="1.1"
						width="20"
						height="18"
						viewBox="0 0 173 191"
						xml:space="preserve"
						id="svg4"
						xmlns="http://www.w3.org/2000/svg"
						xmlns:svg="http://www.w3.org/2000/svg"
					>
						<defs id="defs1" />
						<g
							style="
								opacity: 1;
								fill: none;
								fill-rule: nonzero;
								stroke: none;
								stroke-width: 0;
								stroke-linecap: butt;
								stroke-linejoin: miter;
								stroke-miterlimit: 10;
								stroke-dasharray: none;
							"
							transform="matrix(2.81,0,0,2.81,1.4065934,1.4065934)"
							id="g4"
						>
							<path
								d="m 28.478591,34.933056 c -9.589,0 -17.39,-7.801 -17.39,-17.39 0,-9.5890008 7.801,-17.39000095 17.39,-17.39000095 9.589,0 17.39,7.80100015 17.39,17.39000095 0,9.589 -7.801,17.39 -17.39,17.39 z m 9.263251,-9.467704 c 0,5.729 -14.992251,2.467704 -9.263251,2.467704 5.729,0 4.663629,-4.708877 10.39,-10.39 4.917281,-4.878427 -0.459152,1.027876 -1.126749,7.922296 z"
								style="
									opacity: 1;
									fill: currentColor;
									fill-rule: nonzero;
									stroke: none;
									stroke-width: 1;
									stroke-linecap: butt;
									stroke-linejoin: miter;
									stroke-miterlimit: 10;
									stroke-dasharray: none;
								"
								stroke-linecap="round"
								id="path1"
							/>
							<path
								id="path2"
								style="
									opacity: 1;
									fill: currentColor;
									fill-rule: nonzero;
									stroke: none;
									stroke-width: 1;
									stroke-linecap: butt;
									stroke-linejoin: miter;
									stroke-miterlimit: 10;
									stroke-dasharray: none;
								"
								d="M 36.406332,39.57061 H 20.765346 c -11.1399885,0 -20.20267981,9.063386 -20.20267981,20.203375 v 3.391209 c 0,1.932998 1.56664051,3.500334 3.49963861,3.500334 1.932998,0 3.500334,-1.567336 3.500334,-3.500334 V 59.77468 c 0,-7.279993 5.9227142,-13.202708 13.2027072,-13.202708 h 15.710492 z"
							/>

							<path
								d="m 58.487502,52.155636 -8.515,-8.515 c -1.367,-1.367 -3.582,-1.367 -4.949,0 -1.367,1.366 -1.367,3.583 0,4.949 l 2.54,2.54 -9.058674,-0.149662 c -1.932736,-0.03193 -3.5,1.567 -3.5,3.5 0,1.933 1.567264,3.468068 3.5,3.5 l 9.058674,0.149662 -2.541,2.541 c -1.367,1.367 -1.367,3.583 0,4.949 0.684,0.684 1.579,1.025 2.475,1.025 0.896,0 1.792,-0.342 2.475,-1.025 l 8.515,-8.516 c 1.367,-1.366 1.367,-3.582 0,-4.948 z"
								style="
									opacity: 1;
									fill: currentColor;
									fill-rule: nonzero;
									stroke: none;
									stroke-width: 1;
									stroke-linecap: butt;
									stroke-linejoin: miter;
									stroke-miterlimit: 10;
									stroke-dasharray: none;
								"
								stroke-linecap="round"
								id="path4"
							/>
						</g>
					</svg>
				</span>
			</ng-container>
			<ng-container>
				<span
					class="col-auto activity-timeline__header__icon material-icons md-20 tap-text-color-h6 ng-star-inserted pr-0"
					id="activityTimelineAddPhoneCallBtn"
					(click)="addPhoneCall()"
					>add_ic_call</span
				>
			</ng-container>
			<!-- Restriction for lead -->
			<ng-container *ngIf="isLead">
				<ng-container *appRestrictTo="['FALN']">
					<span
						class="col-auto activity-timeline__header__icon material-icons md-20 tap-text-color-h6 pr-2"
						id="activityTimelineAddNoteBtn"
						(click)="addNote()"
						>note_add</span
					>
				</ng-container>
				<ng-container *appRestrictTo="['FALA']">
					<span
						class="col-auto activity-timeline__header__icon material-icons md-20 tap-text-color-h6 pl-2"
						id="activityTimelineAddActivityBtn"
						(click)="addActivity()"
						>calendar_today</span
					>
				</ng-container>
			</ng-container>
			<!-- Restriction for current client -->
			<ng-container *ngIf="!isLead">
				<ng-container *appRestrictTo="['FAN']">
					<span
						class="col-auto activity-timeline__header__icon material-icons md-20 tap-text-color-h6 pr-2"
						id="activityTimelineAddNoteBtn"
						(click)="addNote()"
						>note_add</span
					>
				</ng-container>
				<ng-container *appRestrictTo="['FACA']">
					<span
						class="col-auto activity-timeline__header__icon material-icons md-20 tap-text-color-h6 pl-2"
						id="activityTimelineAddActivityBtn"
						(click)="addActivity()"
						>calendar_today</span
					>
				</ng-container>
			</ng-container>
		</div>
	</div>

	<div
		class="activity-timeline__add-note clearfix mb-3"
		[class.d-none]="!isAddNote"
	>
		<form novalidate [formGroup]="formNote" (ngSubmit)="saveNote()">
			<div class="form-group mb-2">
				<textarea
					class="form-control theme-crm-field-disabled"
					formControlName="note"
					rows="5"
				></textarea>
			</div>
			<div class="form-group mb-2 d-flex justify-content-end">
				<app-manual-loader-mini
					[isLoading]="isAddingNote$ | async"
					class="p-0"
				></app-manual-loader-mini>
				<ng-container *ngIf="!(isAddingNote$ | async)">
					<button
						class="tap-btn tap-btn--default mr-2"
						type="submit"
						[disabled]="!formNote.valid"
					>
						Save
					</button>
					<button
						class="tap-btn tap-btn--default"
						type="reset"
						(click)="cancelAddNote()"
					>
						Cancel
					</button>
				</ng-container>
			</div>
		</form>
	</div>

	<div class="activity-timeline__container-flow clearfix">
		<!-- Pending -->
		<div class="activity-timeline__container-flow__header pb-3">
			<h5 class="tap-text-primary font-weight-bolder">PENDING</h5>
		</div>
		<div class="activity-timeline__container-flow__list pb-3">
			<div class="activity-timeline__bar"></div>
			<div
				class="activity-timeline__container-flow__list__item d-flex"
				*ngFor="let ac of pendingActivities | sortBy: ['dueDate'] : pendingActivitiesSortBy; let i = index; trackBy: tackByFn"
				#detailsH
			>
				<div class="activity-timeline__container-flow__list__item__icon">
					<span class="material-icons">
						{{ isCall(ac.activityType) ? 'phone' : 'event' }}
					</span>
				</div>
				<div
					class="activity-timeline__container-flow__list__item__info col"
					[class.has-overlay]="(ac?.details && ac.details.length > 140) || hasShowMoreBtn(detailsH, 72)"
					[class.h-auto]="existingActiveShowMoreIds(ac.activityId)"
				>
					<app-manual-loader
						[isLoading]="(isSaving$ | async) && pendingActiveId === ac.activityId"
						class="p-0"
					></app-manual-loader>
					<!-- Restriction for lead -->
					<ng-container *ngIf="isLead">
						<ng-container *appRestrictTo="['FELA', 'FXLA', 'FDLA']">
							<div class="btn-group dropdown" dropdown>
								<button class="tap-btn tap-btn-outline p-0" dropdownToggle>
									<i class="material-icons">more_horiz</i>
								</button>
								<ul class="dropdown-menu" *dropdownMenu>
									<ng-container *appRestrictTo="['FELA']">
										<a
											class="dropdown-item px-2 py-1"
											(click)="editActivity(ac); pendingActiveId = ac.activityId"
											>EDIT</a
										>
									</ng-container>
									<ng-container *appRestrictTo="['FXLA']">
										<a
											class="dropdown-item px-2 py-1"
											(click)="confirmCancelActivity(ac); pendingActiveId = ac.activityId"
											>CANCEL</a
										>
									</ng-container>
									<ng-container *appRestrictTo="['FDLA']">
										<a
											class="dropdown-item px-2 py-1"
											(click)="confirmDeleteActivity(ac); pendingActiveId = ac.activityId"
											>DELETE</a
										>
									</ng-container>
								</ul>
							</div>
						</ng-container>
					</ng-container>
					<!-- Restriction for current client -->
					<ng-container *ngIf="!isLead">
						<ng-container *appRestrictTo="['FECA', 'FXCA', 'FDCA']">
							<div class="btn-group dropdown" dropdown>
								<button class="tap-btn tap-btn-outline p-0" dropdownToggle>
									<i class="material-icons">more_horiz</i>
								</button>
								<ul class="dropdown-menu" *dropdownMenu>
									<ng-container *appRestrictTo="['FECA']">
										<a
											class="dropdown-item px-2 py-1"
											(click)="editActivity(ac); pendingActiveId = ac.activityId"
											>EDIT</a
										>
									</ng-container>
									<ng-container *appRestrictTo="['FXCA']">
										<a
											class="dropdown-item px-2 py-1"
											(click)="confirmCancelActivity(ac); pendingActiveId = ac.activityId"
											>CANCEL</a
										>
									</ng-container>
									<ng-container *appRestrictTo="['FDCA']">
										<a
											class="dropdown-item px-2 py-1"
											(click)="confirmDeleteActivity(ac); pendingActiveId = ac.activityId"
											>DELETE</a
										>
									</ng-container>
								</ul>
							</div>
						</ng-container>
					</ng-container>
					<div class="item-wrapper">
						<div
							class="font-weight-bold clickable"
							(click)="editActivity(ac); pendingActiveId = ac.activityId"
						>
							{{ ac.activityName }}
						</div>
						<div *ngIf="!!ac.meeting" class="meeting-type">
							Meeting Type: {{ ac.meeting || "" }}
						</div>
						<!-- Please do not format this. It will print any new line, spaces, and tabs -->
						<div class="note-details text-break">
							{{ ac.details | slice : 0 :
							(existingActiveShowMoreIds(ac.activityId) ? (ac.details?.length ||
							0) : 140) }}{{ !!ac.details ? '- ' : '' }}Created by {{
							ac?.createdByName || "" }}
						</div>
					</div>
				</div>
				<ng-container
					*ngIf="(ac?.details && ac.details.length > 140) || hasShowMoreBtn(detailsH, 72)"
				>
					<div class="activity-timeline__container-flow__list__item__more">
						<span
							class="font-weight-bold clickable float-right"
							(click)="setActiveShowMoreIds(ac.activityId)"
						>
							<span class="material-icons"
								>{{ existingActiveShowMoreIds(ac.activityId) ? 'expand_less' :
								'expand_more' }}</span
							>
						</span>
					</div>
				</ng-container>
				<div class="activity-timeline__container-flow__list__item__footer">
					<div class="d-flex justify-content-between">
						<div class="text-muted text-truncate">
							{{ ac.dueDate | momentDatetime: true:ac.dueTime }}
						</div>
						<div class="text-muted text-truncate">
							{{ ac?.assignedToAdviserName }}
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Pinned -->
		<div
			class="activity-timeline__container-flow__header pb-3"
			#completedNotesElement
		>
			<div class="d-flex justify-content-between">
				<h5 class="tap-text-primary font-weight-bolder">PINNED</h5>
			</div>
		</div>
		<div class="activity-timeline__container-flow__list">
			<app-manual-loader
				[isLoading]="isloadingNotes$ | async"
			></app-manual-loader>
			<div class="activity-timeline__vert-line"></div>
			<div
				class="activity-timeline__container-flow__list__item d-flex"
				*ngFor="let n of (pinnedNotes$ | async); trackBy: trackByNotesFn; let i = index"
				#noteH
			>
				<div class="activity-timeline__container-flow__list__item__icon">
					<ng-container
						*ngIf="!n.activityType || n.activityType === noteTypes.Note || n.activityType === noteTypes.Asset || n.activityType === noteTypes.Liability"
					>
						<span class="material-icons"> description </span>
					</ng-container>
					<ng-container
						*ngIf="n.activityType === customerTypes.PrimaryCustomerIndividual"
					>
						<i class="fas fa-user"></i>
					</ng-container>
					<ng-container
						*ngIf="n.activityType === customerTypes.SecondaryCustomerIndividual"
					>
						<i class="fas fa-user"></i>
					</ng-container>
					<ng-container
						*ngIf="n.activityType === customerTypes.PrimaryCustomerCompany"
					>
						<i class="fas fa-building"></i>
					</ng-container>
					<ng-container
						*ngIf="n.activityType === customerTypes.SecondaryCustomerTrust"
					>
						<i class="fas fa-users"></i>
					</ng-container>
					<ng-container
						*ngIf="n.activityType === customerTypes.SecondaryCustomerProfessional"
					>
						<i class="fas fa-user-tie"></i>
					</ng-container>
					<ng-container *ngIf="n.activityType === noteTypes.LR">
						<i class="fas fa-life-ring"></i>
					</ng-container>
					<ng-container *ngIf="n.activityType === noteTypes.Mortgage">
						<span class="material-icons"> account_balance </span>
					</ng-container>
					<ng-container *ngIf="n.activityType === noteTypes.Property">
						<i class="fas fa-home"></i>
					</ng-container>
					<ng-container *ngIf="n.activityType === noteTypes.FG">
						<span class="svg">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								enable-background="new 0 0 24 24"
								viewBox="0 0 24 24"
								height="26px"
							>
								<g>
									<rect fill="none" height="24" width="24" />
								</g>
								<g>
									<g />
									<g>
										<path
											fill="#a6a8a9"
											d="M20.57,10.66C20.43,10.26,20.05,10,19.6,10h-7.19c-0.46,0-0.83,0.26-0.98,0.66L10,14.77l0.01,5.51 c0,0.38,0.31,0.72,0.69,0.72h0.62C11.7,21,12,20.62,12,20.24V19h8v1.24c0,0.38,0.31,0.76,0.69,0.76h0.61 c0.38,0,0.69-0.34,0.69-0.72L22,18.91v-4.14L20.57,10.66z M12.41,11h7.19l1.03,3h-9.25L12.41,11z M12,17c-0.55,0-1-0.45-1-1 s0.45-1,1-1s1,0.45,1,1S12.55,17,12,17z M20,17c-0.55,0-1-0.45-1-1s0.45-1,1-1s1,0.45,1,1S20.55,17,20,17z"
										/>
										<polygon
											fill="#a6a8a9"
											points="14,9 15,9 15,3 7,3 7,8 2,8 2,21 3,21 3,9 8,9 8,4 14,4"
										/>
										<rect fill="#a6a8a9" height="2" width="2" x="5" y="11" />
										<rect fill="#a6a8a9" height="2" width="2" x="10" y="5" />
										<rect fill="#a6a8a9" height="2" width="2" x="5" y="15" />
										<rect fill="#a6a8a9" height="2" width="2" x="5" y="19" />
									</g>
								</g>
							</svg>
						</span>
					</ng-container>
					<ng-container
						*ngIf="n.activityType === noteTypes.KiwiSaver || n.activityType === serviceCodes.KiwiSaver"
					>
						<i class="fas fa-kiwi-bird"></i>
					</ng-container>
					<ng-container
						*ngIf="n.activityType === noteTypes.Investment || n.activityType === serviceCodes.Investment"
					>
						<i class="fas fa-chart-line"></i>
					</ng-container>
					<ng-container *ngIf="n.activityType && n.activityName">
						<span class="material-icons">
							{{ isCall(n.activityType) ? 'phone' : 'event' }}
						</span>
					</ng-container>
					<ng-container *ngIf="checkIfEmail(n.activityType)">
						<i class="fas fa-envelope"></i>
					</ng-container>
					<ng-container *ngIf="n.activityType === noteTypes.AdviceProcess">
						<i class="fas fa-book-open"></i>
					</ng-container>
				</div>
				<div
					class="activity-timeline__container-flow__list__item__info col"
					[class.has-overlay]="(n?.notes && n.notes.length > 190) || hasShowMoreBtn(noteH, 72)"
					[class.h-auto]="existingActiveShowMoreIds(n.notesID)"
				>
					<app-manual-loader
						[isLoading]="(isSaving$ | async) && completedActiveId === n.notesID"
						class="p-0"
					>
					</app-manual-loader>
					<!-- Restriction for lead -->

					<div class="btn-group dropdown" dropdown>
						<i class="material-icons md-18">push_pin</i>
						<button class="tap-btn tap-btn-outline p-0" dropdownToggle>
							<i class="material-icons">more_horiz</i>
						</button>
						<ul class="dropdown-menu" *dropdownMenu>
							<a class="dropdown-item px-2 py-1" (click)="pinNote(n, false)"
								>UNPIN</a
							>
						</ul>
					</div>
					<div class="item-wrapper">
						<ng-container
							*ngIf="!!n?.activityType && n?.activityType !== noteTypes.Note"
						>
							<div class="font-weight-bold">{{ n.activityName }}</div>
						</ng-container>
						<!-- Please do not format this. It will print any new line, spaces, and tabs -->
						<div
							class="note-details mt-1 mb-3 text-break"
							[innerHTML]="n.notes | slice: 0:(existingActiveShowMoreIds(n.notesID) ? n.notes.length : 190) | transformLink"
						></div>
					</div>
				</div>
				<ng-container
					*ngIf="(n?.notes && n.notes.length > 190) || hasShowMoreBtn(noteH, 72)"
				>
					<div class="activity-timeline__container-flow__list__item__more">
						<span
							class="font-weight-bold clickable float-right"
							(click)="setActiveShowMoreIds(n.notesID)"
						>
							<span class="material-icons"
								>{{ existingActiveShowMoreIds(n.notesID) ? 'expand_less' :
								'expand_more' }}</span
							>
						</span>
					</div>
				</ng-container>
				<div class="activity-timeline__container-flow__list__item__footer">
					<div class="d-flex justify-content-between">
						<div class="text-muted text-truncate">
							{{ formatToDisplayDatetime(n.createDateTime) }}
						</div>
						<ng-container
							*ngIf="!!n?.activityType && !!n?.activityName; else isNoteFooter"
						>
							<div class="text-muted text-truncate">
								{{ n?.assignedToAdviserName }}
							</div>
						</ng-container>
						<ng-template #isNoteFooter>
							<div class="text-muted text-truncate">{{ n.staffName }}</div>
						</ng-template>
					</div>
				</div>
			</div>
			<div
				class="activity-timeline__container-flow__list__item d-flex mb-3"
			></div>
		</div>

		<!-- Completed -->
		<div
			class="activity-timeline__container-flow__header pb-3"
			#completedNotesElement
		>
			<div class="d-flex justify-content-between">
				<h5 class="tap-text-primary font-weight-bolder">COMPLETED</h5>
				<div class="d-flex activity-timeline__container-flow__header__tabs">
					<h6
						class="mb-0 px-1 py-1"
						[class.active]="notesFilter === noteFilters.All"
						(click)="filterNotes(noteFilters.All)"
					>
						All
					</h6>
					<h6
						class="mb-0 px-1 py-1"
						[class.active]="notesFilter === noteFilters.Activities"
						(click)="filterNotes(noteFilters.Activities)"
					>
						Activities
					</h6>
					<h6
						class="mb-0 px-1 py-1"
						[class.active]="notesFilter === noteFilters.Notes"
						(click)="filterNotes(noteFilters.Notes)"
					>
						Notes
					</h6>
				</div>
			</div>
		</div>
		<div class="activity-timeline__container-flow__list">
			<app-manual-loader
				[isLoading]="isloadingNotes$ | async"
			></app-manual-loader>
			<div class="activity-timeline__vert-line"></div>
			<div
				class="activity-timeline__container-flow__list__item d-flex"
				*ngFor="let n of notes$ | async; trackBy: trackByNotesFn; let i = index"
				#noteH
			>
				<div class="activity-timeline__container-flow__list__item__icon">
					<ng-container
						*ngIf="!n.activityType || n.activityType === noteTypes.Note || n.activityType === noteTypes.Asset || n.activityType === noteTypes.Liability"
					>
						<span class="material-icons"> description </span>
					</ng-container>
					<ng-container
						*ngIf="n.activityType === customerTypes.PrimaryCustomerIndividual"
					>
						<i class="fas fa-user"></i>
					</ng-container>
					<ng-container
						*ngIf="n.activityType === customerTypes.SecondaryCustomerIndividual"
					>
						<i class="fas fa-user"></i>
					</ng-container>
					<ng-container
						*ngIf="n.activityType === customerTypes.PrimaryCustomerCompany"
					>
						<i class="fas fa-building"></i>
					</ng-container>
					<ng-container
						*ngIf="n.activityType === customerTypes.SecondaryCustomerTrust"
					>
						<i class="fas fa-users"></i>
					</ng-container>
					<ng-container
						*ngIf="n.activityType === customerTypes.SecondaryCustomerProfessional"
					>
						<i class="fas fa-user-tie"></i>
					</ng-container>
					<ng-container *ngIf="n.activityType === noteTypes.LR">
						<i class="fas fa-life-ring"></i>
					</ng-container>
					<ng-container *ngIf="n.activityType === noteTypes.Mortgage">
						<span class="material-icons"> account_balance </span>
					</ng-container>
					<ng-container *ngIf="n.activityType === noteTypes.Property">
						<i class="fas fa-home"></i>
					</ng-container>
					<ng-container *ngIf="n.activityType === noteTypes.FG">
						<span class="svg">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								enable-background="new 0 0 24 24"
								viewBox="0 0 24 24"
								height="26px"
							>
								<g>
									<rect fill="none" height="24" width="24" />
								</g>
								<g>
									<g />
									<g>
										<path
											fill="#a6a8a9"
											d="M20.57,10.66C20.43,10.26,20.05,10,19.6,10h-7.19c-0.46,0-0.83,0.26-0.98,0.66L10,14.77l0.01,5.51 c0,0.38,0.31,0.72,0.69,0.72h0.62C11.7,21,12,20.62,12,20.24V19h8v1.24c0,0.38,0.31,0.76,0.69,0.76h0.61 c0.38,0,0.69-0.34,0.69-0.72L22,18.91v-4.14L20.57,10.66z M12.41,11h7.19l1.03,3h-9.25L12.41,11z M12,17c-0.55,0-1-0.45-1-1 s0.45-1,1-1s1,0.45,1,1S12.55,17,12,17z M20,17c-0.55,0-1-0.45-1-1s0.45-1,1-1s1,0.45,1,1S20.55,17,20,17z"
										/>
										<polygon
											fill="#a6a8a9"
											points="14,9 15,9 15,3 7,3 7,8 2,8 2,21 3,21 3,9 8,9 8,4 14,4"
										/>
										<rect fill="#a6a8a9" height="2" width="2" x="5" y="11" />
										<rect fill="#a6a8a9" height="2" width="2" x="10" y="5" />
										<rect fill="#a6a8a9" height="2" width="2" x="5" y="15" />
										<rect fill="#a6a8a9" height="2" width="2" x="5" y="19" />
									</g>
								</g>
							</svg>
						</span>
					</ng-container>
					<ng-container
						*ngIf="n.activityType === noteTypes.KiwiSaver || n.activityType === serviceCodes.KiwiSaver"
					>
						<i class="fas fa-kiwi-bird"></i>
					</ng-container>
					<ng-container
						*ngIf="n.activityType === noteTypes.Investment || n.activityType === serviceCodes.Investment"
					>
						<i class="fas fa-chart-line"></i>
					</ng-container>
					<ng-container *ngIf="n.activityType && n.activityName">
						<span class="material-icons">
							{{ isCall(n.activityType) ? 'phone' : 'event' }}
						</span>
					</ng-container>
					<ng-container *ngIf="checkIfEmail(n.activityType)">
						<i class="fas fa-envelope"></i>
					</ng-container>
					<ng-container *ngIf="n.activityType === noteTypes.AdviceProcess">
						<i class="fas fa-book-open"></i>
					</ng-container>
				</div>
				<div
					class="activity-timeline__container-flow__list__item__info col"
					[class.has-overlay]="(n?.notes && n.notes.length > 190) || hasShowMoreBtn(noteH, 72)"
					[class.h-auto]="existingActiveShowMoreIds(n.notesID)"
				>
					<app-manual-loader
						[isLoading]="(isSaving$ | async) && completedActiveId === n.notesID"
						class="p-0"
					>
					</app-manual-loader>
					<!-- Restriction for lead -->
					<ng-container *ngIf="isLead">
						<div class="btn-group dropdown" dropdown>
							<i *ngIf="n.isPinned" class="material-icons md-18">push_pin</i>

							<button class="tap-btn tap-btn-outline p-0" dropdownToggle>
								<i class="material-icons">more_horiz</i>
							</button>
							<ul class="dropdown-menu" *dropdownMenu>
								<ng-container *appRestrictTo="['FDLN']">
									<a
										class="dropdown-item px-2 py-1"
										(click)="confirmDeleteNote(n); completedActiveId = n.notesID"
										>DELETE</a
									>
								</ng-container>
								<a
									*ngIf="!n.isPinned"
									class="dropdown-item px-2 py-1"
									(click)="pinNote(n, true)"
									>PIN</a
								>
								<a
									*ngIf="n.isPinned"
									class="dropdown-item px-2 py-1"
									(click)="pinNote(n, false)"
									>UNPIN</a
								>
							</ul>
						</div>
					</ng-container>
					<!-- Restriction for current client -->
					<ng-container *ngIf="!isLead">
						<div class="btn-group dropdown" dropdown>
							<i *ngIf="n.isPinned" class="material-icons md-18">push_pin</i>

							<button class="tap-btn tap-btn-outline p-0" dropdownToggle>
								<i class="material-icons">more_horiz</i>
							</button>
							<ul class="dropdown-menu" *dropdownMenu>
								<ng-container *appRestrictTo="['FDN']">
									<a
										class="dropdown-item px-2 py-1"
										(click)="confirmDeleteNote(n); completedActiveId = n.notesID"
										>DELETE</a
									>
								</ng-container>
								<a
									*ngIf="!n.isPinned"
									class="dropdown-item px-2 py-1"
									(click)="pinNote(n, true)"
									>PIN</a
								>
								<a
									*ngIf="n.isPinned"
									class="dropdown-item px-2 py-1"
									(click)="pinNote(n, false)"
									>UNPIN</a
								>
							</ul>
						</div>
					</ng-container>
					<div class="item-wrapper">
						<ng-container
							*ngIf="!!n?.activityType && n?.activityType !== noteTypes.Note"
						>
							<div class="font-weight-bold">{{ n.activityName }}</div>
						</ng-container>
						<!-- Please do not format this. It will print any new line, spaces, and tabs -->
						<div
							class="note-details mt-1 mb-3 text-break"
							[innerHTML]="n.notes | slice: 0:(existingActiveShowMoreIds(n.notesID) ? n.notes.length : 190) | transformLink"
						></div>
					</div>
				</div>
				<ng-container
					*ngIf="(n?.notes && n.notes.length > 190) || hasShowMoreBtn(noteH, 72)"
				>
					<div class="activity-timeline__container-flow__list__item__more">
						<span
							class="font-weight-bold clickable float-right"
							(click)="setActiveShowMoreIds(n.notesID)"
						>
							<span class="material-icons"
								>{{ existingActiveShowMoreIds(n.notesID) ? 'expand_less' :
								'expand_more' }}</span
							>
						</span>
					</div>
				</ng-container>
				<div class="activity-timeline__container-flow__list__item__footer">
					<div class="d-flex justify-content-between">
						<div class="text-muted text-truncate">
							{{ formatToDisplayDatetime(n.createDateTime) }}
						</div>
						<ng-container
							*ngIf="!!n?.activityType && !!n?.activityName; else isNoteFooter"
						>
							<div class="text-muted text-truncate">
								{{ n?.assignedToAdviserName }}
							</div>
						</ng-container>
						<ng-template #isNoteFooter>
							<div class="text-muted text-truncate">{{ n.staffName }}</div>
						</ng-template>
					</div>
				</div>
			</div>
			<div
				class="activity-timeline__container-flow__list__item d-flex mb-3"
			></div>
		</div>
	</div>

	<ng-container
		*ngIf="isLastPage && completedList && completedList?.length > 10"
	>
		<button
			(click)="backToRecentNotes()"
			class="tap-btn tap-btn--default"
			type="button"
		>
			Back to recent notes
		</button>
	</ng-container>
</div>
