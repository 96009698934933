import { PropertyAssetCustomerServiceState } from './property-asset';
import MomentUtil from '../../../../util/moment.util';
import { Moment } from 'moment';
import { util } from '../../../../core/util/util.service';

export class PropertyAssetMapper {
	/**
	 * Map to view
	 */
	public static mapToView(data: PropertyAssetCustomerServiceState): PropertyAssetCustomerServiceState {
		if (!data) {
			return { ...data };
		}
		return {
			...data,
			// tslint:disable-next-line: max-line-length
			propertyOwner: typeof data.propertyOwner === 'string' ? (data.propertyOwner ? JSON.parse(data.propertyOwner) : null) : data.propertyOwner,
			createDateTime: util.isValidMoment(data.createDateTime) ? data.createDateTime : this.formatDateToMoment(data.createDateTime),
			gRM1Date: util.isValidMoment(data.gRM1Date) ? data.gRM1Date : this.formatDateToMoment(data.gRM1Date),
			gRM2Date: util.isValidMoment(data.gRM2Date) ? data.gRM2Date : this.formatDateToMoment(data.gRM2Date),
			purchaseDate: util.isValidMoment(data.purchaseDate) ? data.purchaseDate : this.formatDateToMoment(data.purchaseDate),
			// tslint:disable-next-line: max-line-length
			notes: (data.notes && data.notes.length > 0) ? data.notes?.filter(x => util.isValidMoment(data.createDateTime) ? data.createDateTime : this.formatDateToMoment(x.createDateTime)) : []
		};
	}

	/**
	 * Map to update
	 */
	public static mapToUpsert(data: PropertyAssetCustomerServiceState): PropertyAssetCustomerServiceState {
		return {
			...data,
			gRM1Value: data.gRM1Value ? +data.gRM1Value : null,
			gRM2Value: data.gRM2Value ? +data.gRM2Value : null,
			rentalIncome: data.rentalIncome ? +data.rentalIncome : null,
			propertyOwner: data.propertyOwner && data.propertyOwner.length > 0 ? JSON.stringify(data.propertyOwner) : null,
			createDateTime: MomentUtil.formatDateToServerDate(data.createDateTime),
			gRM1Date: MomentUtil.formatDateToServerDate(data.gRM1Date),
			gRM2Date: MomentUtil.formatDateToServerDate(data.gRM2Date),
			purchaseDate: MomentUtil.formatDateToServerDate(data.purchaseDate),
		};
	}

	/**
	 * Format date to Moment
	 * @param date string or Moment date
	 */
	static formatDateToMoment(date: string | Moment): Moment {
		return typeof date === 'string'
			? util.DateStringToMomentLoose(date)
			: util.DateStringToMomentLoose(MomentUtil.formatToDisplayDate(date));
	}
}
