import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	ViewChild,
	OnChanges,
	ChangeDetectorRef,
	SimpleChanges,
	ElementRef,
	OnDestroy,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { withLatestFrom, map, take, filter, tap, takeUntil } from 'rxjs/operators';
import { Observable, zip, of, Observer } from 'rxjs';

import { BLStaffsQuery } from '../../../domain/bl-staff/bl-staffs.query';
import { NoWhitespaceValidator } from '../../directive/no-whitespace/no-whitespace.directive';

import { LrCustomerServiceState } from '../../models/services/lr-insurance/lr-insurance.model';
import { LRProviderGroupState } from '../../models/services/lr-insurance/lr-provider-group.model';
import { LrInsuranceMapper } from '../../models/services/lr-insurance/lr-insurance.mapper';
import { ViewDisplayValue } from '../../../shared/models/_general/display-value.viewmodel';

import { LinkDocumentComponent } from '../link-document/link-document.component';
import { TransferServiceComponent } from '../transfer-service/transfer-service.component';

import { DeleteModalComponent } from '../../modal/delete-modal/delete-modal.component';
import { ArchiveModalComponent } from '../../modal/archive-modal/archive-modal.component';
import { CollapseComponent } from '../../collapse/collapse.component';

import { ConfirmModalComponent } from '../../modal/confirm-modal/confirm-modal.component';
import { NoteAddModalComponent } from '../../modal/note-modal/note-modal.component';
import { patchValue } from '../service-utils/service.util';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { DateInputComponent } from '../../date-input/date-input.component';
import { UserQuery } from 'src/app/domain/user/user.query';
import { ServicesCodes } from '../../models/services/services.model';
import { Fields, getInvalidWarning, getRequiredWarning } from '../../error-message/error-message';
import { ClientProfileService } from '@modules/crm/client-profile/states/client-profile.service';
import { ComponentBase } from '@core/base/component-base';
import { BusinessProfileService } from '@modules/crm/business-profile/states/business-profile.service';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { ClaimsTable } from '@shared/models/advice-process/advice-process.model';

@Component({
	selector: 'app-lr-insurance-service-form',
	templateUrl: './lr-insurance-service-form.component.html',
	styleUrls: ['./lr-insurance-service-form.component.scss'],
})
export class LrInsuranceServiceFormComponent
	extends ComponentBase
	implements OnInit, OnChanges, OnDestroy
{
	form: UntypedFormGroup;

	currentLinkedDocument: string;
	public bsModalRef: BsModalRef;

	@Input() isLead: boolean;
	@Input() addMode: boolean;
	@Input() isNewGroup: boolean;
	@Input() isCompany: boolean;

	// Actions
	@Output() saveEvent = new EventEmitter<any>();
	@Output() cancelEvent = new EventEmitter<any>();
	@Output() cancelAddEvent = new EventEmitter<any>();
	@Output() archiveEvent = new EventEmitter<any>();
	@Output() deleteEvent = new EventEmitter<any>();
	@Output() deleteNoteEvent = new EventEmitter<any>();
	@Output() splitEvent = new EventEmitter<any>();
	@Output() addNoteEvent = new EventEmitter<any>();

	// Dropdown Values
	@Input() providers: ViewDisplayValue[]; // LRP
	@Input() policyStatuses: ViewDisplayValue[]; // LRPS
	@Input() products: ViewDisplayValue[]; // LRPR
	@Input() policyTypes: ViewDisplayValue[]; // LRPT
	@Input() claimStatus: ViewDisplayValue[]; // LRCS
	@Input() discounts: ViewDisplayValue[]; // LRPD
	@Input() payments: ViewDisplayValue[]; // LRPF
	@Input() cancellationCodes: ViewDisplayValue[]; // LRCC
	@Input() retentionStatus: ViewDisplayValue[]; // LRRS
	@Input() policyOwners: ViewDisplayValue[];
	@Input() lrList: LRProviderGroupState[];

	@Input() lr: LrCustomerServiceState;
	@Input() groupName: string;
	@Input() formId: string;

	@Input() isSaving = false;
	isEditForm = false;
	notes: any[];
	hasPermission = this.userQuery.hasPermission$;

	notesHeader = [
		{ title: 'Created By' },
		{ title: 'Created date & Time' },
		{ title: 'Details' },
		{ title: ' ' },
	];

	@ViewChild(CollapseComponent) collapse: CollapseComponent;
	@ViewChild('formProp') formProp: ElementRef;

	@ViewChild('startDateInput') startDateInput: DateInputComponent;
	@ViewChild('anniversaryDateInput') anniversaryDateInput: DateInputComponent;
	@ViewChild('startTransDateInput') startTransDateInput: DateInputComponent;
	@ViewChild('cancellationDateInput') cancellationDateInput: DateInputComponent;
	@ViewChild('endTransDateInput') endTransDateInput: DateInputComponent;
	@ViewChild('submittedDateInput') submittedDateInput: DateInputComponent;

	@Input() deceasedClients: any[];
	@Input() lrClaims:ClaimsTable[];
	@Input() claimsFeature:boolean = false;
	@Input() redirectToAP$: (data:{id?:string,serviceCode?:string,customerServiceId?:string}) => Observable<any>;

	policyOwnersDropdown: ViewDisplayValue[];

	emailClientTemplateFeature$ =
		this.businessConfigQuery.emailClientTemplateFeature$;

	constructor(
		private fb: UntypedFormBuilder,
		private modalService: BsModalService,
		private blStaffsQuery: BLStaffsQuery,
		private cd: ChangeDetectorRef,
		private loggerService: LoggerService,
		private userQuery: UserQuery,
		private clientService: ClientProfileService,
		private businessService: BusinessProfileService,
		private businessConfigQuery: BusinessConfigQuery
	) {
		super();
		this.form = this.fb.group({
			adviserSP: false,
			anniversaryDate: '',
			cancellationCode: '',
			cancellationDate: '',
			clawbackRate: null,
			commission: null,
			customerServiceID: '',
			endTrans: '',
			exclusions: '',
			originalAdviser: ['', [Validators.required]],
			paymentFrequency: '',
			policyClaimStatus: '',
			policyDiscount: '',
			policyNumber: ['', [Validators.required, NoWhitespaceValidator]],
			policyNumberSuffix: '',
			policyOwners: [],
			policyRetentionStatus: '',
			policyStatus: ['', [Validators.required]],
			policyType: '',
			premium: null,
			products: [],
			provider: ['', [Validators.required]],
			replacement: false,
			externalReplacement: false,
			required: '',
			startDate: '',
			startTrans: '',
			submittedAPI: null,
			submittedDate: '',
			trailGenerating: false,
			note: '',
			doubleSub: false,
			isActive: 1,
			notes: [],
		});
	}

	get originalAdviser() {
		return this.form.get('originalAdviser');
	}
	get policyStatus() {
		return this.form.get('policyStatus');
	}
	get provider() {
		return this.form.get('provider');
	}
	get policyNumber() {
		return this.form.get('policyNumber');
	}
	get formPolicyOwners() {
		return this.form.get('policyOwners');
	}

	get customerServiceID() {
		return this.form.get('customerServiceID');
	}

	advisers$ = this.blStaffsQuery.adviserChoicesOption$;

	originalAdvisers$ = this.blStaffsQuery.allStaffsChoices$.pipe(
		withLatestFrom(this.advisers$),
		// tslint:disable-next-line: max-line-length
		map(([all, adv]) =>
			all
				? all
						?.filter(
							(x) =>
								+x.value === (this.lr ? this.lr.originalAdviser : '') ||
								adv?.find((y) => y.value === x.value)
						)
						?.sort((a, b) => a.display?.localeCompare(b.display))
				: all
		)
	);

	ngOnChanges(changes: SimpleChanges) {
		if (changes) {
			if (changes.isSaving && !this.isSaving) {
				this.prepData();
			}

			if (changes.lr) {
				if (
					changes.lr.previousValue?.notes?.length === this.lr?.notes?.length
				) {
					this.prepData();
				} else {
					this.notes = this.lr.notes;
					this.form.patchValue({ notes: this.lr.notes });
				}
			}
		}
		this.setPolicyOwners();
	}

	ngOnInit() {
		this.addMode ? this.form.enable() : this.form.disable();

		if (this.addMode) {
			zip(
				of(this.payments),
				of(this.claimStatus),
				of(this.retentionStatus),
				of(this.providers),
				of(this.discounts),
				of(this.cancellationCodes)
			)
				.pipe(
					take(1),
					map((ddListList) => {
						const defaultValueList: string[] = ddListList
							?.map((ddList) => ddList?.find((dd) => dd.isDefault))
							?.map((def) => def && def.value);
						return defaultValueList;
					})
				)
				.subscribe(this.setDropdownDefaults);
		}

		this.form.valueChanges
			.pipe(
				filter((x) => !!x),
				tap(() => this.setPolicyOwners()),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	setDropdownDefaults: (defaultValues: string[]) => void = ([
		pf,
		pcs,
		rs,
		p,
		d,
		cc,
	]) => {
		patchValue<any>(this.form, {
			paymentFrequency:
				this.lr && this.lr.paymentFrequency ? this.lr.paymentFrequency : pf,
			provider: this.lr && this.lr.provider ? this.lr.provider : p,
			policyClaimStatus:
				this.addMode && this.lr && this.lr.policyClaimStatus
					? this.lr.policyClaimStatus
					: pcs,
			policyRetentionStatus:
				this.addMode && this.lr && this.lr.policyRetentionStatus
					? this.lr.policyRetentionStatus
					: rs,
			policyDiscount:
				this.addMode && this.lr && this.lr.policyDiscount
					? this.lr.policyDiscount
					: d,
			cancellationCode:
				this.addMode && this.lr && this.lr.cancellationCode
					? this.lr.cancellationCode
					: cc,
		});
	};

	refresh() {
		this.cd.detectChanges();
	}

	setPolicyOwners() {
		this.policyOwnersDropdown = this.getOwnerChoices(
			this.formPolicyOwners.value
		);
	}

	getOwnerChoices(owners: (string | number)[]) {
		if (this.isCompany) {
			return this.businessService.getOwnerChoices(
				owners || [],
				this.policyOwners
			);
		}
		return this.clientService.getOwnerChoices(owners || [], this.policyOwners);
	}

	prepData() {
		this.notes =
			this.lr && this.lr.notes && this.lr.notes.length > 0
				? this.lr.notes
				: null;

		if (this.addMode) {
			this.form.reset(LrInsuranceMapper.mapToAddView(this.lr));
			if (
				this.isNewGroup ||
				!this.lr ||
				(this.lr && this.lr.policyType !== 'Primary')
			) {
				this.form.controls.policyType.setValue('Primary');
			}
			this.form.controls.trailGenerating.setValue(true);
		} else {
			this.form.reset(LrInsuranceMapper.mapToView(this.lr));
		}
		this.setPolicyOwners();
	}

	collapseForm(collapse: boolean) {
		collapse ? this.collapse.Open() : this.collapse.Close();
	}

	formSaving(isSaving: boolean) {
		this.isSaving = isSaving;
		this.refresh();
	}

	editForm(isEdit: boolean) {
		this.isEditForm = isEdit;
		isEdit ? (this.form.enable(), this.collapse.Open()) : this.form.disable();
		this.refresh();
	}

	cancel() {
		if (this.addMode) {
			const confirm = new Observable((obs: Observer<any>) => {
				if (this.addMode) {
					this.cancelAddEvent.emit(true);
				}
				this.isEditForm = false;
				this.prepData();
				this.form.disable();
				obs.complete();
			});

			const decline = new Observable((obs: Observer<any>) => {
				obs.complete();
			});

			const initState = {
				header: 'Discard Confirmation',
				message: `Current information will be discarded?`,
				confirm$: confirm,
				decline$: decline,
			};

			this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
				class: 'modal-dialog-centered modal-dialog',
				initialState: initState,
				ignoreBackdropClick: true,
				keyboard: false,
			});
		} else {
			this.isEditForm = false;
			this.prepData();
			this.form.disable();

			const data = LrInsuranceMapper.mapToView(this.form.value);

			if (!data.startDate['_i']) {
				this.startDateInput.reset();
			}
			if (!data.anniversaryDate['_i']) {
				this.anniversaryDateInput.reset();
			}
			if (!data.startTrans['_i']) {
				this.startTransDateInput.reset();
			}
			if (!data.submittedDate['_i']) {
				this.submittedDateInput.reset();
			}
		}
	}

	archive(isArchive) {
		const msg = this.lr.isActive === 1 ? 'archive' : 'unarchive';
		const initState: any = {
			confirm$: new Observable((obs) => {
				this.isSaving = true;
				this.archiveEvent.emit({ lr: this.lr, isArchive });
				obs.complete();
			}),
			header: 'Archive service',
			message: `Are you sure you want to ${msg} this item?`,
			isArchive: isArchive ? true : false,
		};
		this.modalService.show(ArchiveModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	save(isAddNote?: boolean) {
		if (
			!this.form.valid ||
			this.startDateInput?.isInvalid() ||
			this.anniversaryDateInput?.isInvalid() ||
			this.startTransDateInput.isInvalid() ||
			this.submittedDateInput.isInvalid()
		) {
			if (
				!this.form.value.policyNumber ||
				this.form.value.policyNumber?.trim() === ''
			) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.PolicyNumber));
				return;
			}
			if (!this.form.value.policyStatus) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.PolicyStatus));
				return;
			}
			if (!this.form.value.provider) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.Provider));
				return;
			}
			if (!this.form.value.originalAdviser) {
				this.loggerService.Warning(
					{},
					getRequiredWarning(Fields.OriginalAdviser)
				);
				return;
			}

			if (this.startDateInput?.isInvalid()) {
				this.loggerService.Warning({}, getInvalidWarning(Fields.StartDate));
				return;
			}
			if (this.anniversaryDateInput?.isInvalid()) {
				this.loggerService.Warning(
					{},
					getInvalidWarning(Fields.AnniversaryDate)
				);
				return;
			}
			if (this.startTransDateInput?.isInvalid()) {
				this.loggerService.Warning({}, getInvalidWarning(Fields.CommissionDate));
				return;
			}
			if (this.submittedDateInput?.isInvalid()) {
				this.loggerService.Warning({}, getInvalidWarning(Fields.SubmittedDate));
				return;
			}
		}

		if (isNaN(this.form.value.premium)) {
			this.loggerService.Warning({}, getInvalidWarning(Fields.Premium));
			return;
		}

		if (isNaN(this.form.value.submittedAPI)) {
			this.loggerService.Warning({}, getInvalidWarning(Fields.SubmittedAPI));
			return;
		}

		if (isNaN(this.form.value.commission)) {
			this.loggerService.Warning({}, getInvalidWarning(Fields.Commission));
			return;
		}

		const form = this.form.value;
		form.customerServiceID = this.lr ? this.lr.customerServiceID : '';
		form.isActive = this.addMode ? 1 : this.lr.isActive;
		form.note = form.note;
		const data = LrInsuranceMapper.mapToUpsert(form);
		if (this.addMode) {
			this.saveEvent.emit(data);
		} else {
			this.saveEvent.emit({ data, isAddNote });
		}
	}

	delete() {
		const form = this.form.value;
		this.deleteEvent.emit(form);
	}

	deleteConfirm() {
		const confirm = new Observable((obs: Observer<any>) => {
			this.delete();
			obs.complete();
		});
		const initState = {
			header: 'Delete',
			message: `Are you sure you want to delete ?`,
			delete$: confirm,
		};

		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	deleteNote$ = (data) => {
		return new Observable((obs) => {
			this.isSaving = true;
			this.editForm(false);
			const form = this.form.value;
			this.deleteNoteEvent.emit({ noteId: data.notesID, data: form });
			obs.next();
			obs.complete();
		});
	};

	transferGroup() {
		const newList = JSON.parse(JSON.stringify(this.lrList));
		const mappedList = newList
			?.map((data) => {
				return {
					serviceCode: ServicesCodes.LR?.toLowerCase(),
					id: data.provider + '-' + data.policyNumber,
					name: data.provider + ' - ' + data.policyNumber,
					aPI: data.aPI,
					startDate: data.startDate,
					color: data.color,
				};
			})
			?.filter((x) => x.id !== this.lr.provider + '-' + this.lr.policyNumber);

		const initState = {
			groupList: mappedList,
			pageTitle: 'Transfer Policy',
		};
		this.bsModalRef = this.modalService.show(TransferServiceComponent, {
			class: 'modal-dialog-centered modal-md',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});

		this.bsModalRef.content.getTransferedValue.subscribe((data) => {
			// do the stuff here
			if (data) {
				const form = this.form.value;
				const mappedForm = LrInsuranceMapper.mapToUpsert(form);

				const dash = data.id?.split(/[-]/);

				mappedForm.provider = dash[0];
				mappedForm.policyNumber = dash[1];

				this.splitEvent.emit({ data: mappedForm, isAddNote: false });
			}
		});
	}

	linkDocument() {
		const initState = {
			selectedDetail: 'Link Document',
			initialSelectedTab: 'lrservice',
		};
		this.bsModalRef = this.modalService.show(LinkDocumentComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
		this.bsModalRef.content.getSelectedDocumentValue$.subscribe((result) => {
			this.currentLinkedDocument = result;
		});
	}

	openModalAddNote() {
		const saveNote = (notes: string) =>
			new Observable((obs) => {
				const form = LrInsuranceMapper.mapToView(this.lr);
				form.note = notes;
				this.addNoteEvent.emit(form);
				obs.next();
				obs.complete();
			});
		const initState: any = {
			header: 'Add Note',
			savefn: saveNote,
		};
		this.modalService.show(NoteAddModalComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	trackByValue(index, item: ViewDisplayValue) {
		return item.value;
	}

	openEmailPopup() {
		if (this.isCompany) {
			this.openEmailBusinessPopup();
		} else {
			this.openEmailClientPopup();
		}
	}

	private openEmailBusinessPopup() {
		this.businessService.openECModalSubject$.next({
			documentType: ServicesCodes.LR,
			type: ServicesCodes.NotesLR,
			referenceId: this.customerServiceID.value || 0,
			data: this.form.value
		});
	}

	private openEmailClientPopup(): void {
		this.clientService.openECModalSubject$.next({
			documentType: ServicesCodes.LR,
			type: ServicesCodes.NotesLR,
			referenceId: this.customerServiceID.value || 0,
			data: this.form.value
		});
	}

	ngOnDestroy(): void {
		super.dispose();
	}
}
