import {
	contentEditable,
	getContentWithMergeTags,
} from '../../../../../converter/content-merge-tags';
import { util } from '../../../../../../util/util';
import {
	koatSosMergeTags,
	sosMergeTags,
	sosMetaKey,
	sosOtherAdvice,
	sosOtherAdviceList,
} from './sos.merge-tag';
import { sosTemplate } from './sos.template';
import { MergeTagsMapper } from '../../merge-tags.mapper';
import { MergeTagState } from '../../merge-tags.model';
import { ScopeOfServiceState } from 'src/app/modules/crm/crt-page/crt-kiwisaver/scope-of-services/state/scope-of-service.model';

export class SosMTMapper {
	public static cleanTemplate(template) {
		// Remove \n and \t from content string
		return template?.replace(/\n|\t/g, '') || '';
	}

	public static getTemplate(template, mergeTag) {
		// Get template for each insurance merge tag
		const content = this.cleanTemplate(template);
		return getContentWithMergeTags(content, mergeTag) ?? '';
	}

	public static getTextAreaContent(data, mt) {
		// Parse content from textareas
		const content = util.tryCatchParse(data) ? JSON.parse(data) : data;
		return getContentWithMergeTags(content, mt, true) ?? '';
	}

	public static parseValues(data) {
		// Parse merge tag values
		return (
			data?.reduce((a, c) => {
				const newVal = util.tryCatchParse(c) ? JSON.parse(c) : c;
				return [...a, newVal];
			}, []) || []
		);
	}

	//////////////////////////
	//// MOAT > ROA > ROA ////
	//////////////////////////

	/**
	 * Get Dynamic SOS Merge Tags for ROA
	 * @param mergeTags : MergeTagState[]
	 * @returns : Updated list of merge tags with updated SOS
	 */
	public static updateSosMergeTags(mergeTags: MergeTagState[] = []) {
		return (
			[...mergeTags, ...sosMergeTags]?.map((i) => {
				if (i?.metaKey === sosMetaKey.willText) {
					return {
						...i,
						value: this.getWill(mergeTags),
						noWrap: true,
					};
				}
				if (i?.metaKey === sosMetaKey.wontText) {
					return {
						...i,
						value: this.getWont(mergeTags),
						noWrap: true,
					};
				}
				if (i?.metaKey === sosMetaKey.otherAdviceNotes) {
					return {
						...i,
						value: this.getOtherAdviceNotes(i?.value),
					};
				}
				return i;
			}) || []
		);
	}

	public static getWill(mergeTags: MergeTagState[] = []) {
		const will = this.getTemplate(sosTemplate.sosWill, mergeTags);
		const value = this.getTemplate(will, mergeTags) || '';
		return MergeTagsMapper.wrapMTValueInDiv(sosMetaKey.willText, value);
	}

	public static getWont(mergeTags: MergeTagState[] = []) {
		const wont = this.getTemplate(sosTemplate.sosWont, mergeTags);
		const value = this.getTemplate(wont, mergeTags) || '';
		return MergeTagsMapper.wrapMTValueInDiv(sosMetaKey.wontText, value);
	}

	public static getSosNotes(mergeTag: MergeTagState) {
		const value = `${MergeTagsMapper.updateNewLine(
			mergeTag?.value || ''
		)} <br /><br />`;
		return MergeTagsMapper.wrapMTValueInDiv(mergeTag?.metaKey, value);
	}

	public static getOtherAdviceNotes(value: string[]) {
		return value?.map((x) => `<span ${contentEditable.false}>${x}</span>`);
	}

	public static resetMergeTags(content = '') {
		let newContent = content;
		// Will text
		newContent = MergeTagsMapper.revertMergeTag(
			newContent,
			sosMetaKey.willText
		);
		// Wont text
		newContent = MergeTagsMapper.revertMergeTag(
			newContent,
			sosMetaKey.wontText
		);
		// SOS Advice note
		newContent = MergeTagsMapper.revertMergeTag(
			newContent,
			sosMetaKey.otherAdviceNotes
		);
		return newContent;
	}

	//////////////////////////
	////    MOAT > SOS    ////
	//////////////////////////

	/**
	 * Get Dynamic SOS Merge Tags for MOAT > SOS
	 * @param mergeTags <MergeTagState[]>
	 * @param sosData <ScopeOfServiceState>: CRT Data of SOS
	 * @param settings <SosTemplateSettingsState>: SOS Settings
	 * @returns : Updated list of merge tags with updated SOS
	 */
	public static koatSosMergeTags(
		mergeTags: MergeTagState[] = [],
		sosData: ScopeOfServiceState,
		settings: ScopeOfServiceState
	) {
		return [...mergeTags, ...koatSosMergeTags]?.map((i) => {
			if (i?.metaKey === sosMetaKey.sosOtherAdviceCheckbox) {
				return {
					...i,
					value: this.getOtherAdviceCheckboxes(sosData, settings),
				};
			}
			if (
				i?.metaKey === sosMetaKey.sosWill ||
				i?.metaKey === sosMetaKey.sosWont
			) {
				return {
					...i,
					value: i?.value?.map((x) => x?.replace(/(?:\r\n|\r|\n)/gm, '<br />')),
				};
			}
			return i;
		});
	}

	/**
	 * @param sosData <ScopeOfServiceState>: CRT Data of SOS
	 * @param settings <SosTemplateSettingsState>: SOS Settings
	 * @returns <string> HTML content based on selected services
	 */
	public static getOtherAdviceCheckboxes(
		sosData: ScopeOfServiceState,
		settings: ScopeOfServiceState
	) {
		const createCboxNote = (
			id: string,
			labelText: string,
			isChecked: boolean,
			note?: string
		) => {
			return `<div class="col-12 p-0">
				<div class="custom-control custom-checkbox">
					<input
						type="checkbox"
						class="chckbox custom-control-input"
						id="${id}"
						${isChecked ? 'checked' : ''}
					/>
					<label class="custom-control-label" for="${id}">${labelText}</label>
				</div>
				<p>${isChecked ? note?.replace(/(?:\r\n|\r|\n)/gm, '<br />') : ''}</p>
			</div>`;
		};

		const html = sosOtherAdviceList?.reduce((a, c) => {
			if (c === sosOtherAdvice.lifeAndRiskReview) {
				const isChecked = sosData?.lifeAndRiskReview === 1;
				const cBoxValue = createCboxNote(
					'kiwiSaverAdvice',
					sosOtherAdvice.lifeAndRiskReview,
					isChecked,
					sosData?.lifeAndRiskReviewDetails
				);
				return +settings?.lifeAndRiskReview === 1 ? `${a} ${cBoxValue}` : a;
			}

			if (c === sosOtherAdvice.houseCarAndContentsInsurance) {
				const isChecked = sosData?.houseCarAndContentsInsurance === 1;
				const cBoxValue = createCboxNote(
					'lifeAndRiskReview',
					sosOtherAdvice.houseCarAndContentsInsurance,
					isChecked,
					sosData?.houseCarAndContentsInsuranceDetails
				);
				return +settings?.houseCarAndContentsInsurance === 1 ? `${a} ${cBoxValue}` : a;
			}

			if (c === sosOtherAdvice.residentialMortgageOrLending) {
				const isChecked = sosData?.residentialMortgageOrLending === 1;
				const cBoxValue = createCboxNote(
					'houseCarContentsQuote',
					sosOtherAdvice.residentialMortgageOrLending,
					isChecked,
					sosData?.residentialMortgageOrLendingDetails
				);
				return +settings?.residentialMortgageOrLending === 1 ? `${a} ${cBoxValue}` : a;
			}

			if (c === sosOtherAdvice.otherAdvice) {
				const isChecked = sosData?.otherAdvice === 1;
				const cBoxValue = createCboxNote(
					'otherAdvice',
					sosOtherAdvice.otherAdvice,
					isChecked,
					sosData?.otherAdviceNotes
				);
				return +settings?.other === 1 ? `${a} ${cBoxValue}` : a;
			}
		}, '');
		
		return html;
	}
}
