<div class="cleafix">
	<table class="table">
		<thead>
			<tr class="align-items-center">
				<th class="created-date px-1 pl-0 text-truncate">Created Date</th>
				<th class="reference-number px-1 text-truncate">Ref. Number</th>
				<th class="outcome px-1 text-truncate">Outcome</th>
        <th class="total-paid px-1 text-truncate">Total Paid</th>
        <th class="status px-1 text-truncate">Status</th>
				<th class="view-process px-1 pr-0 text-truncate align-self-stretch">
					<ng-container *ngIf="isLoading">
						<div class="d-flex flex-row bd-highlight justify-content-end px-2">
							<app-manual-loader-mini
								*ngIf="isLoading"
								[isLoading]="isLoading"
							></app-manual-loader-mini>
						</div>
					</ng-container>
				</th>
			</tr>
		</thead>
		<tbody>
			<ng-container *ngIf="isLoading$ | async">
				<app-manual-loader [isLoading]="isLoading$ | async"></app-manual-loader>
			</ng-container>
			<ng-container *ngIf="!(isLoading$ | async)">
				<tr
					class="align-items-center"
					*ngFor="let claim of claimsList; trackBy: trackByFn; let i = index"
				>
					<td class="created-date px-1 pl-0">
            {{ claim.createdDate | momentDatetime: false }}
						
					</td>
					<td class="reference-number px-1">
						{{ claim.referenceNumber}}
					</td>
					<td class="outcome px-1">
						{{ claim.claimOutcome}}
					</td>
          <td class="total-paid px-1">
						{{ claim.totalPaid }}
					</td>
          <td class="status px-1">
						{{ convertAPStatus(claim.status) }}
					</td>
					<td class="view-process px-1 pr-0 text-right">
								<button
									type="button"
									class="btn btn-link p-1"
									*ngIf="claim.adviceProcessID"
									id="deleteNote_{{ i }}"
									(click)="redirectToAdvice(claim)"
								>
									<i class="material-icons tap-text-color-h6 md-20">visibility</i>
								</button>
					</td>
				</tr>
			</ng-container>
		</tbody>
	</table>
	<ng-container
		*ngIf="
			(!!count && count > itemsPerPage) ||
			(!!claims && claims?.length > itemsPerPage)
		"
	>
		<pagination
			[totalItems]="
				count && count > 0 ? count : claims && claims?.length ? claims.length : 0
			"
			[(ngModel)]="currentpage"
			[itemsPerPage]="itemsPerPage"
			[maxSize]="maxSize"
			(pageChanged)="setPage($event)"
		>
		</pagination>
	</ng-container>
</div>