import {
	Component,
	OnInit,
	ViewChild,
	AfterViewInit,
	Input,
	ChangeDetectionStrategy,
	OnDestroy,
} from '@angular/core';
import { TabDirective, TabsetComponent } from 'ngx-bootstrap/tabs';
import { BehaviorSubject, combineLatest, forkJoin, from, of, Subject, zip } from 'rxjs';
import { delay, map, tap, takeUntil, mergeMap, filter, take, switchMap, withLatestFrom, finalize } from 'rxjs/operators';
import { BusinessProfileService } from '../states/business-profile.service';
import { LrInsuranceService } from '../states/pipelines/lr-insurance.service';
import { MortgageService } from '../states/pipelines/mortgage.service';
import { PropertyAssetService } from '../states/pipelines/property-asset.service';
import { FgInsuranceService } from '../states/pipelines/fg-insurance.service';
import { KiwisaverService } from '../states/pipelines/kiwisaver.service';
import { InvestmentService } from '../states/pipelines/investment.service';
import { LrInsuranceServiceComponent } from '../../../../shared/services/lr-insurance-service/lr-insurance-service.component';
import { MortgageServiceComponent } from '../../../../shared/services/mortgage-service/mortgage-service.component';
import { FgInsuranceServiceComponent } from '../../../../shared/services/fg-insurance-service/fg-insurance-service.component';
import { KiwisaverServiceComponent } from '../../../../shared/services/kiwisaver-service/kiwisaver-service.component';
import { ParamMap, ActivatedRoute, Router } from '@angular/router';
import { ShareholdersDirectorsTabComponent } from './shareholders-directors-tab/shareholders-directors-tab.component';
import * as R from 'ramda';
import {
	AdviceProcessAddModelState,
	AdviceProcessCode,
	AdviceProcessStatusState,
	ServiceAdviceProcessState,
} from '../../../../shared/models/advice-process/advice-process.model';
import sort from 'fast-sort';
import { UserQuery } from '../../../../domain/user/user.query';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';
import { LiabilityCustomerServiceState } from '@shared/models/services/assets/assets';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { EmailDocTypeModel } from '@modules/emails/email-settings/state/email-template.store';
import { EmailService } from '@modules/crm/client-review-template/states/email/email.service';
import { filterNonEmailMergeTags } from '@shared/converter/content-merge-tags';
import { CrtMergeTagsService } from '@modules/crm/client-review-template/states/merge-tags/crt-merge-tags.service';
import { ObservableUtil } from '@util/observable.util';
import { ClientReviewTemplateQuery } from '@modules/crm/client-review-template/states/client-review-template.query';
import { MergeTagDataTypes, MergeTagState, MergeTagTable } from '@shared/models/client-review-template/merge-tags/merge-tags.model';
import { BLStaffsQuery } from '@domain/bl-staff/bl-staffs.query';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EmailBusinessModalComponent } from '@shared/modal/crt/email/email-business-modal/email-business-modal.component';
import { AdviceProcessComponent } from '@shared/advice-process/advice-process.component';
import { BusinessMapper } from '@shared/models/business-profile/business/business.mapper';
import { AdviserServiceState, SasReference } from '@shared/models/client-profile/primary-client/primary-client.model';

@Component({
	selector: 'app-business-profile-tabs',
	templateUrl: './business-profile-tabs.component.html',
	styleUrls: ['./business-profile-tabs.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BusinessProfileTabsComponent
	implements OnInit, AfterViewInit, OnDestroy
{
	private onDestroy$: Subject<void> = new Subject<void>();

	@ViewChild('businessProfileTabset') businessProfileTabset: TabsetComponent;
	@ViewChild(ShareholdersDirectorsTabComponent)
	shareholdersDirectorsComponent: ShareholdersDirectorsTabComponent;
	@Input() primaryCompany: any;
	@Input() isLead: boolean;
	@Input() addMode: boolean;

	// Business Profile
	leadGenChoices$ = this.service.leadGenChoices$;
	leadTypeChoices$ = this.service.PCLT$;
	leadOriginChoices$ = this.service.PCLE$;
	adviserGenChoices$ = this.service.adviserChoices$;

	// Business Config
	businessConfig$ = this.businessConfigQuery.businessConfig$;

	// L&R Insurance
	LRProviders$ = this.service.LRProviders$;
	LRPolicyStatus$ = this.service.LRPolicyStatus$;
	LRProducts$ = this.service.LRProducts$;
	LRPolicyTypes$ = this.service.LRPolicyTypes$;
	LRClaimStatus$ = this.service.LRClaimStatus$;
	LRDiscounts$ = this.service.LRDiscounts$;
	LRPayments$ = this.service.LRPayments$;
	LRCancellationCodes$ = this.service.LRCancellationCodes$;
	LRPolicyRetentionStatus$ = this.service.LRPolicyRetentionStatus$;
	LRPolicyOwners$ = this.service.LRPolicyOwners$;

	// mortgages
	Mstatus$ = this.service.MS$;
	Mproviders$ = this.service.MP$;
	MloanType$ = this.service.MLT$;
	MrepaymentFrequency$ = this.service.MRF$;
	MfixedPeriods$ = this.service.MFPE$;
	MmortgageTypes$ = this.service.MT$;
	MrefixStatus$ = this.service.MRS$;
	MborrowingEntities$ = this.service.BorrowingEntities$;
	Msecurities$ = this.service.Securities$;

	// property and assets
	PAvaluations1$ = this.service.SV1$;
	PAvaluations2$ = this.service.SV2$;
	PAtypes$ = this.service.ST$;
	PAuses$ = this.service.SU$;
	PAtitles$ = this.service.STI$;
	PAStatus$ = this.service.SS$;
	PApropertyOwners$ = this.service.PropertyOwners$;
	PADropTypes$ = this.service.PAT$;
	PALiabilityStatus$ = this.service.CLS$;
	PALiabilities$ = this.service.APCRTL$;
	PAassetOwners$ = this.service.PropertyOwners$.pipe(
		map((x) =>
			x?.map((i) => ({
				...i,
				value: +i?.value,
			}))
		)
	);
	APCRTF$ = this.service.APCRTF$;

	// F&G Insurance
	FGstatus$ = this.service.FGS$;
	FGclaimStatus$ = this.service.FGCS$;
	FGaccountStatus$ = this.service.FGAS$;
	FGinsurers$ = this.service.FGI$;
	FGpolicyTypes$ = this.service.FGPT$;
	FGpolicyWriters$ = this.service.FGPW$;
	FGpolicyLines$ = this.service.FGPL$;
	FGpolicyOwners$ = this.service.FGPolicyOwners$;
	FGproviders$ = this.service.FGI$;
	FGpaymentMethods$ = this.service.FGPM$;
	FGfrequencies$ = this.service.FGF$;

	// kiwisaver
	KProvider$ = this.service.KP$;
	IProvider$ = this.service.IP$;
	KStatus$ = this.service.KS$;
	KRetentionStatus$ = this.service.KRS$;
	IStatus$ = this.service.IS$;
	KFundType$ = this.service.KFT$;
	KOrigin$ = this.service.KO$;
	fundOwners$ = this.service.fundOwners$;
	Types$ = this.service.KT$;
	ITypes$ = this.service.IT$;
	kPIRRates$ = this.service.KPIRR$;
	iPIRRates$ = this.service.IPIRR$;
	iRetentionStatus$ = this.service.IRS$;
	clientsInvolvedRaw$ = this.service.clientsInvolvedRaw$;

	advisers$ = this.service.adviserChoices$;
	reviewedBy$ = this.service.reviewedBy$.pipe(
		map((x) => x?.sort((a, b) => a.display?.localeCompare(b.display)))
	);
	allAdviserChoices$ = this.service.allStaffChoices$.pipe(
		map((x) => x?.sort((a, b) => a.display?.localeCompare(b.display)))
	);

	customerID$ = this.service.customerID$;

	leadOrigin$ = this.service.PCLE$;
	leadStatus$ = this.service.PCLT$;

	// Services
	lrInsurance$ = this.service.lrInsurance$;
	mortgage$ = this.service.mortgage$;
	propertyAsset$ = this.service.propertyAsset$;
	fgInsurance$ = this.service.fgInsurance$;
	kiwisavers$ = this.service.kiwiSavers$;
	investments$ = this.service.investments$;
	assets$ = this.service.assets$;
	liabilities$ = this.service.liabilities$;

	// Advice Process
	apChoices$ = this.service.AP$;
	apcrtasChoices$ = this.service.APCRTAS$;
	apcrtatChoices$ = this.service.APCRTAT$;
	apcrtctChoices$ = this.service.APCRTCT$;
	apcrtcoChoices$ = this.service.APCRTCO$;
	apcrtqi1Choices$ = this.service.APCRTQI1$;
	apcrtqi6Choices$ = this.service.APCRTQI6$;
	apcrtqclr7Choices$ = this.service.APCRTQCLR7$;
	apcrtrlChoices$ = this.service.APCRTRL$;
	apcrtfccChoices$ = this.service.APCRTFCC$;
	apcrtynnaChoices$ = this.service.APCRTYNNA$;
	apcrtqik6Choices$ = this.service.APCRTQIK6$;
	carqicar1$ = this.service.CARQICAR1$;
	apcrtqik15Choices$ = this.service.APCRTQIK15$;
	clientsInvolved$ = this.service.clientsInvolved$;
	adviceProcesses$ = this.service.adviceProcesses$.pipe(
		filter((x) => !!x),
		map((x) => R.valuesIn(x)),
		map((x) => R.flatten(x)),
		map((x: ServiceAdviceProcessState[]) => R.filter((y) => !R.isNil(y), x)),
		map((x: ServiceAdviceProcessState[]) =>
			R.filter(
				(y) => y.status > 0 && y.status !== AdviceProcessStatusState.archived,
				x
			)
		),
		map((x: ServiceAdviceProcessState[]) =>
			sort(x).desc((ap) => ap.modifiedDateTime)
		)
	);
	getAdviceProcessQuestionnaires$ =
		this.service.getAdviceProcessQuestionnaires$;
	getAdviceProcessQuestionnairesBusiness$ =
		this.service.getAdviceProcessQuestionnairesBusiness$;
	getDocument$ = this.service.getDocument$;
	uploadDocument$ = this.service.uploadDocument$;
	downloadDocument$ = this.service.downloadDocument$;
	deleteDocument$ = this.service.deleteDocument$;
	getAdviceProcessNotes$ = this.service.getAdviceProcessNotes$;
	addAdviceProcessNotes$ = this.service.addAdviceProcessNotes$;
	deleteAdviceProcessNotes$ = this.service.deleteAdviceProcessNotes$;

	// Documents
	Document$ = this.service.documents$;
	Histories$ = this.service.histories$;

	// Business Code
	hasLR$ = this.service.hasLR$;
	hasM$ = this.service.hasM$;
	hasFG$ = this.service.hasFG$;
	hasK$ = this.service.hasK$;
	hasI$ = this.service.hasI$;
	hasAP$ = this.service.hasAP$;
	hasMOAT$ = this.service.hasMOAT$;
	hasKOAT$ = this.service.hasKOAT$;
	hasCRT$ = this.service.hasCRT$;

	lrIsLoading$ = this.service.lrIsLoading$;
	mIsLoading$ = this.service.mIsLoading$;
	paIsLoading$ = this.service.paIsLoading$;
	aIsLoading$ = this.service.aIsLoading$;
	fgIsLoading$ = this.service.fgIsLoading$;
	kIsLoading$ = this.service.kIsLoading$;
	iIsLoading$ = this.service.iIsLoading$;

	documentIsLoading$ = this.service.documentIsLoading$;
	historyIsLoading$ = this.service.historyIsLoading$;
	adviceProcessIsLoading$ = this.service.adviceProcessIsLoading$;

	hasPermission$ = this.userQuery.hasPermission$;

	// Activity
	companyName$ = this.service.primaryCompany$.pipe(map((x) => x.companyName));
	adviser$ = this.service.primaryCompany$.pipe(
		map((x) => (!!x && !!x.adviser ? x.adviser : null))
	);
	activityType$ = this.service.AT$;
	activityMeeting$ = this.service.AM$;
	adviserChoices$ = this.service.adviserChoices$;
	allStaffChoices$ = this.service.allStaffChoices$;
	activeAdviserChoices$ = this.service.activeAdviserChoices$;
	activityAdviserChoices$ = this.service.allActiveStaffs$;
	adviserCalendarChoices$ = this.service.adviserCalendarChoices$;
	claimOutcome$ = this.service.CCO$;
	addActivity$ = this.service.addActivity;

	isCompany = true;
	isLoading$ = new BehaviorSubject<boolean>(true);
	isKsTabLoading$ = new BehaviorSubject<boolean>(true);

	@ViewChild(LrInsuranceServiceComponent)
	lrComponent: LrInsuranceServiceComponent;
	@ViewChild(MortgageServiceComponent) mComponent: MortgageServiceComponent;
	@ViewChild(FgInsuranceServiceComponent)
	fgComponent: FgInsuranceServiceComponent;
	@ViewChild(KiwisaverServiceComponent) kComponent: KiwisaverServiceComponent;
	@ViewChild(AdviceProcessComponent) apComponent: AdviceProcessComponent;

	addNoteLiabilityFn$ = (data) =>
		this.propertyAssetService.addLiabilityNote(data);

	deleteNoteLiabilityFn$ = (data) =>
		this.propertyAssetService.deleteLiabilityNote(data.id, data.data);

	activeTabId = '';

	mergeTags$ = this.crtMergeTagsService.mergeTags$;
	isECPopupOpen: boolean = false;

	getTimeline$ = (id) => this.service.getActivityTimeline(id);

	// Toggle Feature
	claimsFeature$ = this.businessConfigQuery.claimsFeature$;
	lrClaimsList$ = this.service.lRClaimsList$;
	fGClaimsList$ = this.service.fGClaimsList$;

	constructor(
		private service: BusinessProfileService,
		private lrService: LrInsuranceService,
		private mortgageService: MortgageService,
		private propertyAssetService: PropertyAssetService,
		private fgService: FgInsuranceService,
		private ksService: KiwisaverService,
		private invService: InvestmentService,
		private route: ActivatedRoute,
		private userQuery: UserQuery,
		private businessConfigQuery: BusinessConfigQuery,
		private emailService: EmailService,
		private crtMergeTagsService: CrtMergeTagsService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		protected query: ClientReviewTemplateQuery,
		private blStaffsQuery: BLStaffsQuery,
		private modalService: BsModalService,
	) {}

	ngOnInit(): void {
		this.isLoading$.next(true);
		combineLatest([
			this.paIsLoading$, // Properties loader
			this.aIsLoading$, // Assets loader
		])
			.pipe(
				filter(([x, y]) => !x && !y),
				delay(10),
				tap(() => this.isLoading$.next(false)),
				takeUntil(this.onDestroy$)
			)
			.subscribe();

		this.isKsTabLoading$.next(true);
		combineLatest([
			this.kIsLoading$, // KiwiSaver loader
			this.iIsLoading$, // Investments loader
		])
			.pipe(
				filter(([x, y]) => !x && !y),
				delay(10),
				tap(() => this.isKsTabLoading$.next(false)),
				takeUntil(this.onDestroy$)
			)
			.subscribe();

			this.service.openECModalEmitter$
			.pipe(
				tap((docType) => this.openEmailClientPopup(docType)),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	ngAfterViewInit(): void {
		combineLatest([
			this.route.paramMap,
			this.kIsLoading$, // KiwiSaver loader
			this.iIsLoading$, // Investments loader
		])
			.pipe(
				filter(([params, k, i]) => {
					const tab = params.get('tab');
					if (
						[
							ServicesCodes.KiwiSaver?.toString(),
							ServicesCodes.Investment?.toString(),
						].includes(tab?.toUpperCase())
					) {
						return !k && !i;
					}
					return true;
				}),
				map(([params]) => this.paramsGet(params)),
				tap((x) => {
					if (x.tab || x.customerServiceId) {
						setTimeout(() => {
							this.selectTab({
								customerServiceId: x.customerServiceId,
								serviceCode: x.tab,
							});
						}, 10);
					} else {
						if (this.businessProfileTabset.tabs[0]) {
							this.businessProfileTabset.tabs[0].active = true;
						}
					}
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	cancelAdviceProcess$ = (
		referenceId: number,
		cancellationReason: string,
		notes: string,
		ap?: ServiceAdviceProcessState
	) => this.service.cancelAdviceProcess({ referenceId, cancellationReason, notes }, ap || null);

	paramsGet(params: ParamMap): { tab: string; customerServiceId: number } {
		const tab = params.get('tab');
		const customerServiceId = +params.get('customerServiceId');

		return { tab, customerServiceId };
	}

	closeAdviceProcess$ = (
		ap: ServiceAdviceProcessState,
		isEndProcess?: boolean,
		isReopen?: boolean
	) => this.service.closeClaim(ap, isEndProcess, isReopen);

	// LR
	createLRInsuranceFn$ = (req: { data; linkDocumentId }) => {
		return this.lrService.createLrInsurance(req.data, req.linkDocumentId);
	};

	downloadLink$ = ({ documentID }) => {
		return this.service.downloadLink(documentID);
	};

	updateLRInsuranceFn$ = (data) => {
		const code = AdviceProcessCode.LRClaim;
		return this.lrService.updateLrInsurance(data).pipe(
			take(1),
			tap(() => this.service.updateClaimAdviceProcess(code, data)),
		);
	};

	deleteLRInsuranceFn$ = (req: {
		data: any;
		provider: string;
		policyNumber: string;
	}) => {
		return of(req).pipe(
			mergeMap(() =>
				this.lrService.deleteLrInsurance(
					req.data,
					req.provider,
					req.policyNumber
				)
			)
		);
	};

	archiveLRInsuranceFn$ = (req: {
		data: any;
		provider: string;
		policyNumber: string;
		isArchive: boolean;
	}) => {
		return of(req).pipe(
			mergeMap(() =>
				this.lrService.archiveLrInsurance(
					req.data,
					req.provider,
					req.policyNumber,
					req.isArchive
				)
			)
		);
	};

	deleteLRInsuranceNoteFn$ = (req: {
		id: any;
		data: any;
		provider: string;
		policyNumber: string;
	}) => {
		return of(req).pipe(
			mergeMap(() =>
				this.lrService.deleteLrInsuranceNote(
					req.id,
					req.data,
					req.provider,
					req.policyNumber
				)
			)
		);
	};

	updateLRDocumentFn$ = (req: {
		id: any;
		provider: string;
		policyNumber: string;
	}) => {
		return of(req).pipe(
			mergeMap(() =>
				this.lrService.upsertLRLinkDocument(
					req.id,
					req.provider,
					req.policyNumber
				)
			)
		);
	};

	// Mortgage
	createMortgageFn$ = (req: { data; linkDocumentId }) => {
		return this.mortgageService.createMortgage(req.data, req.linkDocumentId);
	};

	saveMortgageFn$ = (data) => {
		return this.mortgageService.updateMortgage(data);
	};

	deleteMortgageFn$ = (data) => {
		return this.mortgageService.deleteMortgage(data);
	};

	archiveMortgageFn$ = (req: { data: any; isArchive: boolean }) => {
		return of(req).pipe(
			mergeMap(() =>
				this.mortgageService.archiveMortgage(req.data, req.isArchive)
			)
		);
	};

	deleteMortgageNoteFn$ = (req: { id: any; data: any }) => {
		return of(req).pipe(
			mergeMap(() => this.mortgageService.deleteMortgageNote(req.id, req.data))
		);
	};

	updateMortgageDocumentFn$ = (req: {
		linkDocId: any;
		provider: string;
		loanNumber: string;
	}) => {
		return of(req).pipe(
			mergeMap(() =>
				this.mortgageService.upsertMortgageLinkDocument(
					req.linkDocId,
					req.provider,
					req.loanNumber
				)
			)
		);
	};

	// Property
	createPropertyAssetFn$ = (data) => {
		return this.propertyAssetService.createSecurity(data);
	};

	savePropertyAssetFn$ = (data) => {
		return this.propertyAssetService.updateSecurity(data);
	};

	deletePropertyAssetFn$ = (data) => {
		return this.propertyAssetService.deleteSecurity(data);
	};

	archivePropertyAssetFn$ = (req: { data: any; isArchive: boolean }) => {
		return of(req).pipe(
			mergeMap(() =>
				this.propertyAssetService.archiveSecurity(req.data, req.isArchive)
			)
		);
	};

	// Assets
	createAssetFn$ = (data) => {
		return this.propertyAssetService.createAsset(data);
	};

	saveAssetFn$ = (data) => {
		return this.propertyAssetService.updateAsset(data);
	};

	deleteAssetFn$ = (data) => {
		return this.propertyAssetService.deleteAsset(data);
	};

	soldAssetFn$ = (data) => {
		return this.propertyAssetService.soldAsset(data);
	};

	addLiabilityFn$ = (data: LiabilityCustomerServiceState) => {
		return this.propertyAssetService.addLiability(data);
	};

	updateLiabilityFn$ = (data: LiabilityCustomerServiceState) => {
		return this.propertyAssetService.updateLiability(data);
	};

	deleteLiabilityFn$ = (data: LiabilityCustomerServiceState) => {
		return this.propertyAssetService.deleteLiability(data);
	};

	// Fg Insurance
	createFGInsuranceFn$ = (req: {
		data;
		linkDocumentId;
		isAddNewRenewal;
		reference;
		referenceNo;
		customerServiceId;
	}) => {
		return this.fgService.createFGInsurance(
			req.data,
			req.linkDocumentId,
			req.isAddNewRenewal,
			req.reference,
			req.referenceNo,
			req.customerServiceId
		);
	};

	saveFGInsuranceFn$ = (data) => {
		const code = AdviceProcessCode.FGClaim;
		return this.fgService.updateFGInsurance(data).pipe(
			take(1),
			tap(() => this.service.updateClaimAdviceProcess(code, data)),
		);
	};

	deleteFGInsuranceFn$ = (req: { data: any }) => {
		return of(req).pipe(
			mergeMap(() => this.fgService.deleteFGInsurance(req.data))
		);
	};

	archiveFGInsuranceFn$ = (req: {
		data: any;
		provider: string;
		policyNumber: string;
		isArchive: boolean;
	}) => {
		return of(req).pipe(
			mergeMap(() =>
				this.fgService.archiveFGInsurance(
					req.data,
					req.provider,
					req.policyNumber,
					req.isArchive
				)
			)
		);
	};

	deleteFGInsuranceNoteFn$ = (req: {
		id: any;
		data: any;
		provider: string;
		policyNumber: string;
	}) => {
		return of(req).pipe(
			mergeMap(() =>
				this.fgService.deleteFGInsuranceNote(
					req.id,
					req.data,
					req.provider,
					req.policyNumber
				)
			)
		);
	};

	updateFGDocumentFn$ = (req: {
		id: any;
		provider: string;
		policyNumber: string;
		customerServiceID: number;
	}) => {
		return of(req).pipe(
			mergeMap(() =>
				this.fgService.upsertFGLinkDocument(
					req.id,
					req.provider,
					req.policyNumber,
					req.customerServiceID
				)
			)
		);
	};

	// Kiwisaver
	createKiwisaverFn$ = (data) => {
		return this.ksService.createKiwisaver(data);
	};

	saveKiwisaverFn$ = (data) => {
		return this.ksService.updateKiwisaver(data);
	};

	deleteKiwisaverFn$ = (data) => {
		return this.ksService.deleteKiwisaver(data);
	};

	archiveKiwisaverFn$ = (req: { data: any; isArchive: boolean }) => {
		return of(req).pipe(
			mergeMap(() => this.ksService.archiveKiwisaver(req.data, req.isArchive))
		);
	};

	deleteKiwisaverNoteFn$ = (req: { id: any; data: any }) => {
		return of(req).pipe(
			mergeMap(() => this.ksService.deleteNote(req.id, req.data))
		);
	};

	updateKiwisaverDocumentFn$ = (req: {
		id: any;
		customerServiceID: number;
	}) => {
		return of(req).pipe(
			mergeMap(() =>
				this.ksService.upsertKiwisaverLinkDocument(
					req.id,
					req.customerServiceID
				)
			)
		);
	};

	// Investment
	createInvestmentFn$ = (data) => {
		return this.invService.createInvestment(data);
	};

	saveInvestmentFn$ = (data) => {
		return this.invService.updateInvestment(data);
	};

	deleteInvestmentFn$ = (data) => {
		return this.invService.deleteInvestment(data);
	};

	archiveInvestmentFn$ = (req: { data: any; isArchive: boolean }) => {
		return of(req).pipe(
			mergeMap(() => this.invService.archiveInvestment(req.data, req.isArchive))
		);
	};

	deleteInvestmentNoteFn$ = (req: { id: any; data: any }) => {
		return of(req).pipe(
			mergeMap(() => this.invService.deleteNote(req.id, req.data))
		);
	};

	updateInvestmentDocumentFn$ = (req: {
		id: any;
		customerServiceID: number;
	}) => {
		return of(req).pipe(
			mergeMap(() =>
				this.invService.upsertInvestmentLinkDocument(
					req.id,
					req.customerServiceID
				)
			)
		);
	};

	deletePropertyAssetNoteFn$ = (req: { id: any; data: any }) => {
		return of(req).pipe(
			mergeMap(() => this.propertyAssetService.deleteNote(req.id, req.data))
		);
	};

	deleteNoteAssetFn$ = (req: { id: any; data: any }) => {
		return of(req).pipe(
			mergeMap(() =>
				this.propertyAssetService.deleteAssetNote(req.id, req.data)
			)
		);
	};

	deleteHistoryFn$ = (id: number, isAp?: boolean) => {
		return this.service.deleteHistory(id, isAp);
	};

	transferDocFn$ = (req: { doc: any; doctype: string }) => {
		return this.service.TransferDocument(req);
	};

	uploadDocFn$ = (req: { doc: any; doctype: string; customerId: number }) => {
		return this.service.UploadDocument(req);
	};

	deleteDocFn$ = (doc) => {
		return this.service.DeactivateDocument(doc);
	};

	// Next Review Date
	updateLRNextReviewFn$ = (lRNextReview: string) => {
		return this.service.updateNextReview(lRNextReview, ServicesCodes.LR);
	};
	updateMortgageNextReviewFn$ = (nextReview: string) => {
		return this.service.updateNextReview(nextReview, ServicesCodes.Mortgage);
	};

	updateFGNextReviewFn$ = (fGNextReview: string) => {
		return this.service.updateNextReview(fGNextReview, ServicesCodes.FG);
	};

	updateInvestmentKSNextReviewFn$ = (fGNextReview: string) => {
		return this.service.updateNextReview(fGNextReview, ServicesCodes.Investment);
	};

	// Advice Process
	addNewAdviceProcess$ = (data) => this.service.addAdviceProcess(data);
	updateAdviceProcess$ = (data, isEndProcess, isReopen) =>
		this.service.updateAdviceProcess(data, isEndProcess, isReopen);
	deleteAdviceProcess$ = (id, code) =>
		this.service.deleteAdviceProcess(id, code);

	selectTab(item) {
		const tabToActivate = this.businessProfileTabset.tabs?.find(
			(x) => 
				x.id?.toUpperCase() === item.serviceCode?.toUpperCase()||
				(x.id?.toUpperCase()?.includes(ServicesCodes.AdviceProcess) &&
					(item.serviceCode?.includes(ServicesCodes.AdviceProcess)))
		);
		if (tabToActivate) {
			tabToActivate.active = true;
		}

		if (item.serviceCode?.toUpperCase() === ServicesCodes.LR) {
			this.lrComponent?.selectLrTab(item);
		}

		if (item.serviceCode?.toUpperCase() === ServicesCodes.Mortgage) {
			this.mComponent?.selectMTab(item);
		}

		if (item.serviceCode.toUpperCase() === ServicesCodes.FG) {
			this.fgComponent?.selectFgTab(item);
		}

		if (item.serviceCode.toUpperCase() === ServicesCodes.KiwiSaver) {
			this.kComponent?.selectKTab(item);
		}
		if (
			item.serviceCode?.toUpperCase() === ServicesCodes.AdviceProcess ||
			item.serviceCode?.includes(ServicesCodes.AdviceProcess) ||
			item.serviceCode?.toUpperCase() === 'CARLR'
		) {
			this.apComponent?.selectTab(item.id ?? item.customerServiceId);
		}
	}

	addNoteLRFn$ = (data) => {
		return this.lrService.addNote(data);
	};

	addNoteMortgageFn$ = (data) => {
		return this.mortgageService.addNote(data);
	};

	addNoteSecurityFn$ = (data) => {
		return this.propertyAssetService.addNote(data);
	};

	addNoteAssetFn$ = (data) => {
		return this.propertyAssetService.addAssetNote(data);
	};

	addNoteFGFn$ = (data) => {
		return this.fgService.addNote(data);
	};

	addNoteKiwiSaverFn$ = (data) => {
		return this.ksService.addNote(data);
	};

	addNoteInvestmentFn$ = (data) => {
		return this.invService.addNote(data);
	};

	redirectToTab$ = (data) =>{
		return of(data).pipe(finalize(()=>{
			this.selectTab(data);
		}))
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}

	tabSelected(e: TabDirective): void {
		this.activeTabId = e.id;
	}

	sendEmailFn$ = (payload: any, docType: EmailDocTypeModel) => {
		const activeTabId = this.activeTabId;
		return this.emailService
			.sendToRecipients(
				payload,
				docType.type,
				docType.referenceId,
				filterNonEmailMergeTags(this.mergeTags$)
			)
			.pipe(
				mergeMap(() => {
					// since we are refreshing the client profile tab, we need to update the
					// tab and customerServiceId/adviceProcessId parameters.
					// this will set the current tab and current active service/advice process id
					const params: any = {};
					const tab = docType.type.replace('ET', '').toLowerCase();
					params.tab = activeTabId.toLowerCase();
					if (tab === 'ap') {
						params.adviceProcessId = docType.referenceId;
					} else {
						params.customerServiceId = docType.referenceId;
					}
					return from(
						this.router.navigate([], {
							relativeTo: this.activatedRoute,
							queryParams: params
						})
					);
				}),
				switchMap(() => {
					const primaryCompany = this.service.primaryCompany();
					const urlNotes = [this.getTimeline$(primaryCompany.customerID)];

					switch (docType.type) {
						case ServicesCodes.CRTNotesAdviceProcess:
							urlNotes.push(
								this.service.getAdviceProcessesByPrimaryId(
									primaryCompany.customerID
								)
							);
							break;
						case ServicesCodes.NotesLR:
							urlNotes.push(
								this.service.getLRInsurance(primaryCompany.customerID)
							);
							break;
						case ServicesCodes.NotesMortgage:
							urlNotes.push(this.service.getMortage(primaryCompany.customerID));
							break;
						case ServicesCodes.NotesFG:
							urlNotes.push(
								this.service.getFGInsurance(primaryCompany.customerID)
							);
							break;
						case ServicesCodes.NotesInvestment:
						case ServicesCodes.NotesKiwisaver:
							// if (docType.documentType === ServicesCodes.KiwiSaver) {
							urlNotes.push(
								this.service.getKiwisavers(primaryCompany.customerID)
							);
							// } else if (docType.documentType === ServicesCodes.Investment) {
							urlNotes.push(
								this.service.getInvestments(primaryCompany.customerID)
							);
							// }
							break;

						default:
							break;
					}

					return zip(urlNotes);
				}),
				ObservableUtil.finalizeSuccess(() => {
					this.service.closeECModalSubject$.next(docType);
				})
			);
	};

	getAdviser(advisers: AdviserServiceState[], service: string): number {
		const apDisplayValue = SasReference.find((ap) => ap.service === service);
		if (!apDisplayValue) {
			return 0;
		}
		return advisers.find((a) => {
			if (a.services.includes(apDisplayValue?.display)) {
				return true;
			}
			return false;
		})?.adviserId;
	}

	openEmailClientPopup(docType: EmailDocTypeModel): void {
		if (!this.isECPopupOpen) {
			this.isECPopupOpen = true;
			let requests$ = [];

			this.service.closeECModalSubject$.next(docType);
			const currentUser = this.userQuery.getValue();
			const primaryCompany = this.service.primaryCompany();
			const companyId = +primaryCompany?.customerID;
			const activeAP = this.apComponent?.activeAdviceProcess;
			const advisersGroupByServices = BusinessMapper.mapAdviserReworkServicesView(primaryCompany);
			const serviceType =
				docType.type === ServicesCodes.NotesInvestment
					? ServicesCodes.Investment
					: this.activeTabId.toUpperCase();
			const type = docType?.type;
			const adviserId =
				type === ServicesCodes.CRTNotesAdviceProcess
					? activeAP?.adviser
					: this.getAdviser(advisersGroupByServices, serviceType);
			const isClaimAP =
				activeAP?.processCode === AdviceProcessCode.FGClaim ||
				activeAP?.processCode === AdviceProcessCode.LRClaim;

			requests$ = [
				this.crtMergeTagsService.getMergeTags(MergeTagTable.Business),
				this.crtMergeTagsService.getMergeTags(
					MergeTagTable.Staff,
					adviserId || 0
				),
				this.crtMergeTagsService.postMergeTag({
					referenceId: companyId || 0,
					dataType: [
						MergeTagDataTypes.PrimaryCompany,
					],
					table: [MergeTagTable.CustomerDetails],
				}),
			];

			if (type === ServicesCodes.CRTNotesAdviceProcess) {
				requests$.push(
					this.crtMergeTagsService.postMergeTag({
						referenceId: activeAP?.adviceProcessID || 0,
						dataType: [
							isClaimAP
								? MergeTagDataTypes.Claims
								: MergeTagDataTypes.AdviceProcess,
						],
						table: [MergeTagTable.AdviceProcess],
					})
				);
			}
			if (type === ServicesCodes.NotesLR) {
				requests$.push(
					this.crtMergeTagsService.postMergeTag({
						referenceId: docType?.data?.customerServiceID || 0,
						dataType: [MergeTagDataTypes.LR],
						table: [MergeTagTable.LR],
					})
				);
			}
			if (type === ServicesCodes.NotesMortgage) {
				requests$.push(
					this.crtMergeTagsService.postMergeTag({
						referenceId: docType?.data?.customerServiceID || 0,
						dataType: [MergeTagDataTypes.Mortgage],
						table: [MergeTagTable.Mortgage],
					})
				);
			}
			if (type === ServicesCodes.NotesFG) {
				requests$.push(
					this.crtMergeTagsService.postMergeTag({
						referenceId: docType?.data?.customerServiceID || 0,
						dataType: [MergeTagDataTypes.FG],
						table: [MergeTagTable.FG],
					})
				);
			}
			if (type === ServicesCodes.NotesInvestment) {
				requests$.push(
					this.crtMergeTagsService.postMergeTag({
						referenceId: docType?.data?.customerServiceID || 0,
						dataType: [MergeTagDataTypes.Investment],
						table: [MergeTagTable.Investment],
					})
				);
			}
			if (type === ServicesCodes.NotesKiwisaver) {
				requests$.push(
					this.crtMergeTagsService.postMergeTag({
						referenceId: docType?.data?.customerServiceID || 0,
						dataType: [MergeTagDataTypes.KiwiSaver],
						table: [MergeTagTable.KiwiSaver],
					})
				);
			}

			of(docType)
				.pipe(
					take(1),
					mergeMap(() => forkJoin(requests$)),
					map((mergeTags) =>
						mergeTags?.reduce((prev, cur) => {
							prev.push(...(cur ?? []));
							return prev;
						}, [])
					),
					withLatestFrom(this.service.primaryCompany$),
					tap(([mergeTags, primaryCompany]) => {
						const initState = {
							header: 'Email Client',
							adviserId,
							businessConfig$: this.businessConfig$,
							emailSettings$: of({
								subject: '',
								sender: currentUser.EmailAddress,
								blindCarbonCopy: currentUser.EmailAddress,
							}),
							peopleDropdown: this.service.PreferredEmailContacts$,
							adviceProcess: null,
							sendEmailFn$: (data: any) =>
								this.sendEmailFn$(data, docType).pipe(
									delay(500) // wait for the popup to close
								),
							attachments: [],
							showAttachments: true,
							mergeTags$: filterNonEmailMergeTags(
								of(this.query.getValue().mergeTags)
							).pipe(
								map((mt) => {
									const emailTemplateMT = [
										...(<MergeTagState[]>mt),
										...mergeTags,
									];
									if (
										docType.documentType === ServicesCodes.LR &&
										!R.isNil(docType.data)
									) {
										const adviser = this.blStaffsQuery.getEntity(
											docType.data.originalAdviser
										);
										emailTemplateMT.push(
											{
												metaKey: 'ADVISER',
												description: 'LR Insurance Adviser',
												value: adviser.FullName,
												type: 'T',
												secondaryValue: 'N/A Adviser',
											},
											{
												metaKey: 'PROVIDER',
												description: 'LR Insurance Provider',
												value: docType.data.provider,
												type: 'T',
												secondaryValue: 'N/A Provider',
											},
											{
												metaKey: 'PAYMENT_FREQUENCY',
												description: 'LR Insurance Payment Frequency',
												value: docType.data.paymentFrequency,
												type: 'T',
												secondaryValue: 'N/A Payment Frequency',
											},
											{
												metaKey: 'POLICY_NUMBER',
												description: 'LR Insurance Policy Number',
												value: docType.data.policyNumber,
												type: 'T',
												secondaryValue: 'N/A Policy Number',
											}
										);
									}

									return emailTemplateMT;
								})
							),
							defaultLinkDocumentTab: docType.documentType,
							serviceCodeType:
								docType.type === ServicesCodes.NotesKiwisaver
									? ServicesCodes.NotesInvestment
									: isClaimAP 
									? ServicesCodes.NotesClaims
									: docType.type,
							documentInfo: {
								documentType: docType.documentType,
								type: docType.documentType,
								referenceId: docType.referenceId,
								customerId: primaryCompany.customerID,
							},
							primaryCompany,
							showBusinessRecipient: true
						};

						const modalRef = this.modalService.show(EmailBusinessModalComponent, {
							class:
								'modal-dialog-centered modal-dialog modal-lg modal-workflow',
							initialState: initState,
							ignoreBackdropClick: true,
							keyboard: false,
						});

						modalRef.onHide.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
							this.isECPopupOpen = false;
						});
					})
				)
				.subscribe();
		}
	}
	openAPModalFn$=(data:AdviceProcessAddModelState)=>{
		return this.apComponent?.addNewAdviceProcess(data);
	}

	goToPolicy(data: { customerServiceId: number; serviceCode: string }) {
		if (data?.customerServiceId && data?.serviceCode) {
			this.selectTab({
				customerServiceId: data?.customerServiceId,
				serviceCode: data?.serviceCode,
			});
		}
	}
}
