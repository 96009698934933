import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { produce } from 'immer';
import * as moment from 'moment';
import * as R from 'ramda';
import { concat, forkJoin, from, Observable, of, throwError } from 'rxjs';
import {
	catchError,
	concatMap,
	filter,
	finalize,
	map,
	mergeMap,
	reduce,
	retry,
	shareReplay,
	switchMap,
	take,
	tap,
	withLatestFrom
} from 'rxjs/operators';
import { ActivityService } from 'src/app/core/services/activity/activity.service';
import { logMessage } from 'src/app/shared/error-message/error-message';
import { ApiService, JsonResultStatus } from '../../../../../core/base/api.service';
import { ConfigService } from '../../../../../core/config/config.service';
import { LoggerService } from '../../../../../core/logger/logger.service';
import { UserQuery } from '../../../../../domain/user/user.query';
import { UserStore } from '../../../../../domain/user/user.store';
import { ActivityViewModel } from '../../../../../shared/models/_general/activity.viewmodel';
import { StaffSettings } from '../../../../../shared/models/_general/staff-settings.model';
import MomentUtil from '../../../../../util/moment.util';
import { columns, createRowFromPrototype, Metakey } from '../fg-insurance-datatable.config';
import { FgInsuranceRequest, FgInsuranceResponse } from '../fg-insurance-request.model';
import { FgInsuranceQuery } from './fg-insurance.query';
import { FgInsuranceStore } from './fg-insurance.store';

@Injectable()
export class FgInsuranceService {
	constructor(
		protected api: ApiService,
		private fgInsuranceStore: FgInsuranceStore,
		private fgInsuranceQuery: FgInsuranceQuery,
		private userQuery: UserQuery,
		private userStore: UserStore,
		protected configService: ConfigService,
		private activityService: ActivityService,
		private loggerService: LoggerService
	) {}

	clear(): void {
		applyTransaction(() => {
			this.fgInsuranceStore.reset();
			this.fgInsuranceStore.uiStore.reset();
		});
	}

	openPopup = () => this.fgInsuranceStore.uiStore.toggleColumnPopup(true);
	closePopup = () => this.fgInsuranceStore.uiStore.toggleColumnPopup(false);
	togglePopup = () =>
		this.fgInsuranceStore.uiStore.toggleColumnPopup(!this.fgInsuranceQuery.uiQuery.getValue().columnFormPopupOpen);

	search2(req: FgInsuranceRequest) {
		this.fgInsuranceStore.uiStore.setSort(null, null);
		const batchLength = 100;

		const getIndexesToFetch: (res: FgInsuranceResponse) => number[] = R.pipe(
			(res: FgInsuranceResponse) => Math.ceil(res.TotalCount / batchLength - 1),
			(totalPages: number) =>
				Array(totalPages)
					?.fill(1)
					?.map((x, i) => i + 1)
					?.slice(1)
		);
		const searchRequest = (request: FgInsuranceRequest) =>
			this.api.post3<FgInsuranceResponse>('search/services/fg', request);

		const firstPage$ = of(req).pipe(
			map(
				produce(draft => {
					draft.Paging = {
						Index: 2,
						Column: req.Paging.Column,
						Direction: req.Paging.Direction,
					};
				})
			),
			mergeMap(searchRequest),
			shareReplay()
		);

		return firstPage$.pipe(
			mergeMap(res =>
				concat(
					firstPage$,
					from(getIndexesToFetch(res)).pipe(
						map(i =>
							produce(req, draft => {
								draft.Paging = {
									Index: i + 1,
									Column: req.Paging.Column,
									Direction: req.Paging.Direction,
								};
							})
						),
						concatMap(req2 => searchRequest(req2))
					)
				)
			),
			reduce((acc, v) =>
				produce(acc, draft => {
					draft.SearchResults = [...draft.SearchResults, ...v.SearchResults];
					draft.IsComplete = true;
				})
			),
			map(res =>
				applyTransaction(() => {
					return res;
				})
			)
		);
	}

	search(req: FgInsuranceRequest) {
		this.fgInsuranceStore.uiStore.setIsSearching(true);

		return this.api.post3<FgInsuranceResponse>('search/services/fg', req).pipe(
			withLatestFrom(this.fgInsuranceQuery.templateRow$),
			switchMap(([x, templateRow]) => {
				if (req.Paging.Index === 1 && x.TotalCount <= 500 && !x.IsComplete) {
					// Saves initial fetch
					const rows: any[] = R.map(createRowFromPrototype(templateRow), x.SearchResults);
					this.fgInsuranceStore.set([]);
					this.fgInsuranceStore.set([...rows]);
					return this.search2(req);
				}
				return of(x);
			}),
			withLatestFrom(this.fgInsuranceQuery.templateRow$, (res, template) => ({
				response: res,
				templateRow: template,
			})),
			map(res =>
				applyTransaction(() => {
					const rows: any[] = R.map(createRowFromPrototype(res.templateRow), res.response.SearchResults);

					if (req.Paging.Index === 1 && this.fgInsuranceQuery.getCount() !== 0 && res.response.TotalCount > 500) {
						this.fgInsuranceStore.set([]);
						res.response.IsComplete = false;
					}

					const a = this.fgInsuranceQuery.getAll();
					if (req.Paging.Index === 1 && res.response.IsComplete && res.response.TotalCount <= 100) {
						this.fgInsuranceStore.set([]);
						this.fgInsuranceStore.set([...rows]);
					} else {
						this.fgInsuranceStore.set([...a, ...rows]);
					}

					this.fgInsuranceStore.update(state => ({
						...state,
						count: res.response.TotalCount,
						totalPremium: res.response.TotalAPI,
						isComplete: res.response.IsComplete,
					}));

					return res.response;
				})
			),
			finalize(() => {
				this.fgInsuranceStore.setSearchForm(req);
				this.fgInsuranceStore.uiStore.setIsSearching(false);
			})
		);
	}

	/**
	 * @param {FgInsuranceRequest} req request
	 * Sets IsExport to true in akita store
	 * @returns {Observable} Blob
	 * Sets IsExport to false in akita store
	 */
	export(req: FgInsuranceRequest): Observable<any> {
		return this.api.post4('export/services/fg', req).pipe(
			map(x => {
				const obj = this.tryParseJSON(x);
				if (!obj) {
					return new Blob([x], {
						type: 'text/plain',
					});
				} else {
					return obj;
				}
			}),
			retry(1),
			catchError(err => {
				this.loggerService.Log(err, logMessage.shared.export.error);
				return throwError(new Error(err));
			})
		);
	}

	tryParseJSON(jsonString: any): boolean {
		try {
			const o = JSON.parse(jsonString);
			if (o && typeof o === 'object') {
				return o;
			}
		} catch (e) {
			return false;
		}
		return false;
	}

	reloadData() {
		const data = this.fgInsuranceQuery.getAll();
		this.fgInsuranceStore.set([]);
		this.fgInsuranceStore.set([...data]);
	}

	getColumns(): Observable<any> {
		return this.fgInsuranceQuery
			.select(x => x.columns)
			.pipe(
				take(1),
				filter(x => (x ? x.length < 1 : false)),
				mergeMap(x => this.userQuery.staffSettings$),
				tap(x =>
					R.complement(R.either(R.isNil, R.isEmpty))(x)
						? this.fgInsuranceStore.setColumns(!!x.FGSearchColumns ? JSON.parse(x.FGSearchColumns) : [])
						: null
				)
			);
	}

	getColumnWidths(): Observable<any> {
		return this.fgInsuranceQuery
			.select(x => x.columnWidths)
			.pipe(
				take(1),
				filter(x => (x ? x.length < 1 : false)),
				mergeMap(x => this.userQuery.staffSettings$),
				tap(x =>
					R.complement(R.either(R.isNil, R.isEmpty))(x)
						? this.fgInsuranceStore.setColumnWidths(!!x.FGSearchColumnsWidth ? JSON.parse(x.FGSearchColumnsWidth) : [])
						: null
				)
			);
	}

	reorderColumn = (oldIndex: number, newIndex: number) => {
		const newCol = produce(this.fgInsuranceQuery.getValue().columns, draft => {
			const movedCol = draft?.splice(oldIndex, 1);
			draft?.splice(newIndex, 0, movedCol[0]);
		});
		const newColWith = produce(this.fgInsuranceQuery.getValue().columnWidths, draft => {
			const movedCol = draft?.splice(oldIndex, 1);
			draft?.splice(newIndex, 0, movedCol[0]);
		});
		return of(newCol).pipe(
			tap(() => {
				this.fgInsuranceStore.setColumns(newCol);
				this.fgInsuranceStore.setColumnWidths(newColWith);
			}),
			withLatestFrom(this.userQuery.userInfo$),
			map(([newVal, user]) => ({
				...user,
				StaffSettings: {
					...user.StaffSettings,
					FGSearchColumns: JSON.stringify(newVal),
					FGSearchColumnsWidth: JSON.stringify(newColWith),
				},
			})),
			withLatestFrom(this.userQuery.isTapLevel$, this.userQuery.userInfo$),
			mergeMap(([req, isTap, user]) =>
				isTap ? of(null) : this.api.put(`staff/${user.StaffID}/bl`, req).pipe(tap(() => this.userStore.update(req)))
			)
		);
	};

	resizeColumn(prop: string, width: number): Observable<any> {
		const oldColWidths = this.fgInsuranceQuery.getValue().columnWidths?.filter(x => x);
		const newColWidths = produce(oldColWidths, draft => {
			const col = columns?.find(x => x.prop === prop);
			const exists = draft?.some(x => col.metakey === x.metakey);
			if (exists) {
				draft.find(x => col.metakey === x.metakey).width = width;
			} else {
				draft.push({ metakey: col.metakey, width });
			}
		});

		return of(newColWidths).pipe(
			tap(x => this.fgInsuranceStore.setColumnWidths(x)),
			withLatestFrom(this.userQuery.userInfo$),
			map(([newVal, user]) => ({
				...user,
				StaffSettings: {
					...user.StaffSettings,
					FGSearchColumnsWidth: JSON.stringify(newVal),
				},
			})),
			withLatestFrom(this.userQuery.isTapLevel$, this.userQuery.userInfo$),
			mergeMap(([req, isTap, user]) => (isTap ? of(null) : this.api.put(`staff/${user.StaffID}/bl`, req)))
		);
	}

	saveVisibleColumns = (metakeys: Metakey[]) => {
		const newColumns = metakeys;
		const oldColumns = this.fgInsuranceQuery.getValue().columns;
		if (R.equals(newColumns, oldColumns)) {
			return of();
		}

		this.fgInsuranceStore.uiStore.setIsColumnSaving(true);

		const newColumnMetakeys = newColumns;

		return of(newColumnMetakeys).pipe(
			withLatestFrom(this.userQuery.userInfo$),
			map(([newVal, user]) => ({
				...user,
				StaffSettings: {
					...user.StaffSettings,
					FGSearchColumns: JSON.stringify(newVal),
				},
			})),
			withLatestFrom(this.userQuery.isTapLevel$, this.userQuery.userInfo$),
			mergeMap(([req, isTap, user]) =>
				isTap ? of(null) : this.api.put(`staff/${user.StaffID}/bl`, req).pipe(tap(() => this.userStore.update(req)))
			),
			finalize(() => this.fgInsuranceStore.uiStore.setIsColumnSaving(false)),
			tap(() => {
				this.fgInsuranceStore.setColumns(newColumnMetakeys);
			})
		);
	};

	saveField(req: {
		CustomerID: number;
		CustomerServiceID: number;
		MetaKey: Metakey;
		MetaValue: string;
		CustomerServiceType: string;
	}): Observable<JsonResultStatus> {
		const record = this.fgInsuranceQuery.getEntity(req.CustomerServiceID);
		const fields = R.omit(
			[
				'CustomerID',
				'CustomerServiceID',
				'link',
				'ActivityId',
				'PolicyOwnersList',
				'ClientNextActivityId',
				'UserNextActivityId',
			],
			record
		);
		const isCustomerDetail = Object.values(fields)?.find(x => x.metakey === req.MetaKey).isCustomerDetail;

		this.fgInsuranceStore.uiStore.setLoad(req.CustomerServiceID, req.MetaKey, true);
		const apiUrl = isCustomerDetail
			? `contacts/${req.CustomerID}?isPatch=true`
			: `services/${req.CustomerServiceID}?isPatch=true`;
		return this.api.put<JsonResultStatus>(`${apiUrl}`, R.omit(['CustomerServiceID', 'CustomerID'], req)).pipe(
			tap(() =>
				applyTransaction(() => {
					const stateReq = {
						...req,
						CustomerServiceId: isCustomerDetail
							? this.fgInsuranceQuery
									.getAll({ filterBy: row => row.CustomerID === req.CustomerID })
									?.map(x => x.CustomerServiceID)
							: req.CustomerServiceID,
					};
					this.fgInsuranceStore.updateField(stateReq);
					this.fgInsuranceStore.uiStore.setEdit(req.CustomerServiceID, req.MetaKey, false);
					this.setTempValue(req.CustomerServiceID, req.MetaKey, undefined);
				})
			),
			finalize(() => {
				this.fgInsuranceStore.uiStore.setLoad(req.CustomerServiceID, req.MetaKey, false);

				// Updates Total Premium
				if (req.MetaKey === 'Premium' || req.MetaKey === 'Broker Fee' || req.MetaKey === 'Admin Fee') {
					const api = req.MetaKey === 'Premium' ? req.MetaValue : fields.Premium.value;
					const brokerFee = req.MetaKey === 'Broker Fee' ? req.MetaValue : fields.BrokerFee.value;
					const adminFee = req.MetaKey === 'Admin Fee' ? req.MetaValue : fields.AdminFee.value;
					const totalPremium = +api + +brokerFee + +adminFee;
					const wholeNum = totalPremium % 1 === 0;
					req.MetaValue = wholeNum ? totalPremium?.toFixed(4) : totalPremium?.toString();
					req.MetaKey = 'Total Premium';
					this.saveField(req).pipe(take(1)).subscribe();
				}
			})
		);
	}

	edit = (customerServiceId: number, metakey: Metakey) =>
		this.fgInsuranceStore.uiStore.setEdit(customerServiceId, metakey, true);
	cancel = (customerServiceId: number, metakey: Metakey) =>
		applyTransaction(() => {
			this.fgInsuranceStore.uiStore.setEdit(customerServiceId, metakey, false);
			this.setTempValue(customerServiceId, metakey, undefined);
		});

	updateClientAndUserNextActivity = (customerId: number) => {
		return forkJoin(this.updateClientNextActivity(customerId), this.updateUserNextActivity(customerId));
	};

	updateClientNextActivity = (customerId: number) => {
		const customerServiceIds = this.fgInsuranceQuery
			.getAll({ filterBy: row => row.CustomerID === customerId })
			?.map(z => z.CustomerServiceID);
		customerServiceIds?.forEach(id => {
			this.fgInsuranceStore.uiStore.setLoad(id, 'Client Next Activity', true);
		});
		return this.api
			.get<ActivityViewModel>(`activities/${customerId}/customer`, { nextActivityOnly: true })
			.pipe(
				tap(x => {
					this.fgInsuranceStore.update(
						customerServiceIds,
						produce(draft => {
							if (x && !!x.ActivityId) {
								const formattedDate = moment(x.DueDate).format('DD/MM/YYYY');
								draft.ClientNextActivityId = x.ActivityId;
								// tslint:disable-next-line: max-line-length
								draft.ClientNextActivity.value =
									formattedDate + ' - ' + x.AssignedToAdviserName + ' - ' + x.ActivityType + ' - ' + x.ActivityName;
							} else {
								draft.ClientNextActivityId = null;
								draft.ClientNextActivity.value = null;
							}
						})
					);
				}),
				finalize(() => {
					customerServiceIds?.forEach(id => {
						this.fgInsuranceStore.uiStore.setLoad(id, 'Client Next Activity', false);
					});
				})
			);
	};

	updateUserNextActivity = (customerId: number) => {
		const customerServiceIds = this.fgInsuranceQuery
			.getAll({ filterBy: row => row.CustomerID === customerId })
			?.map(z => z.CustomerServiceID);
		customerServiceIds?.forEach(id => {
			this.fgInsuranceStore.uiStore.setLoad(id, 'User Next Activity', true);
		});
		return this.api
			.get<ActivityViewModel>(`activities/${customerId}/adviser`, { nextActivityOnly: true })
			.pipe(
				tap(x => {
					this.fgInsuranceStore.update(
						customerServiceIds,
						produce(draft => {
							if (x && !!x.ActivityId) {
								const formattedDate = moment(x.DueDate).format('DD/MM/YYYY');
								draft.UserNextActivityId = x.ActivityId;
								draft.UserNextActivity.value = formattedDate + ' - ' + x.ActivityType + ' - ' + x.ActivityName;
							} else {
								draft.UserNextActivityId = null;
								draft.UserNextActivity.value = null;
							}
						})
					);
				}),
				finalize(() => {
					customerServiceIds?.forEach(id => {
						this.fgInsuranceStore.uiStore.setLoad(id, 'User Next Activity', false);
					});
				})
			);
	};

	createClientNextActivity = (ac: ActivityViewModel) =>
		of(ActivityViewModel.MapToAdd(ac)).pipe(
			mergeMap(x => this.activityService.Post(x)),
			mergeMap(
				y => {
					if (y) {
						return this.updateClientAndUserNextActivity(ac.Customer.CustomerId);
					}
				},
				o => o
			)
		);

	sort(propSort: string, sort: 'asc' | 'desc') {
		this.fgInsuranceStore.uiStore.setSort(propSort, sort);
	}

	setTempValue = (customerServiceId: number, metakey: string, value: any) =>
		this.fgInsuranceStore.uiStore.setTempValue(customerServiceId, metakey, value);

	updateNote = (customerServiceId: number, value: string) => {
		this.fgInsuranceStore.update(
			customerServiceId,
			produce(draft => {
				const nzToday = MomentUtil.createMomentNz().format('DD/MM/YYYY');
				const ln =
					nzToday +
					' - ' +
					this.userQuery.getValue().FirstName +
					' ' +
					this.userQuery.getValue().LastName +
					' - ' +
					value;
				draft.LastNote.value = ln;
			})
		);
	};

	setSearchForm = (req: FgInsuranceRequest) => {
		this.fgInsuranceStore.setSearchForm(req);
	};
}
