import { Moment } from 'moment';
import * as R from 'ramda';
import { ViewDisplayValue } from '../../_general/display-value.viewmodel';

export interface PrimaryCustomerCompany {
  CompanyName: string;
  TradingName: string;
  Rank: string;
  MailingAddress1: string;
  MailingAddress2: string;
  City: string;
  Postcode: string | number;
  Industry: string;
  AnnualTurnover: string | number;
  Work: string | number;
  Website: string;
  PhysicalAddress: string;
  Email: string;
  PrimaryContact: PrimaryContact;
  LastReview: string | Moment;
  NextReview: string | Moment;
  Adviser: string | number;
  LeadType: string;
  LeadOrigin: string;
  LeadStatus: string;
  LeadGen: number;
  IRDNumber: string | number;
  AltAdviser: string | number;
  Mobile: string | number;
  TradingAs: string;
  ContactStatus: string;
  CustomerID: number;
  CreatedByStaffID: number;
  IsActive: boolean;
  CustomerType: string;
  LeadPending: string | boolean;
}

export interface PrimaryCustomerCompanyState {
  companyName: string;
  tradingName: string;
  rank: string;
  mailingAddress1: string;
  mailingAddress2: string;
  city: string;
  postcode: string | number;
  industry: string;
  annualTurnover: string | number;
  work: string | number;
  website: string;
  physicalAddress: string;
  email: string;
  primaryContact: any;
  lastReview: string | Moment;
  nextReview: string | Moment;
  adviser: string | number;
  leadType: string;
  leadOrigin: string;
  leadStatus: string;
  leadGen: number;
  iRDNumber: string | number;
  altAdviser: any;
  mobile: string | number;
  tradingAs: string;
  contactStatus: string;
  customerID: number;
  createdByStaffID: number;
  isActive?: boolean | number;
  customerType: string;
  leadPending: string | boolean;
  note?: string;
	lRNextReview?: string;
  //Flag for email duplicate
  saveAnyway?:boolean;
}

export interface PrimaryContact {
  CustomerId: number;
  PrimaryCustomerId: string | number;
  Name: string;
  IsCompany: boolean;
  IsAccessible: boolean;
}


export interface PrimaryContactState {
  customerId: number;
  primaryCustomerId: string | number;
  name: string;
  isCompany: boolean;
  isAccessible: boolean;
}

export enum BusinessPeopleTypes {
  Director = 'Director',
  Shareholder = 'Shareholder',
  Others = 'Others'
}

export enum ContactStatusCode {
  CurrentClient = 'C',
  Lead = 'L',
  ExClient = 'X'
}

export enum ContactStatusLabel {
  CurrentClient = 'Current Client',
  Lead = 'Lead',
  ExClient = 'Ex-Client'
}

export const GetStatusLabelByCode = (code: string) => {
  if (code === '') {
    return ContactStatusLabel.CurrentClient;
  }

  const statusCodeKey = R.find((key: string) => ContactStatusCode[key as keyof typeof ContactStatusCode] === code, Object.keys(ContactStatusCode));
  if (statusCodeKey) {
    const label = ContactStatusLabel[statusCodeKey as keyof typeof ContactStatusLabel];
    return label;
  }
  else {
    return ContactStatusLabel.ExClient;
  }

}

export const GetContactStatusViewDisplay = () => {
  const keys: string[] = Object.keys(ContactStatusCode);
  const contactStatuses: ViewDisplayValue[] = [];
  const makeViewDisplay = (key: string) => {
    contactStatuses.push({
      display: ContactStatusLabel[key as keyof typeof ContactStatusLabel],
      value: ContactStatusCode[key as keyof typeof ContactStatusCode]
    })
  }
  R.forEach(makeViewDisplay, keys);
  return contactStatuses;
}

export const GetActiveContactStatuses = () => {
  return [ContactStatusCode.CurrentClient, ContactStatusCode.Lead];
}
