import {
	Component,
	OnInit,
	Output,
	Input,
	EventEmitter,
	ViewChild,
	OnChanges,
	SimpleChanges,
	ChangeDetectorRef,
	ElementRef,
	OnDestroy,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Observer, Observable } from 'rxjs';
import { withLatestFrom, map, take, filter, tap, takeUntil } from 'rxjs/operators';
import { DeleteModalComponent } from '../../modal/delete-modal/delete-modal.component';
import { BLStaffsQuery } from '../../../domain/bl-staff/bl-staffs.query';
import { FgCustomerServiceGroupState, FgCustomerServiceState } from '../../models/services/fg-insurance/fg-insurance.model';
import { FgInsuranceMapper } from '../../models/services/fg-insurance/fg-insurance.mapper';
import { ViewDisplayValue } from '../../../shared/models/_general/display-value.viewmodel';
import { ArchiveModalComponent } from '../../modal/archive-modal/archive-modal.component';
import { ConfirmModalComponent } from '../../modal/confirm-modal/confirm-modal.component';
import { NoteAddModalComponent } from '../../modal/note-modal/note-modal.component';
import { zip, of } from 'rxjs';
import { patchValue } from '../service-utils/service.util';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { DateInputComponent } from '../../date-input/date-input.component';
import { UserQuery } from 'src/app/domain/user/user.query';
import { Fields, getInvalidWarning, getRequiredWarning } from '../../error-message/error-message';
import { util } from '../../../core/util/util.service';
import { NoWhitespaceValidator } from '../../validator/no-whitespace/no-whitespace.directive';
import * as R from 'ramda';
import { TapCollapseComponent } from '../../tap-collapse/tap-collapse.component';
import { numUtil } from 'src/app/util/util';
import { ClientProfileService } from '@modules/crm/client-profile/states/client-profile.service';
import { ComponentBase } from '@core/base/component-base';
import { BusinessProfileService } from '@modules/crm/business-profile/states/business-profile.service';
import { ServicesCodes } from '@shared/models/services/services.model';
import { BusinessConfigQuery } from '../../../domain/business-config/business-config.query';
import { ClaimsTable } from '@shared/models/advice-process/advice-process.model';

@Component({
	selector: 'app-fg-insurance-service-form',
	templateUrl: './fg-insurance-service-form.component.html',
	styleUrls: ['./fg-insurance-service-form.component.scss'],
})
export class FgInsuranceServiceFormComponent extends ComponentBase implements OnInit, OnChanges, OnDestroy {
	public bsModalRef: BsModalRef;

  @ViewChild(TapCollapseComponent) fgTapCollapse: TapCollapseComponent;


	@Input() isLead: boolean;
	@Input() isCompany: boolean;

	@Output() saveEvent = new EventEmitter<any>();
	@Output() deleteEvent = new EventEmitter<any>();
	@Output() archiveEvent = new EventEmitter<any>();
	@Output() deleteNoteEvent = new EventEmitter<any>();
	@Output() cancelAddEvent = new EventEmitter<boolean>();
	@Output() addNoteEvent = new EventEmitter<any>();

	@Input() fg: FgCustomerServiceState;

	@Input() statuses: ViewDisplayValue[];
	@Input() claimStatus: ViewDisplayValue[];
	@Input() accountStatus: ViewDisplayValue[];
	@Input() insurers: ViewDisplayValue[];
	@Input() policyTypes: ViewDisplayValue[];
	@Input() policyWriters: ViewDisplayValue[];
	@Input() policyOwners: ViewDisplayValue[];
	@Input() policyLines: ViewDisplayValue[];
	@Input() originalAdvisers: ViewDisplayValue[];
	@Input() csList: FgCustomerServiceGroupState[];
	@Input() frequencies: ViewDisplayValue[];
	@Input() paymentMethods: ViewDisplayValue[];

	@Input() groupName: string;
	@Input() formId: string;
	@Input() isSaving: boolean;
	@Input() addMode: boolean;
	@Input() isAddNewRenewal: boolean;
	@Input() isNewGroup: boolean;
	@Input() activeSourceId: number;
	@Input() claimsFeature:boolean = false;
	@Input() fGClaims:ClaimsTable[];
	@Input() redirectToAP$: (data:{id?:string,serviceCode?:string,customerServiceId?:string}) => Observable<any>;

	@ViewChild('formProp') formProp: ElementRef;
	@ViewChild('inceptionDateInput') inceptionDateInput: DateInputComponent;
	@ViewChild('renewalDateInput') renewalDateInput: DateInputComponent;
	@ViewChild('policyEndDateInput') policyEndDateInput: DateInputComponent;
	@ViewChild('firstPolicyDateInput') firstPolicyDateInput: DateInputComponent;
	@ViewChild('issueDateInput') issueDateInput: DateInputComponent;


	inceptionDate = '';
	renewalDate = '';
	policyEndDate = '';
	firstPolicyDate = '';
	issueDate = '';
	hasPermission = this.userQuery.hasPermission$;

  /**
   * store form values before editing to retain the
   * original form values when user cancel editing
   */
  originalFormValue: any;

	isEditForm: boolean;
	notes: any[];
	policyOwnersDropdown: ViewDisplayValue[];
	form: UntypedFormGroup = this.fb.group({
		customerServiceID: '',
		customerID: '',
		policyOwners: '',
		policyLines: '',
		premium: null,
		status: ['', [Validators.required]],
		claimStatus: '',
		fGPolicyNumber: ['', [Validators.required, NoWhitespaceValidator]],
		brokerFee: null,
		renewalDate: '',
		accountStatus: '',
		insurer: ['', [Validators.required]],
		totalPremium: null,
		inceptionDate: '',
		policyType: '',
		policyWriter: '',
		quoteNumber: '',
		policyEndDate: '',
		firstPolicyDate: '',
		fixedPeriodEnd: '',
		originalAdviser: ['', [Validators.required]],
		required: '',
		fGPolicyNumberSuffix: '',
		note: '',
		isActive: 1,
		color: '',
		notes: [],
		documentLink: '',
		tracking: '',
		adminFee: null,
		issueDate: '',
		paymentMethod: '',
		frequency: ''
	});

	get status() {
		return this.form.get('status');
	}

	get originalAdviser() {
		return this.form.get('originalAdviser');
	}

	get fGPolicyNumber() {
		return this.form.get('fGPolicyNumber');
	}

	get insurer() {
		return this.form.get('insurer');
	}

	notesHeader = [
		{ title: 'Created By' },
		{ title: 'Created date & Time' },
		{ title: 'Details' },
		{ title: ' ' },
	];

	advisers$ = this.blStaffsQuery.adviserChoicesOption$;
	originalAdvisers$ = this.blStaffsQuery.allStaffsChoices$.pipe(
		withLatestFrom(this.advisers$),
		// tslint:disable-next-line: max-line-length
		map(([all, adv]) =>
			all
				? all
						?.filter(
							(x) =>
								+x.value === (this.fg ? this.fg.originalAdviser : '') ||
								adv?.find((y) => y.value === x.value)
						)
						?.sort((a, b) => a.display?.localeCompare(b.display))
				: all
		)
	);

	emailClientTemplateFeature$ = this.businessConfigQuery.emailClientTemplateFeature$

	@Input() newMtafg: FgCustomerServiceState;

	@Input() activeCustomerServiceId: number;

	get PolicyOwners() {
		return this.form.get('policyOwners');
	}

	get customerServiceID() {
		return this.form.get('customerServiceID');
	}

	constructor(
		private fb: UntypedFormBuilder,
		private modalService: BsModalService,
		private blStaffsQuery: BLStaffsQuery,
		private cd: ChangeDetectorRef,
		private loggerService: LoggerService,
		private userQuery: UserQuery,
		private clientService: ClientProfileService,
		private businessService: BusinessProfileService,
		private businessConfigQuery: BusinessConfigQuery
	) {
		super();
	}

	ngOnChanges(changes: SimpleChanges) {
    if (!changes) { return; }

    if (
      (!changes.isNewGroup?.previousValue && changes.isNewGroup?.currentValue) ||
      (!changes.addMode?.previousValue && changes.addMode?.currentValue)
    ) {
      setTimeout(() => this.fgTapCollapse?.show());
    }

    if (changes.isSaving && !this.isSaving) {
      this.prepData();
    }

    if (changes.fg) {
      if (changes.fg.previousValue?.notes?.length === this.fg?.notes?.length) {
        this.prepData();
      } else {
        this.notes = this.fg.notes;
        this.form.patchValue({ notes: this.fg.notes });
      }
    }
    this.setPolicyOwners();
	}

	ngOnInit() {
		this.addMode ? this.form.enable() : this.form.disable();

		if (!!this.isAddNewRenewal) {
			this.form.get('firstPolicyDate').disable();
		}

		this.form.get('premium').valueChanges.subscribe(() => {
			const premium = isNaN(this.form.get('premium').value)
				? null
				: this.form.get('premium').value;
			const brokerFee = isNaN(this.form.get('brokerFee').value)
				? null
				: this.form.get('brokerFee').value;
			const adminFee = isNaN(this.form.get('adminFee').value)
				? null
				: this.form.get('adminFee').value;
			if (!premium && !brokerFee && !adminFee) {
				this.form.controls.totalPremium.setValue(null);
			} else {
				this.recomputeTotalPremium(+premium, +brokerFee, +adminFee);
			}
		});
		this.form.get('brokerFee').valueChanges.subscribe(() => {
			const premium = isNaN(this.form.get('premium').value)
				? null
				: this.form.get('premium').value;
			const brokerFee = isNaN(this.form.get('brokerFee').value)
				? null
				: this.form.get('brokerFee').value;
			const adminFee = isNaN(this.form.get('adminFee').value)
				? null
				: this.form.get('adminFee').value;
			if (!premium && !brokerFee && !adminFee) {
				this.form.controls.totalPremium.setValue(null);
			} else {
				this.recomputeTotalPremium(+premium, +brokerFee, +adminFee);
			}
		});
		this.form.get('adminFee').valueChanges.subscribe(() => {
			const premium = isNaN(this.form.get('premium').value)
				? null
				: this.form.get('premium').value;
			const brokerFee = isNaN(this.form.get('brokerFee').value)
				? null
				: this.form.get('brokerFee').value;
			const adminFee = isNaN(this.form.get('adminFee').value)
				? null
				: this.form.get('adminFee').value;
			if (!premium && !brokerFee && !adminFee) {
				this.form.controls.totalPremium.setValue(null);
			} else {
				this.recomputeTotalPremium(+premium, +brokerFee, +adminFee);
			}
		});

		if (this.addMode && !this.fg) {
			zip(of(this.insurers), of(this.statuses), of(this.claimStatus), of(this.accountStatus), of(this.policyTypes), of(this.policyWriters))
				.pipe(
					take(1),
					map((ddListList) => {
						const defaultValueList: string[] = ddListList
							?.map((ddList) => ddList?.find((dd) => dd.isDefault))
							?.map((def) => def && def.value);
						return defaultValueList;
					})
				)
				.subscribe(this.setDropdownDefaults);
		}

		this.form.valueChanges
		.pipe(
			filter((x) => !!x),
			tap(() => this.setPolicyOwners()),
			takeUntil(this.onDestroy$)
		)
		.subscribe();
	}

	setDropdownDefaults: (defaultValues: string[]) => void = ([
		i,
		st,
		cs,
		as,
		pt,
		pw
	]) => {
		patchValue<any>(this.form, {
			insurer: i,
			status: st,
			claimStatus: cs,
			accountStatus: as,
			policyType: pt,
			policyWriter: pw
		});
	};

	recomputeTotalPremium(premium: number, brokerFee: number, adminFee: number) {
		let result = R.sum([premium || 0, brokerFee || 0, adminFee || 0]);
		result = +numUtil.formatToNumCurrency4Decimal(result);

		this.form.controls.totalPremium.setValue(result);
	}

	refresh() {
		this.cd.detectChanges();
	}

	setPolicyOwners() {
		this.policyOwnersDropdown = this.getOwnerChoices(this.PolicyOwners.value);
	}

	getOwnerChoices(owners: (string | number)[]) {
		if (this.isCompany) {
			return this.businessService.getOwnerChoices(owners || [], this.policyOwners);
		}
		return this.clientService.getOwnerChoices(owners || [], this.policyOwners);
	}

	prepData() {
		this.notes =
			this.fg && this.fg.notes && this.fg.notes?.length > 0
				? this.fg.notes
				: null;

		const data = FgInsuranceMapper.mapToView(this.fg);

		if (this.addMode && this.fg && this.isAddNewRenewal) {
			const policyLines = [];
			const fgData = this.csList?.find((x) => x?.policyNumber === this.fg?.fGPolicyNumber && x?.provider === this.fg?.insurer);
			// this.csList.forEach(cs => cs.status === 'Inforce' ? JSON.parse(cs.policyLines).forEach(p => !policyLines.includes(p) ? policyLines.push(p) : null) : null);
			fgData?.customerServices?.forEach((cs) => {
				if (cs?.policyLines && cs?.status === 'Inforce') {
					JSON.parse(cs?.policyLines).forEach((p) => {
						if (!policyLines.includes(p)) {
							policyLines.push(p)
						}
					})
				}
			});
			const { inceptionDate, renewalDate } = data;

			this.form.reset({
				...data,
				policyLines: policyLines,
				fGPolicyNumber: '',
				fGPolicyNumberSuffix: '',
				premium: null,
				brokerFee: null,
				totalPremium: null,
				quoteNumber: '',
				policyEndDate: '',
				inceptionDate: util.AddNTime(inceptionDate, 1, 'y'),
				renewalDate: util.AddNTime(renewalDate, 1, 'y'),
				note: `Renewing from Policy #${data.fGPolicyNumber}`,
				tracking: 'Renewal',
				adminFee: null,
				issueDate: null
			});
			this.form.controls['firstPolicyDate'].disable();
		} else if (this.addMode && this.fg) {
			this.form.reset({
				originalAdviser: data.originalAdviser,
				claimStatus: data.claimStatus,
				accountStatus: data.accountStatus,
				fGPolicyNumber: data.fGPolicyNumber,
				fGPolicyNumberSuffix: data.fGPolicyNumberSuffix,
				inceptionDate: data.inceptionDate,
				customerServiceID: data.customerServiceID,
				status: null,
				tracking: 'MTA',
				issueDate: null
			});
		} else {
			if (this.addMode) {
				this.form.reset(FgInsuranceMapper.mapToAddView(this.fg));
				if (this.isNewGroup || !this.fg || (this.fg && this.fg.policyType !== 'Primary')) {
					this.form.controls.policyType.setValue('Primary');
					this.form.controls.tracking.setValue('Original');
				}
			} else {
				this.form.reset(data);
			}
		}
		this.setPolicyOwners();
	}

	formSaving(isSaving: boolean) {
		this.isSaving = isSaving;
		this.refresh();
	}

	editForm(isEdit: boolean) {
    this.isEditForm = isEdit;
    isEdit ? this.form.enable() : this.form.disable();
    this.refresh();

		this.originalFormValue = R.clone(this.form.value);

    if (this.form.value.tracking === 'Renewal' || (this.form.value.tracking === 'Original' && !!this.form.value.firstPolicyDate?.isValid())) {
      this.form.controls.firstPolicyDate.disable();
    }
	}

	resetForm() {
		this.form.reset(FgInsuranceMapper.mapToView(this.fg));
		this.isSaving = false;
	}

	save(isAddNote?: boolean) {
		if (
			!this.form.valid ||
			this.inceptionDateInput?.isInvalid() ||
			this.renewalDateInput?.isInvalid() ||
			this.firstPolicyDateInput?.isInvalid() ||
			this.issueDateInput?.isInvalid() ||
			this.policyEndDateInput?.isInvalid()
		) {
			if (!this.form.value.fGPolicyNumber) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.PolicyNumber));
				return;
			}
			if (!this.form.value.status) {
				this.loggerService.Warning({}, getRequiredWarning(Fields.Status));
				return;
			}
			if (!this.form.value.originalAdviser) {
				this.loggerService.Warning(
					{},
					getRequiredWarning(Fields.OriginalAdviser)
				);
				return;
			}
			if (this.issueDateInput?.isInvalid()) {
				this.loggerService.Warning({}, getInvalidWarning(Fields.IssueDate));
				return;
			}
			if (this.inceptionDateInput?.isInvalid()) {
				this.loggerService.Warning({}, getInvalidWarning(Fields.InceptionDate));
				return;
			}
			if (this.renewalDateInput?.isInvalid()) {
				this.loggerService.Warning({}, getInvalidWarning(Fields.RenewalDate));
				return;
			}
			if (this.firstPolicyDateInput?.isInvalid()) {
				this.loggerService.Warning(
					{},
					getInvalidWarning(Fields.FirstPolicyDate)
				);
				return;
			}
			if (this.policyEndDateInput?.isInvalid()) {
				this.loggerService.Warning({}, getInvalidWarning(Fields.PolicyEndDate));
				return;
			}
			if (!Boolean(this.form.value.insurer) && this.form.value.tracking !== 'MTA') {
				this.loggerService.Warning({}, getRequiredWarning(Fields.Insurer));
				return;
			}
		}

		if (isNaN(this.form.value.premium)) {
			this.loggerService.Warning({}, getInvalidWarning(Fields.Premium));
			return;
		}
		if (isNaN(this.form.value.brokerFee)) {
			this.loggerService.Warning({}, getInvalidWarning(Fields.BrokerFee));
			return;
		}

		const form = this.form.getRawValue();
		form.customerServiceID = this.fg ? this.fg.customerServiceID : '';
		form.isActive = this.addMode ? 1 : this.fg.isActive;
		form.sourceId = this.fg ? this.addMode ? this.activeSourceId : this.fg?.sourceId : null;
		const data = FgInsuranceMapper.mapToUpsert(form);

		if (this.addMode) {
			if (this.fg) {
        Object.keys(data).forEach(key => data[key] === null && delete data[key]);
        this.saveEvent.emit(this.mergeFGandMTAData(this.fg, data));
			} else {
				this.saveEvent.emit(data);
			}
		} else {
			this.saveEvent.emit({ data, isAddNote });
		}
	}

  /**
   * merge FG and MTA data
   * @description delete all blank property of FG that is blank or doesn't exists in MTA
   *              before merging them.
   */
  private mergeFGandMTAData(fgData: FgCustomerServiceState, mtaData: any): any {
    const fgDataClone = R.clone(FgInsuranceMapper.mapToUpsert(fgData));
    // properties that is FG form and MTA form have in common
    [
      'policyLines',
      'fGPolicyNumber',
      'fGPolicyNumberSuffix',
      'premium',
      'status',
      'claimStatus',
      'quoteNumber',
      'brokerFee',
      'originalAdviser',
      'accountStatus',
      'inceptionDate',
      'totalPremium',
      'policyEndDate',
      'required',
      'note',
			'adminFee',
			'policyOwners', // Doesn't exist in MTA form
			'issueDate'
    ].forEach(key => !mtaData[key] && delete fgDataClone[key]);
    return { ...fgDataClone, ...mtaData };
  }

	archive(isArchive) {
		const msg = this.fg.isActive === 1 ? 'archive' : 'unarchive';
		const initState: any = {
			confirm$: new Observable(obs => {
				this.isSaving = true;
				this.archiveEvent.emit({ fg: this.fg, isArchive });
				obs.complete();
			}),
			header: 'Archive service',
			message: `Are you sure you want to ${msg} this item?`,
			isArchive: isArchive ? true : false,
		};
		this.modalService.show(ArchiveModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	cancel() {
		if (this.addMode) {
			const confirm = new Observable((obs: Observer<any>) => {
				if (this.addMode) {
					this.cancelAddEvent.emit(true);
				}
				this.isEditForm = false;
				this.prepData();
				this.form.disable();
				obs.complete();
			});

			const decline = new Observable((obs: Observer<any>) => {
				obs.complete();
			});

			const initState = {
				header: 'Discard Confirmation',
				message: `Current information will be discarded?`,
				confirm$: confirm,
				decline$: decline,
			};

			this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
				class: 'modal-dialog-centered modal-dialog',
				initialState: initState,
				ignoreBackdropClick: true,
				keyboard: false,
			});
		} else {
      this.isEditForm = false;
      this.prepData();
      this.form.disable();

      const data = FgInsuranceMapper.mapToView(this.form.getRawValue());

      if (!data.inceptionDate['_i']) { this.inceptionDateInput.reset(); }
      if (!data.renewalDate['_i']) {
        if (this.renewalDateInput) {
          this.renewalDateInput.reset();
        }
      }

			this.form.setValue(this.originalFormValue);

			if (!this.originalFormValue.policyLines) {
				this.form.get('policyLines').reset([]);
			}

			if (!this.originalFormValue.policyOwners) {
				this.form.get('policyOwners').reset([]);
			}

			this.originalFormValue = null;
		}
	}

	deleteConfirm() {
		const originalFg = this.csList.find((fg) => fg.sourceId === this.fg.customerServiceID);

		const mtaCs = originalFg ? originalFg.customerServices.filter((cs) => cs.tracking === 'MTA').length : 0;
		const confirm = new Observable((obs: Observer<any>) => {
			this.delete();
			obs.complete();
		});
		const initState = {
			header: 'Delete',
			message: originalFg && mtaCs > 0 ?
				`There ${mtaCs === 1 ? 'is an MTA' : 'are MTAs'} under this F&G, kindly remove ${mtaCs === 1 ? 'it' : 'them'} before deleting.` :
				`Are you sure you want to delete?`,
			delete$: confirm,
			canDelete: originalFg && mtaCs > 0 ? false : true
		};
		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	delete() {
		const form = this.form.getRawValue();
		this.deleteEvent.emit(form);
	}

	deleteNote$ = data => {
		return new Observable(obs => {
			this.editForm(false);
			const form = this.form.value;
			const mappedForm = FgInsuranceMapper.mapToUpsert(form);

			this.deleteNoteEvent.emit({ noteId: data.notesID, data: mappedForm });
			obs.next();
			obs.complete();
		});
	};

	openModalAddNote() {
		const saveNote = (notes: string) =>
			new Observable((obs) => {
				const form = FgInsuranceMapper.mapToView(this.fg);
				form.note = notes;
				this.addNoteEvent.emit(form);
				obs.next();
				obs.complete();
			});
		const initState: any = {
			header: 'Add Note',
			savefn: saveNote,
		};
		this.modalService.show(NoteAddModalComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
	}

	saveNewMta() {
		const formValues = this.form.value;
		const newValue = {};
		Object.keys(formValues).forEach(field => {
			if (formValues[field] !== null) {
				newValue[field] = formValues[field]
			}
		});
		const data = {
			...this.fg,
			...newValue
		}
		data.policyOwners = JSON.parse(data.policyOwners)
		const newData = FgInsuranceMapper.mapToUpsert(data);
		this.saveEvent.emit(newData);
	}

	trackByValue(index, item: ViewDisplayValue) {
		return item.value;
	}

	openEmailPopup() {
		if (this.isCompany) {
			this.openEmailBusinessPopup();
		} else {
			this.openEmailClientPopup();
		}
	}

	private openEmailBusinessPopup() {
		this.businessService.openECModalSubject$.next({
			data: this.form.value,
			documentType: ServicesCodes.FG,
			type: ServicesCodes.NotesFG,
      referenceId: this.customerServiceID.value || 0
		});
	}

	openEmailClientPopup() :void {
		this.clientService.openECModalSubject$.next({
			data: this.form.value,
			documentType: ServicesCodes.FG,
			type: ServicesCodes.NotesFG,
      referenceId: this.customerServiceID.value || 0
		});
  }

	ngOnDestroy(): void {
		super.dispose();
	}
}
