const successSave = 'Successfully saved';

export enum DocumentNames {
	Disclosure = 'Disclosure Document',
	SOS = 'Scope of Service Document',
	Declaration = 'Declaration Document',
	SOA = 'Statement of Advice',
	AuthorityToProceed = 'Authority to Proceed Document',
}

export enum Fields {
	ExpensesStop = 'Expenses Could Stop',
	IncomeStart = 'Income Could Start',
	AdditionalIncome = 'Additional Income',
	ExcludedFromAdviceDueTo = 'Exclude From Advice Due To',
	ExpensesStart = 'Expenses Could Start',
	ChangeOfExpenses = 'Type of Expenses',
	OtherCostList = 'Other Cost List',
	PartnersIncome = 'Partner’s Incomes',
	LifeInsuranceRequirementOther = 'Life Insurance Requirement Other field',
	PrimaryContact = 'Primary Contact',
	CompanyName = 'Company Name',
	Adviser = 'Adviser',
	LastReview = 'Last Review',
	NextReview = 'Next Review',
	FirstName = 'First Name',
	LastName = 'Last Name',
	DateOfBirth = 'Date of Birth',
	Income = 'Income',
	FileName = 'FileName',
	ProfessionalType = 'Professional Type',
	TrustName = 'Trust Name',
	TrustType = 'Trust Type',
	BusinessEmailAddress = 'Business Email Address',
	ActivityType = 'Activity Type',
	ActivityName = 'Activity Name',
	Time = 'Time',
	Duration = 'Duration',
	Date = 'Date',
	DueDate = 'Due Date',
	DueTime = 'Due Time',
	ClientsInvolved = 'Clients Involved',
	Claimants = 'Claimants',
	EmailAddress = 'Email Address',
	Status = 'Status',
	OriginalAdviser = 'Original Adviser',
	InceptionDate = 'Inception Date',
	RenewalDate = 'Renewal Date',
	PolicyEndDate = 'Policy End Date',
	IssueDate = 'Issue Date',
	FirstPolicyDate = 'First Policy Date',
	Premium = 'Premium',
	BrokerFee = 'Broker Fee',
	LoanNumber = 'Loan Number',
	Provider = 'Provider',
	SubmittedDate = 'Submitted Date',
	Approved = 'Approved',
	LoanDrawdown = 'Loan Drawdown',
	ExpectedSettlement = 'Expected Settlement',
	FixedPeriodEndDate = 'Fixed Period End Date',
	InterestOnlyExpiry = 'Interest Only Expiry',
	LoanValue = 'Loan Value',
	InterestRate = 'Interest Rate',
	OriginalLoanTerm = 'Original Loan Term',
	PropertyAddress = 'Property Address',
	GRM1Date = 'GRM1 Date',
	GRM2Date = 'GRM2 Date',
	PurchaseDate = 'Purchase Date',
	PropertySettlementDate = 'Property Settlement Date',
	Valuation1 = 'Valuation 1',
	Valuation2 = 'Valuation 2',
	RentalIncome = 'Rental Income',
	FundOwner = 'Fund Owner',
	Investor = 'Investor',
	MemberNumber = 'Member Number',
	FundType = 'Fund Type',
	Contribution = 'Contribution',
	IRDNumber = 'IRD Number',
	Origin = 'Origin',
	StartDate = 'StartDate',
	Type = 'Type',
	InvestmentType = 'Investment Type',
	FUM = 'FUM',
	investorNumber = 'Investor Number',
	product = 'Product',
	PolicyNumber = 'Policy Number',
	PolicyStatus = 'Policy Status',
	AnniversaryDate = 'Anniversary Date',
	StartTrans = 'Start Trans',
	SubmittedAPI = 'Submitted API',
	Commission = 'Commission',
	NetRentalIncome = 'Net Rental Income',
	ExpectedSettlementDate = 'Expected Settlement Date',
	SOAupdated = 'This SOA has been updated by another user and needs to be refreshed. The refresh will redirect you back to the main SOA page.',
	UnderwritingOutcome = 'Underwriting Outcome',
	CancellationDate = 'Cancellation Date',
	CommissionDate = 'Commission Date',
	Insurer = 'Insurer'
}

export const getSaveSuccess = (doc: string) => {
	return `${successSave} ${doc}.`;
};

export const getInvalidWarning = (field: string) => {
	return `Invalid value for ${field}.`;
};

export const getDownloadSuccess = (doc: string) => {
	return `Successfully downloaded ${doc}.`;
};

export const getRequiredWarning = (field: string) => {
	return `${field} is required.`;
};

export const getUploadSuccess = (doc: string) => {
	return `Successfully uploaded ${doc}.`;
};

export const getDocumentSentSuccess = (email: string) => {
	return `Document has been emailed to ${email}.`;
};

export const getUpdateSuccess = (doc: string) => {
	return `Successfully updated ${doc}.`;
};

export const getAlreadyExists = (field: string) => {
	return `${field} already exists.`;
};

export const unallowedFileChars = ['\\','/', ':', '*', '<', '>', '|', '+'];

export const logMessage = {
	shared: {
		general: {
			warning: {
				required: 'Please see required fields.',
				exists: ' already exists.',
				minNotGreaterThanMaxDate:
					'Min date should not be greater than Max date.',
				invalidMinDate: 'Invalid Min Date.',
				invalidMaxDate: 'Invalid Max Date.',
				invalidDate: 'Invalid Date.',
			},
			error: 'Something went wrong please try again later.',
		},
		export: {
			error: 'Export has encountered an error.',
		},
    transfer: {
			error: 'Transfer has encountered an error.',
		},
		fileUploadSize: {
			single: {
				error:
					'The file you are trying to upload is more than 10MB. Please choose a different file or use a compression tool to reduce the size.',
			},
			multi: {
				error:
					'Some of the file(s) you are trying to upload are more than 10MB. Please choose a different file or use a compression tool to reduce the size.',
			},
			dynamic: {
				error:
					'The file you are trying to upload is more than %fileSize%. Please choose a different file or use a compression tool to reduce the size.',
			},
			total: {
				error:
					'The total files you are trying to upload will be more than 10MB. Please choose a different file or use a compression tool to reduce the size.',
			},
		},
		fileLinkSize: {
			single: {
				error:
					'The file you are trying to link is more than 10MB. Please choose a different file or use a compression tool to reduce the size.',
			},
			total: {
				error:
					'The total files you are trying to link will be more than 10MB. Please choose a different file or use a compression tool to reduce the size.',
			},
		},
		fileInvalidExtension: {
			error: 'Invalid file extension.',
		},
		fileInvalidExtensionSpecific:{
			error:'Invalid file extension. Only the following are accepted:%fileType%',
		},
		fileName: {
			error: `A filename can\'t contain any of the following characters ${unallowedFileChars?.join(', ')}. Please amend the name of the file and reupload this.`
		}
	},
	settings: {
		warning: {
			documentEmpty: 'Document cannot be empty.',
		},
		error: {
			sendEmailFail: 'Sending Email Failed.',
		},
	},
	crm: {
		activity: {
			success: {
				create: 'Activity has been created.',
			},
		},
		services: {
			kiwiSaver: {
				warning: {
					fundOwnerMax:
						'A maximum of 1 Fund Owner is able to be selected for KiwiSaver.',
					fundOwnerMin: 'A minimum of 1 Fund Owner is required.',
					fundOwnerExist: 'This Fund Owner has an existing KiwiSaver.',
				},
			},
		},
	},
	oat: {
		shared: {
			warning: {
				invalidSci:
					'Client no longer exists in the CRM. Updates made here has not been pushed to the CRM.',
			},
			insurance: {
				warning: {
					minimum: 'Please add one product.',
				},
			},
			disclosure: {
				success: {
					save: getSaveSuccess(DocumentNames.Disclosure),
				},
			},
			scopeOfService: {
				success: {
					save: getSaveSuccess(DocumentNames.SOS),
				},
			},
			factFind: {
				people: {
					warning: {
						maxPeople: 'Maximum of 3 people reached.',
					},
				},
				assetsLiabilities: {
					otherAssets: {
						sold: 'This will be marked as sold in the CRM',
					},
					investments: {
						remove:
							'This Other Asset / Investment has been removed from the CP. If this is an Asset, please confirm this should be marked as sold - note that until this Advice Process has been synced to the CRM, this will only remove data in the Online Advice Tool. If this is an Investment, this will only remove data in the Online Advice Tool, it will not be deleted from the CRM.',
					},
					mortgages: {
						warning: {
							minimum: 'Please input a minimum value of 0 for Loan Value.',
						},
						deleteApproval:
							'This Mortgage has been removed from the CP. Please note, this will only remove data in the Online Advice Tool, it will not be deleted from the CRM.',
					},
					kiwiSaver: {
						deleteApproval:
							'This KiwiSaver has been removed from the CP. Please note, selecting OK will only remove data in the Online Advice Tool, it will not be deleted from the CRM.',
					},
				},
				delete:
					'This will only remove data in the Online Advice Tool, it will not be deleted from the CRM.',
			},
			declaration: {
				success: {
					save: getSaveSuccess(DocumentNames.Declaration),
				},
			},
		},
		lr: {
			riskAnalysis: {
				warning: {
					invalid: {
						expensesStop: getInvalidWarning(Fields.ExpensesStop),
						incomeStart: getInvalidWarning(Fields.IncomeStart),
						expensesStart: getInvalidWarning(Fields.ExpensesStart),
						otherIncomeList: getInvalidWarning(Fields.OtherCostList),
						partnersIncomes: getInvalidWarning(Fields.PartnersIncome),
						lifeInsuranceOther: getInvalidWarning(
							Fields.LifeInsuranceRequirementOther
						),
					},
				},
			},
			soa: {
				success: {
					save: getSaveSuccess(DocumentNames.SOA),
					update: 'Successfully updated SOA name to ',
				},
				warning: {
					minimum:
						'Save at least one SOA to file or Finalized before proceeding.',
				},
			},
			authorityToProceed: {
				success: {
					save: getSaveSuccess(DocumentNames.AuthorityToProceed),
					download: getDownloadSuccess(DocumentNames.AuthorityToProceed),
				},
			},
		},
		mortgage: {
			factFind: {
				people: {
					warning: {
						maxPeople: 'Maximum of 4 people reached.',
					},
				},
			},
			declaration: {
				error: 'Something went wrong when saving declaration document.',
			},
			application: {
				success: {
					updateName: 'Successfully updated Bank Application name to',
				},
			},
			cashDeposit: {
				warning: {
					securityModified:
						'One or more properties were removed from fact find. Please review the section above.',
				},
			},
			reviewApplication: {
				success:
					'The file is being processed in the background. We’ll send you an email once it’s done.',
			},
			cpInvitation: {
				success: 'Client has been invited to client portal',
				error: 'Client Portal is already shared to ',
				successfullySentAdditionalInformation: 'Successfully sent',
			},
		},
	},
};
