import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FgInsuranceQuery } from '../states/fg-insurance.query';
import { FgInsuranceService } from '../states/fg-insurance.service';
import { Metakey } from '../fg-insurance-datatable.config';
import { Subject } from 'rxjs';
import { TableColumn } from '@swimlane/ngx-datatable';
import { Row } from '../states/fg-insurance.model';
import { takeUntil, withLatestFrom, map } from 'rxjs/operators';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
declare var $: any;

@Component({
  selector: 'app-fg-insurance-columns-form',
  templateUrl: './fg-insurance-columns-form.component.html',
  styleUrls: ['./fg-insurance-columns-form.component.scss'],
})
export class FgInsuranceColumnsFormComponent
  implements OnInit, OnDestroy, AfterViewInit {
  private onDestroy$: Subject<void> = new Subject<void>();

  visibleColumns$ = this.fgInsuranceQuery.tableColumns$;
  hiddenColumns$ = this.fgInsuranceQuery.hiddenTableColumns$;

  visibleColumns: (TableColumn & { metakey: Metakey; prop: keyof Row })[];
  hiddenColumns: (TableColumn & { metakey: Metakey; prop: keyof Row })[];

  isColumnSaving$ = this.fgInsuranceQuery.uiQuery.isColumnSaving$;

  form = new UntypedFormGroup({});
  formInitialized = false;

  constructor(
    private fgInsuranceQuery: FgInsuranceQuery,
    private fgInsuranceService: FgInsuranceService,
		private businessConfigQuery: BusinessConfigQuery
  ) {}

  ngOnInit() {
    this.visibleColumns$
      .pipe(
				withLatestFrom(this.businessConfigQuery.adviserReworkFeature$),
				map(([vcolumns, adviserRework]) =>
					vcolumns.filter((x) =>
						adviserRework
							? x.metakey != 'Adviser'
							: x.metakey != 'FG Adviser'
					)
				),
				takeUntil(this.onDestroy$)
			).subscribe(
        (res) =>
          (this.visibleColumns = res?.filter(
            (x) => x.metakey !== 'Name'
          ))
      );
    this.hiddenColumns$
      .pipe(
				withLatestFrom(this.businessConfigQuery.adviserReworkFeature$),
				map(([vcolumns, adviserRework]) =>
					vcolumns.filter((x) =>
						adviserRework
							? x.metakey != 'Adviser'
							: x.metakey != 'FG Adviser'
					)
				),
				takeUntil(this.onDestroy$)
			).subscribe((res) => (this.hiddenColumns = res));
  }

  ngAfterViewInit() {
    $(document).ready(() => {
      $('.disabled-sortable').parent().addClass('disabled d-none');
    });
  }

  prepareFormValue() {
    return [
      'Name',
      ...this.visibleColumns?.map((x) => x.metakey),
    ] as Metakey[];
  }

  save() {
    if (this.visibleColumns.length === 0) {
      return;
    }
    this.fgInsuranceService
      .saveVisibleColumns(this.prepareFormValue())
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        () => {},
        () => {},
        () => {
          this.fgInsuranceService.closePopup();
        }
      );
  }
  cancel() {
    this.fgInsuranceService.closePopup();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
