import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { mapTo, tap, take } from 'rxjs/operators';
import { DropdownValueService } from '../../../../domain/dropdown-value/dropdown-value.service';
import { FgInsuranceService } from './states/fg-insurance.service';

const dropdownCodes: string[] = ['FGI', 'FGPT', 'FGPW', 'FGS', 'FGCS', 'FGAS', 'FGPL', 'PCLE', 'PCLT', 'AT'];

@Injectable()
export class FgInsuranceResolver implements Resolve<boolean> {
	constructor(private dropdownValueService: DropdownValueService, private fgInsuranceService: FgInsuranceService) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		const dropdown$ = this.dropdownValueService.getDropdownValueList$(dropdownCodes);

		return forkJoin([dropdown$]).pipe(
			tap(() => this.fgInsuranceService.getColumns().pipe(take(1)).subscribe()),
			tap(() => this.fgInsuranceService.getColumnWidths().pipe(take(1)).subscribe()),
			mapTo(true)
		);
	}
}
