import { Injectable } from '@angular/core';
import { ReferralOptionsModel, ReferralTypes } from './referral-options.model';
import { ReferralOptionsStore } from './referral-options.store';
import { ApiService } from '../../../core/base/api.service';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { applyTransaction } from '@datorama/akita';
import { LoggerService } from '../../../core/logger/logger.service';
import { ReferralOptionsQuery } from './referral-options.query';
import { objectUtil } from '../../../util/util';
import { iif, of } from 'rxjs';
import * as R from 'ramda';
import { BusinessConfigService } from '@domain/business-config/business-config.service';

@Injectable()
export class ReferralOptionsService {
	referrals$ = this.referralOptionsQuery.referrals$;
	enabledReferrals = this.referralOptionsQuery.enabledReferrals$;
	activeReferrals = this.referralOptionsQuery.activeReferrals$;
	constructor(
		private api: ApiService,
		private referralOptionsStore: ReferralOptionsStore,
		protected loggerService: LoggerService,
		private referralOptionsQuery: ReferralOptionsQuery,
		private businessConfigService: BusinessConfigService
	) {}

	clear(): void {
		applyTransaction(() => {
			this.referralOptionsStore.reset();
		});
	}

	getReferrals(staffId: number = 0, type: string) {
		const endpoint = `staff/${staffId}/settings/${type}`;
		return this.api.get<ReferralOptionsModel[]>(endpoint).pipe(
			map((data) => {
				const defaultPrio = data.length || 0;
				return data
					? data?.map((item) => ({
							...item,
							Order: item?.ReferralValues?.Priority ?? defaultPrio,
					  }))
					: [];
			}),
			map((data) => R.sort((a, b) => a?.Order - b?.Order, data)),
			tap((data) =>
				applyTransaction(() => {
					const state = data ? objectUtil.mapPascalCaseToCamelCase(data) : [];
					if (type === ReferralTypes.Referral) {
						this.referralOptionsStore.setReferrals(Object.values(state));
					} else if (type === ReferralTypes.Blanket) {
						this.referralOptionsStore.setBlanketReferrals(Object.values(state));
					}
				})
			),
			catchError(() => of(undefined))
		);
	}

	deleteReferral(settingsid, referral) {
		const endpoint = `staff/settings/${settingsid}`;
		return this.api.delete<any>(endpoint).pipe(catchError((err) => of(err)));
	}

	addReferral(referral, referrals = null) {
		const ref = referrals
			? referrals
			: [...this.referralOptionsQuery.referrals];
		ref.push(referral);
		this.referralOptionsStore.setReferrals(ref);
	}

	save(payload, type: string, refetchQuery: boolean = false) {
		const endpoint = `staff/settings/referral-options`;
		return this.api.put<ReferralOptionsModel[]>(endpoint, payload).pipe(
			tap((data) =>
				applyTransaction(() => {
					if (type === ReferralTypes.Referral) {
						this.referralOptionsStore.setReferrals(
							Object.values(objectUtil.mapPascalCaseToCamelCase(payload))
						);
					} else if (type === ReferralTypes.Blanket) {
						this.referralOptionsStore.setBlanketReferrals(
							Object.values(objectUtil.mapPascalCaseToCamelCase(payload))
						);
					}
				})
			),
			concatMap(() => {
				return iif(() => refetchQuery, this.getReferrals(0, type), of(payload));
			}),
			catchError((err) => of(err))
		);
	}

	saveCompareIP(data: { isSync: boolean; adminEmail: string }) {
		return this.api
			.put(`businesses/settings/sync/compare`, {
				SyncToCompare: data.isSync,
				SyncToCompareAdminEmail: data.adminEmail,
			})
			.pipe(
				concatMap((x) => this.businessConfigService.get(true)),
				catchError((err) => of(err))
			);
	}

	saveFocusMedia(data: { isSync: boolean; adminEmail: string }) {
		return this.api
			.put(`businesses/settings/sync/focusmedia`, {
				SyncToFocusMedia: data.isSync,
				SyncToFocusMediaAdminEmail: data.adminEmail,
			})
			.pipe(
				concatMap((x) => this.businessConfigService.get(true)),
				catchError((err) => of(err))
			);
	}
}
