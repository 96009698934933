import MomentUtil from 'src/app/util/moment.util';
import { objectUtil } from 'src/app/util/util';
import {
	AdviceProcess,
	AdviceProcessState,
	ServiceAdviceProcessState,
} from './advice-process.model';
import { omit, uniq } from 'ramda';

const claimFields = [
	'policyNumber',
	'policyNumberSuffix',
	'claimManager',
	'referenceNumber',
	'claimType',
	'totalPaid',
	'policyPaymentMethod',
	'claimOutcome',
	'claimDetails',
	'outcomeDetails',
];

export class AdviceProcessMapper {
	static mapToSate(v: AdviceProcess): AdviceProcessState {
		return objectUtil.mapPascalCaseToCamelCase(v);
	}

	static mapToView(v: ServiceAdviceProcessState) {
		const policyPaymentMethod = !!v?.policyPaymentMethod
			? uniq(v?.policyPaymentMethod?.split(',')?.map((x) => x?.trim()))?.join(
					', '
			  )
			: '';
		const parseClaimType = (data: string) => {
			const list = JSON.parse(data) || [];
			return uniq(list);
		};
		return {
			...v,
			clientsInvolved: !!v.clientsInvolved
				? (JSON.parse(v.clientsInvolved) as any[])?.map(String)
				: [],
			adviceType: !!v.adviceType
				? (JSON.parse(v.adviceType) as any[])?.map(String)
				: [],
			stages: v.stages?.map((s) => ({
				...s,
				value: MomentUtil.formatDateToMoment(s.value),
			})),
			// Claims
			policyPaymentMethod,
			claimType: !!v.claimType ? parseClaimType(v?.claimType) : [],
		};
	}

	static mapToUpsert(
		v: ServiceAdviceProcessState,
		claimsFeature: boolean
	): ServiceAdviceProcessState {
		const cleanPolicyNumber = (policyNumber: string) => {
			if (!policyNumber) {
				return null;
			}
			return policyNumber
				?.split(',')
				?.map((x) => x?.trim())
				?.join(', ');
		};
		const data = {
			...v,
			stages: v.stages?.map((s) => ({
				...s,
				value: MomentUtil.formatDateToServerDate(s.value),
			})),
			clientsInvolved: !!v.clientsInvolved
				? JSON.stringify((v.clientsInvolved as string[])?.map((x) => +x))
				: null,
			adviceType: !!v.adviceType
				? JSON.stringify((v.adviceType as string[])?.map((x) => x))
				: null,
			// Claims
			policyNumber: cleanPolicyNumber(v?.policyNumber),
			claimType:
				!!v.claimType && typeof v?.claimType === 'object'
					? JSON.stringify(v.claimType as string[])
					: null,
		} as ServiceAdviceProcessState;
		return !!claimsFeature
			? data
			: (omit(claimFields, data) as ServiceAdviceProcessState);
	}
}
