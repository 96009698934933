import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	NgZone,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	ValidationErrors,
	Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import sort from 'fast-sort';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as R from 'ramda';
import { iif, Observable, Observer, of, ReplaySubject, Subject } from 'rxjs';
import {
	filter,
	finalize,
	map,
	mergeMap,
	shareReplay,
	switchMap,
	take,
	takeUntil,
	tap,
} from 'rxjs/operators';
import { CommandRoute, RouteService } from 'src/app/core/config/route.service';
import { ClientReviewTemplateService } from 'src/app/modules/crm/client-review-template/states/client-review-template.service';
import { FinalStructureService } from 'src/app/modules/crm/client-review-template/states/final-structure/final-structure.service';
import { CrtNoteService } from 'src/app/modules/crm/client-review-template/states/note/crt-note.service';
import { DisabilityService } from 'src/app/modules/crm/client-review-template/states/risk-analysis/disability/disability.service';
import { LifeService } from 'src/app/modules/crm/client-review-template/states/risk-analysis/life/life.service';
import { RiskAnalysisMedicalService } from 'src/app/modules/crm/client-review-template/states/risk-analysis/medical/medical.service';
import { RiskAnalysisService } from 'src/app/modules/crm/client-review-template/states/risk-analysis/risk-analysis.service';
import { TpdService } from 'src/app/modules/crm/client-review-template/states/risk-analysis/tpd/tpd.service';
import { StatementOfAdviceService } from 'src/app/modules/crm/client-review-template/states/statement-of-advice/statement-of-advice.service';
import MomentUtil from 'src/app/util/moment.util';
import { CustomerService } from '../../../core/customer/customer.service';
import { LoggerService } from '../../../core/logger/logger.service';
import { NoteService } from '../../../core/note/note.service';
import { UserQuery } from '../../../domain/user/user.query';
import { BusinessProfileService } from '../../../modules/crm/business-profile/states/business-profile.service';
import { ClientProfileService } from '../../../modules/crm/client-profile/states/client-profile.service';
import { objectUtil, strUtil, util } from '../../../util/util';
import { AdviceProcessStepWizardComponent } from '../../advice-process-step-wizard/advice-process-step-wizard.component';
import { Fields, getRequiredWarning } from '../../error-message/error-message';
import { AdviceProcessEndModalComponent } from '../../modal/advice-process-end-modal/advice-process-end-modal.component';
import { ClientAddModalComponent } from '../../modal/client-add-modal/client-add-modal.component';
import { DeleteModalComponent } from '../../modal/delete-modal/delete-modal.component';
import { UploadModalComponent } from '../../modal/upload-modal/upload-modal.component';
import { ActivityViewModel } from '../../models/_general/activity.viewmodel';
import {
	CustomerTypes,
	RelationshipTypes,
} from '../../models/_general/client.model';
import { ViewDisplayValue } from '../../models/_general/display-value.viewmodel';
import { AdviceProcessMapper } from '../../models/advice-process/advice-process.mapper';
import {
	AdviceProcessCode,
	AdviceProcessDocumentField,
	AdviceProcessDocumentState,
	AdviceProcessOnlineRoutes,
	AdviceProcessRoutes,
	AdviceProcessSectionCodes,
	AdviceProcessStatusState,
	MOATDocumentField,
	OriginalInsurancePolicyDocument,
	ServiceAdviceProcessState,
} from '../../models/advice-process/advice-process.model';
import { FinalStructure } from '../../models/client-review-template/final-structure/final-structure.model';
import { DocumentGroupState } from '../../models/documents/document-group.model';
import {
	DocumentFile,
	DocumentModelState,
} from '../../models/documents/document.model';
import { NoteTypes } from '../../models/notes/note.model';
import { ServicesCodes } from '../../models/services/services.model';
import { LinkDocumentComponent } from '../../services/link-document/link-document.component';
import {
	AddDocumentModalService,
	AddDocumentModalShowOption,
} from '@shared/components/add-document-modal/add-document-modal.service';
import { ClientProfileQuery } from '@modules/crm/client-profile/states/client-profile.query';

enum ErrorMessages {
	loatMaxClients = 'Maximum of 3 clients allowed',
	moatMaxClients = 'Maximum of 4 clients allowed',
	koatMaxClients = 'Maximum of 2 clients allowed',
}

@Component({
	selector: 'app-advice-process-form',
	templateUrl: './advice-process-form.component.html',
	styleUrls: ['./advice-process-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdviceProcessFormComponent
	implements OnInit, OnChanges, OnDestroy
{
	private onDestroy$ = new Subject<void>();

	@Input() formId = '';
	@Input() types: ViewDisplayValue[];
	@Input() adviceProcess: ServiceAdviceProcessState;
	@Input() advisers: ViewDisplayValue[];
	@Input() reviewedBy: ViewDisplayValue[];
	@Input() allAdviserChoices: ViewDisplayValue[];
	@Input() clientsInvolved: ViewDisplayValue[];
	@Input() clientsInvolvedRaw: any[];
	@Input() isLead: boolean;
	@Input() addMode: boolean;
	@Input() isLoading: boolean;
	@Input() hasCRT: boolean;
	@Input() hasMOAT: boolean;
	@Input() hasKOAT: boolean;
	@Input() hasLOATV2: boolean;
	@Input() hasOfflineCRT: boolean;
	@Input() allStaffChoices: ViewDisplayValue[];
	@Input() isActiveAdviceProcess: boolean = false;

	@Output() goToPolicy: EventEmitter<{
		customerServiceId: number;
		serviceCode: string;
	}> = new EventEmitter<{ customerServiceId: number; serviceCode: string }>();

	processCodes = AdviceProcessCode;

	get isLR() {
		return (
			this.adviceProcess.processCode === this.processCodes.LRAdviceNew ||
			this.adviceProcess.processCode === this.processCodes.LRAdviceReview
		);
	}

	get isMortgage() {
		return (
			this.adviceProcess.processCode === this.processCodes.MortgageAdvice ||
			this.adviceProcess.processCode === this.processCodes.MortgageRefix
		);
	}

	get hasCRTorMOAT() {
		if (this.isLR) {
			return this.hasCRT;
		}
		if (this.isMortgage) {
			return this.hasMOAT;
		}
		return true;
	}

	get isAlterationProcess(): boolean {
		return (
			this.adviceProcess?.processCode ===
			AdviceProcessCode.ClientAlterationRequest
		);
	}

	get isClaim(): boolean {
		if (!this.adviceProcess) {
			return false;
		}
		switch (this.adviceProcess.processCode) {
			case AdviceProcessCode.FGClaim:
			case AdviceProcessCode.LRClaim:
				return true;
		}
		return false;
	}


	@Input()
	set isSaving(value) {
		this.toggleSaving(value);
	}
	get isSaving(): boolean {
		return this._isSaving;
	}
	private _isSaving = false;
	isSaving$ = new ReplaySubject<boolean>(1);
	isPrepopulating: boolean;

	@Input()
	set isEdit(value) {
		this.toggleEdit(value);
	}
	get isEdit(): boolean {
		return this._isEdit;
	}
	private _isEdit = false;
	isEdit$ = new ReplaySubject<boolean>(1);

	get documentType(): string {
		return this.getServiceCode(this.adviceProcess?.processCode);
	}

	@Input() getDocument$: (id: number) => Observable<any>;
	@Input() uploadDocument$: (file: any) => Observable<any>;
	@Input() downloadDocument$: (id: number) => Observable<any>;
	@Input() deleteDocument$: (id: number) => Observable<any>;

	@Input() deleteFn$: (id: number, processCode: string) => Observable<any>;
	@Input() updateFn$: (data: any, isEndProcess?: boolean) => Observable<any>;

	@Input() getQuestionnaires$: (code: string) => Observable<any>;
	@Input() getBusinessQuestionnaires$: (
		code: string,
		customerID?: number
	) => Observable<any>;

	@Input() customerID: number;
	@Input() customerName: string;
	@Input() location: string;
	@Input() adviser: number;
	@Input() claimsFeature: boolean;
	@Input() lrProducts: ViewDisplayValue[];
	@Input() fgPolicyLines: ViewDisplayValue[];
	@Input() claimOutcome: ViewDisplayValue[];
	@Input() activityType$: Observable<ViewDisplayValue[]>;
	@Input() adviserChoices$: Observable<ViewDisplayValue[]>;
	@Input() adviserCalendarChoices$: Observable<ViewDisplayValue[]>;
	@Input() addActivityFn$: (req: ActivityViewModel) => Observable<any>;

	@Input() getAdviceProcessNotes$: (
		customerID: number,
		adviceProcessID: number
	) => Observable<any>;
	@Input() addAdviceProcessNotes$: (req: any) => Observable<any>;
	@Input() deleteAdviceProcessNotes$: (noteID: number) => Observable<any>;

	@Input() document: DocumentGroupState;

	@Input() leadOriginChoice: Observable<ViewDisplayValue[]>;
	@Input() leadTypeChoice: Observable<ViewDisplayValue[]>;
	@Input() trustTypes: Observable<ViewDisplayValue[]>;

	@Input() hasQuickAdd: boolean;
	@Input() isCompany: boolean;

	@Input() getSCI$: (customerId: number) => Observable<any>;
	@Input() getSCT$: (customerId: number) => Observable<any>;
	@Input() getTimeline$: (customerId: number) => Observable<any>;

	@Input() getAdviceProcessCRT$: (
		adviceProcessId: number,
		sectionCode: string
	) => Observable<FinalStructure>;

	@Input() getSoa$: (apId: number) => Observable<any>;

	@Input() apChoices$: ViewDisplayValue[];
	@Input() apcrtasChoices$: ViewDisplayValue[];
	@Input() apcrtatChoices$: Observable<ViewDisplayValue[]>;
	@Input() apcrtctChoices$: ViewDisplayValue[];
	@Input() apcrtcoChoices$: ViewDisplayValue[];
	@Input() apcrtqi1Choices$: Observable<ViewDisplayValue[]>;
	@Input() apcrtqclr7Choices$: Observable<ViewDisplayValue[]>;
	@Input() apcrtqi6Choices$: Observable<ViewDisplayValue[]>;
	@Input() apcrtrlChoices$: Observable<ViewDisplayValue[]>;
	@Input() apcrtfccChoices$: Observable<ViewDisplayValue[]>;
	@Input() apcrtynnaChoices$: Observable<ViewDisplayValue[]>;
	@Input() apcrtqik6Choices$: Observable<ViewDisplayValue[]>;
	@Input() apcrtqik15Choices$: Observable<ViewDisplayValue[]>;
	@Input() carqicar1$: Observable<ViewDisplayValue[]>;

	@Output() cancelFn = new EventEmitter<ServiceAdviceProcessState>();
	@Output() editFn = new EventEmitter<ServiceAdviceProcessState>();
	@Output() prePopulateFn = new EventEmitter<ServiceAdviceProcessState>();

	@Input() lrProvider: ViewDisplayValue[]; // LRP
	@Input() mProvider: ViewDisplayValue[]; // MP
	@Input() kProvider: ViewDisplayValue[]; // KP
	@Input() fgProvider: ViewDisplayValue[]; // FGI

	providers = [];

	form: UntypedFormGroup;
	documents: (AdviceProcessDocumentState & {
		show?: boolean;
		isUploading?: boolean;
		isDeleting?: boolean;
		isDownloading?: boolean;
	})[] = [];
	documentTemp: { [key: string]: DocumentFile | null };
	steps: { stage: string; value: string; formControl: string }[];
	notes: any;
	bsModalRef: BsModalRef;

	hasViewSummary = false;
	isEditable = false;
	isEndProcessLoading = false;
	isReopenLoading = false;
	canView = false;
	adviserList: ViewDisplayValue[];
	crtPage: CommandRoute;

	finalStructure: FinalStructure;
	hasFinalized = false;
	documentFields = AdviceProcessDocumentField;
	moatDocumentFields = MOATDocumentField;
	origPolicyDocument = OriginalInsurancePolicyDocument;

	hasStagesValue = false;
	lrClaimsDocuments$ = this.addDocumentModalService.getAdviceProcessSettings('APLRC');
	fgClaimsDocuments$ = this.addDocumentModalService.getAdviceProcessSettings('APFGC');

	userSecurityGroup$ = this.userQuery.userInfo$.pipe(
		map((x) => x.SecurityGroup)
	);

	@ViewChild('stages') stagesComp: AdviceProcessStepWizardComponent;
	EXCLUDED_IN_CHECKING_OF_DOCUMENT = [
		AdviceProcessDocumentField.FactFind?.toString(),
	];

	pciAndSciCount: number = 0;
	showQuickAdd = false;

	errorMessages = ErrorMessages;

	claimPolicyNumbers: { customerServiceId?: number; name: string }[];

	constructor(
		private fb: UntypedFormBuilder,
		private cd: ChangeDetectorRef,
		private modalService: BsModalService,
		private renderer: Renderer2,
		private customerService: CustomerService,
		private noteService: NoteService,
		private loggerService: LoggerService,
		private profileService: ClientProfileService,
		private businessProfileService: BusinessProfileService,
		private routeService: RouteService,
		private router: Router,
		private finalStructureService: FinalStructureService,
		private crtNoteService: CrtNoteService,
		private crtService: ClientReviewTemplateService,
		private lifeService: LifeService,
		private tpdService: TpdService,
		private disabilityService: DisabilityService,
		private medicalService: RiskAnalysisMedicalService,
		private soaService: StatementOfAdviceService,
		private riskAnalysisService: RiskAnalysisService,
		private userQuery: UserQuery,
		private zone: NgZone,
		private changeDetectorRef: ChangeDetectorRef,
		private addDocumentModalService: AddDocumentModalService,
		private clientProfileQuery: ClientProfileQuery
	) {
		this.form = this.buildForm();
		this.profileService?.apId
			.pipe(
				filter((x) => !!x && !!this.adviceProcess?.customerID),
				takeUntil(this.onDestroy$)
			)
			.subscribe((x) => {
				this.getNotes$({
					CustomerID: this.adviceProcess?.customerID,
					AdviceProcessID: x,
				})
					.pipe(
						tap((notes) => {
							this.notes = notes;
							this.cd.detectChanges();
						}),
						take(1)
					)
					.subscribe();
			});
		this.businessProfileService?.apId
			.pipe(
				filter((x) => !!x && !!this.adviceProcess?.customerID),
				takeUntil(this.onDestroy$)
			)
			.subscribe((x) => {
				this.getNotes$({
					CustomerID: this.adviceProcess?.customerID,
					AdviceProcessID: x,
				})
					.pipe(
						tap((notes) => {
							this.notes = notes;
							this.cd.detectChanges();
						}),
						take(1)
					)
					.subscribe();
			});
	}

	get adviserFC() {
		return this.form.get('adviser');
	}

	get clientsInvolvedFC() {
		return this.form.get('clientsInvolved');
	}

	get provider() {
		return this.form.get('provider');
	}

	get highlightPaymentMethod() {
		if (
			this.claimsFeature &&
			this.adviceProcess?.processCode === AdviceProcessCode.FGClaim
		) {
			const value = this.form?.get('policyPaymentMethod')?.value;
			return value?.includes('None') || value?.includes('Installments');
		}
		return false;
	}

	hasPermission(sr: string[]) {
		return this.userQuery.hasPermission$(sr);
	}

	clearDataFromState() {
		// LOAT State
		this.crtService.clear();
		this.riskAnalysisService.clearData();
		this.lifeService.clearData();
		this.tpdService.clearData();
		this.disabilityService.clearData();
		this.medicalService.clearData();
		this.crtNoteService.clearData();
		this.soaService.clearData();
	}

	setPrepopulate(val: boolean) {
		this.isPrepopulating = val;
		this.cd.detectChanges();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (
			changes.adviceProcess &&
			changes.adviceProcess.currentValue !== undefined
		) {
			if (this.adviceProcess.clientsInvolved) {
				const cis = JSON.parse(this.adviceProcess.clientsInvolved);

				const pci = this.clientsInvolvedRaw?.filter(
					(e) =>
						cis?.indexOf(e.customerID?.toString()) !== -1 &&
						e.customerType === CustomerTypes.PrimaryCustomerIndividual
				);
				const sci = this.clientsInvolvedRaw?.filter(
					(e) =>
						cis?.indexOf(e.customerID?.toString()) !== -1 &&
						e.customerType === CustomerTypes.SecondaryCustomerIndividual &&
						e.relationship !== RelationshipTypes.Child &&
						e.contactMethod !== 'Deceased'
				);

				const lci = this.clientsInvolvedRaw?.filter((e) => {
					const id = e.linkedFromPrimaryCustomer
						? e.relatedCustomerId
						: e.customerId;
					return (
						e.customerType === CustomerTypes.LinkedContact &&
						(e.contactMethod !== 'Deceased' ||
							(e.contactMethod === 'Deceased' &&
								cis?.indexOf(id?.toString()) !== -1))
					);
				});

				this.pciAndSciCount = R.sum([pci?.length, sci?.length, lci?.length]);
				this.checkQuickAdd();
			}

			// Assigning value to form
			const data = this.adviceProcess
				? Object.assign({}, AdviceProcessMapper.mapToView(this.adviceProcess))
				: Object.assign({}, this.form.value);
			this.form.reset({ ...data });

			this.hasViewSummary = this.isClaim ? false :
				this.adviceProcess.status === AdviceProcessStatusState.complete ||
				this.adviceProcess.status === AdviceProcessStatusState.incomplete;

			this.isEditable =
				this.adviceProcess.status === AdviceProcessStatusState.active ||
				this.adviceProcess.status === AdviceProcessStatusState.reopened;

			this.canView =
				this.adviceProcess.status < AdviceProcessStatusState.incomplete;

			// Documents
			// const documents = this.adviceProcess.documents;
			// this.documents = documents.map((x: AdviceProcessDocumentState) => {
			// 	if (x.field === AdviceProcessDocumentField.FactFind) {
			// 		return {
			// 			...x,
			// 			show:
			// 				!!this.adviceProcess.pageStarted?.some((page) =>
			// 					FactFindCodes.includes(page)
			// 				) ||
			// 				!!this.adviceProcess.pageStarted?.some((page) =>
			// 					RiskAnalysisCodes.includes(page)
			// 				),
			// 			isUploading: false,
			// 			isDeleting: false,
			// 			isDownloading: false,
			// 		};
			// 	} else if (x.field === AdviceProcessDocumentField.FinalStructure) {
			// 		return {
			// 			...x,
			// 			show:
			// 				!!documents.find(
			// 					(doc) =>
			// 						doc.field === AdviceProcessDocumentField.AuthorityToProceed &&
			// 						!!doc.value?.documentID
			// 				) &&
			// 				!!this.adviceProcess.pageCompleted?.some(
			// 					(page) => page === AdviceProcessPageCodes.AuthorityToProceed
			// 				),
			// 			isUploading: false,
			// 			isDeleting: false,
			// 			isDownloading: false,
			// 		};
			// 	} else if(MOATDocumentsDisplayOffline.includes(x.field)) {
			// 		return {
			// 			...x,
			// 			show: true,
			// 			isUploading: false,
			// 			isDeleting: false,
			// 			isDownloading: false,
			// 		}
			// 	}
			// 	else {
			// 		const field = GetPageCodeForDocumentField(
			// 			x.field,
			// 			this.adviceProcess.processCode
			// 		);
			// 		return this.getDocumentState(field, x);
			// 	}
			// });
			// this.documentTemp = R.mergeAll(
			// 	documents.map((x) => ({ [x.field]: null }))
			// );

			// Steps wizard or stages
			const steps = this.adviceProcess.stages;
			this.steps = steps.map((x) => ({
				stage: this.convertStageName(x.field),
				value: x.value,
				formControl: strUtil.removeSpace(x.field),
			}));
			// .filter((y) =>
			// 	y.stage === 'Offer of Terms'
			// 		? this.hasOfferOfTerms(
			// 				this.adviceProcess?.startDate,
			// 				this.adviceProcess?.endProcessDate,
			// 				this.adviceProcess?.reopenDate,
			// 				this.adviceProcess?.status
			// 		  )
			// 		: true
			// );

			if (this.adviceProcess.processCode === AdviceProcessCode.MortgageAdvice) {
				this.crtPage = this.routeService.crtPage(
					!this.hasQuickAdd,
					this.customerID,
					this.adviceProcess?.adviceProcessID,
					AdviceProcessOnlineRoutes.Mortgage
				);
			} else if (
				this.adviceProcess.processCode === AdviceProcessCode.KiwiSaverAdvice
			) {
				this.crtPage = this.routeService.crtPage(
					!this.hasQuickAdd,
					this.customerID,
					this.adviceProcess?.adviceProcessID,
					AdviceProcessOnlineRoutes.KiwiSaver
				);
			} else if (
				this.adviceProcess.processCode ===
				AdviceProcessCode.ClientAlterationRequest
			) {
				this.crtPage = this.routeService.clientAlterationRequest(
					this.customerID,
					this.adviceProcess?.adviceProcessID
				);
			} else {
				this.crtPage = this.routeService.clientReviewTemplate(
					!this.hasQuickAdd,
					this.customerID,
					this.adviceProcess?.adviceProcessID
				);
			}

			this.selectAdviceService(this.form.get('adviceService').value, false);

			this.hasStagesValue = this.adviceProcess?.stages?.some((x) => !!x.value);

			if (
				this.isActiveAdviceProcess &&
				this.adviceProcess.status === AdviceProcessStatusState.cancelled
			) {
				this.getNotes$({
					CustomerID: this.adviceProcess.customerID,
					AdviceProcessID: this.adviceProcess.adviceProcessID,
				})
					.pipe(
						tap((notes) => {
							this.zone.run(() => {
								this.notes = notes;
							});
						}),
						takeUntil(this.onDestroy$)
					)
					.subscribe();
			}
			this.setClaimPolicyNumbers();
		}

		// if (
		// 	changes.adviceProcess &&
		// 	changes.adviceProcess.currentValue &&
		// 	changes.adviceProcess.previousValue
		// ) {
		// 	this.getNotes$({
		// 		CustomerID: this.adviceProcess.customerID,
		// 		AdviceProcessID: this.adviceProcess.adviceProcessID,
		// 	})
		// 		.pipe(
		// 			tap((notes) => {
		// 				this.notes = notes;
		// 				this.cd.detectChanges();
		// 			})
		// 		)
		// 		.subscribe();
		// }
		this.getAdviserList();
	}

	convertStageName(field: string) {
		switch (field) {
			case 'Final Structure Stage':
				return 'Final Structure';
				break;
			default:
				return field || '';
				break;
		}
	}

	getAdviserList() {
		const form = this.form.getRawValue();
		const adviser = this.advisers?.find((x) => +x.value === +form?.adviser);

		if (R.either(R.isNil, R.isEmpty)(adviser)) {
			const data = this.allStaffChoices?.find(
				(x) => +x.value === +form?.adviser
			);
			const list = [
				{
					display: data?.display || '',
					value: data?.value,
				},
				...this.advisers,
			];
			this.adviserList = list?.sort(
				(a, b) => a.display?.localeCompare(b.display)
			);
		} else {
			this.adviserList = this.advisers;
		}
	}

	ngOnInit(): void {
		this.lrClaimsDocuments$.pipe(take(1)).subscribe();
		this.fgClaimsDocuments$.pipe(take(1)).subscribe();
		this.getAdviserList();
		if (!!this.getAdviceProcessCRT$ && !!this.adviceProcess?.isOnline) {
			this.getAdviceProcessCRT$(
				this.adviceProcess.adviceProcessID,
				AdviceProcessSectionCodes.FinalStructure
			)
				.pipe(
					filter(
						(x) =>
							!!this.adviceProcess.isOnline && !!this.adviceProcess.isStarted
					),
					takeUntil(this.onDestroy$)
				)
				.subscribe((x) => {
					this.finalStructure = !!x ? x : null;
					this.cd.detectChanges();
				});
		}

		if (!!this.getSoa$) {
			this.getSoa$(this.adviceProcess.adviceProcessID).subscribe((x) => {
				this.hasFinalized = x?.some((val) => val.CurrentPage === 'Finalized');
			});
		}

		this.isEdit$.pipe(takeUntil(this.onDestroy$)).subscribe((x) => {
			this._isEdit = x;
			x ? this.form.enable() : this.form.disable();
			if (this.claimsFeature) {
				this.form.get('provider')?.disable();
			}
		});

		this.isSaving$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((x) => (this._isSaving = x));

		if (this.isCompany) {
			this.clientsInvolvedFC.setValidators([Validators.required]);
		} else {
			this.clientsInvolvedFC.setValidators([
				Validators.required,
				this.clientSelectionValidator,
			]);
		}

		this.form.get('clientsInvolved').valueChanges.subscribe((x) => {
			if (!this.clientsInvolvedRaw) {
				return;
			}

			const apClients = JSON.parse(this.adviceProcess.clientsInvolved).map(
				(id) => +id
			);

			const pci = this.clientsInvolvedRaw?.filter(
				(e) =>
					x?.indexOf(e.customerID?.toString()) !== -1 &&
					e.customerType === CustomerTypes.PrimaryCustomerIndividual
			);
			const sci = this.clientsInvolvedRaw?.filter(
				(e) =>
					x?.indexOf(e.customerID?.toString()) !== -1 &&
					e.customerType === CustomerTypes.SecondaryCustomerIndividual &&
					e.relationship !== RelationshipTypes.Child &&
					(e.contactMethod !== 'Deceased' ||
						(e.contactMethod === 'Deceased' &&
							apClients.includes(e.customerID)))
			);
			const lci = this.clientsInvolvedRaw?.filter((e) => {
				const id = e.linkedFromPrimaryCustomer
					? e.relatedCustomerId
					: e.customerId;
				return (
					x?.indexOf(id?.toString()) !== -1 &&
					e.customerType === CustomerTypes.LinkedContact &&
					(e.contactMethod !== 'Deceased' ||
						(e.contactMethod === 'Deceased' && apClients.includes(id)))
				);
			});

			const scc = this.clientsInvolvedRaw?.filter(
				(e) => e.customerType === CustomerTypes.PrimaryCustomerCompany
			);
			const sct = this.clientsInvolvedRaw?.filter(
				(e) => e.customerType === CustomerTypes.SecondaryCustomerTrust
			);
			const child = this.clientsInvolvedRaw?.filter(
				(e) =>
					e.customerType === CustomerTypes.SecondaryCustomerIndividual &&
					e.relationship === RelationshipTypes.Child &&
					(e.contactMethod !== 'Deceased' ||
						(e.contactMethod === 'Deceased' &&
							apClients.includes(e.customerID)))
			);

			const pci2 = this.clientsInvolvedRaw?.filter(
				(e) => e.customerType === CustomerTypes.PrimaryCustomerIndividual
			);
			const sci2 = this.clientsInvolvedRaw?.filter(
				(e) =>
					e.customerType === CustomerTypes.SecondaryCustomerIndividual &&
					e.relationship !== RelationshipTypes.Child &&
					(e.contactMethod !== 'Deceased' ||
						(e.contactMethod === 'Deceased' &&
							apClients.includes(e.customerID)))
			);

			const scc2 = this.clientsInvolvedRaw?.filter(
				(e) => e.customerType === CustomerTypes.PrimaryCustomerCompany
			);
			const sct2 = this.clientsInvolvedRaw?.filter(
				(e) => e.customerType === CustomerTypes.SecondaryCustomerTrust
			);
			const child2 = this.clientsInvolvedRaw?.filter(
				(e) =>
					e.customerType === CustomerTypes.SecondaryCustomerIndividual &&
					e.relationship === RelationshipTypes.Child &&
					(e.contactMethod !== 'Deceased' ||
						(e.contactMethod === 'Deceased' &&
							apClients.includes(e.customerID)))
			);
			const lci2 = this.clientsInvolvedRaw?.filter((e) => {
				const id = e.linkedFromPrimaryCustomer
					? e.relatedCustomerId
					: e.customerId;
				return (
					e.customerType === CustomerTypes.LinkedContact &&
					(e.contactMethod !== 'Deceased' ||
						(e.contactMethod === 'Deceased' && apClients.includes(id)))
				);
			});

			this.pciAndSciCount = R.sum([pci?.length, sci?.length, lci?.length]);

			if (
				!this.isCompany &&
				this.pciAndSciCount >= 3 &&
				this.adviceProcess.isOnline &&
				(this.adviceProcess.processCode === AdviceProcessCode.LRAdviceNew ||
					this.adviceProcess.processCode === AdviceProcessCode.LRAdviceReview)
			) {
				this.clientsInvolved = this.mapper(pci, sci, scc, sct, child, lci);
			} else if (
				this.adviceProcess.isOnline &&
				this.adviceProcess.processCode === AdviceProcessCode.KiwiSaverAdvice
			) {
				// TAPNZ-11580 KOAT: No need for Business & Trust
				this.clientsInvolved = this.mapper(pci2, sci2, [], [], child2, lci2);
			} else {
				this.clientsInvolved = this.mapper(
					pci2,
					sci2,
					scc2,
					sct2,
					child2,
					lci2
				);
			}

			this.checkQuickAdd();

			this.clientsInvolvedFC.markAllAsTouched();
			this.clientsInvolvedFC.updateValueAndValidity({ emitEvent: false });
		});
		const findReviewBy = this.reviewedBy?.find(
			(x) => +x.value === +this.form.value.reviewedBy
		);
		if (!findReviewBy && !!this.form.value.reviewedBy) {
			const copyReviewedBy = [...this.reviewedBy];
			const findAllReviewBy = this.allAdviserChoices?.find(
				(x) => +x.value === +this.form.value.reviewedBy
			);
			copyReviewedBy.push(
				ViewDisplayValue.Map(findAllReviewBy.value, findAllReviewBy.display)
			);
			this.reviewedBy = copyReviewedBy?.sort(
				(a, b) => a.display?.localeCompare(b.display)
			);
		}
	}

	setClaimPolicyNumbers() {
		const data = this.adviceProcess;
		if (
			this.claimsFeature &&
			(data?.processCode === AdviceProcessCode.FGClaim ||
				data?.processCode === AdviceProcessCode.LRClaim)
		) {
			const customerServices = util.tryParseJson(data?.customerServiceID);
			const policyNumbers = data?.policyNumber?.split(',') || [];
			const list = customerServices?.reduce((a, c, i) => {
				return [
					...a,
					{
						name: policyNumbers?.[i]?.trim() || '',
						customerServiceId: c || null,
					},
				];
			}, []);
			this.claimPolicyNumbers = list || [];
		}
	}

	redirectPolicy(customerServiceId: number) {
		const data = this.adviceProcess;
		this.goToPolicy?.emit({
			customerServiceId,
			serviceCode:
				data?.processCode === AdviceProcessCode.FGClaim
					? ServicesCodes?.FG?.toLocaleLowerCase()
					: data?.processCode === AdviceProcessCode.LRClaim
						? ServicesCodes?.LR?.toLocaleLowerCase()
						: null,
		});
	}

	clientSelectionValidator = (): ValidationErrors | null => {
		if (this.isCompany) {
			return null;
		}

		const process: string = this.adviceProcess.processCode;

		if (this.pciAndSciCount === 0) {
			return { minClients: true };
		}

		this.checkQuickAdd();

		if (
			(process === AdviceProcessCode.LRAdviceNew ||
				process === AdviceProcessCode.LRAdviceReview) &&
			this.adviceProcess.isOnline
		) {
			return this.pciAndSciCount > 3 ? { loatMaxClients: true } : null;
		} else if (
			process === AdviceProcessCode.MortgageAdvice &&
			this.adviceProcess.isOnline
		) {
			return this.pciAndSciCount > 4 ? { moatMaxClients: true } : null;
		} else if (
			process === AdviceProcessCode.KiwiSaverAdvice &&
			this.adviceProcess.isOnline
		) {
			return this.pciAndSciCount > 2 ? { koatMaxClients: true } : null;
		} else {
			return null;
		}
	};

	checkQuickAdd() {
		const process: string = this.adviceProcess?.processCode;
		if (
			(process === AdviceProcessCode.LRAdviceNew ||
				process === AdviceProcessCode.LRAdviceReview) &&
			this.pciAndSciCount >= 3 &&
			this.adviceProcess.isOnline
		) {
			this.zone.run(() => {
				this.showQuickAdd = false;
			});
		} else if (
			process === AdviceProcessCode.MortgageAdvice &&
			this.pciAndSciCount >= 4 &&
			this.adviceProcess.isOnline
		) {
			this.zone.run(() => {
				this.showQuickAdd = false;
			});
		} else if (
			process === AdviceProcessCode.KiwiSaverAdvice &&
			this.pciAndSciCount >= 2 &&
			this.adviceProcess.isOnline
		) {
			this.zone.run(() => {
				this.showQuickAdd = false;
			});
		} else {
			this.zone.run(() => {
				this.showQuickAdd = true;
			});
		}

		this.changeDetectorRef.markForCheck();
	}

	buildForm() {
		// tslint:disable-next-line: no-angle-bracket-type-assertion
		return this.fb.group(<{ [key in keyof ServiceAdviceProcessState]: any }>{
			adviser: this.fb.control('', [Validators.required]),
			reviewedBy: this.fb.control(''),
			clientsInvolved: [[], [Validators.required]],
			reviewGoals: this.fb.control(''),
			importantFactors: this.fb.control(''),

			adviceService: this.fb.control(''),
			provider: this.fb.control(''),
			complaintOutcome: this.fb.control(''),
			complaintType: this.fb.control(''),
			complaintDetails: this.fb.control(''),
			complaintOutcomeDetails: this.fb.control(''),

			adviceType: [[]],

			riskLevel: this.fb.control(''),
			sourceOfFunds: this.fb.control(''),
			sourceOfWealth: this.fb.control(''),
			riskProfile: this.fb.control(''),
			investmentGoals: this.fb.control(''),

			// Claims
			claimManager: this.fb.control(''),
			policyNumber: this.fb.control(''),
			referenceNumber: this.fb.control(''),
			claimType: this.fb.control(''),
			totalPaid: this.fb.control(''),
			policyPaymentMethod: this.fb.control(''),
			claimOutcome: this.fb.control(''),
			claimDetails: this.fb.control(''),
			outcomeDetails: this.fb.control(''),
		});
	}

	get adviceProccessName() {
		if (!this?.types || !this?.adviceProcess) {
			return '';
		}
		return this.types?.find((x) => x.value === this.adviceProcess.processCode)
			?.display;
	}

	get adviceProcessDocumentsCaption(): string {
		if (!this.adviceProcess) {
			return '';
		}
		switch (this.adviceProcess.processCode) {
			case AdviceProcessCode.ClientAlterationRequest:
				return 'Alteration Documents';
			case AdviceProcessCode.FGClaim:
			case AdviceProcessCode.LRClaim:
				return 'Claim Documents';
			default:
				return 'Advice Documents';
		}
	}

	get goalsCaption(): string {
		if (!this.adviceProcess) {
			return '';
		}
		return this.adviceProcess.processCode ===
			AdviceProcessCode.ClientAlterationRequest
			? 'Goals'
			: 'Review Goals';
	}

	get prepopulateBtnCaption(): string {
		if (!this.adviceProcess) {
			return '';
		}
		if (
			this.adviceProcess.processCode ===
			AdviceProcessCode.ClientAlterationRequest
		) {
			return this.adviceProcess.isStarted
				? 'Open Alteration Process'
				: 'Start Alteration Process';
		}
		return this.adviceProcess.isStarted
			? 'Open Advice Process'
			: 'Start Advice Process';
	}

	get stageCaption(): string {
		if (!this.adviceProcess) {
			return '';
		}
		return this.adviceProcess.processCode ===
			AdviceProcessCode.ClientAlterationRequest
			? 'Stage'
			: this.adviceProcess.processCode === AdviceProcessCode.FGClaim ||
			  this.adviceProcess.processCode === AdviceProcessCode.LRClaim
			? 'Claim Stages'
			: 'Advice Stage';
	}

	getDocumentState(pageCode: string, document: AdviceProcessDocumentState) {
		return {
			...document,
			show: !!this.adviceProcess.pageStarted?.some((page) => page === pageCode),
			isUploading: false,
			isDeleting: false,
			isDownloading: false,
		};
	}

	toggleSaving(isSaving: boolean) {
		this.isSaving$.next(isSaving);
	}

	toggleEdit(isEdit: boolean) {
		this.isEdit$.next(isEdit);
	}

	prepareFormValue() {
		const formValue = this.form.getRawValue();
		const stages = this.stagesComp.getValue();
		return {
			...formValue,
			adviser: +formValue.adviser,
			reviewedBy: +formValue.reviewedBy,

			clientsInvolved: R.map(Number, formValue.clientsInvolved),
			adviceType: R.map(String, formValue.adviceType),

			reviewGoals: strUtil.safeTrim(formValue.reviewGoals),
			importantFactors: strUtil.safeTrim(formValue.importantFactors),

			stages: this.adviceProcess.stages?.map((x) => ({
				...x,
				value:
					stages[
						R.keys(stages)?.find((f) => strUtil.removeSpace(x.field) === f)
					],
			})),
			documents: this.adviceProcess.documents?.map((x) => ({
				...x,
				value: !!x.value && !!x.value.documentID ? x.value.documentID : null,
			})),
			// Claims
			claimDetails: strUtil.safeTrim(formValue.claimDetails),
			outcomeDetails: strUtil.safeTrim(formValue.outcomeDetails),
		};
	}

	stageValidations() {
		this.stagesComp.isValid();
	}

	isValid() {
		return (
			this.form.valid &&
			!R.isEmpty(this.form.value.clientsInvolved) &&
			this.stagesComp.isValid()
		);
	}

	save() {
		if (!this.isValid()) {
			if (this.form.get('adviser').invalid) {
				this.loggerService.Log({}, getRequiredWarning(Fields.Adviser));
				return;
			}
			if (
				this.form.get('clientsInvolved').invalid ||
				R.isEmpty(this.form.value.clientsInvolved)
			) {
				if (
					this.adviceProcess.processCode === AdviceProcessCode.FGClaim ||
					this.adviceProcess.processCode === AdviceProcessCode.LRClaim
				) {
					this.loggerService.Log({}, getRequiredWarning(Fields.Claimants));
				} else {
					this.loggerService.Log(
						{},
						getRequiredWarning(Fields.ClientsInvolved)
					);
				}
				return;
			}
			if (!this.stagesComp.isValid()) {
				this.loggerService.Log({}, this.stagesComp.requiredStages());
				return;
			}

			return;
		}

		const data = AdviceProcessMapper.mapToUpsert(
			{
				...this.adviceProcess,
				...this.prepareFormValue(),
			},
			this.claimsFeature
		);

		if (data.adviceService === '') {
			data.provider = '';
		}
		this.isSaving$.next(true);
		this.updateFn$(data, true)
			.pipe(
				tap(
					() => this.isEdit$.next(false),
					() => {},
					() => {
						this.cancelFn.emit(this.adviceProcess);
						this.isSaving$.next(false);
					}
				),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	edit() {
		this.editFn.emit(this.adviceProcess);
		this.isEdit$.next(true);
	}

	cancel() {
		if (!this.addMode) {
			// Assigning value to form
			const data = this.adviceProcess
				? Object.assign({}, AdviceProcessMapper.mapToView(this.adviceProcess))
				: Object.assign({}, this.form.value);
			this.form.reset({ ...data });

			// Documents
			const documents = this.adviceProcess.documents;
			this.documents = documents?.map((x) => ({
				...x,
				show: !!x.value,
				isUploading: false,
				isDeleting: false,
				isDownloading: false,
			}));
			this.documentTemp = R.mergeAll(
				documents?.map((x) => ({ [x.field]: null }))
			);

			// Steps wizard or stages
			const steps = this.adviceProcess.stages;
			this.steps = steps?.map((x) => ({
				stage: x.field,
				value: x.value,
				formControl: strUtil.removeSpace(x.field),
			}));
			// ?.filter((y) =>
			// 	y.stage === 'Offer of Terms'
			// 		? this.hasOfferOfTerms(
			// 				this.adviceProcess?.startDate,
			// 				this.adviceProcess?.endProcessDate,
			// 				this.adviceProcess?.reopenDate,
			// 				this.adviceProcess?.status
			// 		  )
			// 		: true
			// );

			this.cancelFn.emit(this.adviceProcess);
			this.isEdit$.next(false);
		} else {
			this.form.reset();
			this.cancelFn.emit(this.adviceProcess);
		}
	}

	delete() {
		this.isSaving$.next(true);
		this.deleteFn$(
			this.adviceProcess.adviceProcessID,
			this.adviceProcess.processCode
		)
			.pipe(
				tap(
					() => {},
					() => {},
					() => {
						this.cancelFn.emit(this.adviceProcess);
						this.isSaving$.next(false);
						this.cd.detectChanges();
					}
				),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	confirmDelete() {
		const confirm = new Observable((obs: Observer<any>) => {
			this.delete();
			obs.complete();
		});

		const initState = {
			header: 'Delete Advice Process',
			message: `Are you sure you want to delete ${this.types?.find(
				(x) => x.value === this.adviceProcess.processCode
			).display}?`,
			delete$: confirm,
			canDelete: true,
		};

		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	trackByDocument(index: number, item: any) {
		return item.field;
	}

	chooseFile(file: any, field: string) {
		if (file.target.files && file.target.files[0]) {
			const limitSize = 25 * 1024;
			if (
				(Array.from(file.target.files) as File[])?.some(
					(x) => Math.round(x.size / 1024) > limitSize
				)
			) {
				return;
			}
			const reader = new FileReader();
			reader.onload = (e: any) => {
				const imgBase64Path = e.target.result;
				const name = file.target.files[0].name;

				this.documentTemp[field] = {
					DocumentType: this.getServiceCode(this.adviceProcess.processCode),
					CustomerId: this.adviceProcess?.customerID,
					Document: imgBase64Path?.split(',')[1],
					FileName: name,
				};
				this.cd.detectChanges();
			};

			reader.readAsDataURL(file.target.files[0]);
		}
	}

	downloadFile($event) {
		const documentId = $event.documentId;
		const field = $event.field;
		this.documents = this.documents.map((x) =>
			x.field === field ? { ...x, isDownloading: true } : x
		);
		this.downloadDocument$(documentId)
			.pipe(
				mergeMap((x) =>
					iif(
						() => x.FileExtension.toLowerCase() === '.pdf',
						this.openPdfInNewTab(documentId, x?.FileName),
						this.fileDownload(x?.DocumentLink)
					)
				),
				finalize(() => {
					this.documents = this.documents?.map((x) =>
						x.field === field ? { ...x, isDownloading: false } : x
					);
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	openPdfInNewTab = (documentId: string, name?: string) =>
		of({ documentId }).pipe(
			tap((res) => {
				if (res) {
					const pdfUrl = this.router.serializeUrl(
						this.router.createUrlTree(
							this.routeService.viewPdf({ ...res, name })
						)
					);
					window.open(pdfUrl, '_blank');
				}
			})
		);

	fileDownload = (url: string) =>
		of(url).pipe(
			tap((x) => {
				const a = this.renderer.createElement('a');
				this.renderer.setStyle(a, 'display', 'none');
				this.renderer.setAttribute(a, 'href', x);
				a.click();
			})
		);

	uploadFile(file, field: string) {
		this.isSaving$.next(true);
		this.documents = this.documents?.map((x) =>
			x.field === field ? { ...x, isUploading: true } : x
		);
		const request$ =
			typeof file === 'number' ? of(file) : this.uploadDocument$(file);
		let docID;
		return request$.pipe(
			filter((x) => !!x),
			map((x) => {
				return AdviceProcessMapper.mapToUpsert(
					{
						...this.adviceProcess,
						...this.prepareFormValue(),
						documents: this.adviceProcess.documents?.map((d) => ({
							...d,
							value:
								d.field === field
									? x
									: !!d.value && !!d.value.documentID
										? d.value.documentID
										: null,
						})),
					},
					this.claimsFeature
				);
			}),
			switchMap((x) => this.updateFn$(x)),
			map(() => docID),
			tap(
				() => this.isSaving$.next(false),
				() => {},
				() => {
					// this.documents = this.documents.map((x) =>
					// 	x.field === field ? { ...x, isUploading: false } : x
					// );
					this.isSaving$.next(false);
				}
			),
			takeUntil(this.onDestroy$)
		);
	}

	linkDocument(ad: { field: string; tab?: string }) {
		const initState = {
			selectedDetail: 'Link Document',
			document: this.document,
			initialSelectedTab: ad?.tab || 'ap',
		};
		this.bsModalRef = this.modalService.show(LinkDocumentComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState: initState,
			ignoreBackdropClick: true,
		});
		this.bsModalRef.content.getSelectedDocumentValue$
			.pipe(
				switchMap((x: DocumentModelState) => this.uploadFile(x.id, ad?.field)),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	removeFile(event: any): void {
		this.deleteFile(event.field)
	}

	viewFile(event: any): void {
		const doc = this.adviceProcess.documents?.find(
			(d) => d?.field === event.field
		)?.value;
		if (doc) {
			this.openPdfInNewTab(doc?.documentID?.toString(), doc?.fileName)
				.pipe(take(1))
				.subscribe();
		}
	}

	upload(file, field: string): Observable<any> {
		const convertFileToBase64 = (blob, reader = new FileReader()) =>
			new Observable((obs) => {
				if (!(blob instanceof Blob)) {
					return;
				}
				reader.onabort = () => obs.error(reader.error);
				reader.onerror = () => obs.error(reader.error);
				reader.onload = () => obs.next(reader.result);
				reader.onloadend = () => obs.complete();

				return reader.readAsDataURL(blob);
			});
		return of(file).pipe(
			mergeMap((x) => convertFileToBase64(x[0])),
			map(
				(x) =>
					({
						CustomerId: this.customerID,
						DocumentType: this.getDocumentTypeCode(
							this.adviceProcess.processCode
						),
						FileName: file[0].name?.replace('+', ''),
						Document: (x as string)?.split(',')[1],
					}) as DocumentFile
			),
			switchMap((x) => this.uploadFile(x, field)),
			takeUntil(this.onDestroy$)
		);
	}

	openUploadModal($event) {
		const action = $event.action;
		const field = $event.field;
		const upload = (file) =>
			new Observable((obs) => {
				obs.next();
				obs.complete();
			}).pipe(
				mergeMap(() => this.upload(file, field)),
				takeUntil(this.onDestroy$)
			);

		const initState = {
			upload,
			data: {
				DocumentType: this.getDocumentTypeCode(this.adviceProcess.processCode),
				CustomerId: this.customerID,
			},
			isSingleUpload: true,
			isFileList: true,
			headerTitle: `${action} ${field}`,
		};
		this.modalService.show(UploadModalComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	replaceFile(field: string, show: boolean) {
		this.documents = this.documents?.map((x) =>
			field === x.field ? { ...x, show: show ? !!x.value : show } : x
		);
		this.documentTemp[field] = null;
		this.cd.detectChanges();
	}

	deleteFile(field) {
		if (!this.isEdit) {
			this.isSaving$.next(true);
			this.documents = this.documents?.map((x) =>
				x.field === field ? { ...x, isDeleting: true } : x
			);
			const ap = AdviceProcessMapper.mapToUpsert(
				{
					...this.adviceProcess,
					...this.prepareFormValue(),
					documents: this.adviceProcess.documents?.map((d) => ({
						...d,
						value:
							d.field === field
								? null
								: !!d.value && !!d.value.documentID
									? d.value.documentID
									: null,
					})),
				},
				this.claimsFeature
			);
			this.updateFn$(ap)
				.pipe(
					tap(
						() => this.isSaving$.next(false),
						() => {},
						() => {
							this.documents = this.documents?.map((x) =>
								x.field === field ? { ...x, isDeleting: false } : x
							);
							this.isSaving$.next(false);
						}
					),
					takeUntil(this.onDestroy$)
				)
				.subscribe();
		}
	}

	getServiceCode(code: string) {
		switch (code) {
			case AdviceProcessCode.LRAdviceNew:
			case AdviceProcessCode.LRAdviceReview:
			case AdviceProcessCode.ClientAlterationRequest:
				return ServicesCodes.LR;
			case AdviceProcessCode.MortgageAdvice:
			case AdviceProcessCode.MortgageRefix:
				return ServicesCodes.Mortgage;
			case AdviceProcessCode.KiwiSaverAdvice:
				return ServicesCodes.KiwiSaver;
			case 'APHCC':
				return 'H';
			case AdviceProcessCode.BlanketAdvice:
				return ServicesCodes.BlanketAdvice;
			case AdviceProcessCode.FGDomesticNewBusinessAdvice:
				return ServicesCodes.FG;
			case AdviceProcessCode.FGCommercialNewBusinessAdvice:
				return ServicesCodes.FG;
			case AdviceProcessCode.FGDomesticMTAAdvice:
				return ServicesCodes.FG;
			case AdviceProcessCode.FGCommercialMTAAdvice:
				return ServicesCodes.FG;
			case AdviceProcessCode.FGDomesticRenewalAdvice:
				return ServicesCodes.FG;
			case AdviceProcessCode.FGCommercialRenewalAdvice:
				return ServicesCodes.FG;
			case AdviceProcessCode.Complaint:
				return ServicesCodes.Others;
			case AdviceProcessCode.Investment:
				return ServicesCodes.Investment;
			case ServicesCodes.ClientAlterationRequest:
				return ServicesCodes.ClientAlterationRequest;
			default:
				return null;
		}
	}

	getDocumentTypeCode(code: string) {
		switch (code) {
			case AdviceProcessCode.LRAdviceNew:
			case AdviceProcessCode.LRAdviceReview:
			case AdviceProcessCode.ClientAlterationRequest:
			case AdviceProcessCode.LRClaim:
				return ServicesCodes.LR;
			case AdviceProcessCode.MortgageAdvice:
			case AdviceProcessCode.MortgageRefix:
				return ServicesCodes.Mortgage;
			case AdviceProcessCode.KiwiSaverAdvice:
				return ServicesCodes.KiwiSaver;
			case AdviceProcessCode.FGDomesticNewBusinessAdvice:
			case AdviceProcessCode.FGClaim:
				return ServicesCodes.FG;
			case AdviceProcessCode.FGCommercialNewBusinessAdvice:
				return ServicesCodes.FG;
			case AdviceProcessCode.FGDomesticMTAAdvice:
				return ServicesCodes.FG;
			case AdviceProcessCode.FGCommercialMTAAdvice:
				return ServicesCodes.FG;
			case AdviceProcessCode.FGDomesticRenewalAdvice:
				return ServicesCodes.FG;
			case AdviceProcessCode.FGCommercialRenewalAdvice:
				return ServicesCodes.FG;
			case AdviceProcessCode.Complaint:
				return ServicesCodes.Others;
			case AdviceProcessCode.Investment:
				return ServicesCodes.Investment;
			default:
				return ServicesCodes.Others;
		}
	}

	update = (answers) => {
		let data = {
			...this.adviceProcess,
			documents: this.adviceProcess.documents?.map((x) => ({
				...x,
				value: !!x.value ? x.value.documentID : null,
			})),
		};
		const isComplete = this.adviceProcess.status === 3;
		data = {
			...data,
			[isComplete ? 'CompleteQuestions' : 'InCompleteQuestions']: answers,
			endProcessDate: MomentUtil.formatDateToServerDate(
				MomentUtil.createMomentNz()
			),
		};
		return this.updateFn$(data, true).pipe(
			switchMap((x) =>
				this.getNotes$({ CustomerID: this.adviceProcess.customerID }).pipe(
					tap((val) => (this.notes = val)),
					map(() => x)
				)
			),
			takeUntil(this.onDestroy$)
		);
	};

	endProcess(isViewSummary?: boolean) {
		const current = this.adviceProcess;
		const stages = current?.stages;
		const question = stages?.some((x) => !x.value) ? 'I' : 'C';
		// we use ternary condition here since getServiceCode method is dependent in
		// defferent part of our code just to make use no process breaks
		const service =
			current.processCode === AdviceProcessCode.ClientAlterationRequest
				? 'CAR'
				: this.getServiceCode(current.processCode);
		const questionType =
			service === ServicesCodes.FG ? `Q${question}B` : `Q${question}${service}`;
		this.isEndProcessLoading = true;
		const clientsArray = JSON.parse(current?.clientsInvolved) as string[];
		const selectedClients = this.clientsInvolved?.filter(
			(client) => clientsArray?.includes(client.value)
		);
		of(current)
			.pipe(
				switchMap((x) =>
					iif(
						() =>
							(service === ServicesCodes.LR ||
								service === ServicesCodes.Investment) &&
							this.isCompany, // TAPNZ-6987
						this.getBusinessQuestionnaires$(x?.processCode, this.customerID),
						this.getQuestionnaires$(x?.processCode)
					)
				),
				filter((x) => !!x),
				map((x) => x?.map(objectUtil.mapPascalCaseToCamelCase)),
				map((x) => x?.filter((y) => y?.type === questionType)),
				map((x: any[]) => sort(x).asc((q) => q?.settingOrder) as any[]),
				map(
					(x) =>
						x?.map((y) => ({
							adviceProcessQuestionnaireID: y.dviceProcessSettingsId,
							question: y.value1,
							questionKey: y.value,
							questionType: y.type,
							dataType: y.dataType,
						}))
				),
				tap(
					(questionnaires) => {
						this.cd.detectChanges();
						const areStagesCompleted = !stages?.some((x) => !x.value);
						let documents = this.adviceProcess?.documents || [];
						if (this.adviceProcess?.isOnline) {
							documents = this.adviceProcess.documents?.filter((document) => {
								return !this.EXCLUDED_IN_CHECKING_OF_DOCUMENT?.includes(
									document.field
								);
							});
							if (service === ServicesCodes.LR && areStagesCompleted) {
								// TAPNZ-6658: Show Original Insurance Policy document on End Process > Missing Documents
								// LR Complete
								const origPolicyDoc = this.origPolicyDocument;
								const docValue = !this.adviceProcess
									?.showOriginalInsurancePolicy
									? this.origPolicyDocument?.value
									: null;

								documents = [
									...documents,
									{ ...origPolicyDoc, value: docValue },
								];
							}
						}
						const answerList = areStagesCompleted
							? this.adviceProcess.completeQuestions
							: this.adviceProcess.inCompleteQuestions;
						const initialSate = {
							header: `${this.types?.find(
								(x) => x.value === this.adviceProcess.processCode
							).display}`,
							isComplete: areStagesCompleted,
							questions: questionnaires,
							answers: answerList,
							questionType,
							service,
							saveFn: this.update,
							// Activity
							isLead: this.isLead,
							customerId: this.customerID,
							customerName: this.customerName,
							location: this.location,
							adviser: this.adviser,
							activityType$: this.activityType$,
							adviserChoices$: this.adviserChoices$,
							adviserCalendarChoices$: this.adviserCalendarChoices$,
							addActivityFn$: this.addActivityFn$,
							isUploadedAllDocuments: !documents?.some((x) => !!!x.value),
							missingDocuments: documents?.filter((x) => !!!x.value),
							apcrtqi1Choices$: this.apcrtqi1Choices$,
							apcrtqi6Choices$: this.apcrtqi6Choices$,
							apcrtqclr7Choices$: this.apcrtqclr7Choices$,
							apcrtfccChoices$: this.apcrtfccChoices$,
							apcrtynnaChoices$: this.apcrtynnaChoices$,
							apcrtqik6Choices$: this.apcrtqik6Choices$,
							apcrtqik15Choices$: this.apcrtqik15Choices$,
							adviceProcess: current,
							clientsInvolved: selectedClients,
							isViewSummary,
							carqicar1$: this.carqicar1$,
						};
						this.modalService.show(AdviceProcessEndModalComponent, {
							initialState: initialSate,
							class: `modal-dialog-centered modal-advice-process-summary ${
								service === 'CAR' ? 'car-modal-content' : ''
							}`,
							ignoreBackdropClick: true,
						});
					},
					() => {},
					() => {
						this.isEndProcessLoading = false;
						this.cd.detectChanges();
					}
				),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	reopen() {
		let data = {
			...this.adviceProcess,
			documents: this.adviceProcess.documents?.map((x) => ({
				...x,
				value: !!x.value ? x.value.documentID : null,
			})),
		};
		data = {
			...data,
			reopenDate: MomentUtil.formatDateToServerDate(
				MomentUtil.createMomentNz()
			),
			status: 5,
		};
		this.isReopenLoading = true;
		return this.updateFn$(data, false)
			.pipe(
				tap(
					() => {},
					() => {},
					() => {
						this.isReopenLoading = false;
					}
				)
			)
			.subscribe();
	}

	addNotes$ = (data: any) => {
		const req = {
			referenceId: this.adviceProcess.adviceProcessID,
			notes: data.notes,
			customerID: this.customerID,
			type: NoteTypes.AdviceProcess,
		};
		return this.addAdviceProcessNotes$(req).pipe(takeUntil(this.onDestroy$));
	};

	getNotes$ = (data: any) => {
		return this.getAdviceProcessNotes$(
			data.CustomerID,
			this.adviceProcess.adviceProcessID
		).pipe(
			map((x) => (!!x ? x?.map(objectUtil.mapPascalCaseToCamelCase) : [])),
			map(
				(x) =>
					x?.map((n) => ({ ...n, notesID: n.cRTNotesId, customerServiceID: 0 }))
			),
			tap((x) => {
				this.notes = x;
				this.cd.detectChanges();
			}),
			take(1)
			// takeUntil(this.onDestroy$)
		);
	};

	deleteNotes$ = (data: any) => {
		return this.deleteAdviceProcessNotes$(data.cRTNotesId).pipe(
			takeUntil(this.onDestroy$)
		);
	};

	addSCI = (data) =>
		this.customerService.AddSecondaryClient(data).pipe(
			mergeMap((x) =>
				this.getSCI$(this.customerID).pipe(
					map(() => x),
					tap(() => {
						setTimeout(() => {
							this.clientsInvolvedFC.updateValueAndValidity();
						}, 1000);
					})
				)
			)
		);
	addSCT = (data) =>
		this.customerService
			.AddSecondaryTrustByPrimaryClient(data)
			.pipe(mergeMap((x) => this.getSCT$(this.customerID).pipe(map(() => x))));
	addNote = (data) =>
		this.noteService.SaveNote(data).pipe(
			mergeMap((x) => this.getTimeline$(this.customerID).pipe(map(() => x))),
			takeUntil(this.onDestroy$)
		);

	quickAdd = (model: any, note: any) =>
		new Observable<any>((obs) => {
			obs.next(model);
			obs.complete();
		}).pipe(
			mergeMap((m) => {
				m.primaryCustomer = this.customerID;
				if (m.firstName !== undefined && m.lastName !== undefined) {
					m.customerType = CustomerTypes.SecondaryCustomerIndividual;
					m.dateOfBirth = MomentUtil.formatDateToServerDate(m.dateOfBirth);
					return this.addSCI(m);
				}
				if (m.trustName !== undefined) {
					m.customerType = CustomerTypes.SecondaryCustomerTrust;
					return this.addSCT(m);
				}
			}),
			mergeMap(
				(id) => {
					const noteModel = {
						customerID: +id,
						customerServiceID: 0,
						notes: note.notes,
					};
					return !noteModel.notes ? of(id) : this.addNote(noteModel);
				},
				(o) => o
			),
			tap((x) => {
				this.form
					.get('clientsInvolved')
					.reset([...this.form.get('clientsInvolved').value, x?.toString()]);
			})
		);

	quickAddModal() {
		new Observable((obs) => {
			const initState: any = {
				header: 'Quick Add',
				name: '',
				advisers: this.advisers,
				leadOriginChoice: this.leadOriginChoice,
				leadTypeChoice: this.leadTypeChoice,
				trustTypes: this.trustTypes,
				savefn: this.quickAdd,
				searchMode: 'ClientsInvolved',
			};
			this.modalService.show(ClientAddModalComponent, {
				class: 'modal-dialog-centered modal-lg',
				initialState: initState,
				ignoreBackdropClick: true,
			});
			obs.complete();
		}).subscribe();
	}

	deleteDocument(doc) {
		this.businessProfileService
			.DeactivateDocument(doc)
			.pipe(take(1))
			.subscribe();
	}

	downloadDocument(doc) {
		this.businessProfileService
			.downloadLink(doc.documentID)
			.pipe(take(1))
			.subscribe((data) => {
				const link = document.createElement('a');
				link.setAttribute('target', '_blank');
				link.setAttribute('href', data);
				link.setAttribute('download', doc.fileName);
				document.body.appendChild(link);
				link.click();
				link.remove();
			});
	}

	stepWizardLink() {
		if (this.crtPage?.length) {
			return [...this.crtPage, AdviceProcessRoutes.FactFind];
		} else {
			return [];
		}
	}

	prepopulate(isStarted: boolean) {
		if (this.isPrepopulating) {
			return;
		}
		this.clearDataFromState();
		if (isStarted) {
			return this.router.navigate(this.crtPage);
		}
		this.isPrepopulating = true;
		this.prePopulateFn.emit(this.adviceProcess);
	}

	sync() {
		if (!!this.adviceProcess.isOnline && !!this.adviceProcess.isStarted) {
			this.finalStructureService
				.getAndGenerateFinalStructure(+this.adviceProcess.adviceProcessID)
				.pipe(take(1))
				.subscribe();
		}
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}

	mapper(pci, sci, scc, sct, child, lcc) {
		const pc: any =
			!!pci && this.isCompany
				? R.map(
						(c) => ViewDisplayValue.Map(c?.customerID?.toString(), c?.name),
						pci
					)
				: !!pci && pci[0]
					? ViewDisplayValue.Map(
							pci[0].customerID?.toString(),
							`${pci[0].firstName} ${pci[0].lastName}`
						)
					: null;

		const sc: ViewDisplayValue[] = sci
			? R.map(
					(y) =>
						ViewDisplayValue.Map(
							y.customerID?.toString(),
							this.isCompany ? y.name : `${y.firstName} ${y.lastName}`
						),
					sci
				)
			: [];
		const sb: ViewDisplayValue[] = scc
			? R.map(
					(y) =>
						ViewDisplayValue.Map(
							y.customerID?.toString(),
							this.isCompany ? y.name : `${y.companyName}`
						),
					scc
				)
			: [];
		const st: ViewDisplayValue[] = sct
			? R.map(
					(y) =>
						ViewDisplayValue.Map(
							y.customerID?.toString(),
							this.isCompany ? y.name : `${y.trustName}`
						),
					sct
				)
			: [];
		const scic: ViewDisplayValue[] = child
			? R.map(
					(y) =>
						ViewDisplayValue.Map(
							y.customerID?.toString(),
							`${y.firstName} ${y.lastName}`
						),
					child
				)
			: [];

		const lc: ViewDisplayValue[] = lcc
			? R.map(
					(y) =>
						ViewDisplayValue.Map(
							`${
								y?.linkedFromPrimaryCustomer
									? y.relatedCustomerId?.toString()
									: y.customerId?.toString()
							}`,
							`${y.name}`
						),
					lcc
				)
			: [];

		return this.isCompany
			? [...pc, ...sc, ...sb, ...st, ...scic, ...lc].filter((a) => !!a)
			: [pc, ...sc, ...sb, ...st, ...scic, ...lc].filter((a) => !!a);
	}

	// hasOfferOfTerms(
	// 	startDate: string | null,
	// 	endProcessDate: string | null,
	// 	reopenDate: string | null,
	// 	status: number
	// ) {
	// 	const lastDeploymentDate = moment(
	// 		MomentUtil.formatDateToMoment(environment?.lastDeploymentDate)
	// 	).format('LL');

	// 	const sdm = MomentUtil.formatDateToMoment(startDate?.slice(0, 10)).format(
	// 		'DD/MM/YYYY'
	// 	);
	// 	const startDateMoment = moment(sdm, 'DD/MM/YYYY');

	// 	const rdm = MomentUtil.formatDateToMoment(reopenDate?.slice(0, 10)).format(
	// 		'DD/MM/YYYY'
	// 	);
	// 	const reopenDateMoment = moment(rdm, 'DD/MM/YYYY');

	// 	return (
	// 		(!!startDate && startDateMoment.diff(lastDeploymentDate, 'days') >= 0) ||
	// 		(!endProcessDate && !reopenDate) ||
	// 		(!!reopenDate &&
	// 			reopenDateMoment.diff(lastDeploymentDate, 'days') >= 0) ||
	// 		status < 3 ||
	// 		status > 4
	// 	);
	// }

	selectAdviceService(adviceService, isChanges) {
		this.providers = [];
		if (isChanges) {
			this.provider.setValue('');
		}
		if (adviceService !== null || adviceService !== '') {
			switch (adviceService) {
				case 'L&R':
					this.providers = this.lrProvider;
					break;
				case 'KiwiSaver':
					this.providers = this.kProvider;
					break;
				case 'Mortgages':
					this.providers = this.mProvider;
					break;
				case 'F&G':
					this.providers = this.fgProvider;
					break;
				case 'Investment':
					this.providers = this.kProvider;
					break;
				default:
					break;
			}
		}
	}

	cliamAddDocument(claim: any): void {
		const options: AddDocumentModalShowOption = {
			documentType:
				claim.processCode === AdviceProcessCode.FGClaim ? 'FG' : 'LR',
			customerId: this.customerID,
			defaultLinkDocumentTab:
				claim.processCode === AdviceProcessCode.FGClaim ? 'fg' : 'lr',
			clientDocumentLoader$: () => this.clientProfileQuery.documents$,
			linkFn$: (doc: any, data: any) => {
				return this.uploadFile(doc.DocumentId, data.documentName);
			},
			uploadFn$: (doc: any, data: any) => {
				return this.profileService.uploadDocument$(doc).pipe(
					mergeMap((documentId) => {
						return this.uploadFile(documentId, data.documentName);
					})
				);
			},
			documentNames$: this.addDocumentModalService
				.getAdviceProcessSettings(claim.processCode)
				.pipe(shareReplay()),
		};
		this.addDocumentModalService.show(options).subscribe();
	}

	trackByValue = (index: number, item: any) => item?.value;
}
