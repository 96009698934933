import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { mergeMap, map, tap, take } from 'rxjs/operators';
import { UploadModalComponent } from '../modal/upload-modal/upload-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DeleteModalComponent } from '../modal/delete-modal/delete-modal.component';
import { fileUtil } from '@util/util';

@Component({
	selector: 'app-photo',
	templateUrl: './photo.component.html',
	styleUrls: ['./photo.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhotoComponent implements OnInit, OnChanges {
	@Input() notCustomer: boolean;
	@Input() customer: any;
	@Input() imageUrl: string;
	@Input() isViewOnly = false;
	@Input() uploadType: 'default' | 'modal' = 'default';

	@Input() restrict = 'image/x-png,image/jpeg,image/png';
	@Input() headerTitle = 'Add Profile Photo';
	@Input() additionalInfo = 'Ideal dimension is 200x200 px.';
	@Input() customThumbnail: boolean;
	@Input() customThumbnailText: string;
	@Input() stripFileName:boolean = false;

	@Input() uploadFn$: (req: any) => Observable<any>;
	@Input() deleteFn$: (req: any) => Observable<any>;

	tempImage: any;
	isViewPhoto: boolean;
	isLoadingThumbnail: boolean;
	isLoadingView: boolean;
	isDeleting: boolean;

	private limitSize = 25 * 1024;
	private allowedExtensions = ['jpg', 'jpeg', 'png'];

	constructor(
		private cd: ChangeDetectorRef,
		private modalService: BsModalService
	) {}

	get customerInfo() {
		return !!this.customer ? this.customer : null;
	}

	ngOnInit() {
		this.isLoadingThumbnail = true;
		this.isLoadingView = true;
	}

	ngOnChanges(changes: SimpleChanges) {
		this.changedPhoto(changes);
	}

	changedPhoto(changes: SimpleChanges) {
		const notChanged =
			changes.imageUrl === undefined ||
			changes.imageUrl.currentValue === changes.imageUrl.previousValue;
		if (notChanged) {
			return;
		}
		this.tempImage = changes.imageUrl.currentValue;
	}

	onFileChange(event) {
		if (
			event.target.files &&
			event.target.files.length &&
			Math.round(event.target.files[0].size / 1024) <= this.limitSize
		) {
			console.log(event);
			this.convertFileToBase64(event.target.files[0])
				.pipe(take(1))
				.subscribe((res) => {
					this.tempImage = res;
					// need to run CD since file load runs outside of zone
					this.cd.detectChanges();
				});
		}
	}

	convertFileToBase64 = (blob, reader = new FileReader()) =>
		new Observable((obs) => {
			if (!(blob instanceof Blob)) {
				return;
			}
			reader.onabort = () => obs.error(reader.error);
			reader.onerror = () => obs.error(reader.error);
			reader.onload = () => obs.next(reader.result);
			reader.onloadend = () => obs.complete();

			return reader.readAsDataURL(blob);
		});

	convertFileToBytes = (blob, reader = new FileReader()) =>
		new Observable((obs) => {
			if (!(blob instanceof Blob)) {
				return;
			}
			reader.onabort = () => obs.error(reader.error);
			reader.onerror = () => obs.error(reader.error);
			reader.onload = () =>
				obs.next(new Uint8Array(reader.result as Iterable<number>));
			reader.onloadend = () => obs.complete();

			return reader.readAsArrayBuffer(blob);
		});

	delete = (data) => {
		this.isDeleting = true;
		this.cd.detectChanges();
		this.uploadFn$(data)
			.pipe(
				take(1),
				tap(
					() => {
						this.tempImage = undefined;
						this.imageUrl = undefined;

						// Reset all inputs
						const inputFiles: HTMLInputElement[] = Array.from(
							document.querySelectorAll('.photo-file')
						);
						inputFiles?.forEach((x) => (x.value = ''));

						this.cd.detectChanges();
					},
					() => {},
					() => {
						this.isDeleting = false;
						this.cd.detectChanges();
					}
				)
			)
			.subscribe();
	};

	deletePhoto() {
		const confirm = new Observable((obs: Observer<any>) => {
			this.delete(
				!this.notCustomer
					? {
							CustomerID:
								this.customerInfo && this.customerInfo.customerID
									? this.customerInfo.customerID
									: null,
							Photo: null,
							FileName: null,
					  }
					: { Photo: null, FileName: null }
			);
			obs.complete();
		});

		const initState = {
			header: 'Delete Photo',
			message: `Are you sure you want to delete this photo'?`,
			delete$: confirm,
		};

		this.modalService.show(DeleteModalComponent, {
			class: 'modal-dialog-centered',
			initialState: initState,
			ignoreBackdropClick: true,
		});
	}

	viewPhoto() {
		this.isViewPhoto = !this.isViewPhoto;
	}

	openUploadModal() {
		const upload = (req) =>
			new Observable((obs) => {
				obs.next();
				obs.complete();
			}).pipe(
				map(() => req),
				mergeMap((x) => this.convertFileToBase64(x[0]).pipe(take(1))),
				map((base64Image) =>
					!this.notCustomer
						? {
								CustomerID:
									this.customerInfo && this.customerInfo.customerID
										? this.customerInfo.customerID
										: null,
								FileName: `${this.stripFileName?fileUtil.formatFileName(req[0].name):req[0].name}`,
								Photo: `${base64Image}`,
						  }
						: { FileName: `${this.stripFileName?fileUtil.formatFileName(req[0].name):req[0].name}`, Photo: `${base64Image}` }
				),
				mergeMap((x) => this.uploadFn$(x)),
				tap(() => {
					this.convertFileToBase64(req[0])
						.pipe(take(1))
						.subscribe((res) => {
							this.tempImage = res;
							this.cd.detectChanges();
						});
				})
			);

		const initialState = {
			customUpload: upload,
			isSingleUpload: true,
			isFileList: true,
			restrict: this?.restrict ?? 'image/x-png,image/jpeg,image/png',
			allowedFileExtensions: this.allowedExtensions,
			headerTitle: this?.headerTitle ?? 'Add Profile Photo',
			additionalInfo: this?.additionalInfo ?? 'Ideal dimension is 200x200 px.',
			stripFileName:this.stripFileName,
		};

		this.modalService.show(UploadModalComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState,
			ignoreBackdropClick: true,
		});
	}
}
