import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AdviceProcessDocumentState } from 'src/app/shared/models/advice-process/advice-process.model';
import { AdviceProcessDocumentBaseComponent } from '../advice-process-document-base/advice-process-document-base.component';

@Component({
  selector: 'app-offline-documents',
  templateUrl: './offline-documents.component.html'
})
export class OfflineDocumentsComponent extends AdviceProcessDocumentBaseComponent implements OnInit, OnChanges {

	@Output() doRemove = new EventEmitter<{ field: string }>();

	@Output() doView = new EventEmitter<{ field: string }>();

  @Input() d: (AdviceProcessDocumentState & {
		show?: boolean;
		isUploading?: boolean;
		isDeleting?: boolean;
		isDownloading?: boolean;
	});

	@Input() isAPClaim = false;

  constructor() {
    super();
  }

	removeFile(): void {
		this.doRemove.emit();
	}

	viewFile(): void {
		this.doView.emit();
	}

  ngOnChanges() {
  }

  ngOnInit(): void {
  }
}
