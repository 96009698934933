import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import { AddDocumentModalService } from '@shared/components/add-document-modal/add-document-modal.service';
import { CommandRoute } from 'src/app/core/config/route.service';
import {
	AdviceProcessCode,
	AdviceProcessDocumentField,
	AdviceProcessDocumentState,
	AdviceProcessPageCodes,
	AdviceProcessRoutes,
	GetPageCodeForDocumentField,
	KOATDocumentField,
	KiwiSaverAdviceProcessPageIds,
	KiwiSaverAdviceRoutes,
	MOATDocumentField,
	MOATProcessRoutes,
	ServiceAdviceProcessState,
} from 'src/app/shared/models/advice-process/advice-process.model';
import { FinalStructure } from 'src/app/shared/models/client-review-template/final-structure/final-structure.model';

@Component({
	selector: 'app-advice-process-document-base',
	templateUrl: './advice-process-document-base.component.html',
})
export class AdviceProcessDocumentBaseComponent implements OnInit, OnChanges {
	@Input() hasCRT: boolean;
	@Input() hasMOAT: boolean;
	@Input() hasKOAT: boolean;
  @Input() hasLOATV2: boolean;
	@Input() isEditable: boolean;
	@Input() adviceProcess: ServiceAdviceProcessState;
	@Input() hasFinalized: boolean;
	@Input() finalStructure: FinalStructure;
	@Input() crtPage: CommandRoute;

	@Output() doDownload: EventEmitter<{
		documentId: number;
		field: string;
		name: string;
	}> = new EventEmitter<{
		documentId: number;
		field: string;
		name: string;
	}>();
	@Output() doUpload: EventEmitter<{
		action: string;
		field: string;
	}> = new EventEmitter<{
		action: string;
		field: string;
	}>();
	@Output() doLinkDocument: EventEmitter<string> = new EventEmitter<string>();
	@Output() doReplace: EventEmitter<{
		field: string;
		show: boolean;
	}> = new EventEmitter<{
		field: string;
		show: boolean;
	}>();
	@Output() doRemove: EventEmitter<{
		field: string;
	}> = new EventEmitter<{
		field: string;
		show: boolean;
	}>();
	@Output() doView: EventEmitter<{
		field: string;
	}> = new EventEmitter<{
		field: string;
	}>();

	@Output() doCliamAddDocument = new EventEmitter<any>();

	documents: (AdviceProcessDocumentState & {
		show?: boolean;
		isUploading?: boolean;
		isDeleting?: boolean;
		isDownloading?: boolean;
	})[] = [];

	get isClaim(): boolean {
		if (!this.adviceProcess) {
			return false;
		}
		switch (this.adviceProcess.processCode) {
			case AdviceProcessCode.FGClaim:
			case AdviceProcessCode.LRClaim:
				return true;
		}
		return false;
	}

	constructor(
		private addDocumentModalService?: AddDocumentModalService,
	) {}

	ngOnChanges(changes: SimpleChanges) {
		if (
			changes.adviceProcess &&
			changes.adviceProcess.currentValue !== undefined
		) {
			this.prepDocuments();
		}
	}

	ngOnInit(): void {
		this.prepDocuments();
	}

	prepDocuments() {
		let documents = this.adviceProcess.documents?.map(
			(x: AdviceProcessDocumentState) => {
				const field = GetPageCodeForDocumentField(
					x.field,
					this.adviceProcess.processCode
				);
				return this.getDocumentState(field, x);
			}
		);
		switch (this.adviceProcess.processCode) {
			case AdviceProcessCode.FGClaim:
			case AdviceProcessCode.LRClaim:
				documents = documents.filter((d) => Boolean(d?.value));
				break;
		}
		this.documents = documents;
	}

	getDocumentState(pageCode: string, document: AdviceProcessDocumentState) {
		return {
			...document,
			show: !!this.adviceProcess.pageStarted?.some((page) => page === pageCode),
			isUploading: false,
			isDeleting: false,
			isDownloading: false,
		};
	}

	trackByDocument(index: number, item: any) {
		return item.field;
	}

	downloadFile(documentId: number, field: string, name: string) {
		this.doDownload.emit({ documentId, field, name });
	}

	openUploadModal(action: string, field?: string) {
		this.doUpload.emit({ action, field });
	}

	replaceFile(field: string, show: boolean) {
		this.doReplace.emit({ field, show });
	}

	linkDocument(field: string) {
		this.doLinkDocument.emit(field);
	}

	removeFile(field: string): void {
		this.doRemove.emit({field});
	}

	viewFile(field: string): void {
		this.doView.emit({field})
	}

	goToCrtPage(section?: string) {
		switch (section) {
			case AdviceProcessDocumentField.Declaration:
				return [...this.crtPage, AdviceProcessRoutes.Declaration];
				break;
			case AdviceProcessDocumentField.SOS:
				return [...this.crtPage, AdviceProcessRoutes.SOS];
				break;
			case AdviceProcessDocumentField.FactFind:
				return [...this.crtPage, AdviceProcessRoutes.FactFind];
				break;
			case AdviceProcessDocumentField.SOA:
				return [...this.crtPage, AdviceProcessRoutes.SOA];
				break;
			case AdviceProcessDocumentField.AuthorityToProceed:
				return [...this.crtPage, AdviceProcessRoutes.AuthorityToProceed];
				break;
			case 'Client Acceptance':
				return [...this.crtPage, AdviceProcessRoutes.AuthorityToProceed];
				break;
			case AdviceProcessDocumentField.FinalStructure:
				return [
					...this.crtPage,
					AdviceProcessRoutes.FinalStructure,
					{ sync: !this.finalStructure },
				];
				break;
			case MOATDocumentField.Disclosure:
			case AdviceProcessDocumentField.Disclosure:
				return [...this.crtPage, AdviceProcessRoutes.Disclosure];
				break;
			case MOATDocumentField.FactFindDeclaration:
				return [...this.crtPage, ...MOATProcessRoutes.People.split('/')];
				break;
			case MOATDocumentField.ROA:
				return [...this.crtPage, MOATProcessRoutes.ROA];
				break;
			case KOATDocumentField.Declaration:
				return [...this.crtPage, KiwiSaverAdviceRoutes.Declaration];
				break;
			case KOATDocumentField.KiwiSaverInformation:
				return [...this.crtPage, ...KiwiSaverAdviceRoutes.YourKiwiSaver.split('/')];
				break;
			case KOATDocumentField.RiskProfileReview:
				return [...this.crtPage, ...KiwiSaverAdviceRoutes.RiskProfileInformationGathering.split('/')];
				break;
			case KOATDocumentField.AdviceSummary:
				return [
					...this.crtPage,
					...KiwiSaverAdviceRoutes.AdviceSummary.split('/'),
					{
						sync: !this.adviceProcess?.pageStarted?.includes(
							AdviceProcessPageCodes.AdviceSummary
						),
					},
				];
				break;
			default:
				return this.crtPage;
				break;
		}
	}

	addDocument(): void {
		this.doCliamAddDocument.emit(this.adviceProcess);
	}

}
