
/*
 * IMPORTANT!
 * please do not change indexes order unless the order of KiwiSaver Fact Find page changes.
 * NOTE:
 *	- we are using this enum to calculate why route will be next we adviser clicked on next button
 */
export enum CrtKiwiSaverFactFindStepIndex {
	YourKiwiSaver,
	Withdrawal,
	WithdrawalResult,
	Summary,
	WhyKiwiSaver,
	HowKiwiSaverWorks,
	InvestmentStrategy,
	KiwiSaverProjection,
	RiskProfileInformationGathering,
	RiskProfileSummary,
	Providers,
}

export enum CrtKiwiSaverFactFindStepRouteName {
	YourKiwiSaver = 'your-kiwisaver',
	Withdrawal = 'withdrawal',
	WithdrawalResult = 'withdrawal-result',
	Summary = 'summary',
	WhyKiwiSaver = 'why-kiwisaver',
	HowKiwiSaverWorks = 'how-kiwisaver-works',
	InvestmentStrategy = 'investment-strategy',
	PassiveVSActive = 'passive-vs-active',
	RiskVSVolatility = 'risk-vs-volatility',
	KiwiSaverProjection = 'kiwisaver-projection',
	RiskProfileInformationGathering = 'risk-profile-ig',
	RiskProfileSummary = 'risk-profile-summary',
	Providers = 'providers',
}

export function getRouteNameByIndex(index: number): string {
	return CrtKiwiSaverFactFindStepRouteName[
		CrtKiwiSaverFactFindStepIndex[index]
	];
}

export enum KIWISAVER_FF_STEP_COUNT {
	ANIMATIONS = 3,
	INFORMATION_GATHERING = 3,
	KIWISAVER_SUMMARY = 1,
	KIWISAVER_PROJECTION = 1,
	RISK_PROFILE_INFORMATION_GATHERING = 2,
	PROVIDER = 1,
}

/**
 * calculate actual step index
 * ex. in information gathering we need to reset stepIndex if the adviser
 * reached person 1 summary page and go back to information gathering first step
 */
export function getActualStepIndex(
	currentStep: number,
	maxStep: number,
	startingStep = 0
): number {
	let currentStepIndex = startingStep;
	for (let i = startingStep; i < currentStep; i++) {
		if (currentStepIndex > maxStep) {
			currentStepIndex = 0;
		}
		++currentStepIndex;
	}
	return currentStepIndex;
}
