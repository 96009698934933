<div class="activity-form p-2" [ngClass]="{ 'is-client-referral': this.isAS }">
  <div class="row">
    <div [ngClass]="isAS  ? 'col-12' : 'col-lg-5'">
      <div class="position-relative">
        <div class="modal-header">
          <h1 *ngIf="!isAS else modalTitleAS" class="modal-title">
            Schedule Activity
          </h1>
          <ng-template #modalTitleAS>
            <h2 class="modal-title-as">Schedule Activity</h2>
          </ng-template>
        </div>

        <form novalidate [formGroup]="form">
          <div class="row tap-container">
            <div class="col px-0">
              <div *ngIf="(!isAS && !isAR) else asActivityType" class="form-group form-inline row mb-2">
                <div class="col-lg-3 pr-0">
                  <label for="" class="position-relative">
                    Activity Type
                    <span class="tap-required" *ngIf="ActivityTypeInvalid$ | async">
                      *
                    </span>
                  </label>
                </div>
                <div class="col-lg-9">
                  <span class="custom-dropdown w-100">
                    <select
                      id="activityType"
                      class="form-control theme-crm-field-disabled"
                      aria-placeholder="Task/To Do"
                      formControlName="ActivityType"
                      tabindex="7"
                    >
                      <option value=""></option>
                      <option *ngFor="let d of AT" [value]="d.value">
                        {{ d.display }}
                      </option>
                    </select>
                  </span>
                </div>
              </div>

              <ng-template #asActivityType>
                <div class="form-group form-inline row mb-2">
                  <div class="pr-0" [ngClass]="isAS  ? 'col-lg-4' : 'col-lg-3'">
                    <label for="" class="position-relative">
                      Activity Type
                    </label>
                  </div>
                  <div class="font-weight-bold" [ngClass]="isAS  ? 'col-lg-8' : 'col-lg-9'">
                    {{ referralActivityType }}
                  </div>
                </div>
              </ng-template>


              <div class="form-group form-inline row mb-2">
                <div class="pr-0" [ngClass]="isAS  ? 'col-lg-4' : 'col-lg-3'">
                  <label for="" class="position-relative">
                    Activity Name
                    <span class="tap-required" *ngIf="ActivityNameInvalid$ | async">
                      *
                    </span>
                  </label>
                </div>
                <div [ngClass]="isAS  ? 'col-lg-8' : 'col-lg-9'">
                  <input
                    id="activityName"
                    type="text"
                    class="form-control theme-crm-field-disabled"
                    placeholder="Task/To Do"
                    formControlName="ActivityName"
                    tabindex="8"
                  />
                </div>
              </div>

              <ng-container *ngIf="!isAS">
                <div class="form-group form-inline row mb-2">
                  <div class="col-lg-3 pr-0">
                    <label for="" class="position-relative">
                      Date
                      <span class="tap-required"
                        *ngIf="(DueDateInvalid$ | async) || form.get('Appointment').value && !form.get('DueDate').value"
                      >
                        *
                      </span>
                    </label>
                  </div>
                  <div class="col-lg-9">
                    <app-date-input
                      id="date"
                      textboxId="date"
                      textboxClass="theme-crm-field-disabled"
                      formControlName="DueDate"
                      aria-placeholder="dd/mm/yyyy"
                      tabindex="9"
                    >
                    </app-date-input>
                  </div>
                </div>

                <div class="form-group form-inline row mb-2">
                  <div class="col-lg-3 pr-0">
                    <label for="" class="position-relative">
                      Time
                      <span class="tap-required" *ngIf="DueTimeInvalid$ | async">*</span>
                    </label>
                  </div>
                  <div class="col-lg-9">
                    <input
                      id="time"
                      type="text"
                      class="form-control theme-crm-field-disabled"
                      formControlName="DueTime"
                      placeholder="13:30"
                      tabindex="10"
                    />
                  </div>
                </div>
              </ng-container>

              <div class="form-group form-inline row mb-2">
                <div class="pr-0" [ngClass]="isAS  ? 'col-lg-4' : 'col-lg-3'">
                  <label for="" class="position-relative">
                    Duration (minutes)
                    <span class="tap-required" *ngIf="DurationInvalid$ | async"
                      >*</span
                    >
                  </label>
                </div>
                <div [ngClass]="isAS  ? 'col-lg-8' : 'col-lg-9'">
                  <input
                    id="duration"
                    type="number"
                    class="form-control theme-crm-field-disabled"
                    formControlName="Duration"
                    placeholder="30"
                    (keydown)="durationKeydown($event)"
                    tabindex="11"
                  />
                </div>
              </div>
              <div class="form-group form-inline row mb-2">
                <div class="pr-0" [ngClass]="isAS  ? 'col-lg-4' : 'col-lg-3'">
                  <label for="" class="position-relative">
                    Assigned To
                    <span class="tap-required" *ngIf="AdviserInvalid$ | async">
                      *
                    </span>
                  </label>
                </div>
                <div [ngClass]="isAS  ? 'col-lg-8' : 'col-lg-9'">
                  <span class="custom-dropdown w-100">
                    <select
                      id="adviser"
                      class="form-control theme-crm-field-disabled"
                      aria-placeholder="Task/To Do"
                      formControlName="Adviser"
                      tabindex="12"
                    >
                      <option value=""></option>
                      <option *ngFor="let d of advisers" [value]="d.value">{{
                        d.display
                      }}</option>
                    </select>
                  </span>
                </div>
              </div>
              <div *ngIf="!isAS">
                <div *ngIf="!hideClient" class="form-group form-inline row mb-2">
                  <div class="col-lg-3 pr-0">
                    <label for="">Client</label>
                  </div>
                  <div class="col-lg-9">
                    <div
                      id="customer"
                      class="col px-0"
                      app-client-search-control
                      textboxClass="theme-crm-field-disabled"
                      formControlName="Customer"
                      tabindex="13"
                    ></div>
                  </div>
                </div>
              </div>

              <div class="form-group form-inline row mb-2">
                <div class="pr-0" [ngClass]="isAS  ? 'col-lg-4' : 'col-lg-3'">
                  <label for="" class="position-relative">
                    Meeting
                  </label>
                </div>
                <div [ngClass]="isAS  ? 'col-lg-8' : 'col-lg-9'">
                  <span class="custom-dropdown w-100">
                    <select
                      id="meeting"
                      class="form-control theme-crm-field-disabled"
                      aria-placeholder=""
                      formControlName="Meeting"
                      tabindex="14"
                    >
                      <option value=""></option>
                      <option *ngFor="let d of AM" [value]="d.value">
                        {{ d.display }}
                      </option>
                    </select>
                  </span>
                </div>
              </div>

              <div class="form-group form-inline row mb-2" *ngIf="form.get('Meeting').value === 'In Person'">
                <div class="pr-0" [ngClass]="isAS  ? 'col-lg-4' : 'col-lg-3'">
                  <label for="">Location</label>
                </div>
                <div [ngClass]="isAS  ? 'col-lg-8' : 'col-lg-9'">
                  <app-address-input
                    ngProjectAs="input"
                    formControlName="Location"
                    id="activityLocation_{{ i }}"
                    textboxClass="theme-crm-field-disabled"
                      tabindex="15"
                  >
                  </app-address-input>
                </div>
              </div>

              <div class="form-group form-inline row mb-2" [ngClass]="{'align-items-center' : isAS}">
                <div class="pr-0 details-label" [ngClass]="isAS  ? 'col-lg-4' : 'col-lg-3'">
                  <label for="">Details</label>
                </div>
                <div [ngClass]="isAS  ? 'col-lg-8' : 'col-lg-9'">
                  <textarea
                    id="details"
                    rows="3"
                    class="form-control theme-crm-field-disabled"
                    formControlName="Details"
                    tabindex="16"
                  ></textarea>
                </div>
              </div>

              <div class="form-group form-inline row mb-2 mx-0 py-2 link-to-calendar-row">
                <ng-container *ngIf="!isAS">
                  <div
                    class="d-flex d-inline-flex py-1 align-items-center col-auto px-0"
                  >
                    <div class="px-0 d-flex align-items-center col-6">
                      <label class="pr-4 py-1" for="appointment">Link to Calendar?</label>
                    </div>
                    <div class="col-4">
                      <app-checkbox class="clickable">
                        <input
                          formControlName="Appointment"
                          type="checkbox"
                          name="appointment"
                          id="appointment"
                          class="checkbox"
                          tabindex="16"
                        />
                      </app-checkbox>
                    </div>
                  </div>

                  <div class="d-flex d-inline-flex py-1 align-items-center col-auto px-0">
                    <ng-container *appRestrictTo="permissionsToComplete">
                      <div class="col-6 px-0">
                        <label class="pr-4 py-1" for="isCompleted">Complete</label>
                      </div>
                      <div class="col-4">
                        <app-checkbox class="clickable">
                          <input
                            formControlName="IsCompleted"
                            type="checkbox"
                            name="isCompleted"
                            id="isCompleted"
                            class="checkbox"
                            tabindex="17"
                            [attr.disabled]="!!form.get('IsCancelled').value || null"
                          />
                        </app-checkbox>
                      </div>
                    </ng-container>
                  </div>

                  <div class="d-flex d-inline-flex py-1 align-items-center col-auto px-0">
                    <ng-container *appRestrictTo="permissionsToComplete">
                      <div class="col-6 px-0">
                        <label class="pr-4 py-1" for="isCancelled">Cancel</label>
                      </div>
                      <div class="col-4">
                        <app-checkbox class="clickable">
                          <input
                            formControlName="IsCancelled"
                            type="checkbox"
                            name="isCancelled"
                            id="isCancelled"
                            class="checkbox"
                            tabindex="18"
                            [attr.disabled]="!!form.get('IsCompleted').value || null"
                          />
                        </app-checkbox>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>

                <div
                  class="d-flex flex-grow-1 justify-content-end py-1 align-items-center"
                  [ngClass]="{'mt-action-btn-as': isAS}"
                >
                  <div class="ml-2">
                    <ng-container *ngIf="!isSaving; else loader">
                      <button id="saveBtn"
                        tabindex="18"
                        class="tap-text-h4"
                        [ngClass]="isAS ? 'btn btn-primary btn-save-as' : 'tap-btn tap-btn--default'"
                        (click)="save()"
                      >
                        Save
                      </button>
                    </ng-container>
                    <ng-template #loader>
                      <app-manual-loader-mini
                        [isLoading]="isSaving"
                        class="px-2"
                      >
                      </app-manual-loader-mini>
                    </ng-template>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div *ngIf="!isAS" class="col-lg-7">
      <ng-container *ngIf="shouldShowGoogleCalendar$ | async; else noCalendar">
        <div class="googleCalendar w-100 h-100">
          <iframe
            frameborder="0"
            [src]="googleCalendarUrl$ | async"
            class="mt-1"
          ></iframe>
        </div>
      </ng-container>
      <ng-template #noCalendar>
        <div class="bg-dark w-100 h-100"></div>
      </ng-template>
    </div>
  </div>
</div>
