import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { mapTo, take, tap } from 'rxjs/operators';
import { DropdownValueService } from '../../../domain/dropdown-value/dropdown-value.service';
import { LeadSearchService } from './state/lead-search.service';

const dropdownCodes: string[] = ['PCLE', 'LS', 'PCLT', 'AT', 'SAS', 'LST'];

@Injectable()
export class LeadSearchResolver implements Resolve<boolean> {
	constructor(
		private dropdownValueService: DropdownValueService,
		private leadSearchService: LeadSearchService
	) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		const dropdown$ = this.dropdownValueService.getDropdownValueList$(dropdownCodes);

		return forkJoin([dropdown$]).pipe(
			tap(() => this.leadSearchService.getColumns().pipe(take(1)).subscribe()),
			tap(() => this.leadSearchService.getColumnWidths().pipe(take(1)).subscribe()),
			mapTo(true)
		);
	}
}
