import { numUtil } from './../../../../util/util';
import { CustomerTypes } from '@shared/models/_general/client.model';
import { ServicesCodes } from '@shared/models/services/services.model';
import { util } from '@util/util';
import * as R from 'ramda';
import { filter as rFilter } from 'ramda';
import { combineLatest } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { UserQuery } from 'src/app/domain/user/user.query';
import { ClaimsTable, GetServiceByAdviceProcessCode } from 'src/app/shared/models/advice-process/advice-process.model';
import { BLStaffsQuery } from '../../../../domain/bl-staff/bl-staffs.query';
import { BusinessConfigQuery } from '../../../../domain/business-config/business-config.query';
import { DropdownValueQuery } from '../../../../domain/dropdown-value/dropdown-value.query';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';
import { ClientProfileQuery } from './client-profile.query';
import { ClientProfileStore } from './client-profile.store';

export abstract class ClientProfileUtilsService {
	/**
	 * Dropdown choices
	 */
	// L&R Insurance
	LRProviders$ = this.dropdownValueQuery.orderedChoices$('LRP');
	LRPolicyStatus$ = this.dropdownValueQuery.orderedChoices$('LRPS');
	LRProducts$ = this.dropdownValueQuery.orderedChoices$('LRPR');
	LRPolicyTypes$ = this.dropdownValueQuery.orderedChoices$('LRPT');
	LRClaimStatus$ = this.dropdownValueQuery.orderedChoices$('LRCS');
	LRDiscounts$ = this.dropdownValueQuery.orderedChoices$('LRPD');
	LRPayments$ = this.dropdownValueQuery.orderedChoices$('LRPF');
	LRCancellationCodes$ = this.dropdownValueQuery.orderedChoices$('LRCC');
	LRPolicyRetentionStatus$ = this.dropdownValueQuery.orderedChoices$('LRRS');

	PCT$ = this.dropdownValueQuery.orderedChoices$('PCT');
	PCE$ = this.dropdownValueQuery.orderedChoices$('PCE');
	PCPC$ = this.dropdownValueQuery.orderedChoices$('PCPC');
	PCLE$ = this.dropdownValueQuery.orderedChoices$('PCLE');
	PCLT$ = this.dropdownValueQuery.orderedChoices$('PCLT');
	PCR$ = this.dropdownValueQuery.orderedChoices$('PCR');
	LS$ = this.dropdownValueQuery.orderedChoices$('LS');
	CI$ = this.dropdownValueQuery.orderedChoices$('CI');
	SCR$ = this.dropdownValueQuery.orderedChoices$('SCR');
	SCTT$ = this.dropdownValueQuery.orderedChoices$('SCTT');
	LRPS$ = this.dropdownValueQuery.orderedChoices$('LRPS');
	LRPF$ = this.dropdownValueQuery.orderedChoices$('LRPF');
	LRCS$ = this.dropdownValueQuery.orderedChoices$('LRCS');
	LRRS$ = this.dropdownValueQuery.orderedChoices$('LRRS');
	LRP$ = this.dropdownValueQuery.orderedChoices$('LRP');
	LRPT$ = this.dropdownValueQuery.orderedChoices$('LRPT');
	LRPD$ = this.dropdownValueQuery.orderedChoices$('LRPD');
	LRCC$ = this.dropdownValueQuery.orderedChoices$('LRCC');
	LRPR$ = this.dropdownValueQuery.orderedChoices$('LRPR');
	MP$ = this.dropdownValueQuery.orderedChoices$('MP');
	MS$ = this.dropdownValueQuery.orderedChoices$('MS');
	MT$ = this.dropdownValueQuery.orderedChoices$('MT');
	MFPE$ = this.dropdownValueQuery.orderedChoices$('MFPE');
	MRF$ = this.dropdownValueQuery.orderedChoices$('MRF');
	MLT$ = this.dropdownValueQuery.orderedChoices$('MLT');
	SV1$ = this.dropdownValueQuery.orderedChoices$('SV1');
	SV2$ = this.dropdownValueQuery.orderedChoices$('SV2');
	ST$ = this.dropdownValueQuery.orderedChoices$('ST');
	SU$ = this.dropdownValueQuery.orderedChoices$('SU');
	STI$ = this.dropdownValueQuery.orderedChoices$('STI');
	SS$ = this.dropdownValueQuery.orderedChoices$('SS');
	FGS$ = this.dropdownValueQuery.orderedChoices$('FGS');
	FGCS$ = this.dropdownValueQuery.orderedChoices$('FGCS');
	FGAS$ = this.dropdownValueQuery.orderedChoices$('FGAS');
	FGI$ = this.dropdownValueQuery.orderedChoices$('FGI');
	FGPT$ = this.dropdownValueQuery.orderedChoices$('FGPT');
	FGPW$ = this.dropdownValueQuery.orderedChoices$('FGPW');
	FGPL$ = this.dropdownValueQuery.orderedChoices$('FGPL');
	KP$ = this.dropdownValueQuery.orderedChoices$('KP');
	IP$ = this.dropdownValueQuery.orderedChoices$('IP');
	KS$ = this.dropdownValueQuery.orderedChoices$('KS');
	IS$ = this.dropdownValueQuery.orderedChoices$('IS');
	KPO$ = this.dropdownValueQuery.orderedChoices$('KFT');
	KO$ = this.dropdownValueQuery.orderedChoices$('KO');
	AT$ = this.dropdownValueQuery.orderedChoices$('AT');
	AM$ = this.dropdownValueQuery.orderedChoices$('AM');
	MRS$ = this.dropdownValueQuery.orderedChoices$('MRS');
	LT$ = this.dropdownValueQuery.orderedChoices$('LT');
	SAS$ = this.dropdownValueQuery.orderedChoices$('SAS');
	CT$ = this.dropdownValueQuery.orderedChoices$('CT');
	PT$ = this.dropdownValueQuery.orderedChoices$('PT');
	KT$ = this.dropdownValueQuery.orderedChoices$('KT');
	IT$ = this.dropdownValueQuery.orderedChoices$('IT');
	KPIRR$ = this.dropdownValueQuery.orderedChoices$('KPIRR');
	IPIRR$ = this.dropdownValueQuery.orderedChoices$('IPIRR');
	PAT$ = this.dropdownValueQuery.orderedChoices$('PAT');
	FGPM$ = this.dropdownValueQuery.orderedChoices$('FGPM');
	FGF$ = this.dropdownValueQuery.orderedChoices$('FGF');
	APCRTL$ = this.dropdownValueQuery.orderedChoices$('APCRTL');
	IRS$ = this.dropdownValueQuery.orderedChoices$('IRS');
	KRS$ = this.dropdownValueQuery.orderedChoices$('KRS');

	// Advice Process
	AP$ = this.dropdownValueQuery.orderedChoices$('AP');
	APCRTAS$ = this.dropdownValueQuery.orderedChoices$('APCRTAS');
	APCRTAT$ = this.dropdownValueQuery.orderedChoices$('APCRTAT');
	APCRTCT$ = this.dropdownValueQuery.orderedChoices$('APCRTCT');
	APCRTCO$ = this.dropdownValueQuery.orderedChoices$('APCRTCO');
	APCRTQI1$ = this.dropdownValueQuery.orderedChoices$('APCRTQI1');
	APCRTQI6$ = this.dropdownValueQuery.orderedChoices$('APCRTQI6');
	APCRTQCLR7$ = this.dropdownValueQuery.orderedChoices$('APCRTQCLR7');
	APCRTRL$ = this.dropdownValueQuery.orderedChoices$('APCRTRL');
	APCRTFCC$ = this.dropdownValueQuery.orderedChoices$('APCRTFCC');
	APCRTYNNA$ = this.dropdownValueQuery.orderedChoices$('APCRTYNNA');
	APCRTQIK6$ = this.dropdownValueQuery.orderedChoices$('APCRTQIK6');
	CARQICAR1$ = this.dropdownValueQuery.orderedChoices$('CARQICAR1');
	APCRTQIK15$ = this.dropdownValueQuery.orderedChoices$('APCRTQIK15');
	CLS$ = this.dropdownValueQuery.orderedChoices$('CLS');
	APCRTF$ = this.dropdownValueQuery.orderedChoices$('APCRTF');
	CCO$ = this.dropdownValueQuery.orderedChoices$('CCO');

	sciTransferStatus = 3;

	public advisers$ = this.blStaffsQuery.adviserChoicesOption$;
	public allowedInAccessibility$ = this.blStaffsQuery.allowedInAccessibility$;

	public reviewedBy$ = this.blStaffsQuery.availableStaffs$.pipe(
		map((x) =>
			R.filter(
				(y) =>
					y.SecurityGroup === 'BO' ||
					y.SecurityGroup === 'BAI' ||
					y.SecurityGroup === 'BM' ||
					y.SecurityGroup === 'AM',
				x
			)
		),
		map((x) =>
			R.map(
				(y) =>
					ViewDisplayValue.Map(
						y.StaffID?.toString(),
						`${y.FirstName} ${y.LastName}`
					),
				x
			)
		)
	);

	public allAdviserChoices$ = this.blStaffsQuery.allStaffsChoices$;

	public activityAdvisers$ = this.blStaffsQuery.availableStaffs$.pipe(
		map((x) =>
			R.map(
				(y) =>
					ViewDisplayValue.Map(
						y.StaffID?.toString(),
						`${y.FirstName} ${y.LastName}`
					),
				x
			)
		)
	);

	public leadGens$ = this.blStaffsQuery.leadGenChoices$;
	public allStaffChoices$ = this.blStaffsQuery.allStaffsChoices$;
	public adviserCalendarChoices$ = this.blStaffsQuery.adviserCalendarChoices$;
	public adviserCalendars$ = this.blStaffsQuery.adviserCalendarChoices$;
	public clientContactStatus$ = this.query.contactStatus$;

	primaryClient$ = this.query.primaryClient$;
	customerID$ = this.query.customerID$;

	primaryOrder = ['Active', 'Deceased', 'Transferred'];

	relationshipOrder = [
		'Partner/Spouse',
		'Child',
		'Parent',
		'Sibling',
		'Business Partner',
		'Policy Owner',
		'Friend',
		'Other',
	];

	secondaryClients$ = this.query.secondaryClients$.pipe(
		map((clients) => {
			return clients?.map((client) => {
				let tempStatus = 'Active';

				if (client?.isActive === 3) {
					tempStatus = 'Transferred';
				}

				if (client?.contactMethod === 'Deceased') {
					tempStatus = 'Deceased';
				}

				return {
					...client,
					tempStatus,
				};
			});
		}),
		map((x) => {
			const values = [...(x || [])]?.sort((a, b) => {
				const relationshipA =
					a?.relationship === 'Brother' || a?.relationship === 'Sister'
						? 'Sibling'
						: a?.relationship;
				const relationshipB =
					b?.relationship === 'Brother' || b?.relationship === 'Sister'
						? 'Sibling'
						: b?.relationship;
				return (
					this.relationshipOrder.indexOf(relationshipA) -
					this.relationshipOrder.indexOf(relationshipB)
				);
			});
			return values;
		}),
		map((x) => {
			const values = [...(x || [])]?.sort((a, b) => {
				return (
					this.primaryOrder.indexOf(a.tempStatus) -
					this.primaryOrder.indexOf(b.tempStatus)
				);
			});
			return values;
		})
	);

	secondaryBusinesses$ = this.query.secondaryBusinesses$;
	secondaryTrusts$ = this.query.secondaryTrusts$;
	secondaryProfessionals$ = this.query.secondaryProfessionals$;
	linkedContacts$ = this.query.linkedContacts$;

	allActiveStaffs$ = this.blStaffsQuery.allActiveStaffs$;
	altAdviserChoices$ = this.blStaffsQuery.altAdviserChoices$;
	adviserChoices$ = combineLatest([
		this.blStaffsQuery.allStaffsChoices$,
		this.primaryClient$,
		this.advisers$,
	]).pipe(
		// tslint:disable-next-line: max-line-length
		map(([all, primaryClient, adv]) =>
			all
				?.filter(
					(x) =>
						x.value === primaryClient?.adviser?.toString() ||
						adv?.find((y) => y.value === x.value)
				)
				?.sort((a, b) => a.display?.localeCompare(b.display))
		)
	);
	activeAdviserChoices$ = combineLatest([
		this.blStaffsQuery.allStaffsChoices$,
		this.primaryClient$,
		this.advisers$,
	]).pipe(
		withLatestFrom(this.blStaffsQuery.allStaffs$),
		map(([[all, primaryClient, adv], staffs]) =>
			all
				?.filter((x) => {
					const adviser = staffs?.find((s) => +s?.StaffID === +x?.value);
					return (
						(x.value === primaryClient?.adviser?.toString() &&
							adviser?.IsActive > 0) ||
						adv?.find((y) => y.value === x.value)
					);
				})
				?.sort((a, b) => a.display?.localeCompare(b.display))
		)
	);

	PreferredEmailContacts$ = combineLatest([
		this.primaryClient$,
		this.secondaryClients$,
	]).pipe(
		map(([primaryClient, secondaryClients]) => {
			if (!primaryClient) {
				return [];
			}
			const pc = primaryClient
				? ViewDisplayValue.Map(
						primaryClient.customerID
							? primaryClient.customerID?.toString()
							: '',
						`${primaryClient.firstName} ${primaryClient.lastName}`
				  )
				: null;
			const sc = secondaryClients
				? R.map(
						(y) =>
							ViewDisplayValue.Map(
								y.customerID?.toString(),
								`${y.firstName} ${y.lastName}`
							),
						secondaryClients
				  )
				: [];
			return [pc, ...sc];
		})
	);

	leadGenChoices$ = this.blStaffsQuery.allStaffsChoices$.pipe(
		withLatestFrom(this.primaryClient$, this.leadGens$),
		// tslint:disable-next-line: max-line-length
		map(([all, primaryClient, adv]) =>
			all
				? all
						?.filter(
							(x) =>
								x.value === (primaryClient ? primaryClient.adviser : '') ||
								adv?.find((y) => y.value === x.value)
						)
						?.sort((a, b) => a.display?.localeCompare(b.display))
				: all
		)
	);

	activityAdviserChoices$ = this.blStaffsQuery.allStaffsChoices$.pipe(
		withLatestFrom(this.primaryClient$, this.activityAdvisers$),
		// tslint:disable-next-line: max-line-length
		map(([all, primaryClient, adv]) =>
			all
				? all
						?.filter(
							(x) =>
								x.value === (primaryClient ? primaryClient.adviser : '') ||
								adv?.find((y) => y.value === x.value)
						)
						?.sort((a, b) => a.display?.localeCompare(b.display))
				: all
		)
	);

	professionalContacts$ = this.secondaryProfessionals$.pipe(
		map((x) => {
			const scts = x
				? R.map(
						(y) => ViewDisplayValue.Map(y.customerID?.toString(), `${y.name}`),
						x
				  )
				: [];

			return scts;
		})
	);

	// Services
	lrInsurance$ = this.query.lrInsurance$;
	mortgage$ = this.query.mortgage$;
	propertyAsset$ = this.query.propertyAsset$;
	fgInsurance$ = this.query.fgInsurance$;
	kiwiSavers$ = this.query.kiwiSavers$;
	investments$ = this.query.investments$;
	assets$ = this.query.asset$;
	liabilities$ = this.query.liability$;

	// Document
	documents$ = this.query.documents$;
	// Edit History
	histories$ = this.query.histories$;

	// Current Activity Criteria
	criterias$ = this.query.currentActivityCriterias$;

	// Advice Processes
	adviceProcesses$ = this.query.adviceProcesses$;

	// Business Codes
	hasLR$ = combineLatest([
		this.businessConfigQuery.hasLR$,
		this.userQuery.isUserHasLR$,
	]).pipe(map(([hasBusinessLR, hasUserLR]) => hasBusinessLR && hasUserLR));
	hasM$ = combineLatest([
		this.businessConfigQuery.hasM$,
		this.userQuery.isUserHasM$,
	]).pipe(map(([hasBusinessM, hasUserM]) => hasBusinessM && hasUserM));
	hasFG$ = combineLatest([
		this.businessConfigQuery.hasFG$,
		this.userQuery.isUserHasFG$,
	]).pipe(map(([hasBusinessFG, hasUserFG]) => hasBusinessFG && hasUserFG));
	hasK$ = combineLatest([
		this.businessConfigQuery.hasK$,
		this.userQuery.isUserHasK$,
	]).pipe(map(([hasBusinessK, hasUserK]) => hasBusinessK && hasUserK));
	hasI$ = combineLatest([
		this.businessConfigQuery.hasI$,
		this.userQuery.isUserHasI$,
	]).pipe(map(([hasBusinessI, hasUserI]) => hasBusinessI && hasUserI));
	hasAP$ = combineLatest([
		this.businessConfigQuery.hasAP$,
		this.userQuery.isUserHasAP$,
	]).pipe(map(([hasbusinessAP, hasUserAP]) => hasbusinessAP && hasUserAP));
	hasCRT$ = combineLatest([
		this.businessConfigQuery.hasAP$,
		this.businessConfigQuery.hasCRT$,
		this.userQuery.isUserHasAP$,
		this.userQuery.isUserHasCRT$,
	]).pipe(
		map(
			([hasbusinessAP, hasbusinessCRT, hasUserAP, hasUserCRT]) =>
				hasbusinessAP && hasbusinessCRT && hasUserAP && hasUserCRT
		)
	);
	hasMOAT$ = combineLatest([
		this.businessConfigQuery.hasAP$,
		this.businessConfigQuery.hasMOAT$,
		this.userQuery.isUserHasAP$,
		this.userQuery.isUserHasMOAT$,
	]).pipe(
		map(
			([hasbusinessAP, hasbusinessMOAT, hasUserAP, hasUserMOAT]) =>
				hasbusinessAP && hasbusinessMOAT && hasUserAP && hasUserMOAT
		)
	);
  hasLOATV2$ = this.businessConfigQuery.hasLOATV2$;
	hasKOAT$ = combineLatest([
		this.businessConfigQuery.hasAP$,
		this.businessConfigQuery.hasKOAT$,
		this.userQuery.isUserHasAP$,
		this.userQuery.isUserHasKOAT$,
	]).pipe(
		map(
			([hasbusinessAP, hasbusinessKOAT, hasUserAP, hasUserKOAT]) =>
				hasbusinessAP && hasbusinessKOAT && hasUserAP && hasUserKOAT
		)
	);
	hasOfflineCRT$ = combineLatest([
		this.businessConfigQuery.hasAP$,
		this.userQuery.isUserHasAP$,
	]).pipe(map(([hasbusinessAP, hasUserAP]) => hasbusinessAP && hasUserAP));

	hasCPMOAT$ = combineLatest([
		this.businessConfigQuery.hasAP$,
		this.businessConfigQuery.hasMOAT$,
		this.businessConfigQuery.hasCPMOAT$,
		this.userQuery.isUserHasAP$,
		this.userQuery.isUserHasMOAT$,
		this.userQuery.isUserHasCPMOAT$,
	]).pipe(
		map(
			([
				hasbusinessAP,
				hasbusinessMOAT,
				hasBusinessCPMOAT,
				hasUserAP,
				hasUserMOAT,
				hasUserCPMOAT,
			]) =>
				hasbusinessAP &&
				hasbusinessMOAT &&
				hasBusinessCPMOAT &&
				hasUserAP &&
				hasUserMOAT &&
				hasUserCPMOAT
		)
	);

	hasCustomerPortalDocument$ =
		this.businessConfigQuery.hasCustomerPortalDocument$;

	sciIsLoading$ = this.query.sciIsLoading$;
	sccIsLoading$ = this.query.sccIsLoading$;
	sctIsLoading$ = this.query.sctIsLoading$;
	scpIsLoading$ = this.query.scpIsLoading$;

	lrIsLoading$ = this.query.lrIsLoading$;
	mIsLoading$ = this.query.mIsLoading$;
	paIsLoading$ = this.query.paIsLoading$;
	aIsLoading$ = this.query.aIsLoading$;
	fgIsLoading$ = this.query.fgIsLoading$;
	kIsLoading$ = this.query.kIsLoading$;
	iIsLoading$ = this.query.iIsLoading$;

	documentIsLoading$ = this.query.documentIsLoading$;
	historyIsLoading$ = this.query.historyIsLoading$;
	adviceProcessIsLoading$ = this.query.adviceProcessIsLoading$;

	/**
	 * Activity timeline
	 */
	activityTimeline$ = this.query.activityTimeline$.pipe(
		map((x) => (x ? x : { activities: [], notes: [] }))
	);
	// Pending Activities
	pendingActivities$ = this.activityTimeline$.pipe(
		// filter((x) => !x),
		map((x) => x.activities),
		map((activities) =>
			activities?.length
				? R.filter((x) => !x.isCancelled && !x.isCompleted, activities)
				: []
		)
	);

	// Completed Notes
	completedActivities$ = this.activityTimeline$.pipe(
		// filter((x) => !x),
		map((x) => x.notes),
		map((notes) =>
			notes?.length
				? R.filter((x) => !!x.activityType && !!x.activityName, notes)
				: []
		)
	);
	completedNotes$ = this.activityTimeline$.pipe(
		// filter((x) => !x),
		map((x) => x.notes),
		map((notes) =>
			notes?.length ? R.filter((x) => !x.activityName, notes) : []
		)
	);
	allCompletedNotes$ = this.activityTimeline$.pipe(
		// filter((x) => !x),
		map((x) => x.notes)
	);

	LRPolicyOwners$ = combineLatest([
		this.primaryClient$,
		this.secondaryClients$,
		this.secondaryBusinesses$,
		this.secondaryTrusts$,
		this.linkedContacts$,
	]).pipe(
		map(
			([
				primaryClient,
				secondaryClients,
				secondaryBusinesses,
				secondaryTrust,
				linkedContacts,
			]) => {
				if (!primaryClient) {
					return [];
				}
				const pc = primaryClient
					? ViewDisplayValue.Map(
							primaryClient.customerID?.toString(),
							`${primaryClient.firstName} ${primaryClient.lastName}`
					  )
					: null;

				const filterNonTransferedSCI = secondaryClients
					? R.filter(
							(n) => n.isActive !== this.sciTransferStatus,
							secondaryClients
					  )
					: [];

				const sc = filterNonTransferedSCI
					? R.map(
							(y) =>
								ViewDisplayValue.Map(
									y.customerID?.toString(),
									`${y.firstName} ${y.lastName}`
								),
							filterNonTransferedSCI
					  )
					: [];
				const sb = secondaryBusinesses
					? R.map(
							(y) =>
								ViewDisplayValue.Map(
									y.customerID?.toString(),
									`${y.companyName}`
								),
							secondaryBusinesses
					  )
					: [];
				const st = secondaryTrust
					? R.map(
							(y) =>
								ViewDisplayValue.Map(
									y.customerID?.toString(),
									`${y.trustName}`
								),
							secondaryTrust
					  )
					: [];

				const lc = linkedContacts
					? R.map(
							(y) =>
								ViewDisplayValue.Map(
									`${
										y?.linkedFromPrimaryCustomer
											? y.relatedCustomerId?.toString()
											: y.customerId?.toString()
									}`,
									`${y.name}`
								),
							linkedContacts
					  )
					: [];

				return [pc, ...sc, ...sb, ...st, ...lc].sort((a, b) =>
					a.display?.toLowerCase().localeCompare(b.display?.toLowerCase())
				);
			}
		)
	);

	BorrowingEntities$ = combineLatest([
		this.primaryClient$,
		this.secondaryClients$,
		this.secondaryBusinesses$,
		this.secondaryTrusts$,
		this.linkedContacts$,
	]).pipe(
		map(
			([
				primaryClient,
				secondaryClients,
				secondaryBusinesses,
				secondaryTrust,
				linkedContacts,
			]) => {
				if (!primaryClient) {
					return [];
				}
				const pc = primaryClient
					? ViewDisplayValue.Map(
							primaryClient.customerID?.toString(),
							`${primaryClient.firstName} ${primaryClient.lastName}`
					  )
					: null;

				const filterNonTransferedSCI = secondaryClients
					? R.filter(
							(n) => n.isActive !== this.sciTransferStatus,
							secondaryClients
					  )
					: [];

				const sc = filterNonTransferedSCI
					? R.map(
							(y) =>
								ViewDisplayValue.Map(
									y.customerID?.toString(),
									`${y.firstName} ${y.lastName}`
								),
							filterNonTransferedSCI
					  )
					: [];
				const sb = secondaryBusinesses
					? R.map(
							(y) =>
								ViewDisplayValue.Map(
									y.customerID?.toString(),
									`${y.companyName}`
								),
							secondaryBusinesses
					  )
					: [];
				const st = secondaryTrust
					? R.map(
							(y) =>
								ViewDisplayValue.Map(
									y.customerID?.toString(),
									`${y.trustName}`
								),
							secondaryTrust
					  )
					: [];
				const lc = linkedContacts
					? R.map(
							(y) =>
								ViewDisplayValue.Map(
									`${
										y?.linkedFromPrimaryCustomer
											? y.relatedCustomerId?.toString()
											: y.customerId?.toString()
									}`,
									`${y.name}`
								),
							linkedContacts
					  )
					: [];

				return [pc, ...sc, ...sb, ...st, ...lc].sort((a, b) =>
					a.display?.toLowerCase().localeCompare(b.display?.toLowerCase())
				);
			}
		)
	);

	Securities$ = this.propertyAsset$.pipe(
		map((prop) => {
			const pa =
				prop && prop.customerServices
					? R.map(
							(y) =>
								ViewDisplayValue.Map(
									y.customerServiceID?.toString(),
									`${y.propertyAddress}`
								),
							prop.customerServices
					  )
					: null;
			const filtered = pa?.filter(
				(item) => item.display !== 'undefined' && item.display !== undefined
			);
			return filtered;
		})
	);

	FGPolicyOwners$ = combineLatest([
		this.primaryClient$,
		this.secondaryClients$,
		this.secondaryBusinesses$,
		this.secondaryTrusts$,
		this.linkedContacts$,
	]).pipe(
		map(
			([
				primaryClient,
				secondaryClients,
				secondaryBusinesses,
				secondaryTrust,
				linkedContacts,
			]) => {
				if (!primaryClient) {
					return [];
				}
				const pc = primaryClient
					? ViewDisplayValue.Map(
							primaryClient.customerID?.toString(),
							`${primaryClient.firstName} ${primaryClient.lastName}`
					  )
					: null;

				const filterNonTransferedSCI = secondaryClients
					? R.filter(
							(n) => n.isActive !== this.sciTransferStatus,
							secondaryClients
					  )
					: [];

				const sc = filterNonTransferedSCI
					? R.map(
							(y) =>
								ViewDisplayValue.Map(
									y.customerID?.toString(),
									`${y.firstName} ${y.lastName}`
								),
							filterNonTransferedSCI
					  )
					: [];
				const sb = secondaryBusinesses
					? R.map(
							(y) =>
								ViewDisplayValue.Map(
									y.customerID?.toString(),
									`${y.companyName}`
								),
							secondaryBusinesses
					  )
					: [];
				const st = secondaryTrust
					? R.map(
							(y) =>
								ViewDisplayValue.Map(
									y.customerID?.toString(),
									`${y.trustName}`
								),
							secondaryTrust
					  )
					: [];
				const lc = linkedContacts
					? R.map(
							(y) =>
								ViewDisplayValue.Map(
									`${
										y?.linkedFromPrimaryCustomer
											? y.relatedCustomerId?.toString()
											: y.customerId?.toString()
									}`,
									`${y.name}`
								),
							linkedContacts
					  )
					: [];

				return [pc, ...sc, ...sb, ...st, ...lc].sort((a, b) =>
					a.display?.toLowerCase().localeCompare(b.display?.toLowerCase())
				);
			}
		)
	);

	propertyOwners$ = combineLatest([
		this.primaryClient$,
		this.secondaryClients$,
		this.secondaryBusinesses$,
		this.secondaryTrusts$,
		this.linkedContacts$,
	]).pipe(
		map(
			([
				primaryClient,
				secondaryClients,
				secondaryBusinesses,
				secondaryTrust,
				linkedContacts,
			]) => {
				if (!primaryClient) {
					return [];
				}
				const pc = primaryClient
					? ViewDisplayValue.Map(
							primaryClient.customerID?.toString(),
							`${primaryClient.firstName} ${primaryClient.lastName}`
					  )
					: null;

				const filterNonTransferedSCI = secondaryClients
					? R.filter(
							(n) => n.isActive !== this.sciTransferStatus,
							secondaryClients
					  )
					: [];

				const sc = filterNonTransferedSCI
					? R.map(
							(y) =>
								ViewDisplayValue.Map(
									y.customerID?.toString(),
									`${y.firstName} ${y.lastName}`
								),
							filterNonTransferedSCI
					  )
					: [];
				const sb = secondaryBusinesses
					? R.map(
							(y) =>
								ViewDisplayValue.Map(
									y.customerID?.toString(),
									`${y.companyName}`
								),
							secondaryBusinesses
					  )
					: [];
				const st = secondaryTrust
					? R.map(
							(y) =>
								ViewDisplayValue.Map(
									y.customerID?.toString(),
									`${y.trustName}`
								),
							secondaryTrust
					  )
					: [];
				const lc = linkedContacts
					? R.map(
							(y) =>
								ViewDisplayValue.Map(
									`${
										y?.linkedFromPrimaryCustomer
											? y.relatedCustomerId?.toString()
											: y.customerId?.toString()
									}`,
									`${y.name}`
								),
							linkedContacts
					  )
					: [];

				return [pc, ...sc, ...sb, ...st, ...lc].sort((a, b) =>
					a.display?.toLowerCase().localeCompare(b.display?.toLowerCase())
				);
			}
		)
	);

	assetOwners$ = combineLatest([
		this.primaryClient$,
		this.secondaryClients$,
		this.linkedContacts$,
		this.secondaryBusinesses$,
		this.secondaryTrusts$,
	]).pipe(
		map(
			([
				primaryClient,
				secondaryClients,
				linkedContacts,
				businesses,
				trusts,
			]) => {
				if (!primaryClient) {
					return [];
				}
				const pc = primaryClient
					? ViewDisplayValue.Map(
							primaryClient.customerID?.toString(),
							`${primaryClient.firstName} ${primaryClient.lastName}`
					  )
					: null;

				const filterNonTransferedSCI = secondaryClients
					? R.filter(
							(n) => n.isActive !== this.sciTransferStatus,
							secondaryClients
					  )
					: [];

				const sc = filterNonTransferedSCI
					? R.map(
							(y) =>
								ViewDisplayValue.Map(
									y.customerID?.toString(),
									`${y.firstName} ${y.lastName}`
								),
							filterNonTransferedSCI
					  )
					: [];

				const lc = linkedContacts
					? R.map(
							(y) =>
								ViewDisplayValue.Map(
									`${
										y?.linkedFromPrimaryCustomer
											? y.relatedCustomerId?.toString()
											: y.customerId?.toString()
									}`,
									`${y.name}`
								),
							linkedContacts
					  )
					: [];

				const b = businesses
					? R.map(
							(y) =>
								ViewDisplayValue.Map(
									y.customerID?.toString(),
									`${y.companyName}`
								),
							businesses
					  )
					: [];

				const t = trusts
					? R.map(
							(y) =>
								ViewDisplayValue.Map(
									y.customerID?.toString(),
									`${y.trustName}`
								),
							trusts
					  )
					: [];

				return [pc, ...sc, ...lc, ...b, ...t].sort((a, b) =>
					a.display?.toLowerCase().localeCompare(b.display?.toLowerCase())
				);
			}
		)
	);

	fundOwners$ = combineLatest([
		this.primaryClient$,
		this.secondaryClients$,
		this.linkedContacts$,
	]).pipe(
		map(([primaryClient, secondaryClients, linkedContacts]) => {
			if (!primaryClient) {
				return [];
			}
			const pc = primaryClient
				? ViewDisplayValue.Map(
						primaryClient.customerID?.toString(),
						`${primaryClient.firstName} ${primaryClient.lastName}`
				  )
				: null;

			const filterNonTransferedSCI = secondaryClients
				? R.filter(
						(n) =>
							n.isActive !== this.sciTransferStatus &&
							n.contactMethod !== 'Deceased',
						secondaryClients
				  )
				: [];

			const sc = filterNonTransferedSCI
				? R.map(
						(y) =>
							ViewDisplayValue.Map(
								y.customerID?.toString(),
								`${y.firstName} ${y.lastName}`
							),
						filterNonTransferedSCI
				  )
				: [];

			const lc = linkedContacts
				? R.map(
						(y) =>
							ViewDisplayValue.Map(
								`${
									y?.linkedFromPrimaryCustomer
										? y.relatedCustomerId?.toString()
										: y.customerId?.toString()
								}`,
								`${y.name}`
							),
						linkedContacts
				  )
				: [];
			return [pc, ...sc, ...lc].sort((a, b) =>
				a.display?.toLowerCase().localeCompare(b.display?.toLowerCase())
			);
		})
	);

	listOfServices = (id: number) =>
		this.lrInsurance$.pipe(
			withLatestFrom(
				this.mortgage$,
				this.propertyAsset$,
				this.fgInsurance$,
				this.kiwiSavers$,
				this.investments$,
				this.assets$,
				this.liabilities$
			),
			map(([lr, m, pa, fg, ks, inv, ass, liabilityState]) => {
				const checkLr = lr.lRs
					?.map((l) => {
						return {
							...l,
							customerServices: l.customerServices?.filter((cs) => {
								const a = cs.policyOwners ? JSON.parse(cs.policyOwners) : null;
								return (
									cs.isActive === 1 &&
									!!a &&
									a?.find((po) => po && !!(+po === id))
								);
							}),
						};
					})
					?.filter(
						(x) =>
							!!x.customerServices &&
							x.customerServices !== null &&
							x.customerServices.length > 0
					);

				const checkM = m.mortgages
					?.map((mortgage) => {
						return {
							...mortgage,
							customerServices: mortgage.customerServices?.filter((cs) => {
								const a = cs.borrowingEntities
									? JSON.parse(cs.borrowingEntities)
									: null;
								return (
									cs.isActive === 1 &&
									!!a &&
									a?.find((po) => po && !!(+po === id))
								);
							}),
						};
					})
					?.filter(
						(x) =>
							!!x.customerServices &&
							x.customerServices !== null &&
							x.customerServices.length > 0
					);

				const checkPa = pa.customerServices?.filter((cs) => {
					const a = cs.propertyOwner ? JSON.parse(cs.propertyOwner) : null;
					return cs.isActive === 1 && !!a && a?.find((po) => !!(+po === id));
				});

				const checkFg = fg.fGs
					?.map((l) => {
						return {
							...l,
							customerServices: l.customerServices?.filter((cs) => {
								const a = cs.policyOwners ? JSON.parse(cs.policyOwners) : null;
								return (
									cs.isActive === 1 &&
									!!a &&
									a?.find((po) => po && !!(+po === id))
								);
							}),
						};
					})
					?.filter(
						(x) =>
							!!x.customerServices &&
							x.customerServices !== null &&
							x.customerServices.length > 0
					);

				const checkKs = ks?.filter(
					(x) => x.isActive === 1 && +x.fundOwner === id
				);

				const checkInv = inv?.filter(
					(x) =>
						x.isActive === 1 &&
						x.investor.includes(`${id}`) &&
						x.investor.length > 0
				);

				const checkAss = ass?.customerServices?.filter((x: any) => {
					const owners = x.owner ? JSON.parse(x.owner) : [];

					return x.isActive === 1 && owners.includes(id) && owners.length > 0;
				});

				const checkLiabilities = liabilityState.customerServices?.filter(
					(x) => {
						const borrower = util.tryParseJson(x.borrower) ?? [];
						return borrower.includes(id.toString()) && borrower.length > 1;
					}
				);

				return checkLr?.length > 0 ||
					checkM?.length > 0 ||
					checkPa?.length > 0 ||
					checkFg?.length > 0 ||
					checkKs?.length > 0 ||
					checkInv?.length > 0 ||
					checkAss?.length > 0 ||
					checkLiabilities?.length > 0
					? {
							lrs: checkLr?.length > 0 ? checkLr : null,
							mortgages: checkM?.length > 0 ? checkM : null,
							propertyAssets: checkPa?.length > 0 ? checkPa : null,
							fgs: checkFg?.length > 0 ? checkFg : null,
							kiwiSavers: checkKs?.length > 0 ? checkKs : null,
							investments: checkInv?.length > 0 ? checkInv : null,
							assets: checkAss?.length > 0 ? checkAss : null,
							liabilities:
								checkLiabilities?.length > 0 ? checkLiabilities : null,
					  }
					: null;
			})
		);

	clients = (customerID: number) =>
		this.primaryClient$.pipe(
			withLatestFrom(this.secondaryClients$),
			map(([primary, secondaries]) => {
				const pci = primary.professionalContacts
					? JSON.parse(primary.professionalContacts)
					: null;
				const checkPCI = pci ? pci?.find((po) => !!(+po === customerID)) : null;

				const checkSCI = secondaries?.filter((s) => {
					const pc = s.professionalContacts
						? JSON.parse(s.professionalContacts)
						: null;
					return pc && pc?.find((po) => !!(+po === customerID));
				});

				return checkPCI || checkSCI.length > 0
					? {
							pci: checkPCI ? primary : null,
							sci: checkSCI.length > 0 ? checkSCI : null,
					  }
					: null;
			})
		);

	// tslint:disable-next-line: member-ordering
	clientsInvolved$ = combineLatest([
		this.primaryClient$,
		this.secondaryClients$,
		this.secondaryBusinesses$,
		this.secondaryTrusts$,
		this.linkedContacts$,
	]).pipe(
		map(
			([
				primaryClient,
				secondaryClients,
				secondaryBusinesses,
				secondaryTrust,
				linkedContacts,
			]) => {
				if (!primaryClient) {
					return [];
				}
				const sci = secondaryClients?.filter((x) => x?.isActive === 1) || [];
				const pc = primaryClient
					? ViewDisplayValue.Map(
							primaryClient.customerID?.toString(),
							`${primaryClient.firstName} ${primaryClient.lastName}`
					  )
					: null;
				const sc = sci
					? R.map(
							(y) =>
								ViewDisplayValue.Map(
									y.customerID?.toString(),
									`${y.firstName} ${y.lastName}`
								),
							sci
					  )
					: [];
				const sb = secondaryBusinesses
					? R.map(
							(y) =>
								ViewDisplayValue.Map(
									y.customerID?.toString(),
									`${y.companyName}`
								),
							secondaryBusinesses
					  )
					: [];
				const st = secondaryTrust
					? R.map(
							(y) =>
								ViewDisplayValue.Map(
									y.customerID?.toString(),
									`${y.trustName}`
								),
							secondaryTrust
					  )
					: [];

				const lc = linkedContacts
					? R.map(
							(y) =>
								ViewDisplayValue.Map(
									`${
										y?.linkedFromPrimaryCustomer
											? y.relatedCustomerId?.toString()
											: y.customerId?.toString()
									}`,
									`${y.name}`
								),
							linkedContacts
					  )
					: [];
				return [pc, ...sc, ...sb, ...st, ...lc];
			}
		)
	);

	// tslint:disable-next-line: member-ordering
	clientsInvolvedRaw$ = combineLatest([
		this.primaryClient$,
		this.secondaryClients$,
		this.secondaryBusinesses$,
		this.secondaryTrusts$,
		this.linkedContacts$,
	]).pipe(
		map(
			([
				primaryClient,
				secondaryClients,
				secondaryBusinesses,
				secondaryTrust,
				linkedContacts,
			]) => {
				if (!primaryClient) {
					return [];
				}

				const sci = secondaryClients
					? secondaryClients?.filter((x) => +x.isActive === 1)
					: [];
				const scc = secondaryBusinesses ? secondaryBusinesses : [];
				const sct = secondaryTrust ? secondaryTrust : [];
				const lcc = linkedContacts
					? linkedContacts.map((contact) => ({
							...contact,
							customerType: CustomerTypes.LinkedContact,
					  }))
					: [];
				return [primaryClient, ...sci, ...scc, ...sct, ...lcc];
			}
		)
	);

	deceasedClients$ = this.secondaryClients$.pipe(
		map((clients) => {
			return clients?.filter((client) => client.contactMethod) || [];
		})
	);

	/**
	 * Get the Service for Advice Process
	 * @param code ProcessCode
	 * @returns Service Code or null
	 */
	getAdviceProcessService(code: string) {
		return GetServiceByAdviceProcessCode(code);
	}

	/**
	 * Get the list of available choices for people from CRM
	 * @param ownersIds Value of specific input field selected
	 * @param policyOwners available choices
	 * @returns list of available choices with or without deceased clients & linked contacts
	 */
	getOwnerChoices = (
		ownersIds: (string | number)[],
		policyOwners: ViewDisplayValue[]
	) => {
		const owners = (ownersIds || [])?.map((x) => +x);
		const allSci = (this.query.getValue().secondaryClients || [])?.map((x) => ({
			...x,
			id: +x?.customerID,
		}));
		const allLinked = (this.query.getValue().linkedContacts || [])?.map(
			(x) => ({
				...x,
				isLinked: true,
				id: x?.linkedFromPrimaryCustomer
					? +x?.relatedCustomerId
					: +x?.customerId,
			})
		);
		const desceasedSciIds = allSci
			?.filter((x) => x?.contactMethod === 'Deceased')
			?.map((x) => +x?.id);
		const deceasedLinkedIds = allLinked
			?.filter((x) => x?.contactMethod === 'Deceased')
			?.map((x) => +x?.id);
		const deceasedIds = R.uniq([...desceasedSciIds, ...deceasedLinkedIds]);
		const newPolicyOwnersNoDeceased = (policyOwners || [])
			?.filter((x) => !deceasedIds?.includes(+x?.value))
			?.sort((a, b) => a.display?.localeCompare(b.display));

		if (owners?.length > 0) {
			const deceased = owners?.filter((x) => deceasedIds?.includes(+x));

			if (deceased?.length > 0) {
				const policyOwnersDeceased = [...allSci, ...allLinked]?.filter(
					(x) => +deceased?.some((t) => +t === +x.id)
				) as any;
				const policyOwnersDeceasedIds = policyOwnersDeceased?.map(
					(x) => +x?.id
				);
				const potChoices =
					policyOwnersDeceased?.map((x) =>
						ViewDisplayValue.Map(
							`${x.id}`,
							`${x?.isLinked ? x?.name : x.firstName.concat(' ', x.lastName)}`
						)
					) || [];
				const newPolicyOwners =
					policyOwners
						?.filter((x) => {
							const isDeceased = deceasedIds?.includes(+x?.value);
							const isSelected = policyOwnersDeceasedIds?.includes(+x?.value);
							return isSelected ? false : !isDeceased;
						})
						?.map((x) => ({ ...x, value: x?.value?.toString() })) || [];
				const newList = R.uniqBy(R.prop('value'), [
					...newPolicyOwners,
					...potChoices,
				]);

				return newList?.sort((a, b) => a.display?.localeCompare(b.display));
			} else {
				const newList = R.uniqBy(R.prop('value'), newPolicyOwnersNoDeceased);
				return newList?.sort((a, b) => a.display?.localeCompare(b.display));
			}
		} else {
			const newList = R.uniqBy(R.prop('value'), newPolicyOwnersNoDeceased);
			return newList?.sort((a, b) => a.display?.localeCompare(b.display));
		}
	};

	primaryClient = () => this.query.getValue().primaryClient;

	// TODO: Hotfix only, for improvement
	public getFirstServiceCode(): string {
		const allowedServices = ['LR', 'M', 'FG', 'K', 'I'];
		const snapShot = this.userQuery.getValue();
		const services = util.tryParseJson(snapShot.Services) || [];

		if (services.length < 1) {
			return null;
		}

		const filteredServices = services?.filter((service) => allowedServices.includes(service));

		const customSort = (a, b) => {
			const indexA = allowedServices.indexOf(a);
			const indexB = allowedServices.indexOf(b);
			
			return indexA - indexB;
		};

		const sortedServices = filteredServices?.sort(customSort);

		return sortedServices[0];
	}

	lRClaimsList$ = this.adviceProcesses$.pipe(map((advice)=>{
		const documents = {};
		advice?.lRClaimsAdviceProcesses?.slice()?.sort(
			(a,b)=>  new Date(b.createdDateTime).valueOf() - new Date(a.createdDateTime).valueOf()
		)?.map((data)=>{
			const ids = JSON.parse(data.customerServiceID) as string[];
			ids.map((ids)=>{
				const tableData:ClaimsTable = { 
					adviceProcessID:data.adviceProcessID, 
					createdDate:data.createdDateTime, 
					totalPaid:numUtil.formatToCurrency2(numUtil.toNumber(data.totalPaid)),
					referenceNumber:data.referenceNumber,
					claimOutcome:data.claimOutcome,
					claimsDetail:data.claimDetails,
					status:data.status
				};
				documents[ids]?documents[ids].push(tableData):documents[ids]=[tableData];
			})
		});
		return documents;
	}));
	
	fGClaimsList$ = this.adviceProcesses$.pipe(map((advice)=>{
		const documents = {};
		advice?.fGClaimsAdviceProcesses?.slice()?.sort(
			(a,b)=>  new Date(b.createdDateTime).valueOf() - new Date(a.createdDateTime).valueOf()
		)?.map((data)=>{
			const ids = JSON.parse(data.customerServiceID) as string[];
			ids.map((ids)=>{
				const tableData:ClaimsTable = { 
					adviceProcessID:data.adviceProcessID, 
					createdDate:data.createdDateTime, 
					totalPaid:numUtil.formatToCurrency2(numUtil.toNumber(data.totalPaid)),
					referenceNumber:data.referenceNumber,
					claimOutcome:data.claimOutcome,
					claimsDetail:data.claimDetails,
					status:data.status
				};
				documents[ids]?documents[ids].push(tableData):documents[ids]=[tableData];
			})
		});
		return documents;
	}))

	constructor(
		protected store: ClientProfileStore,
		protected query: ClientProfileQuery,
		protected dropdownValueQuery: DropdownValueQuery,
		protected blStaffsQuery: BLStaffsQuery,
		protected businessConfigQuery: BusinessConfigQuery,
		protected userQuery: UserQuery
	) {}
}
