import { Component, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BLStaffsQuery } from '@domain/bl-staff/bl-staffs.query';
import { DropdownValueQuery } from '@domain/dropdown-value/dropdown-value.query';
import { controlType } from '@modules/business-page/business-page-datatable.config';
import {
  TransferExportPayload,
  TransferType,
} from '@modules/transfer/state/transfer.model';
import { TransferQuery } from '@modules/transfer/state/transfer.query';
import { TransferService } from '@modules/transfer/state/transfer.service';
import { ViewDisplayValue } from '@shared/models/_general/display-value.viewmodel';
import { ProgressStatus } from '@shared/progress-popup/progress';
import { TableColumn } from '@swimlane/ngx-datatable';
import { datatableUtil } from '@util/datatable.util';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import {
  Observable,
  Subject,
  combineLatest,
  delay,
  filter,
  map,
  mergeMap,
  of,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { LeadSearchUiStore } from '../state/lead-search-ui.store';
import { Row } from '../state/lead-search.model';
import { LeadSearchStore } from '../state/lead-search.store';
import { LeadSearchQuery } from './../state/lead-search.query';
import { propOr } from 'ramda';
import { FieldMetadata } from '@shared/dynamic-field/field-metadata.model';
import { util } from '@util/util';
import { DEFAULT_INTERVAL_BEFORE_POLLING } from '@modules/exports/state/exports.service';

@Component({
	selector: 'app-lead-bulk-transfer',
	templateUrl: './lead-bulk-transfer.component.html',
	styleUrls: ['./lead-bulk-transfer.component.scss'],
})
export class LeadBulkTransferComponent implements OnDestroy {
	private destroy$: Subject<void> = new Subject<void>();

	@ViewChild(PopoverDirective) popover: PopoverDirective;

	form: FormGroup;
	tableHeight: SafeStyle;

	isTransferring = false;
	isLoading = false;
	advisers$ = of([]);
	index = 0;

	dataRows$ = of([]);
	rows$ = of([]);

	columns$ = of([]);
  
	/** For datatable optimization to determine row identifier. */
	rowIdentity = (row: Row) => row.CustomerId;

	adviserProviders$ = bulkTransferColumns.filter(x => x.metakey !== 'Lead Status');

	allAdviserChoices$ = this.bLStaffsQuery.adviserChoices$;
	allAdviserChoicesAsObject$ = this.bLStaffsQuery.adviserChoices$.pipe(
		map((choices) =>
			util.createLookupFromList(
				(c) => c.value,
				(c) => c.display,
				choices
			)
		),
		shareReplay(1)
	);
	allStaffsChoices$ = this.bLStaffsQuery.allStaffsChoices$;
  allStaffsChoicesAsObject$ = this.bLStaffsQuery.allStaffsChoices$.pipe(
		map((choices) =>
			util.createLookupFromList(
				(c) => c.value,
				(c) => c.display,
				choices
			)
		),
		shareReplay(1)
	);
	leadGenChoices$ = this.bLStaffsQuery.leadGenChoices$;
  leadGenChoicesAsObject$ = this.bLStaffsQuery.leadGenChoices$.pipe(
		map((choices) =>
			util.createLookupFromList(
				(c) => c.value,
				(c) => c.display,
				choices
			)
		),
		shareReplay(1)
	);

	LST$: Observable<ViewDisplayValue[]> =
		this.dropdownValueQuery.orderedChoices$('LST');
	defaultLST = this.dropdownValueQuery
		.getAll({ filterBy: (x) => x.DropdownCode === 'LST' })
		?.find((x) => !!x.IsDefault)?.DropdownValue;

	constructor(
		private fb: FormBuilder,
		private sanitizer: DomSanitizer,
		private activitedRoute: ActivatedRoute,
		private renderer: Renderer2,
		protected bLStaffsQuery: BLStaffsQuery,
		public bsModalRef: BsModalRef,
		public bsModalService: BsModalService,
		protected dropdownValueQuery: DropdownValueQuery,
		private transferService: TransferService,
		private transferQuery: TransferQuery,
		private leadSearchUiStore: LeadSearchUiStore,
		private leadSearchStore: LeadSearchStore,
    private leadSearchQuery: LeadSearchQuery,
	) {}

	ngOnInit(): void {
		this.rows$ = this.dataRows$.pipe(
			map((rows) => [...rows]) // Using the spread operator to clone the array
		);

		this.tableHeight = this.sanitizer.bypassSecurityTrustStyle(
			'calc(100vh - 450px)'
		);

		this.form = this.fb.group({
			adviserType: ['', Validators.required],
			adviser: { value: '', disabled: true }, // Disabled by default
			leadStatus: { value: '', disabled: true }, // Hidden by default
		});

    

		// Watch for changes in userTag to update userAssign and leadStatus controls
		this.form
			.get('adviserType')
			.valueChanges.pipe(
				filter((adviserType) => adviserType !== null && adviserType !== '')
			)
			.pipe(takeUntil(this.destroy$))
			.subscribe((adviserType) => {
				this.form.get('adviser').patchValue('');

				if (adviserType && adviserType != '') {

          this.rows$ = this.dataRows$.pipe(
            map((rows) => [...rows]) // Using the spread operator to clone the array
          );

					this.form.get('adviser').enable();
					this.form.get('adviser').setValidators(Validators.required);
					this.form.get('adviser').updateValueAndValidity({onlySelf:true,emitEvent: true});

					if (adviserType.includes('Mortgage')) {
						this.advisers$ = this.advisers$.pipe(
							switchMap(() => this.bLStaffsQuery.MAdviserChoicesOption$)
						);

						this.columns$ = of(this.generateColumns()).pipe(
							switchMap((col) => of([...col, ...this.createColumn('Mortgage')]))
						);
					} else if (adviserType.includes('LR')) {
						this.advisers$ = this.bLStaffsQuery.LRAdviserChoicesOption$;
						this.columns$ = of(this.generateColumns()).pipe(
							switchMap((col) => of([...col, ...this.createColumn('LR')]))
						);
					} else if (adviserType.includes('FG')) {
						this.advisers$ = this.bLStaffsQuery.FGAdviserChoicesOption$;
						this.columns$ = of(this.generateColumns()).pipe(
							switchMap((col) => of([...col, ...this.createColumn('FG')]))
						);
					} else if (adviserType.includes('KS')) {
						this.advisers$ = this.bLStaffsQuery.KsAdviserChoicesOption$;
						this.columns$ = of(this.generateColumns()).pipe(
							switchMap((col) => of([...col, ...this.createColumn('KS')]))
						);
					} else if (adviserType.includes('GI')) {
						this.advisers$ = this.bLStaffsQuery.GAdviserChoicesOption$;
						this.columns$ = of(this.generateColumns()).pipe(
							switchMap((col) => of([...col, ...this.createColumn('GI')]))
						);
					} else if (adviserType.includes('Investment')) {
						this.advisers$ = this.bLStaffsQuery.InvestmentAdviserChoicesOption$;
						this.columns$ = of(this.generateColumns()).pipe(
							switchMap((col) =>
								of([...col, ...this.createColumn('Investment')])
							)
						);
					} else if (adviserType.includes('Lead')) {
						this.advisers$ = this.advisers$.pipe(
							switchMap(() =>
								this.bLStaffsQuery.leadGens$.pipe(
									map((leadGens) => {
										return leadGens.map((lead) => ({
											display: lead.FullName,
											value: lead.StaffID,
										}));
									})
								)
							)
						);

						this.columns$ = of(this.generateColumns()).pipe(
							switchMap((col) => of([...col, ...this.createColumn('Lead')]))
						);
					} else {
						this.advisers$ = of([]);
					}
				} else {
					this.form.get('adviser').disable();
				}

				const leadStatusControl = this.form.get('leadStatus');
				const enableLeadStatus = adviserType.includes('Lead');

				enableLeadStatus
					? leadStatusControl.enable({ onlySelf: true })
					: leadStatusControl.disable({ onlySelf: true });
				this.setDefaultDropdowns();
			});

		this.form
			.get('adviser')
			.valueChanges.pipe(
				filter((adviserId) => adviserId !== null && adviserId !== '')
			)
			.pipe(takeUntil(this.destroy$))
			.subscribe((adviserId) => {
				let adviserType = this.form.get('adviserType').value;

				if (adviserType === 'Lead Gen') {
					adviserType = 'Lead Gen';

          this.form.get('leadStatus').enable();
					this.form.get('leadStatus').setValidators(Validators.required);
					this.form.get('leadStatus').updateValueAndValidity();

				} else {
          this.form.get('leadStatus').disable();
					this.form.get('leadStatus').clearValidators();
					this.form.get('leadStatus').updateValueAndValidity();

					adviserType = this.form.get('adviserType').value;
				}
			});

		this.columns$ = of([...this.generateColumns(), ...this.createColumn(null)]);
	}

	setDefaultDropdowns() {
		this.form.get('leadStatus')?.setValue(this.defaultLST);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

  updateDataRowsTable(): void {
    combineLatest([this.rows$, this.leadSearchQuery.selectAll()])
      .pipe(
        take(1),
        map(([newRows, existingRows]) => {
          const updatedExistingRows = [...existingRows];

          const column = bulkTransferColumns.find((c) =>
            c.id.includes(this.form.get('adviserType').value)
          );

          newRows.forEach(newRow => {
            const existingRowIndex = existingRows.findIndex(row => row.CustomerId === newRow.CustomerId);

            if (existingRowIndex >= 0) {
              updatedExistingRows[existingRowIndex] = {
                ...newRow,
                bulk: {
                  ...newRow.bulk,
                  value: false,
                },
                ...{
                  [column.prop]: {
                    ...newRow[column.prop],
                    value: this.form.get('adviser').value,
                  },
                  ...(column.metakey.includes('Lead Gen') 
                    && this.form.get('leadStatus').value 
                    && this.form.get('leadStatus').value !== "Unchanged" 
                    && this.form.get('leadStatus').value !== ""
                    ? { LeadStatus: { ...newRow['LeadStatus'], value: this.form.get('leadStatus').value } }
                    : {}
                  ),
                },
              };
            }     
            
          });
          return updatedExistingRows;
        })
      )
      .subscribe(updatedRows => {
        this.leadSearchUiStore.setIsSelectAll(false);
        this.leadSearchStore.set(updatedRows, { idKey: 'CustomerId' } as any);
      });
  }

	cancel() {
		this.bsModalRef.hide();
	}

	processPayload() {
		let payload: TransferExportPayload;

		this.rows$
			.pipe(
				takeUntil(this.destroy$),
				filter((data) => data && data.length > 0)
			)
			.subscribe((data) => {
				let adviserType = this.form.get('adviserType').value;

				if (adviserType === 'Lead Gen') {
					adviserType = 'Lead Gen';
				} else {
					adviserType = this.form.get('adviserType').value;
				}

				payload = {
					CustomerIds: data.map((row) => row.CustomerId),
					Field: bulkTransferColumns.find((c) => c.id.includes(adviserType))
						?.id as string,
					NewValue: this.form.get('adviser').value,
					AdditionalValue: this.form.get('leadStatus').enabled
						? this.form.get('leadStatus').value
						: null,
				};
			});
		return payload;
	}

	transfer() {
    this.isTransferring = true;
		const status = this.transferQuery.getValue().status;

		if (status === ProgressStatus.STARTED) {
			return;
		}

		this.index++;

		const req = this.processPayload();

		if (!req) {
			return;
		}

    this.transferService
    .queue(req, TransferType.LEAD)
    .pipe(
      take(1),
      delay(DEFAULT_INTERVAL_BEFORE_POLLING),
      switchMap((id: string) => {

        this.updateDataRowsTable();
        this.isTransferring = false;
				this.bsModalRef.hide();

        this.transferService.downloadExport(req, TransferType.LEAD);
        
        return this.transferService.startPolling(this.transferService.getTranferStatus(id)).pipe(
          take(1),
        );
      })
    )
    .subscribe(
      (data) => {
        if (data.Status === ProgressStatus.COMPLETE) {
          this.downloadExport(data.DocumentLink);
          this.popover?.hide();
        }
      },
      (err) => {
        this.isTransferring = false;
        this.bsModalRef.hide();
      }
    );
	}

	downloadExport(url: string) {
		const name =
			this.activitedRoute.snapshot.paramMap.get('companyCode') +
			'-Customer.csv';

		const a = this.renderer.createElement('a');
		this.renderer.setStyle(a, 'display', 'none');
		this.renderer.setAttribute(a, 'href', url);
		this.renderer.setAttribute(a, 'download', name);
		a.click();
	}

	generateColumns() {
		const columns: (TableColumn & {
			metakey: string;
			controlType: controlType;
			isRequired?: boolean;
			columnId?: string;
			fieldId?: string;
		})[] = [
			{
				metakey: 'Name',
				prop: 'Name',
				name: 'Client Name',
				width: 250,
				headerClass: 'secondary-background',
				cellClass: 'font-weight-bold fixed-column',
				controlType: 'display',
				columnId: datatableUtil.formatColumnId('Name'),
				fieldId: datatableUtil.formatFieldId('Name'),
			},
		];

		return columns;
	}

	createColumn(id?: string) {
		return id
			? bulkTransferColumns.filter((col) => col.id.includes(id))
			: [
					{
						metakey: '',
						prop: '',
						name: '',
						headerClass: 'secondary-background',
						controlType: 'display',
					},
			  ];
		}
	}

/** Get `display` property from dropdownChoices */
const getDropdownValueFromChoices = (
	choices: ViewDisplayValue[],
	field: FieldMetadata<any>
) => {
	if (!field.value) {
		return '';
	}

	const choiceItem = choices?.find((x) => x.value === field.value);
	return propOr<string, ViewDisplayValue, string>(
		'',
		'display' as keyof FieldMetadata<any>,
		choiceItem
	);
};

export const bulkTransferColumns: (TableColumn & {
	metakey: string;
	id: string;
	controlType: 'display' | 'dropdown';
	isRequired?: boolean;
	columnId?: string;
	fieldId?: string;
	sortValueGetter?: (
		field: FieldMetadata<any>,
		choices?: ViewDisplayValue[]
	) => any;
})[] = [
	{
		metakey: 'L&R Adviser',
		prop: 'LRAdviser',
		id: 'LR Adviser',
		name: 'L&R Adviser',
		width: 250,
		headerClass: 'secondary-background',
		controlType: 'dropdown',
		isRequired: true,
    sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
		columnId: datatableUtil.formatColumnId('LRAdviser'),
		fieldId: datatableUtil.formatFieldId('LRAdviser'),
	},
	{
		metakey: 'Group Adviser',
		prop: 'GroupInsuranceAdviser',
		id: 'GI Adviser',
		name: 'Group Adviser',
		width: 250,
		headerClass: 'secondary-background',
		controlType: 'dropdown',
		isRequired: true,
    sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
		columnId: datatableUtil.formatColumnId('GroupInsuranceAdviser'),
		fieldId: datatableUtil.formatFieldId('GroupInsuranceAdviser'),
	},
	{
		metakey: 'Mortgage Adviser',
		prop: 'MortgageAdviser',
		id: 'Mortgage Adviser',
		name: 'Mortgage Adviser',
		width: 250,
		headerClass: 'secondary-background',
		controlType: 'dropdown',
		isRequired: true,
    sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
		columnId: datatableUtil.formatColumnId('MortgageAdviser'),
		fieldId: datatableUtil.formatFieldId('MortgageAdviser'),
	},
	{
		metakey: 'F&G Adviser',
		prop: 'FGAdviser',
		id: 'FG Adviser',
		name: 'F&G Adviser',
		width: 250,
		headerClass: 'secondary-background',
		controlType: 'dropdown',
		isRequired: true,
    sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
		columnId: datatableUtil.formatColumnId('FGAdviser'),
		fieldId: datatableUtil.formatFieldId('FGAdviser'),
	},
	{
		metakey: 'KiwiSaver Adviser',
		prop: 'KiwiSaverAdviser',
		id: 'KS Adviser',
		name: 'KiwiSaver Adviser',
		width: 250,
		headerClass: 'secondary-background',
		controlType: 'dropdown',
		isRequired: true,
    sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
		columnId: datatableUtil.formatColumnId('KiwiSaverAdviser'),
		fieldId: datatableUtil.formatFieldId('KiwiSaverAdviser'),
	},
	{
		metakey: 'Investment Adviser',
		prop: 'InvestmentAdviser',
		id: 'Investment Adviser',
		name: 'Investment Adviser',
		width: 250,
		headerClass: 'secondary-background',
		controlType: 'dropdown',
		isRequired: true,
    sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
		columnId: datatableUtil.formatColumnId('InvestmentAdviser'),
		fieldId: datatableUtil.formatFieldId('InvestmentAdviser'),
	},
	{
		metakey: 'Lead Gen',
		prop: 'LeadGen',
		id: 'Lead Gen',
		name: 'Lead Gen',
		width: 250,
		headerClass: 'secondary-background',
		controlType: 'dropdown',
		isRequired: false,
    sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
		columnId: datatableUtil.formatColumnId('Lead Gen'),
		fieldId: datatableUtil.formatFieldId('Lead Gen'),
	},
	{
		metakey: 'Lead Status',
		prop: 'LeadStatus',
		name: 'Lead Status',
		id: 'Lead Status',
		width: 250,
		headerClass: 'secondary-background',
		controlType: 'display',
		columnId: datatableUtil.formatColumnId('Lead Status'),
		fieldId: datatableUtil.formatFieldId('Lead Status'),
	},
];
