<div class="notes-table-header py-2">
	<h6 class="d-flex flex-nowrap m-0 align-items-center">
		<span class="mr-3" *ngIf="showHeader">{{ header }}</span>
		<ng-container *ngIf="hasAddNote">
			<!-- Restriction for lead -->
			<ng-container *ngIf="!hideNote">
				<ng-container *ngIf="isLead">
					<ng-container *appRestrictTo="['FALN']">
						<button
							type="button h-auto"
							class="icon-btn"
							[disabled]="isAddingNote$ | async"
							(click)="openModalAddNote()"
							id="addNote"
						>
							<i class="material-icons md-20 tap-text-color-h6">note_add</i>
						</button>
						<ng-container *ngIf="emailClientTemplateFeature$ | async">
							<button
								*ngIf="!hideEmail"
								type="button h-auto"
								class="icon-btn"
								[disabled]="isAddingNote$ | async"
								(click)="openEmailPopup()"
								id="openEmailClient"
							>
								<i class="material-icons md-20 tap-text-color-h6">email</i>
							</button>
						</ng-container>
					</ng-container>
				</ng-container>
				<!-- Restriction for current client -->
				<ng-container *ngIf="!isLead">
					<ng-container *appRestrictTo="['FAN']">
						<button
							type="button h-auto"
							class="icon-btn"
							[disabled]="isAddingNote$ | async"
							(click)="openModalAddNote()"
							id="addNote"
						>
							<i class="material-icons md-20 tap-text-color-h6">note_add</i>
						</button>
						<ng-container *ngIf="emailClientTemplateFeature$ | async">
							<button
								*ngIf="!hideEmail"
								type="button h-auto"
								class="icon-btn"
								[disabled]="isAddingNote$ | async"
								(click)="openEmailPopup()"
								id="openEmailClient"
							>
								<i class="material-icons md-20 tap-text-color-h6">email</i>
							</button>
						</ng-container>
					</ng-container>
				</ng-container>
			</ng-container>
		</ng-container>
	</h6>
</div>
<div class="cleafix">
	<table class="table">
		<thead>
			<tr class="align-items-center">
				<th class="created-by px-1 pl-0 text-truncate">Created By</th>
				<th class="created-date px-1 text-truncate">Created Date & Time</th>
				<th class="details px-1 text-truncate">Details</th>
				<th class="delete px-1 pr-0 text-truncate align-self-stretch">
					<ng-container *ngIf="isLoading">
						<div class="d-flex flex-row bd-highlight justify-content-end px-2">
							<app-manual-loader-mini
								*ngIf="isLoading"
								[isLoading]="isLoading"
							></app-manual-loader-mini>
						</div>
					</ng-container>
				</th>
			</tr>
		</thead>
		<tbody>
			<ng-container *ngIf="isLoading$ | async">
				<app-manual-loader [isLoading]="isLoading$ | async"></app-manual-loader>
			</ng-container>
			<ng-container *ngIf="!(isLoading$ | async)">
				<tr
					class="align-items-center"
					*ngFor="let note of noteList; trackBy: trackByFn; let i = index"
				>
					<td class="created-by px-1 pl-0">
						{{ note.staffName }}
					</td>
					<td class="created-date px-1">
						{{ note.createDateTime | momentDatetime: true }}
					</td>
					<td class="details px-1 note-details text-break">
						<span [innerHTML]="note.notes || '' | safeHtml: ['img', 'a'] | transformLink"></span>
					</td>
					<td class="delete px-1 pr-0 text-right">
						<ng-container *ngIf="isLead">
							<ng-container *appRestrictTo="['FDLN']">
								<app-manual-loader-mini
									style="height: 32px"
									[isLoading]="note.isDeleting"
									*ngIf="note.isDeleting"
								>
								</app-manual-loader-mini>
								<button
									type="button"
									class="btn btn-link p-1"
									*ngIf="!note.isDeleting"
									(click)="deleteConfirm(note)"
									id="deleteNote_{{ i }}"
								>
									<i class="material-icons tap-text-color-h6 md-20"> delete </i>
								</button>
							</ng-container>
						</ng-container>
						<ng-container *ngIf="!isLead">
							<ng-container *appRestrictTo="['FDN']">
								<app-manual-loader-mini
									style="height: 32px"
									[isLoading]="note.isDeleting"
									*ngIf="note.isDeleting"
								>
								</app-manual-loader-mini>
								<button
									type="button"
									class="btn btn-link p-1"
									*ngIf="!note.isDeleting"
									(click)="deleteConfirm(note)"
									id="deleteNote_{{ i }}"
								>
									<i class="material-icons tap-text-color-h6 md-20"> delete </i>
								</button>
							</ng-container>
						</ng-container>
					</td>
				</tr>
			</ng-container>
		</tbody>
	</table>
	<ng-container
		*ngIf="
			(!!count && count > itemsPerPage) ||
			(!!notes && notes?.length > itemsPerPage)
		"
	>
		<pagination
			[totalItems]="
				count && count > 0 ? count : notes && notes?.length ? notes.length : 0
			"
			[(ngModel)]="currentpage"
			[itemsPerPage]="itemsPerPage"
			[maxSize]="maxSize"
			(pageChanged)="setPage($event)"
		>
		</pagination>
	</ng-container>
</div>
