import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { mapTo, take, withLatestFrom, tap } from 'rxjs/operators';
import { AdviceProcessState, GetAllAdviserReworkCodesPascal } from 'src/app/shared/models/advice-process/advice-process.model';
import { Asset } from 'src/app/shared/models/services/assets/assets';
import { Investment } from 'src/app/shared/models/services/investments/investments.model';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';
import { DropdownValueService } from '../../../../domain/dropdown-value/dropdown-value.service';
import { FgInsurance } from '../../../../shared/models/services/fg-insurance/fg-provider-group.model';
import { Kiwisaver } from '../../../../shared/models/services/kiwisaver/kiwisaver.model';
import { LrInsurance } from '../../../../shared/models/services/lr-insurance/lr-provider-group.model';
import { Mortgage } from '../../../../shared/models/services/mortgage/mortgage-group.model';
import { PropertyAsset } from '../../../../shared/models/services/property-asset/property-asset-group.model';
import { BusinessProfileService } from '../states/business-profile.service';
import { UserQuery } from '@domain/user/user.query';
import { BusinessConfigQuery } from '@domain/business-config/business-config.query';
import { RouteService } from '@core/config/route.service';
import { PrimaryCustomerCompany } from '@shared/models/business-profile/business/business.model';
import { util } from '@util/util';
import { either, isEmpty, isNil } from 'ramda';

const dropdownCodes: string[] = [
  // primary form
  'PCT',
  'PCE',
  'PCPC',
  'PCLE',
  'PCLT',
  'PCR',
  'LS',
  'LT',
	'SAS',
  // secondary individual
  'SCR',
  // secondary company
  // secondary trust
  'SCTT',
  // professional contact
  'CT',
  'PT',
  // l&r
  'LRPS',
  'LRPF',
  'LRCS',
  'LRRS',
  'LRP',
  'LRPT',
  'LRPD',
  'LRCC',
  'LRPR',
  // mortgage
  'MP',
  'MS',
  'MT',
  'MFPE',
  'MRF',
  'MLT',
  'MRS',
  // security
  'SV1',
  'SV2',
  'ST',
  'SU',
  'STI',
  'SS',
  'PAT',
	'CLS',
	'APCRTF',
	'APCRTL',
  // f&g
  'FGS',
  'FGCS',
  'FGAS',
  'FGI',
  'FGPT',
  'FGPL',
  'FGPW',
  'FGPM',
  'FGF',
  // kiwisaver
  'KP',
  'KS',
  'KRS',
	// Investment
  'IP',
	'IS',
  'KFT',
  'KO',
  'KT',
	'IT',
	'KPIRR',
	'IPIRR',
  'IRS',
  // activity
  'AT',
  'AM',
  // Advice Process
  'AP',
  'APCRTA',
  'APCRTAS',
  'APCRTAT',
  'APCRTCT',
  'APCRTCO',
  'APCRTQI1',
	'APCRTQI6',
  'APCRTQCLR7',
  'APCRTTIR',
  'APCRTMS',
  'APCRTRL',
  'APCRTFCC',
	'APCRTYNNA',
	'APCRTQIK6',
	'CARQICAR1',
  'APCRTQIK15',
	'CCO'
];

@Injectable()
export class BusinessProfileEditResolver implements Resolve<boolean> {
  constructor(
    private dropdownValueService: DropdownValueService,
    private businessProfileService: BusinessProfileService,
    private router: Router,
    private routeService: RouteService,
		private userQuery: UserQuery,
		private businessConfigQuery: BusinessConfigQuery
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const dropdowns$ =
      this.dropdownValueService.getDropdownValueList$(dropdownCodes);
    const customerId = parseInt(route.paramMap.get('customerId'), 10);
    const service = route.paramMap.get('tab');

    const business$ = this.businessProfileService.getPrimaryCompany(
      customerId,
      service
    );
    const criteria$ = this.businessProfileService
      .getCriterias(customerId)
      .pipe(take(1));
    const activityTimeline$ = this.businessProfileService
      .getActivityTimeline(customerId)
      .pipe(take(1));

    let lrInsurance$: Observable<LrInsurance> = of(
      Object.assign(
        {},
        {
          TotalInforceAPI: 0,
          LRs: [],
        }
      )
    );
    let mortgage$: Observable<Mortgage> = of(
      Object.assign(
        {},
        {
          TotaLending: 0,
          Mortgages: [],
        }
      )
    );
    let security$: Observable<PropertyAsset> = of(
      Object.assign(
        {},
        {
          TotaLending: 0,
          CustomerServices: [],
        }
      )
    );
    let asset$: Observable<Asset> = of(
      Object.assign(
        {},
        {
          Notes: [],
          CustomerServices: [],
        }
      )
    );
    let fgInsurance$: Observable<FgInsurance> = of(
      Object.assign(
        {},
        {
          TotalValue: 0,
          CustomerServices: [],
        }
      )
    );
    let kiwisavers$: Observable<Kiwisaver[]> = of(Object.assign([], []));
    let investments$: Observable<Investment[]> = of(Object.assign([], []));
    let adviceProcess$: Observable<AdviceProcessState> = of(
      Object.assign(
        {},
        {
          complaintAdviceProcesses: [],
          blanketAdviceProcesses: [],
          kiwiSaverAdviceProcesses: [],
          lRAdviceProcesses: [],
          mortgageAdviceProcesses: [],
          fGAdviceProcesses: [],
					investmentAdviceProcesses: [],
					clientAlterationRequests: [],
					fGClaimsAdviceProcesses: [],
					lRClaimsAdviceProcesses: []
        }
      )
    );

    if (service) {
      if (service) {
        if (service === ServicesCodes.LR?.toLowerCase()) {
          lrInsurance$ = this.businessProfileService
            .getLRInsurance(customerId)
            .pipe(take(1));
        }
        if (service === ServicesCodes.Mortgage?.toLowerCase()) {
          security$ = this.businessProfileService
            .getSecurity(customerId)
            .pipe(take(1));
          mortgage$ = this.businessProfileService
            .getMortage(customerId)
            .pipe(take(1));
          asset$ = this.businessProfileService
            .getAsset(customerId)
            .pipe(take(1));
        }
        if (service === ServicesCodes.FG?.toLowerCase()) {
          fgInsurance$ = this.businessProfileService
            .getFGInsurance(customerId)
            .pipe(take(1));
        }
        if (service === ServicesCodes.KiwiSaver?.toLowerCase()) {
          kiwisavers$ = this.businessProfileService
            .getKiwisavers(customerId)
            .pipe(take(1));
        }
        if (service === ServicesCodes.Investment?.toLowerCase()) {
          investments$ = this.businessProfileService
            .getInvestments(customerId)
            .pipe(take(1));
        }
        if (service === ServicesCodes.AdviceProcess?.toLowerCase()) {
          adviceProcess$ = this.businessProfileService
            .getAdviceProcessesByPrimaryId(customerId)
            .pipe(take(1));
        }
      }
    }

    return forkJoin([
      dropdowns$,
      business$,
      criteria$,
      activityTimeline$,
      lrInsurance$,
      mortgage$,
      security$,
      fgInsurance$,
      kiwisavers$,
			investments$,
      adviceProcess$,
    ]).pipe(
			withLatestFrom(
				this.businessConfigQuery.adviserReworkFeature$,
				this.userQuery.isTapLevel$,
				this.businessProfileService.allowedInAccessibility$
			),
			tap(([[, business], adviserReworkFeature, isTapLevel, advisers]) => {
				if (!isTapLevel) {
					this.canViewClient(adviserReworkFeature, business, advisers);
				}
				if (either(isNil, isEmpty)(business)) {
					this.navigateNotFound();
				}
			}),
			mapTo(true)
		);
  }

	canViewClient(
		adviserReworkFeature: boolean,
		business: PrimaryCustomerCompany,
		advisers
	) {
		// TAPNZ-14474
		if (adviserReworkFeature) {
			const canView = GetAllAdviserReworkCodesPascal?.reduce((a, c) => {
				const hasAccessibility = !!advisers?.find(
					(x) => +x?.value === +business?.[c]
				);
				return !!a || hasAccessibility;
			}, false);
			if (!canView) {
				this.navigateNotFound();
			}
		} else {
			const altAdvisers = util.tryParseJson(business?.AltAdviser as any);
			const hasAccessibility = !!advisers?.find(
				(x) => +x?.value === +business?.Adviser || altAdvisers?.includes(+x?.value)
			);
			if (!hasAccessibility) {
				this.navigateNotFound();
			}
		}
	}

	navigateNotFound() {
		this.router.navigate(
			this.routeService.splitToRoutePath(this.routeService.notFoundPage)
		);
	}
}
