import { Moment } from 'moment';
import { Note, NoteState } from '../../notes/note.model';

export interface Kiwisaver {
	FundOwner: (string | number)[];
	Provider: string;
	Status: string;
	MemberNumber: string | number;
	StartDate: string | Moment;
	FundType: string | [];
	IRDNumber: string | number;
	Contribution: number;
	FUM: number;
	Origin: string;
	Type?: string;
	KiwisaverStatusClock: string;
	Color: string;
	CustomerServiceID: number;
	CustomerID: number;
	ServiceCode?: string;
	IsActive?: number;
	SubmittedDate: string | Moment;
	CreateDateTime?: string | Moment;
	CreatedByStaffId?: number;
	ModifiedDateTime?: string | Moment;
	ModifiedByStaffId?: number;
	CreatedByStaffLevel?: number;
	ImportID?: string | number;
	Notes: Note[];
	DocumentLink: string;
	Note: string;
	OriginalAdviser: string | number;
	PendingAdviserApproval?: boolean;
	StartTrans: string | Moment;
	CancellationDate?: string | Moment;
	RetentionStatus?: string;
}

export interface KiwisaverState {
	fundOwner: (string | number)[];
	provider: string;
	status: string;
	memberNumber: string | number;
	startDate: string | Moment;
	fundType: string;
	iRDNumber: string | number;
	contribution: number;
	fUM: number;
	origin: string;
	kiwisaverStatusClock: string;
	color: string;
	customerServiceID: number;
	customerID: number;
	submittedDate: string | Moment;
	isActive?: number;
	serviceCode?: string;
	createDateTime?: string | Moment;
	createdByStaffId?: number;
	modifiedDateTime?: string | Moment;
	modifiedByStaffId?: number;
	createdByStaffLevel?: number;
	importID?: string | number;
	notes?: NoteState[];
	documentLink?: string;
	note?: string;
	originalAdviser: string | number;
	pendingAdviserApproval?: boolean;
	startTrans: string | Moment;
	cancellationDate?: string | Moment;
	retentionStatus?: string;
}

export enum KiwiSaverTypes {
  KiwiSaver = 'KiwiSaver',
  WealthBuilder = 'Wealth Builder',
  Investment = 'Investment'
}

export const KiwiSaverErrorMessage = {
  FundOwnerMax: 'A maximum of 1 Fund Owner is able to be selected for KiwiSaver.',
  FundOwnerMin: 'A minimum of 1 Fund Owner is required.'
}

export enum KiwiSaverStatus {
	Active = 'Active'
}
