import { StoreConfig, Store } from '@datorama/akita';
import produce from 'immer';
import {
	BusinessPeopleTypes,
	PrimaryCustomerCompanyState,
} from '../../../../shared/models/business-profile/business/business.model';
import { MemberSearchState } from '../../../../shared/models/business-profile/members/members.model';
import { LrInsuranceState } from '../../../../shared/models/services/lr-insurance/lr-provider-group.model';
import { MortgageState } from '../../../../shared/models/services/mortgage/mortgage-group.model';
import { PropertyAssetState } from '../../../../shared/models/services/property-asset/property-asset-group.model';
import { FgInsuranceState } from '../../../../shared/models/services/fg-insurance/fg-provider-group.model';
import { KiwisaverState } from '../../../../shared/models/services/kiwisaver/kiwisaver.model';
import { EditHistoryState } from '../../../../shared/models/history/history.model';
import { DocumentGroupState } from '../../../../shared/models/documents/document-group.model';
import { ActivityTimelineState } from '../../../../shared/models/activity-timeline/activity-timeline.model';
import { CurrentActivityCriteriaState } from '../../../../shared/models/current-activity-criteria/current-activity-criteria.model';
import { AdviceProcessState } from 'src/app/shared/models/advice-process/advice-process.model';
import { AssetState, LiabilityState } from 'src/app/shared/models/services/assets/assets';
import { ServicesCodes } from 'src/app/shared/models/services/services.model';
import { InvestmentState } from 'src/app/shared/models/services/investments/investments.model';
import { Injectable } from "@angular/core";

export interface BusinessProfileState {
	primaryCompany: PrimaryCustomerCompanyState;
	directors: MemberSearchState[];
	shareholders: MemberSearchState[];
	others: MemberSearchState[];
	lrInsurance: LrInsuranceState;
	mortgage: MortgageState;
	propertyAsset: PropertyAssetState;
	asset: AssetState;
	liability: LiabilityState;
	fgInsurance: FgInsuranceState;
	kiwiSavers: KiwisaverState[];
	investments: InvestmentState[];
	histories: EditHistoryState[];
	documents: DocumentGroupState;
	activityTimeline: ActivityTimelineState;
	currentActivityCriterias: CurrentActivityCriteriaState[];
	adviceProcesses: AdviceProcessState;

	directorIsLoading: boolean;
	shareholderIsLoading: boolean;
	othersIsLoading: boolean;

	lrIsLoading: boolean;
	mIsLoading: boolean;
	paIsLoading: boolean;
	aIsLoading: boolean;
	fgIsLoading: boolean;
	kIsLoading: boolean;
	iIsLoading: boolean;

	historyIsLoading: boolean;
	documentIsLoading: boolean;

	adviceProcessIsLoading: boolean;
}

@Injectable()
@StoreConfig({ name: 'businessProfile' })
export class BusinessProfileStore extends Store<BusinessProfileState> {
	constructor() {
		super({
			primaryCompany: null,
			directors: null,
			shareholders: null,
			others: null,
			lrInsurance: null,
			mortgage: null,
			propertyAsset: null,
			liability: null,
			fgInsurance: null,
			kiwiSavers: null,
			investments: null,
			histories: null,
			documents: null,
			activityTimeline: null,
			currentActivityCriterias: null,

			directorIsLoading: false,
			shareholderIsLoading: false,
			othersIsLoading: false,

			lrIsLoading: false,
			mIsLoading: false,
			paIsLoading: false,
			aIsLoading: false,
			fgIsLoading: false,
			kIsLoading: false,
			iIsLoading: false,

			historyIsLoading: false,
			documentIsLoading: false,
		} as BusinessProfileState);
	}

	setBusiness(b: PrimaryCustomerCompanyState) {
		this.update(
			produce((draft) => {
				draft.primaryCompany = b;
			})
		);
	}

	setDirectors(b: any) {
		this.update(
			produce((draft) => {
				draft.directors = b;
			})
		);
	}

	setShareholders(b: any) {
		this.update(
			produce((draft) => {
				draft.shareholders = b;
			})
		);
	}

	setOthers(b: any) {
		this.update(
			produce((draft) => {
				draft.others = b;
			})
		);
	}

	// LR
	setLrInsurance(lr: LrInsuranceState) {
		this.update(
			produce((draft) => {
				draft.lrInsurance = lr;
			})
		);
	}

	setMortgage(m: MortgageState) {
		this.update(
			produce((draft) => {
				draft.mortgage = m;
			})
		);
	}

	setPropertyAsset(pa: PropertyAssetState) {
		this.update(
			produce((draft) => {
				draft.propertyAsset = pa;
			})
		);
	}

	setAsset(a: AssetState) {
		this.update(
			produce((draft) => {
				draft.asset = a;
			})
		);
	}

	setLiability(liabilities: LiabilityState) {
		this.update(
			produce((draft) => {
				draft.liability = liabilities;
			})
		);
	}

	setFgInsurance(fg: FgInsuranceState) {
		this.update(
			produce((draft) => {
				draft.fgInsurance = fg;
			})
		);
	}

	setKiwiSaver(k: KiwisaverState[]) {
		this.update({ kiwiSavers: k });
	}

	setInvestments(k: InvestmentState[]) {
		this.update({ investments: k });
	}

	setHistories(h: EditHistoryState[]) {
		this.update({ histories: h });
	}

	setDocuments(d: any) {
		this.update(
			produce((draft) => {
				draft.documents = d;
			})
		);
	}

	setActivityTimeline(at: ActivityTimelineState) {
		this.update(
			produce((draft) => {
				draft.activityTimeline = at;
			})
		);
	}

	setCriterias(c: CurrentActivityCriteriaState[]) {
		this.update(
			produce((draft) => {
				draft.currentActivityCriterias = c;
			})
		);
	}

	setAdviceProcesses(ap: AdviceProcessState) {
		this.update(
			produce((draft) => {
				draft.adviceProcesses = ap;
			})
		);
	}

	setIsLoading(isLoading: boolean, key: string) {
		this.update(
			produce((draft) => {
				if (key === BusinessPeopleTypes.Director?.toLowerCase()) {
					draft.directorIsLoading = isLoading;
				}
				if (key === BusinessPeopleTypes.Shareholder?.toLowerCase()) {
					draft.shareholderIsLoading = isLoading;
				}
				if (key === BusinessPeopleTypes.Others?.toLowerCase()) {
					draft.othersIsLoading = isLoading;
				}

				if (key === ServicesCodes.LR?.toLowerCase()) {
					draft.lrIsLoading = isLoading;
				}
				if (key === ServicesCodes.Mortgage?.toLowerCase()) {
					draft.mIsLoading = isLoading;
				}
				if (key === ServicesCodes.Property?.toLowerCase()) {
					draft.paIsLoading = isLoading;
				}
				if (key === ServicesCodes.Asset?.toLowerCase()) {
					draft.aIsLoading = isLoading;
				}
				if (key === ServicesCodes.FG?.toLowerCase()) {
					draft.fgIsLoading = isLoading;
				}
				if (key === ServicesCodes.KiwiSaver?.toLowerCase()) {
					draft.kIsLoading = isLoading;
				}
				if (key === ServicesCodes.Investment?.toLowerCase()) {
					draft.iIsLoading = isLoading;
				}

				if (key === 'document') {
					draft.documentIsLoading = isLoading;
				}
				if (key === 'history') {
					draft.historyIsLoading = isLoading;
				}
				if (key === ServicesCodes.AdviceProcess?.toLowerCase()) {
					draft.adviceProcessIsLoading = isLoading;
				}
			})
		);
	}
}
