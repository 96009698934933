import {
  Directive,
  OnDestroy,
  HostBinding,
} from "@angular/core";
import {
  BreakpointObserver
} from "@angular/cdk/layout";
import { Subscription } from "rxjs";
import { CrmBreakpoints, View, ScreenSize, HeaderBreakpoints} from './breakpoints';

@Directive({ 
  selector: "[appResponsive]",
  standalone: true
})
export class ResponsiveDirective implements OnDestroy {
  private subscription = new Subscription();
  // protected _elementClass: string[] = [];
  
  @HostBinding(`class.${View.Large}`)
  isDesktop: boolean = true;
  @HostBinding(`class.${View.Medium}`)
  isTablet: boolean = false;
  // for tablet view, 660 - 1030
  @HostBinding(`class.narrow`)
  isNarrow: boolean = false;
  @HostBinding(`class.${View.Small}`)
  isMobile: boolean = false;

  constructor(private observer: BreakpointObserver) {
  }

  ngOnInit() {
    this.subscription.unsubscribe();
    this.subscription = this.observer
      .observe([
        CrmBreakpoints.Small,
        HeaderBreakpoints.XMediumNarrow,
        HeaderBreakpoints.MediumNarrow,
        CrmBreakpoints.Medium,
        CrmBreakpoints.Large,
      ])
      .subscribe(result => {
        const breakpoints = result.breakpoints;
        // header breakpoints 
        this.isNarrow = breakpoints[HeaderBreakpoints.XMediumNarrow] && breakpoints[HeaderBreakpoints.MediumNarrow];
        
        if (breakpoints[CrmBreakpoints.Small]) {
          this.toggleView('small');
        } else if (breakpoints[CrmBreakpoints.Medium]) {
          this.toggleView('medium');
        } else {
          this.toggleView('large');
        }
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private toggleView(view: ScreenSize) {
    this.isDesktop = view === 'large';
    this.isTablet = view === 'medium';
    this.isMobile = view === 'small';
  }


}
