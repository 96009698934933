import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	Resolve,
	RouterStateSnapshot,
} from '@angular/router';
import { ServicingCalculatorSettingsService } from '@modules/tl-moat-settings/servicing-calculators/state/servicing-calculators-settings.service';
import { LinkedContact } from '@shared/models/client-profile/linked-contact/linked.contact.model';
import { Observable, forkJoin, from, of } from 'rxjs';
import {
	catchError,
	concatMap,
	delay,
	finalize,
	mapTo,
	mergeMap,
} from 'rxjs/operators';
import { DocumentListService } from 'src/app/modules/mortgage-settings/document-list/state/document-list.service';
import { ScopeOfServiceSettingsService } from 'src/app/modules/mortgage-settings/scope-of-service-settings/state/scope-of-service-settings.service';
import { SettingsTypes } from 'src/app/modules/mortgage-settings/state/mortgage-settings.model';
import { ReferralTypes } from 'src/app/modules/referral-options/state/referral-options.model';
import { ReferralOptionsService } from 'src/app/modules/referral-options/state/referral-options.service';
import { PrimaryClient, PrimaryClientState } from 'src/app/shared/models/client-profile/primary-client/primary-client.model';
import { SecondaryClient } from 'src/app/shared/models/client-profile/secondary-client/secondary-client.model';
import { SecondaryProfessional } from 'src/app/shared/models/client-profile/secondary-professional/secondary-professional.model';
import { SecondaryTrust } from 'src/app/shared/models/client-profile/secondary-trust/secondary-trust.model';
import { SecondaryBusiness } from 'src/app/shared/models/client-profile/seondary-business/secondary-business.model';
import { DropdownValueService } from '../../../../domain/dropdown-value/dropdown-value.service';
import { UserQuery } from '../../../../domain/user/user.query';
import { ClientProfileService } from '../../client-profile/states/client-profile.service';
import { AdviceProcessSectionCodes } from './../../../../shared/models/advice-process/advice-process.model';
import { ReviewApplicationService } from './application/application-steps/review-application/state/review-application.service';
import { ApplicationService } from './application/state/application.service';
import { AssetService } from './client-sop/assets-and-liabilities/state/asset/asset.service';
import { KiwiSaverService } from './client-sop/assets-and-liabilities/state/kiwiSaver/kiwiSaver.service';
import { LiabilityService } from './client-sop/assets-and-liabilities/state/liability/liability.service';
import { MortgageService } from './client-sop/assets-and-liabilities/state/mortgage/mortgage.service';
import { OtherService } from './client-sop/assets-and-liabilities/state/other/other.service';
import { PropertyService } from './client-sop/assets-and-liabilities/state/property/property.service';
import { ExpenseService } from './client-sop/expenses/state/expenses.service';
import { IncomeService } from './client-sop/income/state/income.service';
import { PeopleEntitiesService } from './client-sop/people-entities/state/people-entities.service';
import { DeclarationService } from './declaration/state/declaration.service';
import { DisclosureService } from './disclosure/state/disclosure.service';
import { MoatIntroService } from './intro/state/moat-intro.service';
import { ScopeOfServiceService } from './scope-of-services/state/scope-of-service.service';
import { CrtMortgageQuery } from './state/crt-mortgage.query';
import { CrtMortgageService } from './state/crt-mortgage.service';
import { MergeTagsService } from './state/merge-tags/merge-tags.service';
import { MortgageAdviceProcessService } from './state/mortgage-adviceprocess/mortgage-advice-process.service';
import { CrtMortgageNoteQuery } from './state/note/crt-mortgage-note.query';
import { CrtMortgageNoteService } from './state/note/crt-mortgage-note.service';
import { LoanService } from './structure-soa/loan/state/loan.service';
import { StructureSoaService } from './structure-soa/state/structure-soa.service';
import { CRTNoteState } from '@shared/models/client-review-template/note/crt-note.model';

const dropdownCodes: string[] = [
	'SCR',
	'PCE',
	'SCTT',
	'APCRTVD',
	'APCRTG',
	'SU',
	'SV1',
	'ST',
	'STI',
	'APCRTF',
	'APCRTBP',
	'APCRTYNNA',
	'APCRTKC',
	'APCRTTC',
	'APCRTI',
	'APCRTIST',
	'APCRTIT',
	'APCRTTM',
	'APCRTYN',
	'KP',
	'KFT',
	'APCRTRP',
	'APCRTA',
	'MP',
	'MLT',
	'APCRTF',
	'APCRTYN',
	'APCRTYNNA',
	'APCRTL',
	'MFPE',
	'APCRTTP',
	'APCRTTIR',
	'APCRTMS',
	'PCT',
	'AT',
];

@Injectable()
export class CrtMortgageResolver implements Resolve<boolean> {
	constructor(
		private dropdownValueService: DropdownValueService,
		private service: CrtMortgageService,
		private userQuery: UserQuery,
		private crtMortageService: CrtMortgageService,
		private mortApService: MortgageAdviceProcessService,
		private introService: MoatIntroService,
		private peopleEntitiesService: PeopleEntitiesService,
		private propertyService: PropertyService,
		private kiwiSaverService: KiwiSaverService,
		private mortgageService: MortgageService,
		private sosSettingsService: ScopeOfServiceSettingsService,
		private disclosureService: DisclosureService,
		private mtService: MergeTagsService,
		private scopeService: ScopeOfServiceService,
		private incomeService: IncomeService,
		private liabilityService: LiabilityService,
		private assetService: AssetService,
		private otherService: OtherService,
		private expenseService: ExpenseService,
		private documentListService: DocumentListService,
		private declarationService: DeclarationService,
		private moatQuery: CrtMortgageQuery,
		private structureSoaService: StructureSoaService,
		private loanService: LoanService,
		private referralOptionsService: ReferralOptionsService,
		private reviewApplicationService: ReviewApplicationService,
		private clientProfileService: ClientProfileService,
		private crtMortgageNoteService: CrtMortgageNoteService,
		private crtMortgageNoteQuery: CrtMortgageNoteQuery,
		private applicationService: ApplicationService,
		private servicingCalcService: ServicingCalculatorSettingsService
	) {}

	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> {
		// Resets
		this.scopeService.reset();
		this.propertyService.clearData();
		this.kiwiSaverService.clearData();
		this.mortgageService.clearData();
		this.assetService.clearData();
		this.liabilityService.clearData();
		this.otherService.clearData();
		this.structureSoaService.clearData();
		this.loanService.clearData();
		this.crtMortgageNoteService.clearData();
		this.expenseService.clearData();
		this.declarationService.clearData();

		const staffId = +this.userQuery.getValue().StaffID;
		const dropdowns$ =
			this.dropdownValueService.getDropdownValueList$(dropdownCodes);
		const adviceProcessId = +route.paramMap.get('adviceProcessId');
		const clientId = +route.paramMap.get('clientId');
		const isCompany =
			route.paramMap.get('customerType')?.toLowerCase() !== 'client';
		let loadMoatSections$: Observable<any>;
		const getSosEmailSettings$ = this.sosSettingsService.getEmailSettings(0);
		const getDocumentList$ = this.documentListService.getDocumentList(
			0,
			SettingsTypes.DocumentList
		);
		// const getBlanketReferrals$ = this.referralOptionsService.getReferrals(
		// 	0,
		// 	ReferralTypes.Blanket
		// );

		let primaryClient$: Observable<PrimaryClient | PrimaryClientState>;
		let secondaryClients$: Observable<SecondaryClient[]>;
		let secondaryTrust$: Observable<SecondaryTrust[]>;
		let secondaryCompanies$: Observable<SecondaryBusiness[]>;
		let secondaryProfessionals$: Observable<SecondaryProfessional[]>;
		// let crtMortgageNotesFactFind$: Observable<CRTNoteState[]>;
		let linkedContacts$: Observable<LinkedContact[]>;

		this.service.setIsCompany(isCompany);

		if (!!adviceProcessId && !!clientId) {
			this.service.setAdviceProcessId(adviceProcessId);
			this.service.setClientId(clientId);

			primaryClient$ = this.service
				.getPrimaryClient(clientId)
				.pipe(catchError(() => of(undefined)));
			secondaryClients$ = this.service
				.getSecondaryClients(clientId)
				.pipe(catchError(() => of([])));
			secondaryTrust$ = this.service
				.getSecondaryTrusts(clientId)
				.pipe(catchError(() => of([])));
			secondaryCompanies$ = this.service
				.getSecondaryCompanies(clientId)
				.pipe(catchError(() => of([])));
			secondaryProfessionals$ = this.service
				.getSecondaryProfessionals(clientId)
				.pipe(catchError(() => of([])));
			linkedContacts$ = this.service
				.getLinkedContacts(clientId)
				.pipe(catchError(() => of([])));

			// crtMortgageNotesFactFind$ = this.crtMortgageNoteService
			// 	.getNotes(
			// 		adviceProcessId,
			// 		this.crtMortgageNoteQuery.getValue().crtNoteTypes.map((x) => x.value)
			// 	)
			// 	.pipe(catchError(() => of(undefined)));

			const getProperty$ = this.propertyService.get(adviceProcessId);
			const getKiwiSaver$ = this.kiwiSaverService.get(adviceProcessId);
			const getMortgage$ = this.mortgageService.get(adviceProcessId);
			const getAsset$ = this.assetService.get(adviceProcessId);
			const getLiaility$ = this.liabilityService.get(adviceProcessId);
			const getOther$ = this.otherService.get(adviceProcessId);
			this.crtMortageService.setSectionsLoading(true);

			loadMoatSections$ = from([
				this.introService.getIntro(0),
				this.disclosureService.getDisclosureDocument(+adviceProcessId),
				this.peopleEntitiesService.getPeople(adviceProcessId, 'FPP'),
				this.peopleEntitiesService.getDependents(adviceProcessId, 'FPD'),
				this.peopleEntitiesService.getTrusts(adviceProcessId, 'FPT'),
				this.peopleEntitiesService.getCompany(adviceProcessId, 'FPC'),

				// Income
				this.incomeService.getIncomeSources(adviceProcessId),
				this.incomeService.getPreviousIncomeSources(adviceProcessId),
				this.incomeService.getRentalIncome(adviceProcessId),
				this.incomeService.getOtherIncome(adviceProcessId),

				// Expenses
				this.expenseService.getMonthlyExpense(
					adviceProcessId,
					AdviceProcessSectionCodes.MonthlyExpense
				),

				getProperty$,
				getKiwiSaver$,
				getMortgage$,
				getAsset$,
				getLiaility$,
				getOther$,
				// getBlanketReferrals$,

				// Application
				this.documentListService.getDocumentList(0, SettingsTypes.DocumentList),
				this.reviewApplicationService.getReviewAppSettings(),
			]).pipe(
				mergeMap((x) => x),
				finalize(() => {
					this.crtMortageService.setSectionsLoading(false);
				})
			);
		}

		return adviceProcessId
			? forkJoin([
					dropdowns$,

					// Get Profiles
					primaryClient$,
					secondaryClients$,
					secondaryTrust$,
					secondaryCompanies$,
					secondaryProfessionals$,
					linkedContacts$,

					// crtMortgageNotesFactFind$,

					this.mortApService.getAdviceProcess(adviceProcessId),
					this.crtMortageService.getPrimaryClient(clientId),
					this.propertyService.getPrimaryClientProperties(clientId),
					this.kiwiSaverService.getPrimaryClientKiwiSavers(clientId),
					this.mortgageService.getPrimaryClientMortgages(clientId),
					getSosEmailSettings$,
					getDocumentList$,

					// Declaration
					this.declarationService.getDeclaration(adviceProcessId),
					this.declarationService.getDeclarationDocument(
						0,
						SettingsTypes.Declaration
					),

					this.declarationService.getDeclarationEmailSettings(),
					this.declarationService.getClientFactFindSettings(),

					// Scope of Services
					this.scopeService.getSosDefault(),
					this.scopeService.getScopeOfService(
						adviceProcessId,
						AdviceProcessSectionCodes.SOS
					),
					this.scopeService.getSosTemplateSettings(),
					// Applications
					this.applicationService.get(adviceProcessId),

					// // Merge Tag
					this.mtService.getDefaultMergeTags(),
					// Application
					this.clientProfileService.getClientDocuments(clientId),
					this.structureSoaService.get(adviceProcessId),
					// Servicing Calculator Settings
					this.servicingCalcService.getServicingCalculatorList(),
			  ]).pipe(
					delay(0),
					concatMap(() =>
						this.mtService.getAdviserProviderMt(
							this.moatQuery.getValue().adviceProcess?.adviser
						)
					),
					finalize(() => {
						loadMoatSections$.subscribe();
					}),
					mapTo(true)
			  )
			: forkJoin([dropdowns$]).pipe(mapTo(true));
	}
}
