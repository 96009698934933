<div class="container-fluid tap-search-page">
	<div class="clearfix py-3">
		<!-- Filter panel -->
		<app-lead-search-form
			(toggleSearch)="onToggleSearch($event)"
		></app-lead-search-form>

		<div class="clearfix">
			<div class="row">
				<div class="col p-0">
					<ngx-datatable
						#mydatatable
						class="bootstrap"
						[style.height]="tblHeight"
						[ngClass]="{
							showSearchFields: showSearchFields,
							'disabled-table': isSearching$ | async,
							'disable-sort': !(hasRow$ | async)
						}"
						[columns]="columnsWithLink$ | async"
						[rows]="rows$ | async"
						[columnMode]="'standard'"
						[rowIdentity]="rowIdentity"
						[scrollbarH]="true"
						[trackByProp]="'CustomerId'"
						[scrollbarV]="true"
						[rowHeight]="40"
						[headerHeight]="32"
						[footerHeight]="false"
						(reorder)="reorder($event)"
						(resize)="resize($event)"
						[externalSorting]="true"
						sortType="single"
						(sort)="sort($event)"
						[rowClass]="getRowClass"
						[sorts]="sorts$ | async"
						(page)="onPage($event, mydatatable.bodyComponent.indexes)"
					>
						<ngx-datatable-column
							*ngFor="let c of columns$ | async"
							[prop]="c.prop"
							[width]="c.width"
							[resizeable]="c.metakey !== 'bulk'"
							[name]="c.name"
							[cellClass]="c.cellClass"
							[draggable]="!(c.metakey === 'Name' || c.metakey === 'bulk')"
							[frozenLeft]="c.metakey === 'Name' || c.metakey === 'bulk'"
						>
							<ng-template
								ngx-datatable-header-template
								let-column="column"
								let-sort="sortFn"
							>
								<ng-container *ngIf="c.metakey === 'bulk'">
									<div
										class="datatable-checkbox-wrapper-header"
										*ngIf="
											!(hasBulkItemCheckedEntity$ | async) ||
											!(hasBulkItemUnCheckedEntity$ | async)
										"
									>
										<input
											type="checkbox"
											[checked]="
												(isSelectAll$ | async) &&
												(hasBulkItemCheckedEntity$ | async)
											"
											[disabled]="false"
											(change)="onChangeBulkCheckValue($event)"
											class="datatable-checkbox chckbox form-control theme-crm-field-disabled"
										/>
									</div>

									<div
										class="datatable-header-cell-wrapper"
										*ngIf="
											(hasBulkItemUnCheckedEntity$ | async) &&
											(hasBulkItemCheckedEntity$ | async)
										"
									>
										<div
											class="datable-checkbox-minus datatable-header-cell-label"
										>
											<label for="box">
												<i
													class="material-icons remove"
													id="remove"
													(click)="deSelectAll()"
												>
													remove
												</i>
											</label>
										</div>
									</div>
								</ng-container>

								<ng-container *ngIf="c.metakey !== 'bulk'">
									<i
										class="material-icons draggable"
										[id]="c.columnId"
										[class.d-none]="c.metakey === 'Name'"
									>
										drag_indicator
									</i>
									<span class="datatable-header-cell-wrapper" (click)="sort()">
										<span class="datatable-header-cell-label">{{
											column.name
										}}</span>
									</span>
								</ng-container>
							</ng-template>

							<ng-template
								ngx-datatable-cell-template
								let-row="row"
								let-value="value"
								let-rowIndex="rowIndex"
							>
								<!-- Tried to provide template for each column but [Object, object] bug happens so made it this way -->
								<ng-container [ngSwitch]="c.controlType">
									<app-display
										*ngSwitchCase="'display'"
										[route]="route(c.metakey, row)"
										[value]="value.value"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
									>
									</app-display>

									<div class="datable-checkbox-value">
										<app-datatable-checkbox
											*ngSwitchCase="'checkbox'"
											id="transfer-cbox-{{ rowIdentity }}"
											[hasControls]="false"
											[disabled]="
												!value?.value &&
												(checkedRows$ | async).length >= transferRowsLimit
											"
											[value]="value?.value"
											(onChangeValue)="onChangeCheckValue($event, row)"
										>
										</app-datatable-checkbox>
									</div>

									<app-money
										*ngSwitchCase="'money'"
										[apiRoundOffOnUI]="true"
										[roundOff]="'0.00'"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
										[value]="value.value"
										[restrict]="value.restrict"
										[isRequired]="c.isRequired"
										[isEditable]="value.editable"
									></app-money>

									<app-display
										*ngSwitchCase="'display-date'"
										[route]="route(c.metakey, row)"
										[value]="value.value | momentDatetime"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
									>
									</app-display>
									<app-long-display
										*ngSwitchCase="'long-display'"
										[value]="value.value"
									></app-long-display>
									
									<app-dropdown
										*ngSwitchCase="'dropdown'"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
										[value]="value.value"
										[restrict]="value.restrict"
										[isLoading]="
											(cellsLoading$ | async)[row.CustomerId + '-' + c.metakey]
										"
										[isEditing]="
											(cellsEditing$ | async)[row.CustomerId + '-' + c.metakey]
										"
										[tempValue]="
											(cellsTempvalue$ | async)[
												row.CustomerId + '-' + c.metakey
											]
										"
										[isRequired]="c.isRequired"
										[isEditable]="value.editable"
										[choices]="c.choices"
										[choicesObject]="c.choicesAsObject"
										[hasSort]="
											c.metakey === 'Adviser' ||
											c.metakey === 'LR Adviser' ||
											c.metakey === 'GI Adviser' ||
											c.metakey === 'Mortgage Adviser' ||
											c.metakey === 'FG Adviser' ||
											c.metakey === 'KS Adviser' ||
											c.metakey === 'Investment Adviser' ||
											c.metakey === 'Lead Gen'
										"
										[allChoices]="
											c.metakey === 'Adviser' ||
											c.metakey === 'LR Adviser' ||
											c.metakey === 'GI Adviser' ||
											c.metakey === 'Mortgage Adviser' ||
											c.metakey === 'FG Adviser' ||
											c.metakey === 'KS Adviser' ||
											c.metakey === 'Investment Adviser' ||
											c.metakey === 'Lead Gen'
												? (allStaffsChoices$ | async)
												: undefined
										"
										[allChoicesObject]="
											c.metakey === 'Adviser' ||
											c.metakey === 'LR Adviser' ||
											c.metakey === 'GI Adviser' ||
											c.metakey === 'Mortgage Adviser' ||
											c.metakey === 'FG Adviser' ||
											c.metakey === 'KS Adviser' ||
											c.metakey === 'Investment Adviser' ||
											c.metakey === 'Lead Gen'
												? (allStaffsChoicesObject$ | async)
												: undefined
										"
										(editEvent)="edit(row.CustomerId, c.metakey)"
										(cancelEvent)="cancel(row.CustomerId, c.metakey)"
										(saveEvent)="saveField(row, c.metakey, value.key, $event)"
										(fieldUpdateEvent)="
											setTempValue(row.CustomerId, c.metakey, $event)
										"
									></app-dropdown>
									<app-textbox
										*ngSwitchCase="'textbox'"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
										[value]="value.value"
										[restrict]="value.restrict"
										[isLoading]="
											(cellsLoading$ | async)[row.CustomerId + '-' + c.metakey]
										"
										[isEditing]="
											(cellsEditing$ | async)[row.CustomerId + '-' + c.metakey]
										"
										[tempValue]="
											(cellsTempvalue$ | async)[
												row.CustomerId + '-' + c.metakey
											]
										"
										[isRequired]="c.isRequired"
										[isEditable]="value.editable"
										(editEvent)="edit(row.CustomerId, c.metakey)"
										(cancelEvent)="cancel(row.CustomerId, c.metakey)"
										(saveEvent)="saveField(row, c.metakey, value.key, $event)"
										(fieldUpdateEvent)="
											setTempValue(row.CustomerId, c.metakey, $event)
										"
									></app-textbox>
									<app-address
										*ngSwitchCase="'address'"
										[route]="route(c.metakey, row)"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
										[value]="value.value"
										[restrict]="value.restrict"
										[isLoading]="
											(cellsLoading$ | async)[row.CustomerId + '-' + c.metakey]
										"
										[isEditing]="
											(cellsEditing$ | async)[row.CustomerId + '-' + c.metakey]
										"
										[tempValue]="
											(cellsTempvalue$ | async)[
												row.CustomerId + '-' + c.metakey
											]
										"
										[isRequired]="c.isRequired"
										(editEvent)="edit(row.CustomerId, c.metakey)"
										(cancelEvent)="cancel(row.CustomerId, c.metakey)"
										(saveEvent)="saveField(row, c.metakey, value.key, $event)"
										(fieldUpdateEvent)="
											setTempValue(row.CustomerId, c.metakey, $event)
										"
									></app-address>
									<app-date
										*ngSwitchCase="'date'"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
										[value]="value.value"
										[restrict]="value.restrict"
										[isLoading]="
											(cellsLoading$ | async)[row.CustomerId + '-' + c.metakey]
										"
										[isEditing]="
											(cellsEditing$ | async)[row.CustomerId + '-' + c.metakey]
										"
										[tempValue]="
											(cellsTempvalue$ | async)[
												row.CustomerId + '-' + c.metakey
											]
										"
										[isRequired]="c.isRequired"
										[isEditable]="value.editable"
										(editEvent)="edit(row.CustomerId, c.metakey)"
										(cancelEvent)="cancel(row.CustomerId, c.metakey)"
										(saveEvent)="saveField(row, c.metakey, value.key, $event)"
										(fieldUpdateEvent)="
											setTempValue(row.CustomerId, c.metakey, $event)
										"
									></app-date>

									<ng-container
										*ngSwitchCase="'activity'"
										[ngSwitch]="c.metakey"
									>
										<app-activity-detail
											*ngSwitchCase="'Client Next Activity'"
											[index]="rowIndex"
											[fieldId]="c.fieldId"
											[cellClass]="getCellClass(value, row)"
											[value]="value.value"
											[restrict]="value.restrict"
											[activityId]="row.ClientNextActivityId"
											[isLoading]="
												(cellsLoading$ | async)[
													row.CustomerId + '-' + c.metakey
												]
											"
											(editActivityEvent)="
												updateClientNextActivityField(row.CustomerId)
											"
											[permissionsToComplete]="['FECA']"
										></app-activity-detail>
										<app-activity-detail
											*ngSwitchCase="'User Next Activity'"
											[index]="rowIndex"
											[fieldId]="c.fieldId"
											[cellClass]="getCellClass(value, row)"
											[value]="value.value"
											[restrict]="value.restrict"
											[activityId]="row.UserNextActivityId"
											[isLoading]="
												(cellsLoading$ | async)[
													row.CustomerId + '-' + c.metakey
												]
											"
											(editActivityEvent)="
												updateUserNextActivityField(row.CustomerId)
											"
											[permissionsToComplete]="['FECA']"
										></app-activity-detail>
									</ng-container>
									<app-note
										*ngSwitchCase="'note'"
										[index]="rowIndex"
										[fieldId]="c.fieldId"
										[value]="value.value"
										[activityType]="row.IsCompany ? 'PCC' : 'PCI'"
										[isActivity]="true"
										[restrict]="value.restrict"
										[isEditable]="value.editable"
										(updateNoteEvent)="updateNoteField(row.CustomerId, $event)"
										[customerId]="row.CustomerId"
									>
									</app-note>
									<ng-container *ngSwitchCase="'multiselect'">
										<app-multiselect
											*ngIf="
												c.metakey === 'Alt. Adviser';
												else defaultMultiselect
											"
											[index]="rowIndex"
											[fieldId]="c.fieldId"
											[value]="value.value"
											[restrict]="value.restrict"
											[isLoading]="
												(cellsLoading$ | async)[
													row.CustomerServiceID + '-' + c.metakey
												]
											"
											[isEditing]="
												(cellsEditing$ | async)[
													row.CustomerServiceID + '-' + c.metakey
												]
											"
											[isRequired]="c.isRequired"
											[choices]="allUnfilteredAdvisersChoices$ | async"
											[choicesObject]="
												allUnfilteredAdvisersChoicesAsObject$ | async
											"
											[fieldName]="c.name"
											(saveEvent)="saveField(row, c.metakey, value.key, $event)"
										>
										</app-multiselect>
										<ng-template #defaultMultiselect>
											<app-multiselect
												[index]="rowIndex"
												[fieldId]="c.fieldId"
												[value]="value.value"
												[restrict]="value.restrict"
												[isLoading]="
													(cellsLoading$ | async)[
														row.CustomerServiceID + '-' + c.metakey
													]
												"
												[isEditing]="
													(cellsEditing$ | async)[
														row.CustomerServiceID + '-' + c.metakey
													]
												"
												[isRequired]="c.isRequired"
												[choices]="availableStaffsChoices$ | async"
												[choicesObject]="
													availableStaffsChoicesAsObject$ | async
												"
												[fieldName]="c.name"
												(saveEvent)="
													saveField(row, c.metakey, value.key, $event)
												"
											>
											</app-multiselect>
										</ng-template>
									</ng-container>
								</ng-container>
							</ng-template>
						</ngx-datatable-column>
						<ngx-datatable-column
							[prop]="linkColumn.prop"
							[name]="linkColumn.name"
							[width]="linkColumn.width"
							[resizeable]="false"
							headerClass="link-header"
							[cellClass]="'link-ellipses'"
						>
							<ng-template
								ngx-datatable-cell-template
								let-value="value"
								let-row="row"
								let-rowIndex="rowIndex"
							>
								<ng-container *ngIf="!(rowsLoading$ | async)[row.CustomerId]">
									<div
										class="dropdown d-flex"
										dropdown
										container="body"
										placement="bottom right"
									>
										<button
											id="toggleMoreBtn_{{ rowIndex }}"
											class="tap-btn tap-btn-outline tap-text-primary px-0 py-0"
											dropdownToggle
										>
											<i
												class="material-icons icon-btn md-18 d-flex align-items-center px-0 py-0 w-auto w-auto"
											>
												more_vert
											</i>
										</button>
										<ul class="dropdown-menu dropdown-menu-right" *dropdownMenu>
											<ng-container *appRestrictTo="['FALA']">
												<a
													id="addActivityBtn_{{ rowIndex }}"
													class="dropdown-item clickable tap-tex-primary"
													(click)="createClientNextActivity(row.CustomerId)"
												>
													Add Activity
												</a>
											</ng-container>
											<a
												id="editBtn_{{ rowIndex }}"
												*ngIf="row.link.IsCompany"
												[routerLink]="businessRoute(row.CustomerId)"
												class="dropdown-item clickable tap-tex-primary"
											>
												Edit
											</a>
											<a
												id="editBtn_{{ rowIndex }}"
												*ngIf="!row.link.IsCompany"
												[routerLink]="clientRoute(row.CustomerId)"
												class="dropdown-item clickable tap-tex-primary"
											>
												Edit
											</a>
											<a
												id="deleteBtn_{{ rowIndex }}"
												*appRestrictTo="['FDL']"
												class="dropdown-item clickable tap-tex-primary"
												(click)="delete(row.CustomerId)"
											>
												Delete
											</a>
										</ul>
									</div>
								</ng-container>
								<app-manual-loader-mini
									*ngIf="(rowsLoading$ | async)[row.CustomerId]"
									[isLoading]="(rowsLoading$ | async)[row.CustomerId]"
								>
								</app-manual-loader-mini>
							</ng-template>
						</ngx-datatable-column>
					</ngx-datatable>
				</div>
			</div>
		</div>
	</div>
</div>
