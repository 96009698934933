import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	QueryList,
	SimpleChanges,
	ViewChild,
	ViewChildren,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import * as R from 'ramda';
import { Observable, Observer, Subject, of } from 'rxjs';
import { concatMap, map, take, takeUntil, tap } from 'rxjs/operators';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { FgInsuranceService } from 'src/app/modules/crm/business-profile/states/pipelines/fg-insurance.service';
import { ViewDisplayValue } from '../../../shared/models/_general/display-value.viewmodel';
import { ConfirmModalComponent } from '../../modal/confirm-modal/confirm-modal.component';
import { DocumentGroupState } from '../../models/documents/document-group.model';
import {
	FgCustomerServiceGroupState,
	FgCustomerServiceState,
} from '../../models/services/fg-insurance/fg-insurance.model';
import { FgInsuranceState } from '../../models/services/fg-insurance/fg-provider-group.model';
import { ServicesCodes } from '../../models/services/services.model';
import { FgInsuranceServiceFormComponent } from '../fg-insurance-service-form/fg-insurance-service-form.component';
import { LinkDocumentComponent } from '../link-document/link-document.component';
import { documentUtil } from '../service-utils/document.util';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { FormBuilder, FormGroup } from '@angular/forms';
import MomentUtil from '@util/moment.util';
import { PrimaryClientState } from '@shared/models/client-profile/primary-client/primary-client.model';
import { FgInsuranceMapper } from '@shared/models/services/fg-insurance/fg-insurance.mapper';
import { AdviceProcessCode } from '@shared/models/advice-process/advice-process.model';
import { mainGroupSorter } from '../service-utils/fg-sorter.util';
@Component({
	selector: 'app-fg-insurance-service',
	templateUrl: './fg-insurance-service.component.html',
	styleUrls: ['./fg-insurance-service.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FgInsuranceServiceComponent
	implements OnInit, OnChanges, OnDestroy
{
	private onDestroy$ = new Subject<void>();

	public bsModalRef: BsModalRef;

	@Input() isLead: boolean;
	@Input() isCompany: boolean;
	@Input() isLoading = false;
	@Input() documentIsLoading: boolean;

	@Input() addFn$: (data) => Observable<any>;
	@Input() saveFn$: (data) => Observable<any>;
	@Input() deleteFn$: (data) => Observable<any>;
	@Input() archiveFn$: ({
		data,
		provider,
		policyNumber,
		isArchive,
	}) => Observable<any>;
	@Input() deleteNoteFn$: ({
		id,
		data,
		provider,
		policyNumber,
	}) => Observable<any>;
	@Input() updateDocumentFn$: ({
		id,
		customerId,
		provider,
		policyNumber,
		customerServiceID,
	}) => Observable<any>;
	@Input() downloadLink$: ({ documentID }) => Observable<string>;
	@Input() addNoteFn$: ({ data, provider, policyNumber }) => Observable<any>;

	@Input() fgInsurance: FgInsuranceState;
	@Input() customerId: number;

	@Input() status: ViewDisplayValue[];
	@Input() claimStatus: ViewDisplayValue[];
	@Input() accountStatus: ViewDisplayValue[];
	@Input() insurers: ViewDisplayValue[];
	@Input() policyTypes: ViewDisplayValue[];
	@Input() policyWriters: ViewDisplayValue[];
	@Input() policyOwners: ViewDisplayValue[];
	@Input() policyLines: ViewDisplayValue[];
	@Input() paymentMethods: ViewDisplayValue[];
	@Input() frequencies: ViewDisplayValue[];

	@Input() document: DocumentGroupState;

	@Input() primaryClient: PrimaryClientState;
	@Input() primaryCompany: any;

	@Input() updateNextReviewFn$: (data) => Observable<any>;

	fgInsuranceState: FgInsuranceState;

	activeGroupNavId: string;

	isAddNewFg = false;
	isAddNewMta = false;
	isAddNewRenewal = false;
	isSaving = false;
	isSavingDocument = false;
	isAddNewGroup = false;
	isShowArchived = false;
	hasSelectedDocument = false;
	isSavingNRD = false;

	activeInsurer: string;
	activeFG:FgCustomerServiceGroupState;
	activePolicyNumber: string;
	activeGroupName: string;
	activeCustomerServiceId: number;
	activeSourceId: number;

	prevInsurer: string;
	prevPolicyNumber: string;
	prevCurrentCustomerServiceId: number;

	currentLinkedDocument: any;
	currentLinkedDocumentId: string;

	// fgList: FgCustomerServiceState[];
	fgList: FgCustomerServiceGroupState[];

	/**
	 * original FG when creating Renewal
	 */
	private renewalFGOriginal: FgCustomerServiceState | null;

	@ViewChild('sidebarNav') sidebarNav: TabsetComponent;
	@ViewChildren('fgForms') fgForms: QueryList<FgInsuranceServiceFormComponent>;
	private addForm: FgInsuranceServiceFormComponent;
	@ViewChild('addForm', { static: false }) set content(
		content: FgInsuranceServiceFormComponent
	) {
		if (content) {
			this.addForm = content;
		}
	}

	newMtaGroupName: string = null;
	newfGPolicyNumber: string = null;
	newfGPolicyNumberSuffix: string = null;
	isAddRenewal = false;

	fgFormState: FgCustomerServiceState;

	popupCount = 0;

	newFgRenewal: any = null;
	// csList: FgCustomerServiceState[];
	csList: FgCustomerServiceGroupState[];

	originalActiveFg: any = null;

	form: FormGroup;

	//Input needed for Claims
	@Input() adviser: number;
	@Input() claimsFeature:boolean = false;
	@Input() fGClaimsList:any;
	@Input() redirectToAP$: (data:{id?:string,serviceCode?:string,customerServiceId?:string}) => Observable<any>;
	@Input() openAPModalFn$:(data)=>void;

	constructor(
		private modalService: BsModalService,
		private cd: ChangeDetectorRef,
		private fgService: FgInsuranceService,
		private loggerService: LoggerService,
		private fb: FormBuilder,
	) {
		this.form = this.fb.group({
			fGNextReview: [''],
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes) {
			if (changes.primaryClient || changes.primaryCompany) {
				this.setFGReviewDate();
			}
			if (changes.fgInsurance && changes.fgInsurance.currentValue !== null) {
				this.fgInsuranceState = Object.assign({}, this.fgInsurance);
				this.fgInsuranceState.fGs = this.fgInsuranceState.fGs.map(fg => {
					const allInactive = fg.customerServices.every(cs => cs.isActive === 0);
					const anyActive = fg.customerServices.some(cs => cs.isActive === 1);
					const allArchived = fg.customerServices.every(cs => cs.isActive === 2);
					const isActive = allInactive ? 0 : (anyActive ? 1 : (allArchived ? 2 : null));
					return {...fg, isActive};
				})?.sort(mainGroupSorter);

				this.fgList = this.fgInsuranceState.fGs;

				if (
					this.fgInsuranceState &&
					this.fgInsuranceState.fGs?.length > 0 &&
					!this.activeInsurer
				) {
					this.setInit();
				}

				if (changes.customerId) {
					this.setInit();
				}

				if (this.fgInsuranceState && this.fgInsuranceState.fGs?.length === 1) {
					this.setInit();
				}
			}

			if (
				changes.documentIsLoading &&
				changes.documentIsLoading.currentValue === false
			) {
				const getFg = this.fgInsuranceState.fGs?.find(
					(x) =>
						x?.provider === this.activeInsurer &&
						x?.policyNumber === this.activePolicyNumber &&
						x?.sourceId === this.activeSourceId
				);
				if (!!getFg?.linkDocument) {
					this.currentLinkedDocumentId = getFg?.linkDocument;
					this.currentLinkedDocument = documentUtil(
						this.document,
						+this.currentLinkedDocumentId
					);
				} else {
					this.currentLinkedDocumentId = null;
					this.currentLinkedDocument = null;
				}
			}

			if (
				changes.fgInsurance &&
				changes.fgInsurance.previousValue &&
				this.activeInsurer
			) {
				let i = -1;
				this.fgInsuranceState.fGs?.filter((fg, index) => {
					if (
						fg.provider === this.activeInsurer &&
						fg.policyNumber === this.activePolicyNumber &&
						(fg.sourceId === this.activeSourceId ||
							fg.customerServices.find(
								(cs) => cs.customerServiceID === this.activeCustomerServiceId
							))
					) {
						return (i = index);
					}
				});

				if (i === -1) {
					// set last tab to active
					return setTimeout(() => {
						const tab =
							this.sidebarNav?.tabs?.[this.fgInsuranceState?.fGs?.length - 1];
						if (tab) {
							tab.active = true;
						}
					}, 0);
				} else {
					return setTimeout(() => {
						const tab = this.sidebarNav?.tabs?.[i];
						if (tab) {
							tab.active = true;
						}
					}, 0);
				}
			}
		}
	}

	ngOnInit() {
		if (!this.fgInsurance) {
			this.fgInsuranceState = Object.assign(
				{},
				{
					totalInforceApi: 0,
					fGs: [],
				}
			);
			this.fgList = [];
		}
		if (!this.fgInsurance || this.fgInsurance.fGs.length < 1) {
			this.activeGroupName = '';
			this.activeInsurer = '';
			this.activeFG = null;
			this.activePolicyNumber = '';

			this.currentLinkedDocumentId = '';
			this.currentLinkedDocument = null;
			this.activeCustomerServiceId = null;
		} else {
			this.setInit();
		}
		this.setFGReviewDate();
	}

	setFGReviewDate() {
		const data = this.isCompany ? this.primaryCompany : this.primaryClient;
		const fGNextReview = FgInsuranceMapper.mapFgNextReviewView(
			data?.fGNextReview
		);
		this.form.reset({ fGNextReview });
	}

	downloadLink = (data) => {
		this.downloadLink$({ documentID: data })
			.pipe(
				tap((res) => {
					window.location.href = res;
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	};

	refresh() {
		this.cd.detectChanges();
	}

	trackByFn(index: number, item: FgCustomerServiceState) {
		return index;
	}

	trackById(index: number, item: FgCustomerServiceState) {
		return item.customerServiceID;
	}

	setInit() {
		const firstFG =
			this.fgInsuranceState?.fGs?.length > 0
				? this.fgInsuranceState?.fGs[0]
				: null;
		this.activeGroupName = firstFG?.provider + ': ' + firstFG?.policyNumber;
		this.originalActiveFg = firstFG?.customerServices.find(
			(fg) => fg.tracking === 'Original' || fg.tracking === 'Renewal'
		);
		this.activeInsurer = firstFG?.provider;
		this.activeFG = firstFG;
		this.activePolicyNumber = firstFG?.policyNumber;
		this.activeCustomerServiceId = this.originalActiveFg?.customerServiceID;
		this.activeSourceId = this.originalActiveFg?.customerServiceID;
		this.currentLinkedDocumentId = firstFG?.linkDocument;
		this.currentLinkedDocument = documentUtil(
			this.document,
			+this.currentLinkedDocumentId
		);
	}

	selectFgTab = (item) => {
		const id = !!item.id ? item.id : item.customerServiceId;
		const fgGroup = this.fgList?.find((fg) =>
			fg.customerServices?.some((cs) => cs.customerServiceID === id)
		);

		if (fgGroup) {
			const groupNavId = fgGroup.provider + '-' + fgGroup.policyNumber;
			setTimeout(() => {
				const selectedTabIndex = this.sidebarNav?.tabs?.findIndex(
					(x) => x.id === groupNavId
				);
				if (selectedTabIndex > -1) {
					this.sidebarNav.tabs[selectedTabIndex].active = true;
					const form = this.fgForms?.find(
						(lr) => lr.form.value.customerServiceID === id
					);
					if (form) {
						setTimeout(() => {
							form.formProp.nativeElement.scrollIntoView({
								behavior: 'smooth',
							});
						}, 1);
					}
				}
			}, 1000);
		}
	};

	/**
	 * Show/Hide archived services
	 * @param isShowArchived boolean
	 */
	showArchived(isShowArchived: boolean) {
		this.isShowArchived = isShowArchived;
	}

	selectTab() {
		let count = 0;
		if (this.isShowArchived) {
			count = this.fgInsuranceState.fGs?.filter((x) => x).length - 1;
		} else {
			count = this.fgInsuranceState.fGs?.filter((x) => x).length - 1;
		}

		setTimeout(() => {
			this.sidebarNav.tabs[count].active = true;
		}, 2);
	}

	addNewFGGroup() {
		this.isAddNewFg = true;
		this.isAddNewGroup = true;
		this.fgFormState = null;
		this.activeGroupName = '';
		this.activeInsurer = '';
		this.activeFG = null;
		this.activePolicyNumber = '';
		this.popupCount = 0;
		this.fgFormState = null;
		this.activeSourceId = null;

		// Should add new data on the list
		this.fgInsuranceState.fGs = [
			...(this.fgInsuranceState?.fGs ?? []),
			{
				provider: '',
				policyNumber: '',
				customerServices: [],
				startDate: null,
				aPI: 0,
				firstPolicyDate: null,
				linkDocument: '',
				color: 'dark',
			},
		];

		this.currentLinkedDocumentId = '';
		this.currentLinkedDocument = null;

		setTimeout(() => {
			this.sidebarNav.tabs[this.sidebarNav.tabs.length - 1].active = true;
		}, 0);
	}

	selectGroupConfirm(fg) {
		if (!fg.provider) {
			return;
		}

		this.activeSourceId = fg.sourceId;

		const selectedTab = fg.provider + '-' + fg.policyNumber;

		if (
			!this.isAddNewGroup &&
			this.isAddNewFg &&
			(fg.provider !== this.activeInsurer ||
				fg.policyNumber !== this.activePolicyNumber) &&
			this.popupCount < 1
		) {
			this.selectGroup(selectedTab);
		}

		if (this.isAddNewGroup) {
			this.activeInsurer = '';
			this.activePolicyNumber = '';
			this.selectGroup(selectedTab);
		}

		this.currentLinkedDocumentId = fg.linkDocument;
		this.currentLinkedDocument = this.currentLinkedDocumentId
			? documentUtil(this.document, +this.currentLinkedDocumentId)
			: null;

		this.activeInsurer = fg.provider;
		this.activeFG = fg;
		this.activePolicyNumber = fg.policyNumber;

		const originalRenewCs = fg.customerServices?.find(
			(fg) => fg.tracking === 'Original' || fg.tracking === 'Renewal'
		);

		if (originalRenewCs) {
			this.originalActiveFg = originalRenewCs;
			this.activeCustomerServiceId = originalRenewCs.customerServiceID;
		}
	}

	selectGroup(selectedTab: string) {
		this.popupCount++;
		const groupNavId = this.activeInsurer + '-' + this.activePolicyNumber;

		const confirm = new Observable((obs: Observer<any>) => {
			if (this.isAddNewFg && this.isAddNewGroup) {
				this.fgInsuranceState.fGs?.pop();
			}
			this.isAddNewFg = false;
			this.isAddNewGroup = false;
			this.popupCount = 0;
			this.refresh();

			setTimeout(() => {
				this.sidebarNav.tabs.find((x) => x.id === selectedTab).active = true;
			}, 2);
			obs.complete();
		});

		const decline = new Observable((obs: Observer<any>) => {
			this.popupCount = 0;
			this.refresh();
			obs.complete();
		});

		const initState = {
			header: 'Discard Confirmation',
			message: `Current information will be discarded?`,
			confirm$: confirm,
			decline$: decline,
		};

		this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});

		if (this.isAddNewFg && this.isAddNewGroup) {
			setTimeout(() => {
				this.sidebarNav.tabs[this.sidebarNav.tabs.length - 1].active = true;
			}, 2);
		} else {
			setTimeout(() => {
				this.sidebarNav.tabs.find((x) => x.id === groupNavId).active = true;
			}, 2);
		}
	}

	cancelAddNewGroup(cancel: boolean) {
		this.isAddNewFg = cancel;
		this.isAddNewGroup = false;
		this.sidebarNav.tabs[0].active = true;
		this.fgInsuranceState.fGs?.pop();
	}

	createFg(data) {
		data.customerID = this.customerId;

		setTimeout(() => {
			this.addForm.formSaving(true);
			this.addForm.editForm(false);
		}, 0);

		this.addFn$({
			data,
			linkDocumentId: this.currentLinkedDocumentId,
			isAddNewRenewal: this.isAddNewRenewal,
			reference: this.prevInsurer,
			referenceNo: this.prevPolicyNumber,
			customerServiceId: this.prevCurrentCustomerServiceId,
		})
			.pipe(
				tap((x) => {
					if (x) {
						if (this.isAddNewGroup) {
							this.currentLinkedDocument = null;
						}
						this.isAddNewGroup = false;
						this.isAddNewFg = false;
						this.isSaving = false;
						this.popupCount = 0;
						this.activeInsurer = data.insurer;
						setTimeout(()=>{
							this.activeFG = this.fgInsuranceState.fGs?.find(
								(x)=>
									data.insurer===x.provider&&
									x.policyNumber===data.fGPolicyNumber
							);
						},0)
						this.activePolicyNumber = data.fGPolicyNumber;
						if (data.tracking === 'Original' || data.tracking === 'Renewal') {
							this.activeSourceId = x[0]?.newFGId || 0;
							this.activeCustomerServiceId = data.customerServiceID;
						}
						this.isAddNewRenewal = false;
						this.refresh();
					}
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(() => {
				this.addForm.formSaving(false);
			});
	}

	saveFg(data) {
		this.fgForms
			?.find((fg) => fg.form.value.customerServiceID === data.customerServiceID)
			.formSaving(true);

		this.isAddNewGroup = false;

		data.customerID = this.customerId;
		data.documentLink = this.currentLinkedDocumentId;

		this.saveFn$(data)
			.pipe(
				tap((x) => {
					if (x) {
						this.activeInsurer = data.insurer;
						this.activeFG = data;
						this.activePolicyNumber = data.fGPolicyNumber;
						this.activeCustomerServiceId = data.customerServiceID;
						this.isAddNewFg = false;
						this.popupCount = 0;
						this.refresh();

						setTimeout(() => {
							this.isSaving = false;
							this.activeFG = this.fgInsuranceState.fGs?.find(
								(x)=>
									data.insurer===x.provider&&
									x.policyNumber===data.fGPolicyNumber
							);

						}, 0);
					}
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(
				() => {
					this.fgForms
						?.find(
							(fg) => fg.form.value.customerServiceID === data.customerServiceID
						)
						.formSaving(false);

					this.fgForms
						?.find(
							(fg) => fg.form.value.customerServiceID === data.customerServiceID
						)
						.editForm(false);
				},
				() => {
					this.fgForms
						?.find(
							(fg) => fg.form.value.customerServiceID === data.customerServiceID
						)
						.formSaving(false);
				}
			);
	}

	deleteFg(data) {
		this.fgForms
			?.find(
				(fg) =>
					fg.form.getRawValue().customerServiceID === data.customerServiceID
			)
			.formSaving(true);
		const provider = this.activeInsurer;
		const policyNumber = this.activePolicyNumber;
		data.customerID = this.customerId;

		this.deleteFn$({ data, provider, policyNumber })
			.pipe(
				tap((x) => {
					if (x) {
						this.fgForms
							?.find(
								(fg) =>
									fg.form.value.customerServiceID === data.customerServiceID
							)
							.formSaving(false);
						this.activeInsurer = provider;
						this.activePolicyNumber = policyNumber;

						this.fgInsuranceState.fGs?.filter((fg) => {
							if (
								fg.provider === this.activeInsurer &&
								fg.policyNumber === this.activePolicyNumber &&
								fg.customerServices?.find(
									(cs) => cs.customerServiceID === data.customerServiceID
								)
							) {
								fg.customerServices?.filter((cs, i) => {
									if (i === 0) {
										this.activeCustomerServiceId =
											fg.customerServices[1]?.customerServiceID;
									} else {
										this.activeCustomerServiceId =
											fg.customerServices[0]?.customerServiceID;
									}
								});
							}
						});
					}
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(() => {
				this.fgForms
					?.find(
						(x) => x.form.value.customerServiceID === data.customerServiceID
					)
					.formSaving(false);
				return setTimeout(() => {
					const tab = this.sidebarNav?.tabs?.[0];
					if (tab) {
						tab.active = true;
					}
				}, 0);
			});
	}

	confirmModal(
		data,
		addMode: boolean,
		isDelete: boolean,
		msg: string = 'There is no primary alteration in this group. Are you sure you want to continue?'
	) {
		const confirm = new Observable((obs: Observer<any>) => {
			if (!isDelete) {
				addMode ? this.createFg(data) : this.saveFg(data);
			} else {
				this.deleteFg(data);
			}
			obs.complete();
		});

		const decline = new Observable((obs: Observer<any>) => {
			if (addMode) {
				this.addForm.editForm(true);
			}
			obs.complete();
		});

		const initState = {
			header: 'Confirmation Message',
			message: msg,
			confirm$: confirm,
			decline$: decline,
		};

		this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
			class: 'modal-dialog-centered modal-dialog',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});

		this.refresh();
	}

	confirmCreate(data) {
		if (
			(data.insurer !== this.activeInsurer ||
				data.fGPolicyNumber !== this.activePolicyNumber) &&
			data.tracking === 'MTA'
		) {
			this.loggerService.Warning(
				{},
				"Can't add MTA FG with different policy insurer or number from the original FG"
			);
		} else {
			this.createFg(data);
		}
	}

	archiveFg(req: { fg; isArchive }) {
		this.fgForms
			?.find(
				(fg) => fg.form.value.customerServiceID === req.fg.customerServiceID
			)
			?.formSaving(true);
		const provider = this.activeInsurer;
		const policyNumber = this.activePolicyNumber;
		const newReq = Object.assign({}, req.fg);
		newReq.customerID = this.customerId;

		this.archiveFn$({
			data: newReq,
			provider,
			policyNumber,
			isArchive: req.isArchive,
		})
			.pipe(
				tap((x) => {
					if (x) {
						if (
							req.fg.customerServiceID ===
							this.originalActiveFg.customerServiceID
						) {
							this.originalActiveFg = {
								...this.originalActiveFg,
								isActive: req.isArchive ? 2 : 1,
							};
						}
						this.fgForms
							?.find(
								(lr) =>
									lr.form.value.customerServiceID === req.fg.customerServiceID
							)
							?.formSaving(false);
					}
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(() => {
				this.fgForms
					?.find(
						(lr) => lr.form.value.customerServiceID === req.fg.customerServiceID
					)
					.formSaving(false);
			});
	}

	deleteNote = (req: { noteId; data }) => {
		this.fgForms
			?.find(
				(fg) => fg.form.value.customerServiceID === req.data.customerServiceID
			)
			.formSaving(true);
		const provider = this.activeInsurer;
		const policyNumber = this.activePolicyNumber;
		req.data.customerID = this.customerId;
		this.deleteNoteFn$({
			id: req.noteId,
			data: req.data,
			provider: this.activeInsurer,
			policyNumber: this.activePolicyNumber,
		})
			.pipe(
				tap((x) => {
					if (x) {
						this.fgForms
							?.find(
								(fg) =>
									fg.form.value.customerServiceID === req.data.customerServiceID
							)
							.formSaving(false);
						this.activeInsurer = provider;
						this.activePolicyNumber = policyNumber;
					}
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(() => {
				this.fgForms
					?.find(
						(lr) =>
							lr.form.value.customerServiceID === req.data.customerServiceID
					)
					.formSaving(false);
			});
	};

	upsertDocument(id) {
		this.documentIsLoading = true;
		const provider = this.activeInsurer;
		const policyNumber = this.activePolicyNumber;
		const customerServiceID = this.activeCustomerServiceId;
		this.cd.detectChanges();

		this.updateDocumentFn$({
			id,
			customerId: this.customerId,
			provider,
			policyNumber,
			customerServiceID,
		}).subscribe(
			(x) => {
				if (x) {
					this.currentLinkedDocumentId = id;
					this.currentLinkedDocument = id
						? documentUtil(this.document, +this.currentLinkedDocumentId)
						: null;
					this.cd.detectChanges();
				}
			},
			() => {
				this.documentIsLoading = false;
				this.cd.detectChanges();
			},
			() => {
				this.documentIsLoading = false;
				this.cd.detectChanges();
			}
		);
	}

	linkDocument() {
		const initState = {
			selectedDetail: 'Link Document',
			document: this.document,
			initialSelectedTab: ServicesCodes.FG?.toLowerCase(),
		};
		this.bsModalRef = this.modalService.show(LinkDocumentComponent, {
			class: 'modal-dialog-centered modal-lg',
			initialState: initState,
			ignoreBackdropClick: true,
			keyboard: false,
		});
		this.bsModalRef.content.getSelectedDocumentValue$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(
				(doc) => {
					this.documentIsLoading = true;
					this.upsertDocument(doc.id);
					this.hasSelectedDocument = true;
				},
				() => {
					this.hasSelectedDocument = false;
				},
				() => {}
			);
	}

	unlinkedDocument() {
		this.documentIsLoading = true;
		this.upsertDocument('');
		this.hasSelectedDocument = false;
		this.cd.markForCheck();
	}

	addNote(data) {
		data.customerID = this.customerId;
		this.addNoteFn$(data)
			.pipe(
				tap((x) => {
					if (x) {
						setTimeout(() => {
							this.isSaving = false;
							this.fgForms
								?.find(
									(fg) =>
										fg.form.value.customerServiceID === data.customerServiceID
								)
								.formSaving(false);
						}, 0);
					}
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(
				() => {
					this.fgForms
						?.find(
							(x) => x.form.value.customerServiceID === data.customerServiceID
						)
						.formSaving(false);
				},
				() => {
					const lr = this.fgForms?.find(
						(x) => x.form.value.customerServiceID === data.customerServiceID
					);
					if (lr) {
						lr.formSaving(false);
						lr.prepData();
					}
				},
				() => {
					this.fgForms
						?.find(
							(x) => x.form.value.customerServiceID === data.customerServiceID
						)
						.formSaving(false);
				}
			);
	}

	confirmSaveFG(req: { data; isAddNote }) {
		if (
			(req.data.insurer !== this.activeInsurer ||
				req.data.fGPolicyNumber !== this.activePolicyNumber) &&
			(req.data.tracking === 'Original' || req.data.tracking === 'Renewal')
		) {
			const fg = this.fgInsuranceState.fGs?.find(
				(fg) =>
					fg.provider === this.activeInsurer &&
					fg.policyNumber === this.activePolicyNumber &&
					fg.customerServices.find(
						(cs) => cs.customerServiceID === this.activeCustomerServiceId
					)
			);

			if (
				fg &&
				fg.customerServices?.filter((fg) => fg.tracking === 'MTA').length > 0
			) {
				this.loggerService.Warning(
					{},
					"Can't edit policy insurer or policy number due to existing MTA(s)"
				);
			} else {
				this.saveFg(req.data);
			}
		} else if (
			(req.data.insurer !== this.activeInsurer ||
				req.data.fGPolicyNumber !== this.activePolicyNumber) &&
			req.data.tracking === 'MTA'
		) {
			this.loggerService.Warning(
				{},
				"Can't edit MTA FG with different policy insurer or number from the original FG"
			);
		} else {
			this.saveFg(req.data);
		}
	}

	addMta() {
		this.fgFormState = null;
		const fgState = this.fgInsuranceState.fGs?.find(
			(fg) =>
				fg.provider === this.activeInsurer &&
				fg.policyNumber === this.activePolicyNumber &&
				fg.customerServices.find(
					(cs) => cs.customerServiceID === this.activeCustomerServiceId
				)
		);

		if (fgState) {
			let formState = null;
			const state = fgState.customerServices?.find(
				(cs) =>
					cs.policyType &&
					cs.policyType?.toLowerCase() === 'primary' &&
					cs.status &&
					cs.status?.toLowerCase() === 'inforce'
			);
			const state2 = fgState.customerServices?.find(
				(cs) => cs.policyType && cs.policyType?.toLowerCase() === 'primary'
			);

			state
				? (formState = state)
				: state2
				? (formState = state2)
				: (formState = fgState.customerServices.find(
						(fg) => fg.tracking === 'Original' || fg.tracking === 'Renewal'
				  ));

			this.fgFormState = { ...formState, required: null };
		}
		this.isAddNewFg = true;
	}

	addNewRenewal() {
		this.isAddNewFg = true;
		this.isAddNewGroup = true;
		this.isAddNewRenewal = true;
		this.activeGroupName = '';
		this.popupCount = 0;

		if (this.fgInsuranceState.fGs.length === 1) {
			const customerServiceID =
				this.fgInsuranceState.fGs[0].customerServices?.[0]?.customerServiceID;
			this.activeCustomerServiceId =
				this.fgInsuranceState.fGs[0].sourceId || customerServiceID;
		}

		const newFgRenewal = this.fgInsuranceState.fGs.find(
			(fg) =>
				fg.provider === this.activeInsurer &&
				fg.policyNumber === this.activePolicyNumber &&
				fg.customerServices.find(
					(cs) => cs.customerServiceID === this.activeCustomerServiceId
				)
		);

		this.fgInsuranceState.fGs.forEach((f) => {
			if (
				this.renewalFGOriginal &&
				f.policyNumber !== this.activePolicyNumber &&
				f.customerServices.find(
					(fg) => fg.customerServiceID === this.activeCustomerServiceId
				)
			) {
				return;
			}
			this.renewalFGOriginal = f.customerServices.find(
				(s) => s.tracking === 'Original'
			);
		});
		// if we find original FG
		if (this.renewalFGOriginal) {
			// make it imutable
			this.renewalFGOriginal = R.clone(this.renewalFGOriginal);
		}

		const fgRenewal = newFgRenewal.customerServices.find(
			(fg) => fg.tracking === 'Original' || fg.tracking === 'Renewal'
		);

		this.csList = this.fgInsuranceState.fGs;
		this.fgFormState = fgRenewal;

		this.prevInsurer = this.activeInsurer;
		this.prevPolicyNumber = this.activePolicyNumber;
		this.prevCurrentCustomerServiceId = fgRenewal.customerServiceID;

		this.activeInsurer = '';
		this.activePolicyNumber = '';
		// this.activeCustomerServiceId = null;
		this.activeSourceId = fgRenewal.customerServiceID;

		// Should add new data on the list
		this.fgInsuranceState.fGs = [
			...(this.fgInsuranceState?.fGs || []),
			{
				provider: this.activeInsurer,
				policyNumber: this.activePolicyNumber,
				customerServices: [],
				startDate: null,
				aPI: 0,
				firstPolicyDate: null,
				linkDocument: '',
				color: 'green',
			},
		];

		this.currentLinkedDocumentId = '';
		this.currentLinkedDocument = null;

		setTimeout(() => {
			this.sidebarNav.tabs[this.fgInsuranceState.fGs.length - 1].active = true;
		}, 0);
	}

	cancelAddMta() {
		this.isAddNewFg = false;
		this.popupCount = 0;
	}

	cancelNewGroup() {
		this.isAddNewGroup = false;
		this.isAddNewFg = false;
		this.isAddNewRenewal = false;
		this.popupCount = 0;

		// Removes last data on array
		this.fgInsuranceState.fGs.pop();

		this.renewalFGOriginal = null;

		this.sidebarNav.tabs[0].active = true;
	}

	newRenewal() {
		this.addNewFGGroup();
	}

	updateReviewDateFn$ = (event: MatDatepickerInputEvent<Moment>) =>
		of(event).pipe(
			map((x) => (!!x?.value ? MomentUtil.formatToServerDate(x.value) : '')),
			concatMap(this.updateNextReviewFn$)
		);

	openClaimsAdvice(){
		const {clientsInvolved,customerServiceId} = this.getInfoOnActiveFG()
		const selectedInfo = {
			clientsInvolved: clientsInvolved,
			customerID: this.customerId,
			processCode: AdviceProcessCode.FGClaim,
			adviser: this.adviser,
			customerServiceId:customerServiceId,
		}
		this.openAPModalFn$(selectedInfo);
	}

	getInfoOnActiveFG(){
		const customerServiceId = [];
		const customerServiceList:FgCustomerServiceState[] = []
		this.activeFG?.customerServices.map(item=>{
			customerServiceList.push(FgInsuranceMapper.mapToView(item));
			customerServiceId.push(item.customerServiceID);
		});
		const clientsInvolved = this.uniqPolicyOwners(customerServiceList);
		return {customerServiceId,clientsInvolved}
	}

	uniqPolicyOwners(data:FgCustomerServiceState[]){
		return R.pipe(
			R.map(R.prop('policyOwners')),
			R.flatten,
			R.reject(R.isNil),
			R.uniq
		)(data);
	}

	updateReviewDate() {
		this.isSavingNRD = true;
		const formControl = this.form.get('fGNextReview');
		const formatedDate = !!formControl?.value
			? MomentUtil.formatToServerDate(formControl?.value)
			: null;

		// if (formatedDate) {
			const currentErrors = formControl.errors;
			// If there are errors and 'dateError' is present, remove it
			if (currentErrors && currentErrors.dateError) {
				delete currentErrors.dateError;

				// Set the updated errors
				formControl.setErrors(
					Object.keys(currentErrors).length > 0 ? currentErrors : null
				);
			}
			return this.updateNextReviewFn$(formatedDate)
				.pipe(take(1))
				.subscribe(() => {
					this.isSavingNRD = false;
				});
		// } else {
		// 	this.setFGReviewDate();
		// 	this.isSavingNRD = false;
		// }
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
		this.onDestroy$.unsubscribe();
	}
}
