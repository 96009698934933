<div class="container-fluid">
	<div class="row">
		<div class="col plus-icon">
			<div
				class="custom-sidebar-nav__divider-header d-flex align-items-center py-2"
			>
				<div class="col px-0 form-inline">
					<span class="font-weight-bold px-0 sidebar__title"
						>Total API:
						{{ fgInsuranceState.totalInforceApi | currency }}
					</span>
					<!-- Add New Group -->
					<ng-container *appRestrictTo="['FAS']">
						<button
							type="button"
							[disabled]="isAddNewFg || isLoading"
							(click)="addNewFGGroup()"
							class="add-button-sidenav border-0 font-weight-bold md-20 tap-text-color-h6"
							id="fgAddNew"
						>
							<i class="material-icons add-button-sidenav-plus">add</i>
						</button>
					</ng-container>
				</div>
			</div>
		</div>

		<div class="col-auto float-right px-5 pb-3">
			<div class="row">
				<!-- <ng-container *ngIf="isLoading">
          <div
            class="d-flex flex-row bd-highlight justify-content-end pt-1"
          >
            <app-manual-loader-mini
              *ngIf="isLoading"
              [isLoading]="isLoading"
            ></app-manual-loader-mini>
          </div>
        </ng-container> -->

				<ng-container *ngIf="claimsFeature">
					<div
						*ngIf="
							!isAddNewGroup &&
							fgInsuranceState &&
							fgInsuranceState?.fGs?.length > 0 &&
							activeFG?.color==='green'
						"
					>
						<button
							type="button"
							class="tap-btn tap-btn--default btn-sm mr-2"
							[disabled]="isSaving"
							(click)="openClaimsAdvice()"
							id="lrCreateClaim"
						>
							Create Claim
						</button>
					</div>
				</ng-container>

				<div *ngIf="!isLoading && (isSavingDocument || documentIsLoading)">
					<div class="d-flex flex-row bd-highlight justify-content-end pt-1">
						<app-manual-loader-mini
							class="d-flex"
							[isLoading]="isSavingDocument || documentIsLoading"
						>
						</app-manual-loader-mini>
					</div>
				</div>


				<!-- Link Document -->
				<div *ngIf="!isAddNewGroup && currentLinkedDocument && fgInsuranceState?.fGs?.length > 0">
					<div class="btn btn-sm document-button-container px-2 mr-1">
						<i class="material-icons md-20 document-font-color">description</i>

						<span
							*ngIf="currentLinkedDocument.id"
							class="wrap-document document-font-color cursor-pointer"
							data-toggle="tooltip"
							title="{{
								'Policy Schedule - ' +
									(currentLinkedDocument.createDateTime | momentDatetime)
							}}"
							(click)="downloadLink(currentLinkedDocument.id)"
							target="_blank"
						>
							<span>{{
								"Policy Schedule - " +
									(currentLinkedDocument.createDateTime | momentDatetime)
							}}</span>
						</span>

						<div
							class="wrap-document document-font-color"
							*ngIf="!currentLinkedDocument.documentLink"
						>
							<span>{{
								"Policy Schedule - " +
									(currentLinkedDocument.createDateTime | momentDatetime)
							}}</span>
						</div>

						<ng-container *appRestrictTo="['FES']">
							<button
								type="button"
								class="border-0 document-button p-0 pl-1"
								(click)="unlinkedDocument()"
								[disabled]="isSaving"
								id="fgUnlinkedDocument"
							>
								<span class="material-icons pt-1"> close </span>
							</button>
						</ng-container>
					</div>

					<ng-container *appRestrictTo="['FES']">
						<button
							type="button"
							class="tap-btn tap-btn--default btn-sm mr-2"
							[disabled]="isSaving"
							(click)="linkDocument()"
							id="fgReplaceDocument"
						>
							Replace Policy Document
						</button>
					</ng-container>
				</div>

				

				<ng-container *appRestrictTo="['FES']">
					<button
						*ngIf="!isAddNewGroup && !currentLinkedDocument && fgInsuranceState?.fGs?.length > 0"
						type="button"
						class="tap-btn tap-btn--default btn-sm mr-2"
						[disabled]="isAddNewFg && isSaving"
						(click)="linkDocument()"
						id="fglinkDocument"
					>
						Link Document
					</button>

					<div *ngIf="(!isAddNewGroup && fgInsuranceState.fGs?.length > 0 && originalActiveFg && originalActiveFg.isActive !== 2)">
						<button
							type="button"
							class="tap-btn tap-btn--default btn-sm mr-2"
							[disabled]="isAddNewFg && isSaving"
							(click)="addNewRenewal()"
							id="renewalBtn"
						>
							Renewal
						</button>
						<button
							type="button"
							class="tap-btn tap-btn--default btn-sm mr-2"
							[disabled]="isAddNewFg && isSaving"
							(click)="addMta()"
							id="mtaBtn"
						>
							MTA
						</button>
					</div>
				</ng-container>

				<!-- Show Archive -->
				<ng-container *appRestrictTo="['FVAS']">
					<div *ngIf="!isAddNewGroup && fgInsuranceState?.fGs?.length > 0">
						<label for="showArchive" class="mr-2">Show Archived</label>
						<app-checkbox
							class="clickable d-inline-block"
							style="transform: translate(0px, 4px)"
						>
							<input
								type="checkbox"
								[checked]="!!isShowArchived"
								name="showArchive"
								id="fgShowArchive"
								class="checkbox"
								(change)="showArchived($event.target.checked)"
							/>
						</app-checkbox>
					</div>
				</ng-container>
			</div>
		</div>
	</div>

	<div class="row next-review-date p-0 m-0 mb-3" [formGroup]="form">
		<div class="col-5 p-0 d-flex align-items-center">
			<label>Next Review:</label>
		</div>
		<div class="col-7 p-0">
			<app-date-input
				textboxId="fGNextReview"
				formControlName="fGNextReview"
				textboxClass="theme-crm-field-disabled"
				placeholder="dd/mm/yyyy"
				style="flex: 1"
				useActionButtons="true"
				[isSavingDate]="isSavingNRD"
				(onSaveDateEvent)="updateReviewDate()"
			>
			</app-date-input>
		</div>
	</div>

	<div class="row main-content">
		<div class="col">
			<ng-container *ngIf="isLoading">
				<app-manual-loader
					[isLoading]="isLoading"
					[opts]="{
						lines: 9,
						length: 27,
						width: 20,
						radius: 45,
						scale: 0.5
					}"
				></app-manual-loader>
			</ng-container>
			<ng-container *ngIf="!isLoading">
				<tabset #sidebarNav class="tap-tabset-sidebar" [vertical]="true">
					<ng-template
						ngFor
						let-fg
						[ngForOf]="fgInsuranceState.fGs"
						let-i="index"
						[ngForTrackBy]="trackByFn"
					>
						<ng-container
							*ngIf="
								fg?.isActive !== 2 || (fg?.isActive === 2 && isShowArchived)
							"
						>
							<tab
								(selectTab)="selectGroupConfirm(fg)"
								id="{{ fg.provider + '-' + fg.policyNumber }}"
								[customClass]="
									fg.color === 'green'
										? 'nav-item--success'
										: fg.color === 'yellow'
										? 'nav-item--warning'
										: fg.color === 'red'
										? 'nav-item--danger'
										: fg.color === 'dark'
										? 'nav-item--dark'
										: ''
								"
							>
								<div class="col">
									<ng-template tabHeading>
										<ng-container *ngIf="fg.policyNumber; else tabHeadingAdd">
											<strong>{{
												(fg.provider??' ') + " - " + fg.policyNumber
											}}</strong>
											<br />
											<span class="font-italic">
												Value: {{ fg.aPI ? (fg.aPI | currency) : "0" }}
											</span>
											<br />
											<span
												>First Policy Date:
												{{ fg?.firstPolicyDate || "" | momentDatetime }}
											</span>
											<i
												class="material-icons arrow-right md-20 d-flex align-items-center"
												>chevron_right</i
											>
										</ng-container>
									</ng-template>
								</div>
								<div class="col px-0 px-md-3 pr-lg-0">
									<div class="container-fluid px-0 px-md-3 pr-lg-0">
										<ng-container
											*ngIf="
												isAddNewFg &&
												((fg.provider === this.activeInsurer &&
													fg.policyNumber === this.activePolicyNumber) ||
													(!fg.provider && !fg.policyNumber))
											"
										>
											<app-fg-insurance-service-form
												#addForm
												id="fg_{{ i }}"
												[formId]="fg.provider + '-' + fg.policyNumber"
												[isLead]="isLead"
												[isCompany]="isCompany"
												[addMode]="true"
												[isNewGroup]="isAddNewGroup"
												[isAddNewRenewal]="isAddNewRenewal"
												[fg]="fgFormState"
												[csList]="csList"
												[isSaving]="isSaving"
												[policyOwners]="policyOwners"
												[policyLines]="policyLines"
												[insurers]="insurers"
												[statuses]="status"
												[claimStatus]="claimStatus"
												[accountStatus]="accountStatus"
												[paymentMethods]="paymentMethods"
												[frequencies]="frequencies"
												[policyTypes]="policyTypes"
												[policyWriters]="policyWriters"
												[groupName]="
													fg.policyNumber
														? (fg.provider??' ') + ': ' + fg.policyNumber
														: 'New Group'
												"
												[activeCustomerServiceId]="activeCustomerServiceId"
												[activeSourceId]="activeSourceId"
												(saveEvent)="confirmCreate($event)"
												(cancelAddEvent)="
													fg.provider && fg.policyNumber && !isAddNewRenewal
														? cancelAddMta()
														: cancelNewGroup()
												"
											>
											</app-fg-insurance-service-form>
										</ng-container>

										<ng-container>
											<ng-template
												ngFor
												let-data
												[ngForOf]="fg.customerServices"
												let-i="index"
												[ngForTrackBy]="trackById"
											>
												<ng-container
													*ngIf="
														data.isActive !== 2 ||
														(data?.isActive === 2 && isShowArchived)
													"
												>
													<app-fg-insurance-service-form
														#fgForms
														id="fg_{{ i }}"
														[formId]="fg.provider + '-' + fg.policyNumber + i"
														[isLead]="isLead"
														[isCompany]="isCompany"
														[class.d-none]="
															data.isActive === 2 && !isShowArchived
														"
														[addMode]="false"
														[fg]="data"
														[csList]="fgInsuranceState.fGs"
														[isSaving]="isSaving"
														[policyOwners]="policyOwners"
														[policyLines]="policyLines"
														[insurers]="insurers"
														[statuses]="status"
														[claimStatus]="claimStatus"
														[accountStatus]="accountStatus"
														[paymentMethods]="paymentMethods"
														[frequencies]="frequencies"
														[policyTypes]="policyTypes"
														[policyWriters]="policyWriters"
														[groupName]="(fg.provider??' ')+ ': ' + fg.policyNumber"
														[activeCustomerServiceId]="activeCustomerServiceId"
														[activeSourceId]="activeSourceId"
														(saveEvent)="confirmSaveFG($event)"
														(deleteEvent)="deleteFg($event)"
														(archiveEvent)="archiveFg($event)"
														(deleteNoteEvent)="deleteNote($event)"
														(addNoteEvent)="addNote($event)"
														[fGClaims]="fGClaimsList?.[data.customerServiceID]??[]"
														[redirectToAP$]="redirectToAP$"
														[claimsFeature]="claimsFeature"
													>
													</app-fg-insurance-service-form>
												</ng-container>
											</ng-template>
										</ng-container>
									</div>
								</div>
							</tab>
						</ng-container>
					</ng-template>
				</tabset>
			</ng-container>
		</div>
	</div>
</div>

<ng-template #tabHeadingAdd>
	<strong>Add F&G Insurance</strong><br />
	<i class="material-icons arrow-right md-20 d-flex align-items-center"
		>chevron_right</i
	>
</ng-template>
