import {
  IncomeSourceState,
  incomeDetailsList,
} from './income-source-details.model';
import { FactFindComputationState } from './factfind-computation.model';
import { convertToAnnual } from '../../../../modules/crm/client-review-template/income-budget/calculations/annual-conversion';
import { numUtil } from '../../../../util/util';

export class IncomeSourceMapper {
  public static mapIncomeToView(data: IncomeSourceState) {
    return {
      ...data,
      incomeEarner: +data.incomeEarner,
      occupation: data.occupation,
      employment: data.employment,
      totalGrossIncome: +numUtil.formatToNumCurrency(data.totalGrossIncome),
    };
  }

  /** Map people to dropdown CRM */
  public static mapCRMPeopleToDd(list) {
    if (!list) {
      return [];
    }
    return list?.map((x) => {
      return {
        display: x.firstName + ' ' + x.lastName,
        value: x.customerID,
				isActive: false,
				isDefault: false,
        isCRT: false,
        customerType: x?.customerType,
      };
    });
  }

  /** Map people to dropdown CRT */
  public static mapCRTPeopleToDd(list) {
    if (!list) {
      return [];
    }
    return list?.map((x) => {
      return {
        display: x.name,
        value: x.customerId,
				isActive: false,
				isDefault: false,
        isCRT: true,
        customerType: x?.customerType,
      };
    });
  }

  /** Mapping for view */
  public static mapToView(data: any) {
    return {
      ...data,
      incomeEarner: +data.incomeEarner,
      incomeType: data.incomeType,
      employer: data.employer,
      industry: data.industry,
      occupation: data.occupation,
      employment: data.employment,
      kiwiSaverContribution: data.kiwiSaverContribution,
      details: data.details,
      detailsList: [],
    };
  }

  /**
   * Map to update
   */
  public static mapToUpsert(
    data: IncomeSourceState,
    cid?: number,
    adviceProcessId?: number
  ): IncomeSourceState {
    let totalGross = 0;
    data.detailsList?.map(
      (dl) =>
        (totalGross += +convertToAnnual(
          +dl.incomeSourceValue,
          dl.incomeSourceFrequency
        ))
    );
    return {
      ...data,
      cRTId: cid,
      adviceProcessId,
      incomeEarner: +data.incomeEarner,
      incomeType: data.incomeType,
      employer: data.employer,
      industry: data.industry,
      occupation: data.occupation,
      employment: data.employment,
      kiwiSaverContribution: data.kiwiSaverContribution,
      totalGrossIncome: +numUtil.formatToNumCurrency(totalGross),
      details: JSON.stringify(data.detailsList),
      detailsList: data.detailsList?.map(
        (dl) =>
          // tslint:disable-next-line: no-angle-bracket-type-assertion
          <incomeDetailsList> {
            incomeSourceFrequency: dl.incomeSourceFrequency,
            incomeSourceType: dl.incomeSourceType,
            incomeSourceValue: +dl.incomeSourceValue,
          }
      ),
      factFindComputation: {
        // adviceProcessId,
        sectionCode: 'FFC',
        totalPropertyValue: 0,
        totalAssetValue: 0,
        overallTotalGrossIncome: 0,
        totalNetRentalIncome: 0,
        annualTaxableJointIncome: 0,
        monthlyTaxableJointIncome: 0,
        monthlyAfterTaxIncome: 0,
      },
      sectionCode: 'FII',
    };
  }

  /**
   * Map to tempFactFind
   */
  public static mapTempFactFind(
    data: IncomeSourceState,
    tempFactFind?: FactFindComputationState
  ): IncomeSourceState {
    return {
      ...data,
      factFindComputation: {
        ...tempFactFind,
        // adviceProcessId: data.adviceProcessId,
        sectionCode: 'FFC',
      },
    };
  }
}
