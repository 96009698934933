<div
	class="col-12 pt-4 sos-template-settings__froala position-relative"
	[class.view-mode]="!isTapLevel"
>
	<app-wysiwyg
		#contentEditor
		[id]="'sosTemplateSettingsEditor'"
		[shortcodes]="shortcodes"
		[content]="currentTemplate"
		[isTapLevel]="isTapLevel"
		[allowAllHtmlTagsAttrs]="true"
		[undoReset$]="undoReset$"
		(undoStack)="getUndoStack($event)"
		designClass="pdf-design-v2-froala"
	></app-wysiwyg>

	<div class="row pl-3 pr-1 mb-2">
		<div class="col-12 col-lg-6 pl-0">
			<ng-container *ngIf="isTapLevel && !isLoading">
				<button
					type="button"
					class="btn btn-primary my-2 tap-bg-primary"
					(click)="reloadTemplate($event)"
					*ngIf="!isPreview"
					[disabled]="isLoading"
				>
					RELOAD TEMPLATE
				</button>
			</ng-container>
		</div>
		<div class="col-12 col-lg-6 text-right pr-2">
			<ng-container *ngIf="isTapLevel && !isLoading">
				<button
					type="button"
					class="btn btn-primary my-2 mx-1 tap-bg-primary"
					(click)="backToEdit()"
					*ngIf="isPreview"
				>
					BACK TO EDIT
				</button>
				<button
					type="button"
					class="btn btn-primary my-2 mx-1 tap-bg-primary"
					(click)="showPreview()"
					*ngIf="!isPreview"
					[disabled]="isLoading"
				>
					PREVIEW
				</button>
				<button
					type="button"
					class="btn btn-primary my-2 mx-1 tap-bg-primary"
					(click)="save()"
					*ngIf="!isPreview"
					[disabled]="isLoading"
				>
					SAVE
				</button>
			</ng-container>

			<ng-container *ngIf="isLoading">
				<app-manual-loader-mini
					[isLoading]="isLoading"
				></app-manual-loader-mini>
			</ng-container>
		</div>
	</div>
</div>
