export interface ReferralOptionsModel {
	SettingsID: number;
	ReferenceID: number;
	Type: string;
	Status: boolean;
	IsDefault: boolean;
	ReferralService: string;
	ReferralValues: {
		ReferralLink: string;
		IsChecked: boolean;
		Priority: number;
	};
}
export interface ReferralState {
	settingsID: number;
	referenceID: number;
	type: string;
	status: boolean;
	isDefault: boolean;
	referralService: string;
	referralValues: {
		referralLink: string;
		isChecked: boolean;
		priority: number;
	};
	order?: number;
	isChecked?: boolean;
	ReferralLink?: string;
}

export enum ReferralTypes {
	Referral = 'RO',
	Blanket = 'ROB',
	Compare = 'ROC',
	FocusMedia = 'ROFM',
}
