import { TableColumn } from '@swimlane/ngx-datatable';
import { produce } from 'immer';
import * as R from 'ramda';
import { FieldMetadata } from '../../../../shared/dynamic-field/field-metadata.model';
import { ViewDisplayValue } from '../../../../shared/models/_general/display-value.viewmodel';
import { Row } from './states/fg-insurance.model';
import { FgInsurance } from './fg-insurance-request.model';
import { computeUtil, util } from '../../../../util/util';
import { datatableUtil } from 'src/app/util/datatable.util';

/**
 * possible metakeys
 */
export type Metakey =
	| 'Name'
	| 'Adviser'
	| 'FG Adviser'
	| 'Contact Status'
	| 'Policy Owners'
	| 'Premium'
	| 'Status'
	| 'Claim Status'
	| 'Policy Lines'
	| 'FG Policy Number'
	| 'Broker Fee'
	| 'Admin Fee'
	| 'Renewal date'
	| 'Account Status'
	| 'Insurer'
	| 'Total Premium'
	| 'Issue date'
	| 'Inception date'
	| 'Policy Type'
	| 'Policy Writer'
	| 'Quote number'
	| 'Policy End Date'
	| 'First Policy date'
	| 'Original Adviser'
	| 'Required'
	| 'FG Status Clock'
	| 'Client Next Activity'
	| 'User Next Activity'
	| 'Last Note'
	| 'Additional Contacts'
	| 'Lead Origin'
  | 'Tracking';

/** possible control types. Move this somewhere more appropriate. */
export type controlType =
	| 'display'
	| 'long-display'
	| 'textbox'
	| 'dropdown'
	| 'note'
	| 'date'
	| 'address'
	| 'activity'
	| 'multiselect'
	| 'textarea'
	| 'money'
	| 'checkbox';

/** ngx-datatable `TableColumn` but with additional properties.
 * Should move this to be shared with other datatables.
 */
export type EnhancedTableColumn = TableColumn & {
	/** Metakey of the column. Used for indexing ui state and requesting changes */
	metakey: Metakey;
	/** Property key in rows. */
	prop: keyof Row;
	/** Replaces `ascFn` and `descFn`. Provides sortable values. */
	sortValueGetter: (
		field: FieldMetadata<any>,
		choices?: ViewDisplayValue[],
		row?: Row
	) => any;
	/** Dropdown choices used for fields in this column.
	 * To be filled up in Query file.
	 */
	choices?: ViewDisplayValue[];
	/** Dropdown choices used for fields in this column.
	 * To be filled up in Query file.
	 */
	choicesAsObject?: { [key: string]: string };
	/** Type of control.
	 * Determines which datatable field control to use.
	 * Carefully check that the type used is same here and in the template.
	 */
	controlType: controlType;
	/** indicator if column is required */
	isRequired?: boolean;
	isTwoDecimal?: boolean;
	columnId?: string;
	fieldId?: string;
};

/** Get `display` property from dropdownChoices */
const getDropdownValueFromChoices = (
	choices: ViewDisplayValue[],
	field: FieldMetadata<any>
) => {
	if (!field.value) {
		return '';
	}

	const choiceItem = choices?.find((x) => x.value === field.value);
	return R.propOr<string, ViewDisplayValue, string>(
		'',
		'display' as keyof FieldMetadata<any>,
		choiceItem
	);
};

/** Get index of value from choices or null if not found. */
const getDropdownOrder = (
	choices: ViewDisplayValue[],
	field: FieldMetadata<any>
) => {
	if (!field.value) {
		return '';
	}

	const choiceIndex = choices?.findIndex((x) => x.value === field.value);
	return choiceIndex > -1 ? choiceIndex : undefined;
};
/** Get multi value display */
const getMultiselectValues = (
	choices: ViewDisplayValue[],
	field: FieldMetadata<any>
) => {
	if (!field.value || field.value === '[]' || field.value.length === 0) {
		return '';
	}
	const values: string[] =
		typeof field.value === 'string'
			? Array.isArray(JSON.parse(field.value))
				? JSON.parse(field.value)
				: []
			: [];
	return values
		?.map((v) =>
			R.propOr(
				'',
				'display',
				choices?.find((c) => `${c.value}` === `${v}`)
			)
		)
		?.filter((x) => x)
		?.join(', ');
};

const statusClock = (field: FieldMetadata<any>) => {
	const arr = field.value?.split(' ');
	const timePeriod = arr?.pop();
	return timePeriod === 'year' || timePeriod === 'years'
		? +(arr[0] * 365)
		: R.complement(R.isEmpty)(field.value)
		? +arr[0]
		: '';
};

const contactStatus = (value: string) => {
	switch (value) {
		case 'L':
			return 'Lead';
		case 'C':
			return 'Client';
		case 'X':
			return 'Ex-Client';
	}
};
/** get sort value for checkboxes */
const getCheckboxSortValue = (field: FieldMetadata<any>) => {
	const isTrue: boolean =
		field.value === true ||
		(typeof field.value === typeof '' && field.value?.toLowerCase() === 'true');
	return R.defaultTo('', isTrue ? '1' : '2');
};
/** Manipulate date string to be year month date.
 * Used for sorting.
 */
const reformatDateString = (date: string) => {
	const b = R.defaultTo('', date)?.split(/\D/);
	return b?.reverse().join('/');
};

/* Splice date on last note field then format date */
const spliceDateAndReformat = (note: string) => {
	const splicedDate = R.defaultTo('', note)?.slice(0, 10);
	return reformatDateString(splicedDate);
};

/**
 * possible columns with their own configurations
 */
export const columns: EnhancedTableColumn[] = [
	{
		metakey: 'Name',
		prop: 'Name',
		name: 'Client Name',
		width: 200,
		headerClass: 'secondary-background',
		cellClass: 'font-weight-bold fixed-column',
		sortValueGetter: (f, c) => f.value,
		controlType: 'display',
		columnId: datatableUtil.formatColumnId('Name'),
		fieldId: datatableUtil.formatFieldId('Name'),
	},
	{
		metakey: 'Adviser',
		prop: 'Adviser',
		name: 'Adviser',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) =>
			getDropdownValueFromChoices(c, {
				...f,
				value: f.value ? f.value?.toString() : f.value,
			} as FieldMetadata<any>),
		controlType: 'dropdown',
		isRequired: true,
		columnId: datatableUtil.formatColumnId('Adviser'),
		fieldId: datatableUtil.formatFieldId('Adviser'),
	},
	// Adviser Rework	
	{
		metakey: 'FG Adviser',
		prop: 'FGAdviser',
		name: 'F&G Adviser',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) =>
			getDropdownValueFromChoices(c, {
				...f,
				value: f.value ? f.value?.toString() : f.value,
			} as FieldMetadata<any>),
		controlType: 'dropdown',
		isRequired: true,
		columnId: datatableUtil.formatColumnId('F&G Adviser'),
		fieldId: datatableUtil.formatFieldId('F&G Adviser'),
	},
	{
		metakey: 'Contact Status',
		prop: 'ContactStatus',
		name: 'Contact Status',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f) => f.value,
		controlType: 'display',
		columnId: datatableUtil.formatColumnId('Contact Status'),
		fieldId: datatableUtil.formatFieldId('Contact Status'),
	},
	{
		metakey: 'Policy Owners',
		prop: 'PolicyOwners',
		name: 'Policy Owners',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c, r) => {
			const isEmpty = util.isNullOrEmpty(f.value);
			const noChoices = util.isNullOrEmpty(r.PolicyOwnersList);
			if (isEmpty || noChoices) {
				return undefined;
			}

			const customChoices = R.uniq(
				r.PolicyOwnersList?.map((po) =>
					ViewDisplayValue.Map(po.Id?.toString(), po.Name)
				)
			);
			return getMultiselectValues(customChoices, f);
		},
		controlType: 'multiselect',
		columnId: datatableUtil.formatColumnId('Policy Owners'),
		fieldId: datatableUtil.formatFieldId('Policy Owners'),
	},
	{
		metakey: 'Premium',
		prop: 'Premium',
		name: 'Premium',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) =>
			f.value && +f.value !== 0 ? +f.value : undefined,
		controlType: 'money',
		isTwoDecimal: false,
		columnId: datatableUtil.formatColumnId('Premium'),
		fieldId: datatableUtil.formatFieldId('Premium'),
	},
	{
		metakey: 'Status',
		prop: 'Status',
		name: 'Status',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => getDropdownOrder(c, f),
		controlType: 'dropdown',
		isRequired: true,
		columnId: datatableUtil.formatColumnId('Status'),
		fieldId: datatableUtil.formatFieldId('Status'),
	},
	{
		metakey: 'Claim Status',
		prop: 'ClaimStatus',
		name: 'Claim Status',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => getDropdownOrder(c, f),
		controlType: 'dropdown',
		columnId: datatableUtil.formatColumnId('Claim Status'),
		fieldId: datatableUtil.formatFieldId('Claim Status'),
	},
	{
		metakey: 'Policy Lines',
		prop: 'PolicyLines',
		name: 'Policy Lines',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => getMultiselectValues(c, f),
		controlType: 'multiselect',
		columnId: datatableUtil.formatColumnId('Policy Lines'),
		fieldId: datatableUtil.formatFieldId('Policy Lines'),
	},
	{
		metakey: 'FG Policy Number',
		prop: 'FGPolicyNumber',
		name: 'Policy Number',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) =>
			f.value && +f.value !== 0 ? +f.value?.split('-').join('.') : undefined,
		controlType: 'textbox',
		columnId: datatableUtil.formatColumnId('FG Policy Number'),
		fieldId: datatableUtil.formatFieldId('FG Policy Number'),
	},
	{
		metakey: 'Broker Fee',
		prop: 'BrokerFee',
		name: 'Broker Fee',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) =>
			f.value && +f.value !== 0 ? +f.value : undefined,
		controlType: 'money',
		isTwoDecimal: false,
		columnId: datatableUtil.formatColumnId('Broker Fee'),
		fieldId: datatableUtil.formatFieldId('Broker Fee'),
	},
	{
		metakey: 'Admin Fee',
		prop: 'AdminFee',
		name: 'Admin Fee',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) =>
			f.value && +f.value !== 0 ? +f.value : undefined,
		controlType: 'money',
		isTwoDecimal: false,
		columnId: datatableUtil.formatColumnId('Admin Fee'),
		fieldId: datatableUtil.formatFieldId('Admin Fee'),
	},
	{
		metakey: 'Renewal date',
		prop: 'RenewalDate',
		name: 'Renewal Date',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'date',
		columnId: datatableUtil.formatColumnId('Renewal date'),
		fieldId: datatableUtil.formatFieldId('Renewal date'),
	},
	{
		metakey: 'Account Status',
		prop: 'AccountStatus',
		name: 'Account Status',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
		controlType: 'dropdown',
		columnId: datatableUtil.formatColumnId('Account Status'),
		fieldId: datatableUtil.formatFieldId('Account Status'),
	},
	{
		metakey: 'Insurer',
		prop: 'Insurer',
		name: 'Insurer',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
		controlType: 'dropdown',
		columnId: datatableUtil.formatColumnId('Insurer'),
		fieldId: datatableUtil.formatFieldId('Insurer'),
	},
	{
		metakey: 'Total Premium',
		prop: 'TotalPremium',
		name: 'Total Premium',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) =>
			f.value && +f.value !== 0 ? +f.value : undefined,
		controlType: 'money',
		columnId: datatableUtil.formatColumnId('Total Premium'),
		fieldId: datatableUtil.formatFieldId('Total Premium'),
	},
	{
		metakey: 'Issue date',
		prop: 'IssueDate',
		name: 'Issue Date',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'date',
		columnId: datatableUtil.formatColumnId('Issue date'),
		fieldId: datatableUtil.formatFieldId('Issue date'),
	},
	{
		metakey: 'Inception date',
		prop: 'InceptionDate',
		name: 'Inception Date',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'date',
		columnId: datatableUtil.formatColumnId('Inception date'),
		fieldId: datatableUtil.formatFieldId('Inception date'),
	},
	{
		metakey: 'Policy Type',
		prop: 'PolicyType',
		name: 'Policy Type',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
		controlType: 'dropdown',
		columnId: datatableUtil.formatColumnId('Policy Type'),
		fieldId: datatableUtil.formatFieldId('Policy Type'),
	},
	{
		metakey: 'Policy Writer',
		prop: 'PolicyWriter',
		name: 'Policy Writer',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
		controlType: 'dropdown',
		columnId: datatableUtil.formatColumnId('Policy Writer'),
		fieldId: datatableUtil.formatFieldId('Policy Writer'),
	},
	{
		metakey: 'Quote number',
		prop: 'QuoteNumber',
		name: 'Quote number',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'textbox',
		columnId: datatableUtil.formatColumnId('Quote number'),
		fieldId: datatableUtil.formatFieldId('Quote number'),
	},
	{
		metakey: 'Policy End Date',
		prop: 'PolicyEndDate',
		name: 'Policy End Date',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'date',
		columnId: datatableUtil.formatColumnId('Policy End Date'),
		fieldId: datatableUtil.formatFieldId('Policy End Date'),
	},
	{
		metakey: 'First Policy date',
		prop: 'FirstPolicyDate',
		name: 'First Policy date',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'date',
		columnId: datatableUtil.formatColumnId('First Policy date'),
		fieldId: datatableUtil.formatFieldId('First Policy date'),
	},
	{
		metakey: 'Original Adviser',
		prop: 'OriginalAdviser',
		name: 'Original Adviser',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) =>
			getDropdownValueFromChoices(c, {
				...f,
				value: f.value ? f.value?.toString() : f.value,
			} as FieldMetadata<any>),
		controlType: 'dropdown',
		columnId: datatableUtil.formatColumnId('Original Adviser'),
		fieldId: datatableUtil.formatFieldId('Original Adviser'),
	},
	{
		metakey: 'Required',
		prop: 'Required',
		name: 'Required',
		width: 300,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'textarea',
		columnId: datatableUtil.formatColumnId('Required'),
		fieldId: datatableUtil.formatFieldId('Required'),
	},
	{
		metakey: 'FG Status Clock',
		prop: 'StatusClock',
		name: 'Status Clock',
		width: 300,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => statusClock(f),
		controlType: 'display',
		columnId: datatableUtil.formatColumnId('FG Status Clock'),
		fieldId: datatableUtil.formatFieldId('FG Status Clock'),
	},
	{
		metakey: 'Client Next Activity',
		prop: 'ClientNextActivity',
		name: 'Client Next Activity',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => spliceDateAndReformat(f.value),
		controlType: 'activity',
		cellClass: 'activity',
		columnId: datatableUtil.formatColumnId('Client Next Activity'),
		fieldId: datatableUtil.formatFieldId('Client Next Activity'),
	},
	{
		metakey: 'User Next Activity',
		prop: 'UserNextActivity',
		name: 'Your Next Activity',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => spliceDateAndReformat(f.value),
		controlType: 'activity',
		cellClass: 'activity',
		columnId: datatableUtil.formatColumnId('User Next Activity'),
		fieldId: datatableUtil.formatFieldId('User Next Activity'),
	},
	{
		metakey: 'Last Note',
		prop: 'LastNote',
		name: 'Last Note',
		width: 300,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => spliceDateAndReformat(f.value),
		controlType: 'note',
		columnId: datatableUtil.formatColumnId('Last Note'),
		fieldId: datatableUtil.formatFieldId('Last Note'),
	},
	{
		metakey: 'Additional Contacts',
		prop: 'AdditionalContact',
		name: 'Additional Contacts',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'long-display',
		columnId: datatableUtil.formatColumnId('Additional Contacts'),
		fieldId: datatableUtil.formatFieldId('Additional Contacts'),
	},
	{
		metakey: 'Lead Origin',
		prop: 'LeadOrigin',
		name: 'Lead Origin',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => getDropdownValueFromChoices(c, f),
		controlType: 'dropdown',
		columnId: datatableUtil.formatColumnId('Lead Origin'),
		fieldId: datatableUtil.formatFieldId('Lead Origin'),
	},
  {
		metakey: 'Tracking',
		prop: 'Tracking',
		name: 'Tracking',
		width: 200,
		headerClass: 'secondary-background',
		sortValueGetter: (f, c) => f.value,
		controlType: 'display',
		columnId: datatableUtil.formatColumnId('Tracking'),
		fieldId: datatableUtil.formatFieldId('Tracking')
  }
];
/**
 * column configuration for link
 */
export const linkColumn: TableColumn & { prop: 'link' } = {
	prop: 'link',
	name: '',
	width: 18,
};
/**
 * template for Row
 */
export const templateRow: Row = {
	CustomerID: null,
	CustomerServiceID: null,
	ActivityId: null,
	PolicyOwnersList: null,
	ClientNextActivityId: null,
	UserNextActivityId: null,
	Name: {
		metakey: 'Name',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Name').prop,
		required: false,
		editable: false,
	},
	Adviser: {
		metakey: 'Adviser',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Adviser').prop,
		required: true,
		editable: true,
		isCustomerDetail: true,
		restrict: ['FEC'],
	},
	// Adviser Rework
	FGAdviser: {
		metakey: 'FG Adviser',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'FG Adviser').prop,
		required: true,
		editable: false,
		isCustomerDetail: true,
		restrict: ['FEC'],
	},
	ContactStatus: {
		metakey: 'Contact Type',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Contact Status').prop,
		required: false,
		editable: false,
	},
	PolicyOwners: {
		metakey: 'Policy Owners',
		value: null,
		choices: [],
		controlType: 'multiselect',
		id: null,
		key: columns?.find((x) => x.metakey === 'Policy Owners').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		hasToolTip: true,
		restrict: ['FES'],
	},
	Premium: {
		metakey: 'Premium',
		value: '0.0000',
		choices: [],
		controlType: 'money',
		id: null,
		key: columns?.find((x) => x.metakey === 'Premium').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	Status: {
		metakey: 'Status',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Status').prop,
		required: true,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	ClaimStatus: {
		metakey: 'Claim Status',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Claim Status').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	PolicyLines: {
		metakey: 'Policy Lines',
		value: null,
		choices: [],
		controlType: 'multiselect',
		id: null,
		key: columns?.find((x) => x.metakey === 'Policy Lines').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		hasToolTip: true,
		restrict: ['FES'],
	},
	FGPolicyNumber: {
		metakey: 'FG Policy Number',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'FG Policy Number').prop,
		required: false,
		editable: false,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	BrokerFee: {
		metakey: 'Broker Fee',
		value: '0.00',
		choices: [],
		controlType: 'money',
		id: null,
		key: columns?.find((x) => x.metakey === 'Broker Fee').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	AdminFee: {
		metakey: 'Admin Fee',
		value: '0.0000',
		choices: [],
		controlType: 'money',
		id: null,
		key: columns?.find((x) => x.metakey === 'Admin Fee').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	RenewalDate: {
		metakey: 'Renewal date',
		value: null,
		choices: [],
		controlType: 'date',
		id: null,
		key: columns?.find((x) => x.metakey === 'Renewal date').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	AccountStatus: {
		metakey: 'Account Status',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Account Status').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	Insurer: {
		metakey: 'Insurer',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Insurer').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	TotalPremium: {
		metakey: 'Total Premium',
		value: '0.0000',
		choices: [],
		controlType: 'money',
		id: null,
		key: columns?.find((x) => x.metakey === 'Total Premium').prop,
		required: false,
		editable: false,
		isCustomerDetail: false,
	},
	IssueDate: {
		metakey: 'Issue date',
		value: null,
		choices: [],
		controlType: 'date',
		id: null,
		key: columns?.find((x) => x.metakey === 'Issue date').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	InceptionDate: {
		metakey: 'Inception date',
		value: null,
		choices: [],
		controlType: 'date',
		id: null,
		key: columns?.find((x) => x.metakey === 'Inception date').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	PolicyType: {
		metakey: 'Policy Type',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Policy Type').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	PolicyWriter: {
		metakey: 'Policy Writer',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Policy Writer').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	QuoteNumber: {
		metakey: 'Quote number',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Quote number').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	PolicyEndDate: {
		metakey: 'Policy End Date',
		value: null,
		choices: [],
		controlType: 'date',
		id: null,
		key: columns?.find((x) => x.metakey === 'Policy End Date').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	FirstPolicyDate: {
		metakey: 'First Policy Date',
		value: null,
		choices: [],
		controlType: 'date',
		id: null,
		key: columns?.find((x) => x.metakey === 'First Policy date').prop,
		required: false,
		editable: false,
		isCustomerDetail: false,
		restrict: ['FES'],
	},
	Required: {
		metakey: 'Required',
		value: null,
		choices: [],
		controlType: 'textarea',
		id: null,
		key: columns?.find((x) => x.metakey === 'Required').prop,
		required: false,
		editable: true,
		isCustomerDetail: false,
		hasToolTip: true,
		restrict: ['FES'],
	},
	OriginalAdviser: {
		metakey: 'Original Adviser',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Original Adviser').prop,
		required: false,
		editable: false,
		isCustomerDetail: false,
		restrict: ['FEO'],
	},
	StatusClock: {
		metakey: 'FG Status Clock',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'FG Status Clock').prop,
		required: false,
		editable: false,
	},
	ClientNextActivity: {
		metakey: 'Client Next Activity',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Client Next Activity').prop,
		required: false,
		editable: false,
		hasToolTip: true,
		restrict: ['FECA'],
	},
	UserNextActivity: {
		metakey: 'User Next Activity',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'User Next Activity').prop,
		required: false,
		editable: false,
		hasToolTip: true,
		restrict: ['FECA'],
	},
	LastNote: {
		metakey: 'Last Note',
		value: null,
		choices: [],
		controlType: 'textarea',
		id: null,
		key: columns?.find((x) => x.metakey === 'Last Note').prop,
		required: false,
		editable: true,
		hasToolTip: true,
		restrict: ['FAN'],
	},
	AdditionalContact: {
		metakey: 'Additional Contacts',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Additional Contacts').prop,
		required: false,
		editable: false,
		hasToolTip: true,
	},
	LeadOrigin: {
		metakey: 'Lead Origin',
		value: null,
		choices: [],
		controlType: 'dropdown',
		id: null,
		key: columns?.find((x) => x.metakey === 'Lead Origin').prop,
		required: false,
		editable: true,
		isCustomerDetail: true,
		restrict: ['FEC'],
	},
	Tracking: {
		metakey: 'Tracking',
		value: null,
		choices: [],
		controlType: 'textbox',
		id: null,
		key: columns?.find((x) => x.metakey === 'Tracking').prop,
		required: false,
		editable: false
	},
	// Floating action buttons
	link: null,
};

export const createRowPrototypeWithChoices = (
	advisers: ViewDisplayValue[],
	nextActivtyType: ViewDisplayValue[],
	policyStatus: ViewDisplayValue[],
	policyType: ViewDisplayValue[],
	accountStatus: ViewDisplayValue[],
	insurer: ViewDisplayValue[],
	claimStatus: ViewDisplayValue[],
	policyLine: ViewDisplayValue[],
	policyWriter: ViewDisplayValue[],
	allAdvisers: ViewDisplayValue[]
) => {
	return produce(templateRow, (draft) => {
		draft.Adviser.choices = advisers;
		draft.ClientNextActivity.choices = nextActivtyType;
		draft.UserNextActivity.choices = nextActivtyType;
		draft.Status.choices = policyStatus;
		draft.PolicyType.choices = policyType;
		draft.AccountStatus.choices = accountStatus;
		draft.Insurer.choices = insurer;
		draft.ClaimStatus.choices = claimStatus;
		draft.PolicyLines.choices = policyLine;
		draft.PolicyWriter.choices = policyWriter;

		draft.OriginalAdviser.choices = allAdvisers;
	});
};

export const createRowFromPrototype = R.curry(
	(template: Row, row: FgInsurance) =>
		produce<Row>(template, (draft) => {
			draft.CustomerID = row.CustomerID;
			draft.CustomerServiceID = row.CustomerServiceID;
			draft.PolicyOwnersList = row.PolicyOwnersList;
			draft.ClientNextActivityId = row.ClientNextActivityId;
			draft.UserNextActivityId = row.UserNextActivityId;

			draft.Name.metakey = 'Name';
			draft.Name.value = row.Name;

			draft.Adviser.metakey = 'Adviser';
			draft.Adviser.value = row.Adviser;

			// Adviser Rework
			draft.FGAdviser.metakey = row.FGAdviser?.MetaKey || 'FG Adviser';
			draft.FGAdviser.value = row.FGAdviser;

			draft.ContactStatus.metakey = 'Contact Status';
			draft.ContactStatus.value = contactStatus(row.ContactStatus);

			draft.PolicyOwners.metakey = 'Policy Owners';
			draft.PolicyOwners.value = row.PolicyOwners;
			draft.PolicyOwners.choices = row.PolicyOwnersList?.map(
				(x: { Id: number; Name: string }) => ({
					display: x.Name,
					value: x.Id?.toString(),
				})
			) as ViewDisplayValue[];

			draft.Premium.metakey = 'Premium';
			draft.Premium.value = row.Premium
				? row.Premium === '0' || row.Premium === null
					? draft.Premium.value
					: row.Premium
				: draft.Premium.value;

			draft.Status.metakey = 'Status';
			draft.Status.value = row.Status;

			draft.ClaimStatus.metakey = 'Claim Status';
			draft.ClaimStatus.value = row.ClaimStatus;

			draft.PolicyLines.metakey = 'Policy Lines';
			draft.PolicyLines.value = row.PolicyLines;

			draft.FGPolicyNumber.metakey = 'FG Policy Number';
			draft.FGPolicyNumber.value = row.FGPolicyNumber;

			draft.BrokerFee.metakey = 'Broker Fee';
			draft.BrokerFee.value = row.BrokerFee
				? row.BrokerFee === '0' || row.BrokerFee === null
					? draft.BrokerFee.value
					: row.BrokerFee
				: draft.BrokerFee.value;

			draft.AdminFee.metakey = 'Admin Fee';
			draft.AdminFee.value = row.AdminFee
				? row.AdminFee === '0' || row.AdminFee === null
					? draft.AdminFee.value
					: row.AdminFee
				: draft.AdminFee.value;

			draft.RenewalDate.metakey = 'Renewal date';
			draft.RenewalDate.value = row.RenewalDate;

			draft.AccountStatus.metakey = 'Account Status';
			draft.AccountStatus.value = row.AccountStatus;

			draft.Insurer.metakey = 'Insurer';
			draft.Insurer.value = row.Insurer;

			draft.TotalPremium.metakey = 'Total Premium';
			draft.TotalPremium.value = row.TotalPremium
				? row.TotalPremium === '0' || row.TotalPremium === null
					? draft.TotalPremium.value
					: row.TotalPremium
				: draft.TotalPremium.value;

			draft.IssueDate.metakey = 'Issue date';
			draft.IssueDate.value = row.IssueDate;

			draft.InceptionDate.metakey = 'Inception date';
			draft.InceptionDate.value = row.InceptionDate;

			draft.PolicyType.metakey = 'Policy Type';
			draft.PolicyType.value = row.PolicyType;

			draft.PolicyWriter.metakey = 'Policy Writer';
			draft.PolicyWriter.value = row.PolicyWriter;

			draft.QuoteNumber.metakey = 'Quote number';
			draft.QuoteNumber.value = row.QuoteNumber;

			draft.PolicyEndDate.metakey = 'Policy End Date';
			draft.PolicyEndDate.value = row.PolicyEndDate;

			draft.FirstPolicyDate.metakey = 'First Policy date';
			draft.FirstPolicyDate.value = row.FirstPolicyDate;

			draft.OriginalAdviser.metakey = 'Original Adviser';
			draft.OriginalAdviser.value = row.OriginalAdviser;
			draft.OriginalAdviser.choices = draft.OriginalAdviser.choices?.filter(
				(x) =>
					x.value === row.OriginalAdviser ||
					draft.Adviser.choices?.find((y) => y.value === x.value)
			);

			draft.Required.metakey = 'Required';
			draft.Required.value = row.Required;

			draft.StatusClock.metakey = 'FG Status Clock';
			draft.StatusClock.value = computeUtil.calcStatusClock(row.StatusClock);

			draft.ClientNextActivity.metakey = 'Client Next Activity';
			draft.ClientNextActivity.value = row.ClientNextActivity;

			draft.UserNextActivity.metakey = 'User Next Activity';
			draft.UserNextActivity.value = row.UserNextActivity;

			draft.LastNote.metakey = 'Last Note';
			draft.LastNote.value = row.LastNote;

			draft.AdditionalContact.metakey = 'Additional Contacts';
			draft.AdditionalContact.value = row.AdditionalContact;

			draft.LeadOrigin.metakey = 'Lead Origin';
			draft.LeadOrigin.value = row.LeadOrigin;

			draft.Tracking.metakey = 'Tracking';
			draft.Tracking.value = row.Tracking;

			draft.link = {
				CustomerId: row.CustomerID,
				CustomerServiceId: row.CustomerServiceID,
				IsCompany: row.IsCompany,
			};
		})
);
