<div class="container-fluid">
	<div class="row align-items-center">
		<div class="col-auto pr-lg-0 plus-icon">
			<div
				class="custom-sidebar-nav__divider-header d-flex align-items-center py-2"
			>
				<div class="col px-0 form-inline">
					<div class="font-weight-bold px-0 sidebar__title"></div>
					<!-- Add New Group -->
					<ng-container *ngIf="!isLoading">
						<ng-container *appRestrictTo="['FAAP']">
							<button
								type="button"
								class="add-button-sidenav border-0 font-weight-bold md-20 tap-text-color-h6"
								(click)="addNewAdviceProcess()"
							>
								<i class="material-icons add-button-sidenav-plus">add</i>
							</button>
						</ng-container>
					</ng-container>
				</div>
			</div>
		</div>

		<div class="col"></div>

		<div class="col-auto ml-auto">
			<div class="row align-items-center">
				<!-- <ng-container *ngIf="isLoading">
					<div class="d-flex flex-row bd-highlight justify-content-end pt-2">
						<app-manual-loader-mini
							[isLoading]="isLoading"
						></app-manual-loader-mini>
					</div>
				</ng-container> -->
				<ng-container *ngIf="!isLoading">
					<ng-container *appRestrictTo="['FEAP']">
						<ng-container *ngIf="activeAdviceProcess?.status !== 6">
							<ng-container
								*ngIf="
									!!activeAdviceProcess &&
									(activeAdviceProcess?.status < 3 ||
										activeAdviceProcess?.status > 4)
								"
							>
								<ng-container *ngIf="canEndProcess||displayCancelProcess">
									<div
										class="d-flex flex-row bd-highlight justify-content-end px-2"
										[tooltip]="disableEndProcess ? tooltipTemplate : null"
										container="body"
										placement="left"
									>
										<ng-container *ngIf="isClaim; else nonClaimTemplate">
											<ng-container
												*ngIf="
												activeAdviceProcess?.status === adviceProcessStatus.complete ||
												activeAdviceProcess?.status === adviceProcessStatus.incomplete
												; else openClaimTemplate"
											>
												<button
													(click)="reopenAdviceProcessClaim()"
													class="tap-btn tap-btn--default"
													[ngClass]="{ 'btn-disabled': disableEndProcess }"
													[disabled]=" isEdit || disableEndProcess"
												>
													Reopen
												</button>
											</ng-container>
											<ng-template #openClaimTemplate>
												<button
													(click)="cancelAdviceProcessClaim()"
													class="tap-btn tap-btn--default"
													[ngClass]="{ 'btn-disabled': disableEndProcess }"
													[disabled]=" isEdit || disableEndProcess || isClaimClosing"
												>
													Close
													<i
														*ngIf="isClaimClosing"
														class="fas fa-spinner fa-pulse"
													></i>
												</button>
											</ng-template>
										</ng-container>
										<ng-template #nonClaimTemplate>
											<button
												*ngIf="displayCancelProcess"
												(click)="cancelAdviceProcess()"
												class="tap-btn tap-btn--default"
												[ngClass]="{ 'btn-disabled': disableEndProcess }"
												[disabled]=" isEdit || disableEndProcess"
											>
												Cancel
											</button>
											<button
												*ngIf="!displayCancelProcess"
												(click)="endProcess()"
												class="tap-btn tap-btn--default"
												[ngClass]="{ 'btn-disabled': disableEndProcess }"
												[disabled]="isEndProcessLoading || isEdit || disableEndProcess"
											>
												End Process
												<i
													*ngIf="isEndProcessLoading"
													class="fas fa-spinner fa-pulse"
												></i>
											</button>
										</ng-template>
									</div>
								</ng-container>
							</ng-container>
							<ng-container
								*ngIf="
									!!activeAdviceProcess &&
									activeAdviceProcess?.status > 2 &&
									activeAdviceProcess?.status < 5
								"
							>
								<div
									class="d-flex flex-row bd-highlight justify-content-end px-2"
								>
									<button
										(click)="confirmReopen()"
										class="tap-btn tap-btn--default"
										[disabled]="isReopenLoading"
									>
										Reopen
										<i
											*ngIf="isReopenLoading"
											class="fas fa-spinner fa-pulse"
										></i>
									</button>
								</div>
							</ng-container>
						</ng-container>
					</ng-container>
				</ng-container>
			</div>
		</div>
	</div>
	<div class="row main-content">
		<div class="col">
			<ng-container *ngIf="isLoading">
				<app-manual-loader
					[isLoading]="isLoading"
					[opts]="{
						lines: 9,
						length: 27,
						width: 20,
						radius: 45,
						scale: 0.5
					}"
				></app-manual-loader>
			</ng-container>
			<ng-container *ngIf="!isLoading">
				<tabset #sidebarNav class="tap-tabset-sidebar" [vertical]="true">
					<ng-template
						ngFor
						let-ap
						[ngForOf]="adviceProcesses"
						let-formId="index"
						[ngForTrackBy]="trackbyFn"
					>
						<ng-container
							*ngIf="
							ap.status !== 0 ||
							ap.status !== 2 ||
							(this.claimsFeature &&
								(ap?.processCode === processCodes?.FGClaim ||
									ap?.processCode === processCodes?.LRClaim))
						"
						>
							<tab
								[id]="ap.adviceProcessID"
								(selectTab)="selectTab(ap.adviceProcessID)"
								[customClass]="
									ap.status === 3
										? 'nav-item--success'
										: ap.status === 4 || ap.status === 6
										? ''
										: 'nav-item--orange'
								"
							>
								<ng-template tabHeading>
									<strong>{{ adviceProccessName(ap.processCode) }}</strong
									><br />
									<span class="font-italic"
										>Started: {{ ap.startDate | momentDatetime }}</span
									><br />

									<ng-container
										*ngIf="
											ap?.processCode === processCodes?.FGClaim ||
												ap?.processCode === processCodes?.LRClaim;
											else showStageStatus
										"
									>
										<span>{{ ap?.provider || "" }}</span>
									</ng-container>

									<ng-template #showStageStatus>
										<span>
											{{ ap.status !== 6 ? adviceProcessStage(ap.stages) :
											"Cancelled" }}
										</span>
									</ng-template>

									<i
										class="material-icons arrow-right md-20 d-flex align-items-center"
										>chevron_right</i
									>
								</ng-template>
								<app-advice-process-form
									#apForm
									[isActiveAdviceProcess]="activeAdviceProcess.adviceProcessID === ap.adviceProcessID"
									[addMode]="false"
									[isEdit]="false"
									[isLoading]="isLoading"
									[adviceProcess]="ap"
									[advisers]="advisers"
									[reviewedBy]="reviewedBy"
									[allAdviserChoices]="allAdviserChoices"
									[allStaffChoices]="allStaffChoices"
									[clientsInvolved]="clientsInvolved"
									[clientsInvolvedRaw]="clientsInvolvedRaw"
									[updateFn$]="updateAdviceProcessFn$"
									[deleteFn$]="deleteAdviceProcessFn$"
									[getDocument$]="getDocument$"
									[uploadDocument$]="uploadDocument$"
									[downloadDocument$]="downloadDocument$"
									[deleteDocument$]="deleteDocument$"
									[getQuestionnaires$]="getQuestionnaires$"
									[getBusinessQuestionnaires$]="getBusinessQuestionnaires$"
									[formId]="ap.adviceProcessID"
									[types]="types"
									[isLead]="isLead"
									[customerID]="customerID"
									[customerName]="customerName"
									[adviser]="adviser"
									[activityType$]="activityType$"
									[adviserChoices$]="adviserChoices$"
									[adviserCalendarChoices$]="adviserCalendarChoices$"
									[addActivityFn$]="addActivityFn$"
									[getAdviceProcessNotes$]="getAdviceProcessNotes$"
									[addAdviceProcessNotes$]="addAdviceProcessNotes$"
									[deleteAdviceProcessNotes$]="deleteAdviceProcessNotes$"
									[document]="document"
									[leadOriginChoice]="leadOriginChoice"
									[leadTypeChoice]="leadTypeChoice"
									[trustTypes]="trustTypes"
									[hasQuickAdd]="hasQuickAdd"
									[getTimeline$]="getTimeline$"
									[getSCI$]="getSCI$"
									[getSCT$]="getSCT$"
									(editFn)="addEditingList($event)"
									(cancelFn)="removeFromEditingList($event)"
									(prePopulateFn)="prePopulate($event)"
									[apChoices$]="apChoices$"
									[apcrtasChoices$]="apcrtasChoices$"
									[apcrtatChoices$]="apcrtatChoices$"
									[apcrtctChoices$]="apcrtctChoices$"
									[apcrtcoChoices$]="apcrtcoChoices$"
									[apcrtqi1Choices$]="apcrtqi1Choices$"
									[apcrtqclr7Choices$]="apcrtqclr7Choices$"
									[apcrtqi6Choices$]="apcrtqi6Choices$"
									[apcrtrlChoices$]="apcrtrlChoices$"
									[apcrtfccChoices$]="apcrtfccChoices$"
									[apcrtynnaChoices$]="apcrtynnaChoices$"
									[apcrtqik6Choices$]="apcrtqik6Choices$"
									[apcrtqik15Choices$]="apcrtqik15Choices$"
									[carqicar1$]="carqicar1$"
									[hasCRT]="hasCRT"
									[hasMOAT]="hasMOAT"
									[hasKOAT]="hasKOAT"
									[hasLOATV2]="hasLOATV2"
									[hasOfflineCRT]="hasOfflineCRT"
									[getAdviceProcessCRT$]="getAdviceProcessCRT$"
									[getSoa$]="getSoa$"
									[lrProvider]="lrProvider"
									[mProvider]="mProvider"
									[kProvider]="kProvider"
									[fgProvider]="fgProvider"
									[isCompany]="isCompany"
									[claimsFeature]="claimsFeature"
									[lrProducts]="lrProducts"
									[fgPolicyLines]="fgPolicyLines"
									[claimOutcome]="claimOutcome"
									(goToPolicy)="goToPolicyEvent($event)"
								>
								</app-advice-process-form>
							</tab>
						</ng-container>
					</ng-template>
				</tabset>
			</ng-container>
		</div>
	</div>
</div>

<ng-template #tooltipTemplate>
	<div [innerHtml]="tooltipLabel"></div>
</ng-template>
