<tabset
	#businessProfileTabset
	class="tap-tabset tap-tabset--border-radius-left-right tap-client-profile__tabset"
>
	<tab
		*ngIf="true"
		heading="Shareholders & Directors"
		id="shareholdersAndDirectors"
		[customClass]="'m-0'"
		(selectTab)="tabSelected($event)"
	>
		<app-shareholders-directors-tab
			[isLead]="isLead"
			[addMode]="addMode"
			[primaryCompany]="primaryCompany"
			[leadGens]="leadGenChoices$ | async"
			[leadTypes]="leadTypeChoices$ | async"
			[leadOrigins]="leadOriginChoices$ | async"
			[adviserGenChoices]="adviserGenChoices$ | async"
			[allStaffChoices]="allStaffChoices$ | async"
		>
		</app-shareholders-directors-tab>
	</tab>
	<tab
		*ngIf="(hasAP$ | async) && (hasPermission$(['FAP']) | async)"
		heading="Advice Process"
		id="ap"
		[customClass]="'m-0'"
		(selectTab)="tabSelected($event)"
	>
		<app-advice-process
			[customerID]="customerID$ | async"
			[adviceProcesses]="adviceProcesses$ | async"
			[advisers]="activeAdviserChoices$ | async"
			[allStaffChoices]="allStaffChoices$ | async"
			[reviewedBy]="reviewedBy$ | async"
			[allAdviserChoices]="allAdviserChoices$ | async"
			[clientsInvolved]="clientsInvolved$ | async"
			[clientsInvolvedRaw]="clientsInvolvedRaw$ | async"
			[addNewAdviceProcess$]="addNewAdviceProcess$"
			[updateAdviceProcess$]="updateAdviceProcess$"
			[deleteAdviceProcess$]="deleteAdviceProcess$"
			[getQuestionnaires$]="getAdviceProcessQuestionnaires$"
			[getBusinessQuestionnaires$]="getAdviceProcessQuestionnairesBusiness$"
			[getDocument$]="getDocument$"
			[uploadDocument$]="uploadDocument$"
			[downloadDocument$]="downloadDocument$"
			[deleteDocument$]="deleteDocument$"
			[isLead]="isLead"
			[customerName]="companyName$ | async"
			[adviser]="adviser$ | async"
			[activityType$]="activityType$"
			[activityMeeting$]="activityMeeting$"
			[adviserChoices$]="activityAdviserChoices$"
			[adviserCalendarChoices$]="adviserCalendarChoices$"
			[addActivityFn$]="addActivity$"
			[getAdviceProcessNotes$]="getAdviceProcessNotes$"
			[addAdviceProcessNotes$]="addAdviceProcessNotes$"
			[deleteAdviceProcessNotes$]="deleteAdviceProcessNotes$"
			[document]="Document$ | async"
			[isLoading]="adviceProcessIsLoading$ | async"
			[hasQuickAdd]="false"
			[apChoices$]="apChoices$ | async"
			[apcrtasChoices$]="apcrtasChoices$ | async"
			[apcrtatChoices$]="apcrtatChoices$"
			[apcrtctChoices$]="apcrtctChoices$ | async"
			[apcrtcoChoices$]="apcrtcoChoices$ | async"
			[apcrtqi1Choices$]="apcrtqi1Choices$"
			[apcrtqi6Choices$]="apcrtqi6Choices$"
			[apcrtrlChoices$]="apcrtrlChoices$"
			[apcrtfccChoices$]="apcrtfccChoices$"
			[apcrtynnaChoices$]="apcrtynnaChoices$"
			[apcrtqclr7Choices$]="apcrtqclr7Choices$"
			[apcrtqik6Choices$]="apcrtqik6Choices$"
			[carqicar1$]="carqicar1$"
			[apcrtqik15Choices$]="apcrtqik15Choices$"
			[hasOfflineCRT]="hasAP$"
			[hasCRT]="hasCRT$ | async"
			[hasMOAT]="hasMOAT$ | async"
			[hasKOAT]="hasKOAT$ | async"
			[isCompany]="isCompany"
			[lrProvider]="LRProviders$ | async"
			[mProvider]="Mproviders$ | async"
			[kProvider]="KProvider$ | async"
			[fgProvider]="FGproviders$ | async"
			[cancelAdviceProcess$]="cancelAdviceProcess$"
			[closeAdviceProcess$]="closeAdviceProcess$"
			[businessConfig]="businessConfig$ | async"
			[primaryCompany]="primaryCompany"
			[lrInsurance]="lrInsurance$ | async"
			[fgInsurance]="fgInsurance$ | async"
			[claimsFeature]="claimsFeature$ | async"
			[lrProducts]="LRProducts$ | async"
			[fgPolicyLines]="FGpolicyLines$ | async"
			[claimOutcome]="claimOutcome$ | async"
			(goToPolicy)="goToPolicy($event)"
		>
		</app-advice-process>
	</tab>
	<tab
		*ngIf="hasLR$ | async"
		heading="L&R Insurance"
		id="lr"
		[customClass]="'m-0'"
		(selectTab)="tabSelected($event)"
	>
		<app-lr-insurance-service
			[isCompany]="true"
			[isLead]="isLead"
			[primaryCompany]="primaryCompany"
			[downloadLink$]="downloadLink$"
			[customerId]="customerID$ | async"
			[lrInsurance]="lrInsurance$ | async"
			[providers]="LRProviders$ | async"
			[policyStatuses]="LRPolicyStatus$ | async"
			[products]="LRProducts$ | async"
			[policyTypes]="LRPolicyTypes$ | async"
			[claimStatus]="LRClaimStatus$ | async"
			[discounts]="LRDiscounts$ | async"
			[payments]="LRPayments$ | async"
			[cancellationCodes]="LRCancellationCodes$ | async"
			[retentionStatus]="LRPolicyRetentionStatus$ | async"
			[policyOwners]="LRPolicyOwners$ | async"
			[document]="Document$ | async"
			[addFn$]="createLRInsuranceFn$"
			[saveFn$]="updateLRInsuranceFn$"
			[deleteFn$]="deleteLRInsuranceFn$"
			[archiveFn$]="archiveLRInsuranceFn$"
			[deleteNoteFn$]="deleteLRInsuranceNoteFn$"
			[updateDocumentFn$]="updateLRDocumentFn$"
			[updateNextReviewFn$]="updateLRNextReviewFn$"
			[isLoading]="lrIsLoading$ | async"
			[documentIsLoading]="documentIsLoading$ | async"
			[addNoteFn$]="addNoteLRFn$"
			[adviser]="adviser$ | async"
			[claimsFeature]="claimsFeature$ | async"
			[lrClaimsList] = "lrClaimsList$ | async"
			[redirectToAP$]="redirectToTab$" 
			[openAPModalFn$]="openAPModalFn$"
		>
		</app-lr-insurance-service>
	</tab>
	<!-- Mortgage Insurance Tab -->
	<tab
		*ngIf="hasM$ | async"
		heading="Mortgages"
		id="m"
		[customClass]="'m-0'"
		(selectTab)="tabSelected($event)"
	>
		<app-mortgage-service
			[isLead]="isLead"
			[primaryCompany]="primaryCompany"
			[isCompany]="true"
			[customerId]="customerID$ | async"
			[mortgage]="mortgage$ | async"
			[borrowingEntities]="MborrowingEntities$ | async"
			[securities]="Msecurities$ | async"
			[status]="Mstatus$ | async"
			[mortgageTypes]="MmortgageTypes$ | async"
			[loanTypes]="MloanType$ | async"
			[fixedPeriods]="MfixedPeriods$ | async"
			[providers]="Mproviders$ | async"
			[repaymentFrequency]="MrepaymentFrequency$ | async"
			[refixStatus]="MrefixStatus$ | async"
			[document]="Document$ | async"
			[addFn$]="createMortgageFn$"
			[saveFn$]="saveMortgageFn$"
			[deleteFn$]="deleteMortgageFn$"
			[archiveFn$]="archiveMortgageFn$"
			[deleteNoteFn$]="deleteMortgageNoteFn$"
			[updateDocumentFn$]="updateMortgageDocumentFn$"
			[updateNextReviewFn$]="updateMortgageNextReviewFn$"
			[isLoading]="mIsLoading$ | async"
			[addNoteFn$]="addNoteMortgageFn$"
		>
		</app-mortgage-service>
	</tab>
	<!-- Property & Assets (Security) Tab -->
	<tab
		*ngIf="hasM$ | async"
		heading="Assets & Liabilities"
		id="propertyAndAssets"
		[customClass]="'m-0'"
		(selectTab)="tabSelected($event)"
	>
		<app-property-assets-service
			[isActive]="activeTabId === 'propertyAndAssets'"
			[isLead]="isLead"
			[isCompany]="true"
			[customerId]="customerID$ | async"
			[propertyAsset]="propertyAsset$ | async"
			[assetState]="assets$ | async"
			[liabilityState]="liabilities$ | async"
			[propertyOwners]="PApropertyOwners$ | async"
			[valuations1]="PAvaluations1$ | async"
			[valuations2]="PAvaluations2$ | async"
			[types]="PAtypes$ | async"
			[uses]="PAuses$ | async"
			[titles]="PAtitles$ | async"
			[status]="PAStatus$ | async"
			[liabilities]="PALiabilities$ | async"
			[liabilityStatus]="PALiabilityStatus$ | async"
			[loanTypes]="MloanType$ | async"
			[lenders]="Mproviders$ | async"
			[repaymentFrequency]="APCRTF$ | async"
			[addFn$]="createPropertyAssetFn$"
			[saveFn$]="savePropertyAssetFn$"
			[deleteFn$]="deletePropertyAssetFn$"
			[archiveFn$]="archivePropertyAssetFn$"
			[deleteNoteFn$]="deletePropertyAssetNoteFn$"
			[isLoading$]="isLoading$"
			[addNoteFn$]="addNoteSecurityFn$"
			[paDropTypes]="PADropTypes$ | async"
			[addNoteAssetFn$]="addNoteAssetFn$"
			[deleteNoteAssetFn$]="deleteNoteAssetFn$"
			[assetOwners]="PAassetOwners$ | async"
			[addAssetFn$]="createAssetFn$"
			[saveAssetFn$]="saveAssetFn$"
			[deleteAssetFn$]="deleteAssetFn$"
			[soldAssetFn$]="soldAssetFn$"
			[addNoteLiabilityFn$]="addNoteLiabilityFn$"
			[deleteNoteLiabilityFn$]="deleteNoteLiabilityFn$"
			[saveLiabilityFn$]="addLiabilityFn$"
			[updateLiabilityFn$]="updateLiabilityFn$"
			[deleteLiabilityFn$]="deleteLiabilityFn$"
		>
		</app-property-assets-service>
	</tab>
	<!-- F&G Insurance Tab -->
	<tab
		*ngIf="hasFG$ | async"
		heading="F&G Insurance"
		id="fg"
		[customClass]="'m-0'"
		(selectTab)="tabSelected($event)"
	>
		<app-fg-insurance-service
			[isLead]="isLead"
			[primaryCompany]="primaryCompany"
			[isCompany]="true"
			[downloadLink$]="downloadLink$"
			[customerId]="customerID$ | async"
			[fgInsurance]="fgInsurance$ | async"
			[policyOwners]="FGpolicyOwners$ | async"
			[policyLines]="FGpolicyLines$ | async"
			[insurers]="FGinsurers$ | async"
			[status]="FGstatus$ | async"
			[claimStatus]="FGclaimStatus$ | async"
			[paymentMethods]="FGpaymentMethods$ | async"
			[frequencies]="FGfrequencies$ | async"
			[accountStatus]="FGaccountStatus$ | async"
			[policyTypes]="FGpolicyTypes$ | async"
			[policyWriters]="FGpolicyWriters$ | async"
			[document]="Document$ | async"
			[addFn$]="createFGInsuranceFn$"
			[saveFn$]="saveFGInsuranceFn$"
			[deleteFn$]="deleteFGInsuranceFn$"
			[archiveFn$]="archiveFGInsuranceFn$"
			[deleteNoteFn$]="deleteFGInsuranceNoteFn$"
			[updateDocumentFn$]="updateFGDocumentFn$"
			[updateNextReviewFn$]="updateFGNextReviewFn$"
			[isLoading]="fgIsLoading$ | async"
			[documentIsLoading]="documentIsLoading$ | async"
			[addNoteFn$]="addNoteFGFn$"
			[adviser]="adviser$ | async"
			[claimsFeature]="claimsFeature$ | async"
			[fGClaimsList] = "fGClaimsList$ | async"
			[redirectToAP$]="redirectToTab$"
			[openAPModalFn$]="openAPModalFn$"
		>
		</app-fg-insurance-service>
	</tab>
	<!-- KiwiSaver/Investment Insurance Tab -->
	<tab
		*ngIf="(hasK$ | async) || (hasI$ | async)"
		heading="Investment"
		id="k"
		[customClass]="'m-0'"
		(selectTab)="tabSelected($event)"
	>
		<ng-container *ngIf="(isKsTabLoading$ | async) else showKSTab">
			<span class="p-2">
				<app-manual-loader-mini [isLoading]="true"></app-manual-loader-mini>
			</span>
		</ng-container>
		<ng-template #showKSTab>
			<app-kiwisaver-service
				[isLead]="isLead"
				[primaryCompany]="primaryCompany"
				[isCompany]="true"
				[customerId]="customerID$ | async"
				[fundTypes]="KFundType$ | async"
				[kstatus]="KStatus$ | async"
				[istatus]="IStatus$ | async"
				[investmentTypes]="ITypes$ | async"
				[kPIRRates]="kPIRRates$ | async"
				[iPIRRates]="iPIRRates$ | async"
				[origins]="KOrigin$ | async"
				[providers]="KProvider$ | async"
				[iProviders]="IProvider$ | async"
				[fundOwners]="fundOwners$ | async"
				[propertyOwners]="PApropertyOwners$ | async"
				[types]="Types$ | async"
				[kiwiSavers]="kiwisavers$ | async"
				[investments]="investments$ | async"
				[document]="Document$ | async"
				[hasK]="hasK$ | async"
				[hasI]="hasI$ | async"
				[addKsFn$]="createKiwisaverFn$"
				[saveKsFn$]="saveKiwisaverFn$"
				[deleteKsFn$]="deleteKiwisaverFn$"
				[archiveKsFn$]="archiveKiwisaverFn$"
				[deleteNoteKsFn$]="deleteKiwisaverNoteFn$"
				[updateDocumentKsFn$]="updateKiwisaverDocumentFn$"
				[addNoteKsFn$]="addNoteKiwiSaverFn$"
				[addInvFn$]="createInvestmentFn$"
				[saveInvFn$]="saveInvestmentFn$"
				[deleteInvFn$]="deleteInvestmentFn$"
				[archiveInvFn$]="archiveInvestmentFn$"
				[deleteNoteInvFn$]="deleteInvestmentNoteFn$"
				[updateDocumentInvFn$]="updateInvestmentDocumentFn$"
				[addNoteInvFn$]="addNoteInvestmentFn$"
				[downloadLink$]="downloadLink$"
				[isLoading]="kIsLoading$ | async"
				[isCompany]="isCompany"
				[documents$]="Document$"
				[documentIsLoading$]="documentIsLoading$"
				[updateNextReviewFn$]="updateInvestmentKSNextReviewFn$"
				[kretentionStatus]="KRetentionStatus$ | async"
				[iretentionStatus]="iRetentionStatus$ | async"
			>
			</app-kiwisaver-service>
		</ng-template>
	</tab>
	<!-- Edit history Tab -->
	<tab
		*appRestrictTo="['FEH']"
		heading="Edit History"
		id="editHistory"
		[customClass]="'m-0'"
		(selectTab)="tabSelected($event)"
	>
		<div class="ml-3">
			<app-edit-history
				[isLead]="isLead"
				[notes]="Histories$ | async"
				[deleteFn$]="deleteHistoryFn$"
				[isLoading]="historyIsLoading$ | async"
			>
			</app-edit-history>
		</div>
	</tab>
	<!-- Documents -->
	<tab
		*ngIf="true"
		heading="Documents"
		id="documentEdit"
		[customClass]="'m-0'"
		(selectTab)="tabSelected($event)"
	>
		<app-edit-document
			[isLead]="isLead"
			[customerId]="customerID$ | async"
			[document]="Document$ | async"
			[transferDocFn$]="transferDocFn$"
			[uploadDocFn$]="uploadDocFn$"
			[downloadLink$]="downloadLink$"
			[deleteDocFn$]="deleteDocFn$"
			[hasLR]="hasLR$ | async"
			[hasM]="hasM$ | async"
			[hasFG]="hasFG$ | async"
			[hasK]="hasK$ | async"
			[hasI]="hasI$ | async"
			[hasAP]="hasAP$ | async"
			[hasPermission]="hasPermission$(['FAP']) | async"
			[isLoading]="documentIsLoading$ | async"
		>
		</app-edit-document>
	</tab>
</tabset>
